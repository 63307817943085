import React from 'react';
import { Card } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { shippingConsoleUrl } from '../../../utils/environmentProvider';
import { TrackedLink } from '../../../trackingLayer/trackedLink';

export const ShipmentsWidget: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card header={t('dashboard.shipmentWidget.panelName')} isMinimal={true}>
      <div className={'toolbox'}>
        <div>
          <TrackedLink
            item={'dashboard.shipmentWidget.link'}
            href={`${shippingConsoleUrl}/#/ship`}
          >
            {t('dashboard.shipmentWidget.viewCreateShipments')}
          </TrackedLink>
        </div>
      </div>
    </Card>
  );
};
