import React from 'react';
import { renderKeyValue } from './renderKeyValue';
import IconNavigationNext from '@cimpress-technology/react-streamline-icons/lib/IconNavigationNext';
import { colors } from '@cimpress/react-components';
import { TrackedLink } from '../../trackingLayer/trackedLink';
import { renderAddressFields } from './deliveryAddress';

export const renderShipments = (t, shipments, dateFormatterFunc) => {
  return shipments?.length
    ? <>
      {shipments.map((s, i) => <div key={s.shipmentId} className={'shipmentBox'}>
        {renderKeyValue(`#${shipments.length - i}`, <>&nbsp;</>)}
        {renderKeyValue(t('shipping.shipDate'), dateFormatterFunc(s.created))}
        {renderKeyValue(t('shipping.pickupdateTime'), s.pickupDateTime)}
        {renderKeyValue(t('shipping.shipping_quantity'), s.quantity)}
        {renderKeyValue(t('shipping.carrierService'), s.carrier)}
        {(s.tracking || []).map(track => <div key={track.trackingId}>
          {renderKeyValue(t('shipping.trackingNumber'), track.trackingUrl ? <TrackedLink item={'shipments.link.trackingUrl'}
            target={'_blank'}
            href={track.trackingUrl}>
            {track.trackingId}&nbsp;<IconNavigationNext color={colors.info.base} style={{ verticalAlign: 'middle' }} size={'1x'}/>
          </TrackedLink> : track.trackingId)}
        </div>)}
        {s.destinationAddress
          ? <div className={'shipment-address'}>
            {renderAddressFields(t, s.destinationAddress)}
          </div>
          : null}
      </div>)}
    </ >
    : <span>{t('details.noShipments')}</span>;
};
