import React, { useState } from 'react';
import { colors, Tooltip } from '@cimpress/react-components';
import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import IconCheck from '@cimpress-technology/react-streamline-icons/lib/IconCheck';
import { useTranslation } from 'react-i18next';

interface CopyToClipboardIconProps {
  toCopy: string;
}

export const CopyToClipboardIcon: React.FC<CopyToClipboardIconProps> = ({ toCopy }) => {
  const { t } = useTranslation();
  const [iconState, setIconState] = useState({
    iconWeight: 'regular',
    iconColor: colors.ocean.base
  });

  const copyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = toCopy;

    try {
      document.body.appendChild(textArea);
      textArea.select();
      const successful = document.execCommand('copy');
      setTimeout(() => {
        setIconState({
          iconWeight: 'fill',
          iconColor: successful ? colors.success.darkest : colors.danger.darkest
        });
      }, 100);
      document.body.removeChild(textArea);
    } catch (err) {
      //
    }

    setTimeout(() => {
      setIconState({
        iconWeight: 'regular',
        iconColor: colors.ocean.base
      });
    }, 1000);
  };

  return <Tooltip contents={t('common.copy-to-clipboard')}>
    <span onClick={() => copyToClipboard()} style={{ cursor: 'pointer' }}>
      {iconState.iconWeight === 'regular'
        ? <IconCopy style={{ verticalAlign: 'middle' }} color={iconState.iconColor} weight={iconState.iconWeight}/>
        : <IconCheck style={{ verticalAlign: 'middle' }} color={iconState.iconColor} weight={iconState.iconWeight}/>}
    </span>
  </Tooltip>;
};
