import * as authActions from '../store/auth/actions';
import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
import jwt from 'jsonwebtoken';

const CLIENT_ID = 'JJNWzdKHOL68jgivh7LyZWUNOXWUWit9';

export const authWrapper = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
  logoutRedirectUri: '/'
});

export class Auth {
  public readonly ssoAuth;
  private profile: {} = {};
  private readonly dispatch;
  private accessToken = '';

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.ssoAuth = authWrapper;

    this.ssoAuth.on('tokenExpired', async () => {
      // This is invoked each time the current token in localStorage becomes expired.
      // This will also be immediately invoked if the token in localStorage is already expired. (configurable via emitInitialTokenExpired)
      console.log('receivedTokenExpired');
      await this.login();
    });
  }

  get userId(): string {
    const content = jwt.decode(this.accessToken);
    return content ? content.sub as string : '';
  }

  async fastSafeTokenAccess(): Promise<string|null> {
    if (this.accessToken) {
      return this.accessToken;
    }

    try {
      await this.login();
      return this.accessToken;
    } catch (e) {
      console.log('fastSafeTokenAccess', e);
      return null;
    }
  }

  get isLoggedIn() {
    return !!this.ssoAuth.isLoggedIn();
  }

  async login(): Promise<void> {
    try {
      await this.ssoAuth.ensureAuthentication({
        nextUri: window.location.pathname + window.location.search
      });
      this.profile = this.ssoAuth.getProfile() || {};
      // HACK for FS's wrapper
      // @ts-ignore
      this.profile.user_id = this.profile.sub;
      this.accessToken = this.ssoAuth.getAccessToken();
      this.dispatch(authActions.onRefreshedProfile(this.profile));
      this.dispatch(authActions.onRefreshedToken(this.accessToken));
    } catch (e) {
      console.log('login failed', e);
      this.dispatch(authActions.onAuthFailure('Authentication unsuccessful.'));
    }
  }

  async logout() {
    await this.ssoAuth.logout('/');
    this.dispatch(authActions.onLogout());
  }
}
