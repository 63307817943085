const PRODUCT = 'product';
const DESIGN = 'design';
const MERCHANDISING = 'merchandising';

const checkResponse = res => {
  if (res.ok) {
    return res.json();
  }
  throw new Error(`${res.status} ${res.statusText}`);
};

const purposeValue = (purpose = '') => {
  switch (purpose.toLowerCase()) {
    case PRODUCT:
      return 1;
    case DESIGN:
      return 0;
    case MERCHANDISING:
      return 2;
    default:
      return 3;
  }
};

const getRequiredAttributes = (accessToken, mcpSku, variableAttributes) => {
  if (!variableAttributes) {
    return Promise.resolve(null);
  }

  const options = {
    method: 'GET',
    mode: 'cors',
    headers: new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`
    })
  };

  return fetch(`https://attribute-validator.products.cimpress.io/v1/ruleSets/${mcpSku}:validate`, options)
    .then(checkResponse)
    .then(res => {
      // get only the required attributes, to avoid passing in too many attributes and exceeding the url length limit
      if (res.missingAttributes) {
        const requiredAttrs = {};
        res.missingAttributes.forEach(a => (requiredAttrs[a.attributeKey] = variableAttributes[a.attributeKey]));
        return requiredAttrs;
      }
      return variableAttributes;
    })
    .catch(() => {
      return variableAttributes;
    });
};

const getFallbackScenes = ({ mcpSku, docRefUrl, variableAttributes, width }) => {
  const options = {
    method: 'GET',
    mode: 'cors'
  };

  let uri = `https://misc-rendering.documents.cimpress.io/scene/docref/info?docRefUrl=${encodeURIComponent(
    docRefUrl
  )}&width=${width}&sku=${mcpSku}`;
  if (variableAttributes) {
    uri += `&skuVariables=${encodeURIComponent(JSON.stringify(variableAttributes))}`;
  }

  return fetch(uri, options)
    .then(checkResponse)
    .then(res => {
      const previewUrls = [];
      res.Surfaces.forEach(surface => {
        surface.Scenes.forEach(scene => {
          const info = `${surface.Name} - ${scene.Purpose.toLowerCase()} ${scene.SubPurpose.toLowerCase()}`;
          if (previewUrls.find(itm => itm.info === info) || !scene.RenderingUrl) {
            // don't duplicate surface/scene combos
            return;
          }
          previewUrls.push({ url: scene.RenderingUrl, info });
        });
      });
      return previewUrls;
    });
};

export const getProductScenes = ({ mcpSku, width, auth, variableAttributes, docRefUrl, includeMerchandising }) => {
  if (!auth) {
    console.error('Cannot get product scenes without auth');
    return Promise.resolve([]);
  }

  let docPromise = Promise.resolve(null);

  let docRefUrl1 = docRefUrl;
  if (docRefUrl1) {
    if (docRefUrl1.startsWith('http:')) {
      docRefUrl1 = `https:${docRefUrl1.substring(5)}`;
    }
    docPromise = fetch(docRefUrl, { method: 'GET' }).then(checkResponse);
  }

  let requiredAttributes;
  return getRequiredAttributes(auth.getAccessToken(), mcpSku, variableAttributes)
    .then(res => (requiredAttributes = res))
    .then(() => docPromise)
    .then(docRef => {
      const accessToken = auth.getAccessToken();
      const headers = new Headers({
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      });
      const queryOptions = {
        ReferenceId: mcpSku,
        AssetTypes: ['scene']
      };
      if (requiredAttributes) {
        queryOptions.Attributes = requiredAttributes;
      }

      const body = JSON.stringify(queryOptions);

      const options = {
        method: 'POST',
        mode: 'cors',
        headers,
        body
      };

      return fetch(
        'https://assets.documents.cimpress.io/v3/assets/-/links:query?pageSize=100&useLegacySceneFallback=true',
        options
      )
        .then(checkResponse)
        .then(res => {
          const previewUrls = [];
          if (res._embedded && res._embedded.item && res._embedded.item.length > 0) {
            const sortedItems = res._embedded.item.sort((a, b) => {
              return purposeValue(a.purpose) - purposeValue(b.purpose);
            });
            sortedItems.forEach(item => {
              const purpose = item.purpose.toLowerCase();
              if (!includeMerchandising && purpose === MERCHANDISING) {
                return;
              }
              const info = `${purpose} ${item.subPurpose.toLowerCase()}`;
              if (item._links.content.href) {
                const url = `https://rendering.documents.cimpress.io/v2/orders/preview?width=${width}&scene=${encodeURIComponent(
                  item._links.content.href
                )}${docRef ? `&instructions_uri=${encodeURIComponent(docRef.renderingInstructionsUrl)}` : ''}`;
                if (previewUrls.find(itm => itm.url === url)) {
                  // don't duplicate surface/scene combos
                  return;
                }
                previewUrls.push({ url, info });
              }
            });
          }
          if (previewUrls.length || !docRefUrl) {
            return previewUrls;
          }
          return getFallbackScenes({ mcpSku, docRefUrl, variableAttributes: requiredAttributes, width });
        });
    });
};
