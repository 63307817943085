import ReactGA from 'react-ga';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../store';
import { AuthActions } from '../storeActions';
import { Auth } from '../../utils/auth';
import * as settingsActions from '../settings/actions';
import { getAccount } from '../../clients/accounts/accountsClient';

export const onRefreshedToken = accessToken => {
  return { type: AuthActions.REFRESH_TOKEN, accessToken };
};
export const onRefreshedProfile = profile => ({ type: AuthActions.REFRESH_PROFILE, profile });
export const onLogout = () => ({ type: AuthActions.LOGOUT });
export const onAuthFailure = error => ({ type: AuthActions.FAILURE, error });

let auth: Auth;

export const initializeAuth = (): ThunkAction<void, AppState, void, AnyAction> => dispatch => {
  auth = new Auth(dispatch);
};

export const login = (): ThunkAction<void, AppState, void, AnyAction> => async (dispatch, getState) => {
  await auth.login();
  const state = getState();

  const user = state.auth?.profile?.email || state.auth?.profile?.sub;
  const profile = state.auth?.profile;
  const accountId = profile['https://claims.cimpress.io/account'];
  if (user) {
    ReactGA.set({ userId: user });
    if (profile) {
      getAccount(state.auth?.accessToken, accountId)
        .then(account => {
          if (!account) {
            return;
          }
        })
        .catch(() => {
          // Avoiding @typescript-eslint/no-empty-function failure
        });
    }
    dispatch(settingsActions.getCustomizrSettings());
  }
};

export const logout = () => async dispatch => {
  await auth.logout();
  dispatch(onLogout());
};
