import { FulfillersActions } from '../storeActions';
import { AppState } from '../store';
import {
  Fulfiller,
  GetSelectedFulfillerIdsErrorAction,
  GetSelectedFulfillerIdsSuccessAction,
  GetFulfillersErrorAction,
  GetFulfillersSuccessAction
} from './types';
import { getUserPermissionsForResourceType } from 'coam-client';
import { ErrorDetails } from '../commonTypes';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { i18n } from '../../locales/i18n';
import { getSelectedFulfillerIds } from '../../utils/pomSettings';
import { getFulfillers } from '../../clients/fulfillerIdentity/getFulfillers';

export const setSelectedFulfillerId = selectedFulfillerIds => ({
  type: FulfillersActions.SET_SELECTED_FULFILLER_ID, selectedFulfillerIds
});

export const setSelectedFulfillers = selectedFulfillers => ({
  type: FulfillersActions.SET_SELECTED_FULFILLERS, selectedFulfillers
});

const getSelectedFulfillerIdsError = (): GetSelectedFulfillerIdsErrorAction => ({
  type: FulfillersActions.GET_SELECTED_FULFILLER_IDS_ERROR
});

const getSelectedFulfillerIdsSuccess = (values: string[]): GetSelectedFulfillerIdsSuccessAction => ({
  type: FulfillersActions.GET_SELECTED_FULFILLER_IDS_SUCCESS,
  data: values
});

export const getSelectedFulfillerIdsRequest = () => async (dispatch, getState) => {
  dispatch({ type: FulfillersActions.GET_SELECTED_FULFILLER_IDS_REQUEST });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  let selectedFulfillerIds;
  try {
    selectedFulfillerIds = (await getSelectedFulfillerIds(accessToken)) || [];
  } catch (e) {
    SentryWrapper.reportError(e);
    return dispatch(getSelectedFulfillerIdsError());
  }

  return dispatch(getSelectedFulfillerIdsSuccess(selectedFulfillerIds));
};

const getFulfillersSuccess = (values: Fulfiller[]): GetFulfillersSuccessAction => ({
  type: FulfillersActions.GET_FULFILLERS_SUCCESS,
  data: values
});

const getFulfillersError = (error: ErrorDetails): GetFulfillersErrorAction => ({
  type: FulfillersActions.GET_FULFILLERS_ERROR,
  error
});

const getResourcesFromPermission = async (accessToken: string, principalId: string) => {
  const data = await getUserPermissionsForResourceType(accessToken, principalId, 'fulfillers');
  return data.filter(item => item.permissions.includes('read:order'));
};

export const getFulfillersRequest = () => async (dispatch, getState) => {
  dispatch({ type: FulfillersActions.GET_FULFILLERS_REQUEST });
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const principalId = state.auth.profile?.sub;

  try {
    const [fulfillerData, fulfillerAccessResponse] = await Promise.all([
      getFulfillers(accessToken),
      getResourcesFromPermission(accessToken, principalId)
    ]);

    const fulfillerAccess = new Set(fulfillerAccessResponse.map(permission => permission.identifier));
    const fulfillerDataWithAccess = fulfillerAccess.has('*')
      ? fulfillerData
      : fulfillerData.filter(fulfiller => fulfillerAccess.has(fulfiller.fulfillerId));

    const sortedFulfillers = fulfillerDataWithAccess
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ fulfillerId, name, email, phone }) => ({ fulfillerId, name, email, phone }));

    return dispatch(getFulfillersSuccess(sortedFulfillers));
  } catch (error) {
    SentryWrapper.reportError(error);
    dispatch(getFulfillersError({ message: i18n.t('errorMessages.failedToRetrieveFulfillersData') }));
  }
  return null;
};
