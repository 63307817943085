import React from 'react';
import { trackEvent } from './utils';

export interface TrackedLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  item: string;
}

export const TrackedLink: React.FC<TrackedLinkProps> = props => {
  const { item, onClick, ...linkProps } = props;

  return <a
    onClick={e => {
      trackEvent(item);
      if (onClick) {
        onClick(e);
      }
    }}
    {...linkProps}
  >{props.children}</a>;
};
