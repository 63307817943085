import { apiRequest } from '../../../../clients/apiClient';
import { useAsync } from 'react-async-hook';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useState } from 'react';
import useConstant from 'use-constant';

const supportedServices = {
  'recs:aramex-domestic-express': true,
  'recs:bluedart-priority': true,
  'recs:bluedart-rush': true,
  'recs:bluedart-cod': true,
  'recs:bpost-parcel-priority': true,
  'recs:colissimo-home-unsigned': true,
  'recs:dhl-paket': true,
  'recs:dhl-paket-international': true,
  'recs:dpd-classic-road': true,
  'recs:dpd-classic': true,
  'recs:fedex-2-3-day-n': true,
  'recs:fedex-next-day-by-9am': true,
  'recs:fedex-next-day-by-10am': true,
  'recs:fedex-next-day-by-12noon': true,
  'recs:fedex-next-day-by-eob': true,
  'recs:fedex-next-day-freight': true,
  'recs:fedex-express-saver': true,
  'recs:fedex-priority-overnight': true,
  'recs:fedex-standard-overnight': true,
  'recs:fedex-international-economy': true,
  'recs:fedex-international-priority': true,
  'recs:fedex-ground': true,
  'recs:fedex-second-day': true,
  'recs:gls-germany': true,
  'recs:gls-netherlands': true,
  'recs:gls-hybrid': true,
  'recs:postnl-smo': true,
  'recs:postnl-avg': true,
  'recs:postnl-avg-10': true,
  'recs:postnl-avg-12': true,
  'recs:postnl-avg-17': true,
  'recs:postnl-avg-sna': true,
  'recs:postnl-eps': true,
  'recs:postnl-eps-to-customer': true,
  'recs:postnl-be-sna': true,
  'recs:postnl-service-point': true,
  'recs:postnl-eps-to-customer-combilabel': true,
  'recs:nacex-19d1': true,
  'recs:nacex-19d2': true,
  'recs:nacex-19d4': true,
  'recs:nacex-19d6': true,
  'recs:nz-post-parcel-non-signature': true,
  'recs:nz-post-express-non-signature': true,
  'recs:ontrac-ground': true,
  'recs:ontrac-palletized-freight': true,
  'recs:ontrac-same-day': true,
  'recs:ontrac-sunrise': true,
  'recs:ontrac-sunrise-gold': true,
  'recs:ups-i-parcel-express': true,
  'recs:ups-i-parcel-saver': true,
  'recs:ups-i-parcel-select': true,
  'recs:tnt-express-fr-domestic-j': true,
  'recs:tnt-economy-express': true,
  'recs:tnt-express': true,
  'recs:toll-priority-b2c-economy-express': true
};

export const useTrackingUrl = (accessToken, carrierServiceKey) => {
  const [trackingId, setTrackingId] = useState('');
  const effect = async (tId, csKey) => {
    if (csKey && csKey.startsWith('recs:') && tId) {
      try {
        const url = new URL('/api/v0/tracking/urls', 'https://trackingurl.shipping.cimpress.io');
        url.searchParams.append('carrierServiceKey', csKey);
        url.searchParams.append('trackingNumber', tId);

        const trackingResponse = await apiRequest<any>({
          url: url.toString(),
          headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
          accessToken: accessToken,
          noErrorReportingForStatusCodes: [404]
        });

        return {
          trackingUrl: trackingResponse?.data?.trackingUrl || '',
          error: undefined
        };
      } catch (error) {
        console.warn(supportedServices[csKey]
          ? 'Failed to resolve Tracking URL'
          : 'Failed to resolve Tracking URL - but likely not yet supported');
        SentryWrapper.reportError(error);
        return {
          trackingUrl: undefined,
          error: error
        };
      }
    }

    return {
      trackingUrl: undefined,
      error: undefined
    };
  };

  const debouncedSearchFunction = useConstant(() => AwesomeDebouncePromise(effect, 400));

  const response = useAsync(async () => {
    const r = await debouncedSearchFunction(trackingId, carrierServiceKey);
    return r;
  }, [accessToken, carrierServiceKey, trackingId]);

  return {
    trackingId: trackingId,
    setTrackingId: setTrackingId,
    trackingUrl: response.result?.trackingUrl,
    loadingTrackingUrl: response.loading
  };
};
