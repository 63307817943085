import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { useTranslation } from 'react-i18next';

export const PdfViewer: React.FC<PdfViewerProps> = (props: PdfViewerProps) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState();

  return (props.pdf
    ? <div className='documentSection'>
      <div className='documentPreview'>
        <Document
          file={props.pdf}
          onLoadSuccess={loadedPDF => {
            setNumPages(loadedPDF.numPages);
            if (props.onPagesCount) {
              props.onPagesCount(numPages);
            }
          }}
        >
          {[...Array(numPages).keys()].map((k, i) => <Page key={i} pageNumber={k + 1} />)}
        </Document>
      </div>
    </div>
    : <span>{t('error.error')}</span>
  );
};

interface PdfViewerProps {
  pdf: any;
  onPagesCount: any;
}
