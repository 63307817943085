import { OrderItemWithStatusAndOrderInfo } from '../../../../clients/foma/itemsClient';
import { getRemainingQuantity } from '../../quantities';
import { DeliveryRequest } from '../../../../clients/delivery/deliveryRequestModel';

interface ItemsCheckResult {
  haveTestItems: boolean;
  haveMultipleAddresses: boolean;
  haveValidItems: boolean;
  errors: string[];
}

// eslint-disable-next-line no-unused-vars
export const itemsCheckWithSplitShipping = (t, items: OrderItemWithStatusAndOrderInfo[]): ItemsCheckResult => {
  // as of now, support only 1 item with multiple addresses, otherwise - error
  if (items && items.length > 1) {
    return {
      haveTestItems: items.some(it => it.isTestItem),
      haveMultipleAddresses: false,
      haveValidItems: false,
      errors: [
        t('shipping.shippingMultipleSplitShippedItemsNotAllowed')
      ]
    } as ItemsCheckResult;
  }

  const quantityToShip = getRemainingQuantity(items[0]?.orderedQuantity, items[0].status);

  return {
    haveTestItems: items.some(it => it.isTestItem),
    haveMultipleAddresses: false,
    haveValidItems: !!(items[0] && quantityToShip),
    errors: []
  } as ItemsCheckResult;
};

export const itemsCheckAssumingNoSplitShipping = (t, items: OrderItemWithStatusAndOrderInfo[], deliveryRequestMap: Record<string, DeliveryRequest>): ItemsCheckResult => {
  let valid = false;
  let testItemsPresent = false;
  let multipleAddresses = false;
  let missingAddress = false;
  let lastAddress = '';

  items
    .every(item => {
      if (item.isTestItem) {
        testItemsPresent = true;
        return false;
      }

      const itemAddresses: any[] = [];
      for (const dd of item.deliveryDetails) {
        const addr = (deliveryRequestMap[dd.links?.self?.href || '']
          || { destinationAddress: 'NO_ADDRESS' }).destinationAddress;
        itemAddresses.push(addr);
      }
      if (itemAddresses.length === 0) {
        missingAddress = true;
        return false;
      }

      if (!lastAddress) {
        lastAddress = JSON.stringify(itemAddresses[0]);
      }

      for (const addr of itemAddresses) {
        if (lastAddress !== JSON.stringify(addr)) {
          multipleAddresses = true;
          return false;
        }
      }

      const quantityToShip = getRemainingQuantity(item?.orderedQuantity, item.status);
      if (!valid && item && quantityToShip) {
        valid = true;
      }
      return true;
    });

  const errors = [] as string[];
  if (multipleAddresses) {
    errors.push(t('shipping.multipleDeliveryAddressWarning'));
  }
  if (testItemsPresent) {
    errors.push(t('shipping.testItemsCannotBeShipped'));
  }
  if (missingAddress) {
    errors.push(t('shipping.atLeastOneItemHasNoAddress'));
  }
  if (!valid) {
    errors.push(t('shipping.requiresAtLeastOneValidItem'));
  }

  return {
    haveTestItems: testItemsPresent,
    haveMultipleAddresses: multipleAddresses,
    haveValidItems: valid,
    errors: errors
  } as ItemsCheckResult;
};
