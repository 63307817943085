import React from 'react';
import moment, { Moment } from 'moment';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import { DatetimepickerProps } from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DateRange } from '../../store/items/types';
import { AppState } from '../../store/store';
import './items.scss';
import { trackEvent } from '../../trackingLayer/utils';

export const DateRangePicker: React.FC<DatePickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const locale = useSelector<AppState, string>(state => state.settings.locale);
  const format = ((locale.toLowerCase() === 'iso8601' || locale.toLocaleLowerCase() === 'x-iso8601'))
    ? 'YYYY-MM-DD'
    : moment().locale(locale).localeData().longDateFormat('L');

  const { start, end } = value;
  const fromMoment = start;
  const toMoment = end;

  const updateValue = (fromOrTo: StartOrEnd, newValue: (Moment|string)) => {
    if (onChange) {
      const currentValues = { start, end };
      if (newValue) {
        trackEvent(`filters.expectedShipDate.${fromOrTo}`);
      }
      if (typeof newValue === 'object') {
        onChange({
          ...currentValues,
          [fromOrTo]: fromOrTo === 'start'
            ? newValue.utc()
            : newValue.utc().endOf('day')
        });
      } else {
        onChange({ ...currentValues,
          [fromOrTo]: newValue ? moment(newValue) : null
        });
      }
    }
  };

  const sharedProps = (fromOrTo: StartOrEnd): DateTimePickerWithMcpAdditions => ({
    placeholder: t(`items.datepicker.${fromOrTo}`),
    onChange: selectedDate => updateValue(fromOrTo, selectedDate),
    closeOnSelect: true,
    dateFormat: `${t(`items.datepicker.${fromOrTo}`).split('').join('\\')}: ${format}`,
    timeFormat: false
  });

  return (
    <div className={'dateRangePicker'}>
      <div className={'dateRangePickerWrapWithClose'}>
        <DatePicker
          value={start}
          readonly={true}
          isValidDate={someDate => end ? moment(someDate).isSameOrBefore(toMoment) : true}
          {...sharedProps('start')}
        />
        {start
          ? <i className="fa fa-close" aria-hidden="true" onClick={e => {
            updateValue('start', '');
            e.stopPropagation();
          }}/>
          : null}
      </div>
      <div className={'dateRangePickerWrapWithClose'}>
        <DatePicker
          value={end}
          readonly={true}
          isValidDate={someDate => start ? moment(someDate).isSameOrAfter(fromMoment) : true}
          {...sharedProps('end')}
        />
        {end
          ? <i className="fa fa-close" aria-hidden="true" onClick={e => {
            updateValue('end', '');
            e.stopPropagation();
          }}/>
          : null}
      </div>
    </div>
  );
};

interface DatePickerProps {
  onChange?: (range: DateRange) => void;
  value: DateRange;
}

type StartOrEnd = ('start' | 'end');

// They didn't add types for the datepicker
// This should probably be submitted to the component library at some point
interface DateTimePickerWithMcpAdditions extends DatetimepickerProps {
  placeholder: string;
}
