export function deleteKey(sourceObject, key) {
  const newObject = { ...sourceObject };
  delete newObject[key];
  return newObject;
}

export function addKeyValue(sourceObject, key, value) {
  const newObject = { ...sourceObject };
  newObject[key] = value;
  return newObject;
}
