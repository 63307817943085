import { AxiosError } from 'axios';
import { apiRequest, ApiResponse, getErrorDetailsFromAxiosError } from '../apiClient';
import { fomaUrl, isProd } from '../../utils/environmentProvider';
import { ErrorDetails, LinkData } from '../../store/commonTypes';
import {
  getItemByShortItemId,
  getItemStatusByShortItemId,
  getOrder,
  Order,
  OrderItem,
  OrderItemStatus
} from './itemsClient';
import {
  ResponsePostRequestV1,
  ResponsePostDelayItemsV1,
  ActionTypeKeysEnum,
  postResponse,
  Env,
  TransmissionMethodKeysEnumForSirV1,
  isRetryableError
} from '@cimpress-technology/supplier-integrations-client';

interface NotificationsResponse {
  count: number;
  links: Links;
  notifications: Notification[];
}

export interface Notification {
  notificationId: string;
  items: NotificationItem[];
  order: NotificationOrder;
  createdDate: string;
  status: NotificationStatuses;
  type: NotificationTypes;
  links: Links;

  // TODO: Type these!
  fulfiller?: any;
  changeRequest?:{
    deliveryChangeDetails: {
      destinationAddress?: any;
      itemDeliveryDetails?:Array<{
        itemId?: string,
        deliveryDetails?: Array<
          {
            quantity: number,
            links: LinkData,
            isSample?: boolean
          }>
      }>
    }
  }
}

export interface NotificationOrder {
  orderId: string;
  links: Links;
}

export interface NotificationItem {
  itemId: string;
  links: Links;
  status: string;
}

interface Links {
  [key: string]: LinkData;
}

interface NotificationRequestRejectionExpectation {
  href: string;
  name: string;
}

export interface NotificationRequestRejectionDetails {
  reason: string;
  unmetExpectations: NotificationRequestRejectionExpectation[];
}

export interface NotificationRequestProcessDeviation {
  reasonCategories?: string[];
  reason?: string;
  expectedShipDate?: string;
}

export interface ItemsNotificationsByTypes {
  [itemId: string]: {
    CancellationRequest?: string[];
    ChangeRequest?: string[];
    ArtworkChangeRequest?: string[];
    ShipmentRequirementChangeRequest?: string[];
  };
}

export interface GroupedAndUngroupedNotifications {
  notifications: Record<string, Notification>;
  groupedNotifications: ItemsNotificationsByTypes;
}

export enum NotificationStatuses {
  All = 'All',
  New = 'New'
}

export enum NotificationTypes {
  OrderRequest = 'OrderRequest',
  CancellationRequest = 'CancellationRequest',
  ChangeRequest = 'ChangeRequest',
  ArtworkChangeRequest = 'ArtworkChangeRequest',
  ShipmentRequirementChangeRequest = 'ShipmentRequirementChangeRequest'
}

export enum CreateNotificationType {
  ProductionStarted = 'ProductionStarted',
  ItemsRejected = 'ItemsRejected',
  ProcessDeviation = 'ProcessDeviation'
}

export interface HandleFilteredNewNotificationsResponse {
  hasActionUrl: boolean;
  responseErrors: ErrorDetails[];
}
export async function getAllFulfillerTypedNotifications(fulfillerId: string, accessToken: string, notificationType: NotificationTypes): Promise<Notification[]> {
  const url = `${fomaUrl}/v1/notifications`;
  const query = { fulfillerId, type: notificationType, status: NotificationStatuses.New, limit: 101 };
  const response = await apiRequest<NotificationsResponse>({ url, query, accessToken });
  return response?.data?.notifications ?? [];
}

export async function getAllItemNotifications(item: OrderItem, accessToken: string): Promise<Notification[]> {
  const url = new URL(item.links.notifications.href);
  const response = await apiRequest<NotificationsResponse>({ url: url.href, accessToken: accessToken });
  return response?.data?.notifications ?? [];
}

// TODO: OMG
export const getItemStatusForNotificationItemIds = (notifications: Notification[], accessToken: string): Promise<(OrderItemStatus|null)[]> => {
  let allItems: NotificationItem[] = [];
  notifications.forEach(a => {
    allItems = allItems.concat(a.items);
  });

  const uniqueItemIds = [...new Set(allItems.map(it => it.itemId))];

  return Promise.all(uniqueItemIds.map(itemId => getItemStatusByShortItemId(itemId, accessToken)));
};

// TODO: OMG
export const getItemInfoForNotificationItemIds = (notifications: Notification[], accessToken: string): Promise<(OrderItem|null)[]> => {
  let allItems: NotificationItem[] = [];
  notifications.forEach(a => {
    allItems = allItems.concat(a.items);
  });

  const uniqueItemIds = [...new Set(allItems.map(it => it.itemId))];

  return Promise.all(uniqueItemIds.map(itemId => getItemByShortItemId(itemId, accessToken)));
};

// TODO: OMG
export const getOrderInfoForNotifications = (notifications: Notification[], accessToken: string): Promise<(Order | null)[]> => {
  const uniqueOrderIds = [...new Set(notifications.map(nt => nt.order.orderId))];
  return Promise.all(uniqueOrderIds.map(orderId => getOrder(orderId, accessToken)));
};

async function handleFilteredNewNotifications(accessToken: string,
  itemNotifications: Notification[],
  notificationType: NotificationTypes,
  itemId: string,
  getAcceptUrl: boolean,
  postBody = {}
): Promise<HandleFilteredNewNotificationsResponse> {
  const responseErrors: ErrorDetails[] = [];
  const filteredNotifications = itemNotifications.filter(n => n.status === NotificationStatuses.New && n.type === notificationType);
  const actionUrl = getNotificationItemActionUrl(filteredNotifications[0], itemId, accessToken, getAcceptUrl);
  if (actionUrl) {
    const handleNotificationResponse = await handleNotification(actionUrl, accessToken, postBody);
    if (handleNotificationResponse?.error) {
      responseErrors.push(handleNotificationResponse.error);
    }
  }
  return { hasActionUrl: !!actionUrl, responseErrors };
}

function getNotificationItemActionUrl(notification: Notification, itemId: string, accessToken: string, getAcceptUrl: boolean): null | string {
  if (!notification) {
    return null;
  }
  const itemData = notification?.items?.find(i => i.itemId === itemId);

  if (itemData && itemData.status === NotificationStatuses.New) {
    return (getAcceptUrl
      ? itemData?.links?.accept?.href
      : itemData?.links?.reject?.href) ?? null;
  }

  return null;
}

function groupNotificationsByItemId(notifications: Notification[], type: NotificationTypes, notificationsMap): ItemsNotificationsByTypes {
  notifications.forEach(n => {
    n.items.forEach(i => {
      notificationsMap[i.itemId] = notificationsMap[i.itemId] ?? {};
      notificationsMap[i.itemId][type] = [...notificationsMap[i.itemId][type] ?? [], n.notificationId];
    });
  });
  return notificationsMap;
}

export async function groupFulfillersItemsNotificationsTypesByItemId(selectedFulfillerIds: string[], accessToken: string): Promise<GroupedAndUngroupedNotifications> {
  let groupedNotifications = {};
  let notifications: Record<string, Notification> = {};
  await Promise.all(selectedFulfillerIds.map(async fulfillerId => {
    await Promise.all([
      NotificationTypes.ChangeRequest,
      NotificationTypes.CancellationRequest,
      NotificationTypes.ArtworkChangeRequest,
      NotificationTypes.ShipmentRequirementChangeRequest
    ].map(async type => {
      const typedNotifications = await getAllFulfillerTypedNotifications(fulfillerId, accessToken, type);
      groupedNotifications = groupNotificationsByItemId(typedNotifications, type, groupedNotifications);
      notifications = typedNotifications.reduce((acc, n) => ({ ...acc, [n.notificationId]: n }), notifications);
    }));
  }));
  return { groupedNotifications, notifications };
}

export async function performPostOrderItemRejectionActions(item: OrderItem, accessToken) {
  const itemNotifications = await getAllItemNotifications(item, accessToken);

  await handleFilteredNewNotifications(accessToken, itemNotifications, NotificationTypes.CancellationRequest, item.itemId, false, { costIncurred: false });
  await handleFilteredNewNotifications(accessToken, itemNotifications, NotificationTypes.ChangeRequest, item.itemId, false);
  await handleFilteredNewNotifications(accessToken, itemNotifications, NotificationTypes.ArtworkChangeRequest, item.itemId, false);
  await handleFilteredNewNotifications(accessToken, itemNotifications, NotificationTypes.ShipmentRequirementChangeRequest, item.itemId, false);
}

export async function markAsDelayed(item: OrderItem, accessToken: string, processDeviation: Omit<ResponsePostDelayItemsV1['delayItems'], 'items'>): Promise<ErrorDetails|undefined> {
  const siPostRequestPayload: ResponsePostRequestV1 = {
    transmissionMethodKey: TransmissionMethodKeysEnumForSirV1.Pom,
    actionTypeKey: ActionTypeKeysEnum.DelayItems,
    actionTypeConfiguration: {
      [ActionTypeKeysEnum.DelayItems]: {
        items: [{ itemId: item.itemId }],
        ...processDeviation
      }
    }
  };

  try {
    await postResponse(accessToken, siPostRequestPayload, isProd ? Env.PRODUCTION : Env.INTEGRATION, { retryCondition: isRetryableError });
  } catch (err) {
    return getErrorDetailsFromAxiosError(err as AxiosError)?.error;
  }

  return undefined;
}

function handleNotification(url: string, accessToken: string, data = {}): Promise<ApiResponse | null> {
  return apiRequest({
    url,
    method: 'post',
    headers: {
      prefer: 'wait=5'
    },
    data,
    accessToken: accessToken
  });
}
