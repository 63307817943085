import React from 'react';
import * as H from 'history';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { useTranslation } from 'react-i18next';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { Tooltip } from '@cimpress/react-components';

export const PrintJobSheetButton: React.FC<PrintJobSheetButtonProps> = props => {
  const disabled = props.disabled || !props.items.filter(item => item).length;
  const { t } = useTranslation();

  const btnProps = {
    disabled: disabled,
    onClick() {
      props.history.push({
        pathname: '/items/jobsheet',
        search: `?${props.items.filter(i => i).map(i => `itemId=${i.itemId}`).join('&')}`
      });
    }
  };

  const btnContent = <>
    {t('buttons.printJobSheet')}
  </>;

  return props.dropDown
    ? <button {...btnProps} title={disabled ? props?.tooltipMessage : ''}>{btnContent}</button>
    : <Tooltip show={disabled} contents={props?.tooltipMessage}><TrackedButton item="printJobsheet" {...btnProps} type={'default'}>{btnContent}</TrackedButton></Tooltip>;
};

interface PrintJobSheetButtonProps {
  disabled?: boolean;
  tooltipMessage?: string;
  dropDown?: boolean;
  history: H.History;
  items: OrderItemWithStatusAndOrderInfo[];
}

