import React, { useMemo } from 'react';
import deepEqual from 'deep-equal';
import { Dropdown, Table, Tooltip, colors, Alert } from '@cimpress/react-components';
import { IconInformationCircle, IconUndo } from '@cimpress-technology/react-streamline-icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { toggleRowSelection, toggleAllSelection } from '../../store/items/actions';
import { PlatformItems, SelectionState } from '../../store/items/types';
import { ViewColumn } from '../../models/views';
import { sortingKeysMap } from '../../clients/foma/itemSearchRequestModel';
import { Column } from 'react-table';
import {
  ProductCellFormat,
  getProductCategoriesCellFormatter,
  QuantityCellFormat,
  DateCellFormatter,
  DeliveryOptionsCellFormatter,
  getFulfillerCellFormatter,
  getOrderIdCellFormatter, getItemIdCellFormatter, StatusCellFormat, PrintJobCellFormat, DeliveryCountryCellFormatter
} from './cellFormatters';
import { FulfillersState } from '../../store/fulfillers/types';
import { AcceptOrderButton } from '../shared/buttons/acceptOrderButton';
import { isAcceptDisabled, isNotifyDelayDisabled, isRejectDisabled, isSetToProductionDisabled, isBulkShipDisabled, isJobsheetDisabled } from './buttonStates';
import { SetToProductionButton } from '../shared/buttons/setToProductionButton';
import { ShipButton } from '../shared/buttons/shipButton';
import { NotifyDelayButton } from '../shared/buttons/notifyDelayButton';
import { RejectButton } from '../shared/buttons/rejectButton';
import { DownloadDocumentButton } from '../shared/buttons/downloadDocumentButton';
import { PrintJobSheetButton } from '../shared/buttons/printJobSheetButton';
import { DownloadCsvButton } from '../shared/buttons/downloadCsvButton';
import { useTranslation } from 'react-i18next';
import { CustomizeColumnsButton } from './columnsModal';
import { tableTranslations } from '../../utils/tableTranslations';
import { useSelectedView } from '../shared/hooks/useSelectedView';
import { ColumnWidths, usePersistentColumnResizing } from '../shared/hooks/usePersistentColumnResizing';
import { getCustomizrSettings, pomApplicationResourceId, setColumnWidths } from '../../store/settings/actions';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { PrettyHeaderButton } from './prettyHeaderButton';
import { keysSupportingLocalSort } from '../../utils/localSorting';

const customCellWidth: ColumnWidths = {
  'order.orderId': 120,
  'itemId': 120,
  'quantity': 120
};

const tableId = 'items';

export const ItemsTable: React.FC<ItemsTableProps> = props => {
  const { data, pageSize, errors, isLoading, page, sorted } = useSelector((state: AppState) => state.items.table, deepEqual);
  const { selectedRows, selectedAll } = useSelector<AppState, SelectionState>(state => state.items.selection);
  const fulfillersState = useSelector<AppState, FulfillersState>(state => state.fulfillers);
  const settings = useSelector((state: AppState) => state.settings);
  const currentColumnWidths = settings.customColumnWidths?.[tableId];
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const byItemIds = useSelector((state: AppState) => state.items.table.byItemIds);
  const platformItems = useSelector<AppState, PlatformItems>(state => state.items.table.platformItemsByItemsId);
  const isTableActionRunning = useSelector((state: AppState) => state.items.table.isLoading || false);
  const selectedItems = Object.keys(selectedRows).map(selectedRow => byItemIds[selectedRow]);
  const noItemsSelected = Object.keys(selectedRows).length === 0;
  const { selectedView } = useSelectedView();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleOnResizedChange, applyColumnWidths, resetWidths } = usePersistentColumnResizing(accessToken,
    pomApplicationResourceId,
    tableId,
    currentColumnWidths,
    customCellWidth,
    (_tableId: string, newColumnSizes: ColumnWidths) => dispatch(setColumnWidths(tableId, newColumnSizes)),
    SentryWrapper.reportError);

  const uniqueAttributeKeys = useMemo(() => {
    const items = data?.items || [];
    const attributeKeys = new Set<string>([]);

    items.forEach(item => {
      const attr = item.product?.attributes;
      if (Array.isArray(attr)) {
        attr.forEach(at => attributeKeys.add(at.name));
      }
    });

    return [...attributeKeys].sort((a, b) => a.localeCompare(b));
  }, [data && data.items]); // eslint-disable-line react-hooks/exhaustive-deps

  const fulfillersMap = {};
  (fulfillersState.fulfillers?.data || []).forEach(f => {
    fulfillersMap[f.fulfillerId] = f;
  });

  const customCellFormatMap = {
    'Quantity': QuantityCellFormat,
    'product': ProductCellFormat,
    'printJob': PrintJobCellFormat,
    'status': StatusCellFormat,
    'Forecasted Ship Date': DateCellFormatter,
    'Promised Delivery': DateCellFormatter,
    'shipDate': DateCellFormatter,
    'Promised Date': DateCellFormatter,
    'cancellationAcknowledgeDate': DateCellFormatter,
    'Last Updated Date': DateCellFormatter,
    'productionStartedDate': DateCellFormatter,
    'acceptanceDate': DateCellFormatter,
    'order.promisedArrivalDate': DateCellFormatter,
    'receivedDate': DateCellFormatter,
    'rejectedDate': DateCellFormatter,
    'Delivery Option': DeliveryOptionsCellFormatter,
    'Fulfiller': getFulfillerCellFormatter(fulfillersMap),
    'Order Id': getOrderIdCellFormatter(settings.useMerchantIds || false),
    'Item Id': getItemIdCellFormatter(settings.useMerchantIds || false),
    'Categories': getProductCategoriesCellFormatter(selectedView?.extensions.ui.screens.itemDetails.leafProductCategoriesOnly.show),
    'Delivery Country': DeliveryCountryCellFormatter
  };

  const columns = props.selectedColumns
    ? props.selectedColumns.map(c => {
      const accessor = c.accessor || c.name;
      const columnInfo: Column<any> = {
        Header: t(`columns.${c.name}`, c.name),
        accessor: accessor,
        id: accessor,
        sortable: accessor in sortingKeysMap || keysSupportingLocalSort.includes(accessor)
      };

      const columnDescription = t(`columnDescriptions.${c.name}`, { defaultValue: null });
      if (columnDescription) {
        columnInfo.Header = function TooltipHeader() {
          return <span>
            {t(`columns.${c.name}`, c.name)}
            &nbsp;
            <Tooltip contents={columnDescription}>
              <IconInformationCircle color={colors.info.base} size={'1x'} weight={'fill'} />
            </Tooltip>
          </span>;
        };
      }

      if (c.name in customCellFormatMap) {
        const cellFormatter = customCellFormatMap[c.name];
        columnInfo.Cell = cellFormatter;
        if (DateCellFormatter === cellFormatter) {
          columnInfo.width = 120;
        }
      }

      return columnInfo;
    })
    : [
      { Header: 'ItemId', accessor: 'itemId' },
      { Header: 'Quantity', accessor: 'quantity' }
    ];

  applyColumnWidths(columns);

  const selectedRowsCount = Object.keys(selectedRows).length;
  const tableMessages = tableTranslations(t);

  const fulfillerIds = fulfillersState.selectedFulfillers && fulfillersState.selectedFulfillers?.length > 0
    ? fulfillersState.selectedFulfillers
    : fulfillersState.fulfillers.data?.map(fulfiller => fulfiller.fulfillerId);

  if (errors) {
    tableMessages.noDataText = <div>
      <h2>{tableMessages.errorText} <span className={'clickableLabel linkColor'} onClick={() => props.onRetryRequest()}>{t('common.tryAgain')}</span> </h2>
      {errors.map((e, i) => <small key={i}>{e.details as string}</small>)}
    </div>;
  }

  for (const tempItem of selectedItems) {
    tempItem.platformItemId = platformItems[tempItem.itemId]?.platformItemId;
  }

  return (
    <div>
      {fulfillerIds && fulfillerIds.length > 25
        ? <Alert
          type={'warning'}
          title={t('errorMessages.tooManyFulfillersSelectedTitle')}
          message={t('errorMessages.tooManyFulfillersSelected')}
          dismissible={false} />
        : null}
      <div className={'flexContainer'}>
        <div className={'flexChild statusLineWrapper'}>
          {selectedRowsCount === 0
            ? <span>{t('items.statusLine.showing', { count: data?.items?.length, total: data?.totalCount })} </span>
            : <span>{t('items.statusLine.selected', { count: selectedRowsCount, total: data?.totalCount })} </span>
          }
        </div>

        {selectedView?.extensions.features.customizeColumns.enabled
          ? <div className={'flexChild statusLineWrapper'} style={{ whiteSpace: 'nowrap' }}>
            <CustomizeColumnsButton attributeKeys={uniqueAttributeKeys} />
          </div>
          : null}

        <div className={'flexChild statusLineWrapper'} style={{ whiteSpace: 'nowrap' }}>
          <PrettyHeaderButton
            icon={IconUndo}
            label={t('table.resetWidths')}
            activeLabel={t('table.resettingWidths')}
            onClick={() => resetWidths(() => dispatch(getCustomizrSettings()))} />
        </div>

        <div className={'flexChildGrow'}>
          <div className={'itemsListActions'} style={{ textAlign: 'right' }}>
            {selectedView?.extensions.ui.actions.accept.show
              ? <AcceptOrderButton t={t} dispatch={dispatch} items={selectedItems}
                disabled={noItemsSelected || isTableActionRunning || isAcceptDisabled(selectedItems)}
                refreshItemList={true} />
              : null}
            {selectedView?.extensions.ui.actions.setToProduction.show
              ? <SetToProductionButton dispatch={dispatch} items={selectedItems}
                disabled={noItemsSelected || isTableActionRunning || isSetToProductionDisabled(selectedItems)}
                refreshItemList={true} />
              : null}
            <ShipButton t={t} history={props.history} items={selectedItems}
              disabled={noItemsSelected || isTableActionRunning || isBulkShipDisabled(selectedItems)} />

            <Dropdown style={{ whiteSpace: 'nowrap' }} title={t('actions.otherActions')}>
              <NotifyDelayButton dropDown items={selectedItems}
                disabled={noItemsSelected || isTableActionRunning || isNotifyDelayDisabled(selectedItems)}
                refreshItemList={true} />
              {selectedView?.extensions.ui.actions.reject.show
                ? <RejectButton dropDown dispatch={dispatch} items={selectedItems}
                  disabled={noItemsSelected || isTableActionRunning || isRejectDisabled(selectedItems)} refreshItemList={true} />
                : null}
              <hr />
              <PrintJobSheetButton dropDown disabled={isJobsheetDisabled(selectedItems)} history={props.history} items={selectedItems} tooltipMessage={t('jobsheet.disableJobsheetTooltipMessage')}/>
              <DownloadDocumentButton dropDown disabled={noItemsSelected} dispatch={dispatch} items={selectedItems} />
              {selectedView?.extensions.ui.actions.downloadCsv.show
                ? <DownloadCsvButton dropDown accessToken={accessToken} disabled={noItemsSelected} dispatch={dispatch}
                  items={selectedItems} />
                : null}
            </Dropdown>
          </div>
        </div>
      </div>

      <div className={'itemsListTable'}>
        <Table
          loading={isLoading}
          columns={columns}
          data={data?.items}
          page={page}
          pageSize={pageSize}
          sorted={sorted}
          onPageChange={pageIndex => props.onSetTableState({ page: pageIndex })}
          onPageSizeChange={pageSizeField => props.onSetTableState({ pageSize: pageSizeField, page: 0 })}
          onSortedChange={sortField => props.onSetTableState({ sorted: sortField })}
          onResizedChange={handleOnResizedChange}
          pages={Math.ceil((data?.totalCount || 0) / (pageSize || 1))}
          minRows={data?.items?.length ? 0 : 10} // we need the 10 minimum rows to show the spinner properly on initial table load
          showPaginationBottom={true}
          showPaginationTop={false}
          pageSizeOptions={[10, 20, 30, 100]}
          selectable={true}
          toggleSelection={key => dispatch(toggleRowSelection(key.split('select-')[1]))}
          toggleAll={() => dispatch(toggleAllSelection())}
          isSelected={key => !!selectedRows[key]}
          selectAll={selectedAll}
          keyField={'itemId'}
          getTdProps={(state, rowInfo) => ({
            onClick(e) {
              const checkbox = e?.target?.querySelector("input[type='checkbox']");
              return !checkbox
                ? props.onRowClick(rowInfo?.original?.itemId)
                : checkbox.click();
            }
          })}
          manual
          {...tableMessages}
        />
      </div>
    </div>
  );
};

interface ViewColumnWithCell extends ViewColumn {
  Cell?: any;
}

interface ItemsTableProps {
  history: any;
  selectedColumns: ViewColumnWithCell[];
  onRowClick: Function;
  onSetTableState: Function;
  onRetryRequest: Function;
}
