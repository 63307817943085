import React from 'react';
import { Label, TextField } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';

import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { getRemainingQuantity } from '../quantities';

import './shipping.scss';
import { IdField } from '../../shared/idField';
import { usePlatformOrderItem } from '../../shared/hooks/usePlatformOrderItem';
import { renderKeyValue } from '../../shared/renderKeyValue';
import { ItemFulfillmentPreview } from '../../shared/itemFulfillmentPreview';

export const ShipItemCard: React.FC<ShipItemCardProps> = props => {
  const { item, accessToken, itemShipped, itemIndex, useMerchantIds } = props;
  const { product, isTestItem, status } = item;
  const shownItemId = useMerchantIds ? item.orderInfo.merchantInformation.itemId || item.itemId : item.itemId;
  const rejectedQuantity = status?.statusDetails.rejected?.quantity || 0;
  const cancelledQuantity = status?.statusDetails.cancelled?.quantity || 0;
  const shippedQuantity = status?.statusDetails.shipped?.quantity || 0;

  const platformItemLink = ((item?.links || {})['platform-item'] || {}).href;
  const { loadingPlatformOrderItem, platformOrderItem } = usePlatformOrderItem(accessToken, platformItemLink, item?.itemId || null);

  const { t } = useTranslation();

  const orderedQuantity = props.orderedQuantityOverride || item?.orderedQuantity;
  const remainingQuantity = getRemainingQuantity(orderedQuantity, status);

  const renderItemLabels = () => <>
    {isTestItem ? <>&nbsp;<Label text={t('shipping.test_item')} /></> : null}
    {props.cancellationPending ? <>&nbsp;<Label text={t('shipping.cancellationPending')} type="danger" /></> : null}
    {props.addressChangePending ? <>&nbsp;<Label text={t('shipping.addressChangePending')} type="warning" /></> : null}
    {props.artworkChangePending ? <>&nbsp;<Label text={t('shipping.artworkChangePending')} type="warning" /></> : null}
    {props.shipmentRequirementChangePending ? <>&nbsp;<Label text={t('shipping.shipmentRequirementChangePending')} type="warning" /></> : null}
    {cancelledQuantity > 0 ? <>&nbsp;<Label text={t('common.cancelled')} type="danger" /></> : null}
  </>;

  return (
    <div className={'row'}>
      <div className='col-lg-2 col-md-3 col-sm-4 col-xs-12'>
        <div className='flexContainer'>
          <div className='flexChild'><h3>#{itemIndex + 1}&nbsp;</h3></div>
          <div className='flexChildGrow'>
            {loadingPlatformOrderItem
              ? null
              : <>
                <ItemFulfillmentPreview
                  accessToken={accessToken}
                  manufacturingReadyDataUrl={platformOrderItem?.manufacturingReadyDataUrl}
                  fomaPreviewUrl={item?.links?.preview?.href}
                  pxSize={168}
                  minimal={true}
                  hideIndicators
                  skuCode={item.product?.sku}
                  documentReferenceUrl={platformOrderItem?.documentReferenceUrl}
                  variableAttributes={platformOrderItem?.variableAttributes || []}
                  standardOutputUrl={platformOrderItem?._links.standardOutputUrl?.href}
                />
              </>
            }
          </div>
        </div>
      </div >
      <div className='col-lg-10 col-md-9 col-sm-8 col-xs-12'>
        <div className='flexContainer' style={{ marginTop: '5px' }}>
          <div className='flexChildGrow'>
            <h5 className='shipCardTitle'>{product.name} {renderItemLabels()}</h5>
            {product.description !== product.name ? <p>{product.description}</p> : null}
            {renderKeyValue(t('details.itemId'), <IdField id={shownItemId} linkTo={`/items/${item.itemId}`}/>)}
            {renderKeyValue(t('details.sku'), <IdField id={product.sku} />)}
          </div>
          <div className='flexChild' style={{ minWidth: '200px' }}>
            {props.doNotCalculateRemainder
              ? null
              : <div>
                {`${t('shipping.totalQuantity')} ${orderedQuantity}`}
                {cancelledQuantity ? <span>{` - ${cancelledQuantity} ${t('common.cancelled')}`}</span> : null}
                {rejectedQuantity ? <span>{` - ${rejectedQuantity} ${t('common.rejected')}`}</span> : null}
                <span>{` - ${shippedQuantity} ${t('common.shipped')}`}</span>
                <span>{` = ${remainingQuantity} ${t('shipping.remainingQuantity')}`}</span>
              </div>}
            <TextField
              label={t('shipping.shipping_quantity')}
              type="number"
              step={1}
              min={0}
              max={orderedQuantity}
              disabled={itemShipped || isTestItem}
              value={props.initialQuantityToShip}
              onChange={({ target }) => !isNaN(Number(target?.value)) ? props.onShippingDataChange(Number(target?.value)) : null}
            />
          </div >
        </div>
      </div >

    </div >
  );
};

interface ShipItemCardProps {
  item: OrderItemWithStatusAndOrderInfo;
  cancellationPending: boolean;
  addressChangePending: boolean;
  artworkChangePending: boolean;
  shipmentRequirementChangePending: boolean;
  accessToken: string;
  itemIndex: number;
  itemShipped?: boolean;
  useMerchantIds?: boolean;
  initialQuantityToShip: number;
  onRemove: Function;
  onShippingDataChange: Function;
  orderedQuantityOverride?: number;
  doNotCalculateRemainder?: boolean;
}
