import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedView } from '../shared/hooks/useSelectedView';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import IconUser from '@cimpress-technology/react-streamline-icons/lib/IconUser';
import { TrackedLink } from '../../trackingLayer/trackedLink';

export const ContactInfo: React.FC = () => {
  const { t } = useTranslation();
  const { selectedView } = useSelectedView();
  return (
    <div>
      {selectedView?.extensions.features.showDocumentationLinks.enabled ? <div>
        <h4><IconBookOpen/> {t('contact.documentation.title')}</h4>
        <div>
          {t('contact.documentation.sentence')}&nbsp;
          <TrackedLink item={'link.documentation'} href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/165118864/Production+Order+Manager">{t('contact.documentation.here')}</TrackedLink>
        </div>
        <hr/>
      </div> : null}
  
      <div>
        <h4><IconUser/> {t('contact.needHelp.title')}</h4>
        <div>
          {t('contact.needHelp.text.s1')}<br/>
          {t('contact.needHelp.text.s2')} <TrackedLink item={'link.supportEmail'} href={`mailto:${selectedView?.extensions.support.email}`}>{selectedView?.extensions.support.email}</TrackedLink>.<br/>
          {t('contact.needHelp.text.s3')}<br/>
        </div>
      </div>
    </div>);
};
