import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@cimpress/react-components';
import { debounce } from 'debounce';

import { DateRangePicker } from './dateRangePicker';
import { AppState } from '../../store/store';
import { ProductCategory } from '../../models/products';
import { useSelectedView } from '../shared/hooks/useSelectedView';
import ReactGA from 'react-ga';
import { DeliveryOption } from '../../models/deliveryOptions';
import { TrackedSelect } from '../../trackingLayer/trackedSelect';

const onTextSearchChange = debounce((fulltext, updateItemsFilters) => {
  ReactGA.event({
    category: window.location.href,
    action: 'search.filter.fulltext',
    label: fulltext
  });
  updateItemsFilters({ fulltext });
}, 500);

const useTextSearch = (text, updateItemsFilters) => {
  const [textSearch, setTextSearch] = useState(text);

  const setText = (value: string) => {
    onTextSearchChange(value, updateItemsFilters);
    setTextSearch(value);
  };

  useEffect(() => { setTextSearch(text || ''); }, [text]);

  return { textSearch, setTextSearch: setText };
};

export const ItemsFilters: React.FC<ItemsFiltersProps> = props => {
  const { t } = useTranslation();
  const itemsFilters = useSelector((state: AppState) => state.items.filters);
  const productCategories = useSelector<AppState, ProductCategory[] | undefined>((state: AppState) => state.productCategories.categories?.data);
  const deliveryOptions = useSelector<AppState, DeliveryOption[] | undefined>((state: AppState) => state.deliveryOptions.deliveryOptions?.data);
  const { selectedView } = useSelectedView();

  const updateItemsFilters = filters => {
    props.onUpdateItemsFilters(filters);
  };

  const { textSearch, setTextSearch } = useTextSearch(itemsFilters.fulltext, updateItemsFilters);

  const deliveryOptionsSelectOptions = deliveryOptions ? deliveryOptions.map(d => ({ value: d.id, label: d.name || d.id })) : [];
  const productCategoriesOptions = productCategories
    ? productCategories
      .filter(x => x.isLeaf || !selectedView?.extensions.ui.screens.itemsList.leafProductCategoriesOnly.show)
      .map(x => ({
        value: x.name,
        label: x.name
      }))
    : [];

  const selectedProductCategoriesOptions = productCategoriesOptions.filter(a => itemsFilters.productCategories?.includes(a.value));
  const selecteddeliveryOptionsSelectOptions = deliveryOptionsSelectOptions.filter(a => itemsFilters.deliveryOptionIds?.includes(a.value));
  const isDeliveryOptionColumnPresent = !!selectedView?.columns.find(c => c.name === 'Delivery Option');

  return <Fragment>
    <div className='row'>
      <div className={'col-md-4'}>
        <TextField
          value={textSearch}
          onChange={({ target }) => {
            const searchText = (target?.value || '').substr(0, 99);
            setTextSearch(searchText);
          }}
          label={t('filters.search')} />
      </div>
      <div className={'col-md-4'}>
        <DateRangePicker
          value={{
            start: itemsFilters.expectedShipTimeFrom,
            end: itemsFilters.expectedShipTimeTo
          }}
          onChange={v => {
            updateItemsFilters({
              expectedShipTimeFrom: v?.start?.toISOString().substring(0, 10),
              expectedShipTimeTo: v?.end?.toISOString().substring(0, 10)
            });
          }}
        />
      </div>
      <div className={'col-md-4'}>
        <TrackedSelect
          item={'filters.productCategories'}
          tether
          // Marking this a ts-ignore as after adding package @cimpress-technology/shipment-planner-component
          // there seem mismatch between types for the different version of react-select package.
          // @ts-ignore
          isMulti={true}
          isDisabled={!productCategories || productCategories.length === 0}
          label={`${t('filters.productCategories')}`}
          // @ts-ignore
          value={selectedProductCategoriesOptions}
          options={productCategoriesOptions}
          onChange={values => {
            // @ts-ignore
            updateItemsFilters({ productCategories: values?.map(a => a.value) || [] });
          }} />
        {isDeliveryOptionColumnPresent
          ? <TrackedSelect
            item={'filters.deliveryOption'}
            tether
            // @ts-ignore
            isMulti={true}
            isDisabled={!deliveryOptions || deliveryOptions.length === 0}
            label={`${t('filters.deliveryOption')}`}
            // @ts-ignore
            value={selecteddeliveryOptionsSelectOptions}
            options={deliveryOptionsSelectOptions}
            onChange={values => {
              // @ts-ignore
              updateItemsFilters({ deliveryOptionIds: values?.map(a => a.value) || [] });
            }} />
          : null}

      </div>
    </div>
  </Fragment>;
};

interface ItemsFiltersProps {
  onUpdateItemsFilters: Function;
}
