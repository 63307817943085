import { PrepressOutput } from '../../../clients/prepress/prepressModels';
import { getManufacturingReadyData, getStandardOutputData, isManufacturingReadyDataJSON } from '../../../clients/prepress/prepressCalls';
import { ErrorResponse, SuccessResponse } from '../../../clients/apiClient';
import { useAsync } from 'react-async-hook';

const getPreviews = (prepressResult: SuccessResponse<PrepressOutput> | ErrorResponse<PrepressOutput> | undefined) => {
  // option 1 - standard previews
  let previews = prepressResult?.data?.DefaultPreviewSet?.map(p => ({
    url: p.Url,
    info: `${p.PageNumber}`
  })) || [];

  // option 2 - more standard previews?
  if (previews.length === 0) {
    previews = prepressResult?.data?.previews?.map(p => ({
      url: p._links?.self?.href,
      info: p.type
    })) || [];
  }

  // auto-add scenes
  Object.keys(prepressResult?.data?.Scenes || {}).forEach(key => {
    previews.push({
      url: prepressResult?.data?.Scenes[key] || '',
      info: key
    });
  });

  return previews;
};

export const usePrepressOutput = (accessToken: string, manufacturingDataReadyUrl?: string, standardOutputUrl?: string) => {
  const response = useAsync(async () => {
    let colors;
    let prepressOutput;
    if (accessToken) {
      try {
        if (standardOutputUrl) {
          const response = await getStandardOutputData(accessToken, standardOutputUrl);
          // As suggested by Prepress team, it’s reasonable to use the logic: “always use output spot colors, unless they don’t exist in which case use input”.
          // This will prevent you from having to include both input and output spot colors, since fulfillers should never need both.
          const outputColors: Array<string> = [];
          const inputColors: Array<string> = [];
          response?.data?.inkCoverage.PageAverages.forEach(avg => avg.Separations.forEach(val => {
            if (!val.IsProcessColor) {outputColors.push(val.ColorantName);}
          }));

          if (outputColors?.length <= 0) {
            response?.data?.inputDocumentInfo.pages.forEach(page => page.inks.forEach(ink => {
              if (!ink.IsProcessColor) {inputColors.push(ink.ColorantName);}
            }));
          }
          colors = outputColors?.length > 0 ? outputColors : inputColors;
        }
        if (manufacturingDataReadyUrl) {
          const isMRDInJSON = await isManufacturingReadyDataJSON(accessToken, manufacturingDataReadyUrl);
          if (!isMRDInJSON) {
            return {
              colors,
              prepressResult: {
                error: {
                  message: 'Prepress NOT configured to generate previews, contact Support.'
                }
              },
              prepressPreviews: []
            };
          }

          // if we are here, we should either have previews OR assume we should try to get them...
          prepressOutput = await getManufacturingReadyData(accessToken, manufacturingDataReadyUrl);
        }
        return {
          prepressResult: prepressOutput,
          colors: colors.length > 0 ? colors : prepressOutput?.data?.Colors,
          prepressPreviews: getPreviews(prepressOutput),
          error: false
        };
      } catch (error) {
        console.warn(error);
        return {
          prepressResult: undefined,
          prepressPreviews: [],
          error: error
        };
      }
    }
    return {
      prepressResult: undefined,
      prepressPreviews: [],
      error: false,
      colors: colors
    };
  },
  // eslint-disable-next-line
    [accessToken, standardOutputUrl, manufacturingDataReadyUrl]);

  return {
    loadingPrepressResult: response.loading,
    prepressResult: response.result?.prepressResult,
    colors: response.result?.colors,
    prepressPreviews: response.result?.prepressPreviews || [],
    error: response.error ?? response.result?.error
  };
};
