import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../utils/dateTimeUtils';
import { Dropdown, Table } from '@cimpress/react-components';
import { Notification } from '../../clients/foma/notificationActionClient';
import { StatusKeysToDisplay } from '../../utils/statusInfo';
import { useHistory } from 'react-router-dom';

import { renderList } from './utils';
import { ReactTableFunction } from 'react-table';
import { FomaItemState } from '../../store/items/types';
import { tableTranslations } from '../../utils/tableTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { getFomaOrderItem } from '../../store/items/actions';
import { useSelectedView } from '../shared/hooks/useSelectedView';
import { getCustomizrSettings, pomApplicationResourceId, setColumnWidths } from '../../store/settings/actions';
import { ColumnWidths, usePersistentColumnResizing } from '../shared/hooks/usePersistentColumnResizing';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

interface CancellationsTableProps {
  useMerchantIds: boolean;
  disabled: boolean;
  isLoading: boolean;
  resetWidthsCounter: number;
  pageSize: number;
  cancellationRequests: Notification[];
  onSelectionChanged: (selected: Record<string, boolean>) => void;
  onAcceptWithCost: (notificationId: string) => void;
  onAcceptWithoutCost: (notificationId: string) => void;
  onReject: (notificationId: string) => void;
}

const customCellWidth: ColumnWidths = {
  notificationId: 100,
  createdDate: 140,
  orderId: 140,
  actions: 160
};

const tableId = 'pendingActions_cancellations';

const CancellationsTable: React.FC<CancellationsTableProps> = ({
  disabled,
  isLoading,
  resetWidthsCounter,
  cancellationRequests,
  useMerchantIds,
  pageSize,
  onSelectionChanged, onAcceptWithoutCost, onAcceptWithCost, onReject }) => {
  const settings = useSelector((state: AppState) => state.settings);
  const currentColumnWidths = settings.customColumnWidths?.[tableId];
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const orderItemsMap = useSelector((state: AppState): Record<string, FomaItemState> => state.items.byId || {});
  const [selectedRows, setSelectedRows] = useState({});
  const [loadedNotifications, setLoadedNotifications] = useState({});
  const [selectedPage, setSelectedPage] = useState(0);
  const hasSelected = Object.values(selectedRows).some(a => !!a);
  const { t } = useTranslation();
  const dateFormatter = useDateTimeFormatter(true);
  const dateFormatFunc = date => dateFormatter(new Date(date));
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleOnResizedChange, applyColumnWidths, resetWidths } = usePersistentColumnResizing(accessToken,
    pomApplicationResourceId,
    tableId,
    currentColumnWidths,
    customCellWidth,
    (_tableId, newColumnSizes) => dispatch(setColumnWidths(tableId, newColumnSizes)),
    SentryWrapper.reportError);

  useEffect(() => {
    if (resetWidthsCounter === 0) { return; }
    resetWidths(() => dispatch(getCustomizrSettings()));
  }, [resetWidthsCounter, resetWidths, dispatch]);

  const { selectedView } = useSelectedView();

  useEffect(() => {
    const firstItem = selectedPage * pageSize;
    const cancellationRequestsCurrentPage = cancellationRequests.slice(firstItem, firstItem + pageSize);
    cancellationRequestsCurrentPage
      .forEach(notification => {
        if (!loadedNotifications[notification.notificationId]) {
          setLoadedNotifications(Object.assign(loadedNotifications, { [notification.notificationId]: true }));
          notification.items.forEach(item => {
            const itemInfo = orderItemsMap[item.itemId];
            if (!itemInfo) {
              dispatch(getFomaOrderItem(item.itemId));
            }
          });
        }
      });
  }, [loadedNotifications, selectedPage, cancellationRequests, orderItemsMap, dispatch, pageSize]);

  const gp = (state, row) => {
    return {
      onClick() {
        history.push({
          pathname: `/pendingActions/cancellations/${row.original.notificationId}`
        });
      },
      style: {
        cursor: 'pointer'
      }
    };
  };

  const columns = [
    {
      id: 'notificationId',
      Header: t('pendingActions.cancellations.table.id'),
      show: false,
      accessor(cancellationRequest): string {
        return cancellationRequest.notificationId;
      },
      Cell(row) {
        return `${row.original.notificationId.substring(0, 8)}...`;
      }
    },
    {
      id: 'createdDate',
      Header: t('pendingActions.cancellations.table.createdAt'),
      accessor: cancellationRequest => cancellationRequest.createdDate,
      Cell: cancellationRequest => dateFormatFunc(cancellationRequest.original.createdDate),
      // eslint-disable-next-line
      getProps: gp as unknown as ReactTableFunction
    },
    {
      id: 'items',
      Header: t('pendingActions.cancellations.table.items'),
      getProps: gp as unknown as ReactTableFunction,
      accessor: cancellationRequest => cancellationRequest.items.join(', '),
      Cell(cancellationRequest) {
        const itemsTexts: string[] = [];
        (cancellationRequest.original.items || []).forEach(it => {
          const item = orderItemsMap[it.itemId];
          if (item?.isLoading === true) {
            itemsTexts.push(`${it.itemId} - ${t('common.loading')} ...`);
          } else {
            const itemId = useMerchantIds ? item?.data?.merchantInformation?.itemId || it.itemId : it.itemId;
            const detailedStatus = item?.data?.status;
            const totalQuantity = item?.data?.orderedQuantity || 0;
            let itemText = `${itemId} - Ordered: ${totalQuantity} `;
            StatusKeysToDisplay.forEach(k => {
              if (detailedStatus && detailedStatus.statusDetails && detailedStatus.statusDetails[k]) {
                const q = detailedStatus.statusDetails[k].quantity;
                itemText += `${t(`statuses.${k}`)}: ${q}; `;
              }
            });
            itemsTexts.push(itemText);
          }
        });
        return renderList(itemsTexts);
      },
      sortable: false
    },
    {
      Header: t('pendingActions.cancellations.table.orderId'),
      id: 'orderId',
      getProps: gp as unknown as ReactTableFunction,
      accessor: item => useMerchantIds
        ? orderItemsMap[item.items[0].itemId]?.data?.orderInfo?.merchantInformation?.orderId || item.order.orderId
        : item.order.orderId,
      sortable: false
    },
    {
      id: 'product',
      accessor(cancellationRequest) {
        const itemsTexts: string[] = [];
        (cancellationRequest.items || []).forEach(it => {
          if (orderItemsMap[it.itemId]?.isLoading === true) {
            itemsTexts.push(`${it.itemId} - ${t('common.loading')} ...`);
          } else {
            itemsTexts.push(orderItemsMap[it.itemId]?.data?.product?.name);
          }
        });
        return renderList(itemsTexts);
      },
      getProps: gp as unknown as ReactTableFunction,
      Header: t('pendingActions.cancellations.table.product'),
      sortable: false
    },
    {
      Header: t('pendingActions.cancellations.table.actions'),
      id: 'actions',
      accessor: item => item.order.orderId,
      Cell(row) {
        return <Dropdown title={t('pendingActions.cancellations.table.actions')} disabled={hasSelected || disabled}>
          <button onClick={() => {
            onAcceptWithoutCost(row.original.notificationId);
          }}>{t('pendingActions.cancellations.buttons.confirmNoCosts')}</button>
          <button onClick={() => {
            onAcceptWithCost(row.original.notificationId);
          }}>{t('pendingActions.cancellations.buttons.confirmWithCosts')}</button>
          {selectedView?.extensions?.ui?.actions?.rejectCancellation?.show
            ? <button onClick={() => {
              onReject(row.original.notificationId);
            }}>{t('pendingActions.cancellations.buttons.rejectCancellation')}</button>
            : null}
        </Dropdown>;
      },
      sortable: false
    }
  ];

  applyColumnWidths(columns);

  const selectAll = !cancellationRequests.some(cr => !selectedRows[cr.notificationId]);

  return <>
    <Table
      columns={columns}
      data={cancellationRequests || []}
      loading={isLoading}
      condensed={false}
      selectable={false}
      toggleSelection={key => {
        const rowId = key.split('select-')[1];
        const newSelectedRows = Object.assign({}, selectedRows, { [rowId]: !selectedRows[rowId] });
        setSelectedRows(newSelectedRows);
        onSelectionChanged(newSelectedRows);
      }}
      toggleAll={() => {
        const newSelectedRows = {};
        if (!selectAll) {
          cancellationRequests.forEach(cr => newSelectedRows[cr.notificationId] = true);
        }
        setSelectedRows(newSelectedRows);
        if (onSelectionChanged) {
          onSelectionChanged(newSelectedRows);
        }
      }}
      selectAll={selectAll}
      keyField={'notificationId'}
      isSelected={key => {
        return !!selectedRows[key];
      }}
      filterable={false}
      pageSize={cancellationRequests.length === 0 ? pageSize : Math.min(cancellationRequests.length, pageSize)}
      onPageChange={setSelectedPage}
      onResizedChange={handleOnResizedChange}
      showPageSizeOptions={false}
      {...tableTranslations(t)}
      noDataText={t('pendingActions.noDataText')}
    />
  </>;
};

export {
  CancellationsTable
};
