import React from 'react';
import { renderKeyValue } from './renderKeyValue';
import { Tooltip, colors } from '@cimpress/react-components';
import { IconQuestionCircle } from '@cimpress-technology/react-streamline-icons/lib';

export const renderSuggestedSchedule = (t, plan, dateFormatterFunc) => {
  return plan
    ? <div className='item-details-box'>
      <h5>{t('details.suggestedSchedule')} &nbsp;
        <Tooltip direction={'top'} contents={t('details.suggestedScheduleTooltip')} >
          <IconQuestionCircle size={'1x'} color={colors.info.base} weight={'fill'} />
        </Tooltip>
      </h5>
      {renderKeyValue(t('details.expectedShipDate'), dateFormatterFunc(plan.expectedShipTime))}
      {renderKeyValue(t('shipping.carrierService'), plan.expectedCarrierService?.name)}
    </div >
    : null;
};
