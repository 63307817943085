import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { ViewsState } from '../../../store/views/types';
import { SettingsState } from '../../../store/settings/types';
import { View } from '../../../models/views';

export interface SelectedView {
  selectedView: View|undefined;
}

export const useSelectedView = (): SelectedView => {
  const { views } = useSelector<AppState, ViewsState>(state => state.views);
  const { preferredViewId } = useSelector<AppState, SettingsState>(state => state.settings);

  // for now, do not load.
  const activeView = views.isLoading
    ? undefined
    : views?.data?.find(view => view.viewId === preferredViewId) || (views?.data ? views?.data[0] : undefined);

  return { selectedView: activeView };
};
