
export const REJECTION_REASONS_KEYS = [
  'product',
  'inventory',
  'production',
  'artwork',
  'pricing',
  'other'
];

export const REJECTION_EXPECTATIONS_CODE = {
  product: 'validProductConfiguration',
  inventory: 'sufficientinventory',
  production: 'productionCompleted',
  artwork: 'validArtwork',
  pricing: 'pricingIssue',
  other: 'miscellaneous'
};

