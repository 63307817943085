import { apiRequest } from '../apiClient';
import { Claim } from './claimModel';

// TODO: Add expiry for the cache
const cache = {};

export async function getClaim(accessToken, claimLink): Promise<Claim|undefined> {
  if (cache[claimLink]) {
    return cache[claimLink];
  }

  const response = await apiRequest<Claim>({
    url: claimLink,
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken,
    query: {}
  });

  if (!response) {
    return undefined;
  }

  if (response.error) {
    throw response;
  }

  return response.data;
}

interface ClaimsResults {
  results: Claim[];
}

export async function getClaims(accessToken, claimLink): Promise<Claim[]|undefined> {
  if (cache[claimLink]) {
    return cache[claimLink];
  }

  const response = await apiRequest<ClaimsResults>({
    url: claimLink,
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken,
    query: {}
  });

  if (!response) {
    return undefined;
  }

  if (response.error) {
    throw response;
  }

  return response.data?.results;
}
