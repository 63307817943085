import React from 'react';
import { TrackedLocalLink } from '../../../trackingLayer/trackedLocalLink';

export const NumberDisplayCard: React.FC<NumberDisplayCardProps> = (props: NumberDisplayCardProps) => {
  const id = props.text.replace(/\s/g, '-').toLowerCase();

  // TODO: Fix
  return <TrackedLocalLink
    item={props.gaItem || 'anyNumberLink'}
    to={props.link}
    className={props.className}
  >
    <p id={id}>{props.count}</p>
    <p>{props.text}</p>
  </TrackedLocalLink>;
};

interface NumberDisplayCardProps {
  count: number;
  text: string;
  link: string;
  gaItem: string;
  className?: string;
}
