import {
  groupFulfillersItemsNotificationsTypesByItemId,
  ItemsNotificationsByTypes,
  Notification, getAllItemNotifications
} from '../../clients/foma/notificationActionClient';
import {
  postResponse,
  ResponsePostRequestV1,
  ActionTypeKeysEnum,
  Env,
  TransmissionMethodKeysEnumForSirV1,
  isRetryableError
} from '@cimpress-technology/supplier-integrations-client';
import { NotificationsActions } from '../storeActions';
import { GetNotificationsForItemSuccessAction, GetNotificationsSuccessAction } from './types';
import { AppState } from '../store';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { OrderItem } from '../../clients/foma/itemsClient';
import { isProd } from '../../utils/environmentProvider';

const getNotificationsSuccess = (notifications: Record<string, Notification>, groupedNotifications: ItemsNotificationsByTypes, itemsData: any): GetNotificationsSuccessAction => ({
  type: NotificationsActions.GET_NOTIFICATIONS_SUCCESS,
  notifications,
  groupedNotifications,
  itemsData
});

const getNotificationsForItemSuccess = (notifications: Record<string, Notification>): GetNotificationsForItemSuccessAction => ({
  type: NotificationsActions.GET_NOTIFICATIONS_FOR_ITEM_SUCCESS,
  notifications
});

export const getNotifications = (fulfillerIds: string[]) => async (dispatch, getState) => {
  const state = getState() as AppState;

  // TODO: Fix me...
  if (state.notifications.isLoading) {
    return;
  }

  dispatch({
    type: NotificationsActions.GET_NOTIFICATIONS_REQUEST,
    errors: null
  });

  try {
    const { groupedNotifications, notifications } = await groupFulfillersItemsNotificationsTypesByItemId(fulfillerIds ?? [], state.auth.accessToken);

    const ids: string[] = [];
    Object.values(notifications)
      .forEach(cr => cr.items.forEach(it => ids.push(it.itemId)));

    /*
    const itemsData = await getItemsWithStatusAndOrderInfo(ids, state.auth.accessToken);
    const itemsDataMap = {};
    itemsData.forEach(item => {
      itemsDataMap[item.itemId] = item;
    });
     */
    const itemsDataMap = null;

    dispatch(getNotificationsSuccess(notifications, groupedNotifications, itemsDataMap));
  } catch (e) {
    SentryWrapper.reportError(e);
    dispatch({
      type: NotificationsActions.GET_NOTIFICATIONS_ERROR,
      errors: e
    });
  }
};

export const getNotificationsForItem = (item: OrderItem) => async (dispatch, getState) => {
  const state = getState() as AppState;

  // TODO: Fix me...
  if (state.notifications.isLoading) {
    return;
  }

  dispatch({
    type: NotificationsActions.GET_NOTIFICATIONS_FOR_ITEM_REQUEST,
    errors: null
  });

  try {
    const itemNotifications = await getAllItemNotifications(item, state.auth.accessToken);
    const result: Record<string, Notification> = {};
    itemNotifications.forEach(n => {
      result[n.notificationId] = n;
    });

    dispatch(getNotificationsForItemSuccess(result));
  } catch (e) {
    SentryWrapper.reportError(e);
    dispatch({
      type: NotificationsActions.GET_NOTIFICATIONS_FOR_ITEM_ERROR,
      errors: e
    });
  }
};

export const acceptAction = ({
  actionTypeKey,
  notificationUrl,
  itemId,
  costsIncurred = false
}: {
  actionTypeKey: ActionTypeKeysEnum.AcceptAddressChangeRequest
    | ActionTypeKeysEnum.AcceptArtworkChangeRequest
    | ActionTypeKeysEnum.AcceptShipmentRequirementChangeRequest
    | ActionTypeKeysEnum.AcceptCancellationRequest,
  notificationUrl: string,
  itemId?: string,
  costsIncurred: boolean,
}) => async (dispatch, getState) => {
  const state = getState() as AppState;
  const selectedFulfillers = state.fulfillers.selectedFulfillers;

  dispatch({
    type: NotificationsActions.HANDLE_NOTIFICATIONS_REQUEST,
    actionTypeKey,
    notificationParams: null,
    errors: null
  });

  try {
    const siPostRequestPayload: Partial<ResponsePostRequestV1> = {
      transmissionMethodKey: TransmissionMethodKeysEnumForSirV1.Pom
    };

    switch (actionTypeKey) {
      case ActionTypeKeysEnum.AcceptAddressChangeRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] }),
            costsIncurred
          }
        };
        break;

      case ActionTypeKeysEnum.AcceptArtworkChangeRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] }),
            costsIncurred
          }
        };
        break;
      
      case ActionTypeKeysEnum.AcceptShipmentRequirementChangeRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] }),
            costsIncurred
          }
        };
        break;

      case ActionTypeKeysEnum.AcceptCancellationRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] }),
            costsIncurred
          }
        };
        break;

      default:
        throw new Error(`Unsupported action (${actionTypeKey}) to accept`);
    }

    try {
      await postResponse(state.auth.accessToken, siPostRequestPayload as ResponsePostRequestV1, isProd ? Env.PRODUCTION : Env.INTEGRATION, { retryCondition: isRetryableError });
    } catch (err) {
      SentryWrapper.reportError(err);
      dispatch({
        type: NotificationsActions.HANDLE_NOTIFICATIONS_ERROR,
        errors: [err]
      });
    }

    dispatch({
      type: NotificationsActions.HANDLE_NOTIFICATIONS_SUCCESS
    });

    dispatch(getNotifications(selectedFulfillers));
  } catch (e) {
    SentryWrapper.reportError(e);
    dispatch({
      type: NotificationsActions.HANDLE_NOTIFICATIONS_ERROR,
      errors: [e]
    });
  }
};

export const rejectAction = ({
  actionTypeKey,
  notificationUrl,
  itemId
}: {
  actionTypeKey: ActionTypeKeysEnum.RejectAddressChangeRequest
    | ActionTypeKeysEnum.RejectArtworkChangeRequest
    | ActionTypeKeysEnum.RejectShipmentRequirementChangeRequest
    | ActionTypeKeysEnum.RejectCancellationRequest,
  notificationUrl: string,
  itemId?: string
}) => async (dispatch, getState) => {
  const state = getState() as AppState;
  const selectedFulfillers = state.fulfillers.selectedFulfillers;

  dispatch({
    type: NotificationsActions.HANDLE_NOTIFICATIONS_REQUEST,
    actionTypeKey,
    notificationParams: null,
    errors: null
  });

  try {
    const siPostRequestPayload: Partial<ResponsePostRequestV1> = {
      transmissionMethodKey: TransmissionMethodKeysEnumForSirV1.Pom
    };

    switch (actionTypeKey) {
      case ActionTypeKeysEnum.RejectAddressChangeRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] })
          }
        };
        break;

      case ActionTypeKeysEnum.RejectArtworkChangeRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] })
          }
        };
        break;

      case ActionTypeKeysEnum.RejectShipmentRequirementChangeRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] })
          }
        };
        break;
      
      case ActionTypeKeysEnum.RejectCancellationRequest:
        siPostRequestPayload.actionTypeKey = actionTypeKey;
        siPostRequestPayload.actionTypeConfiguration = {
          [actionTypeKey]: {
            requestUrl: notificationUrl,
            ...(itemId && { items: [{ itemId }] })
          }
        };
        break;

      default:
        throw new Error(`Unsupported action (${actionTypeKey}) to reject`);
    }

    try {
      await postResponse(state.auth.accessToken, siPostRequestPayload as ResponsePostRequestV1, isProd ? Env.PRODUCTION : Env.INTEGRATION, { retryCondition: isRetryableError });
    } catch (err) {
      SentryWrapper.reportError(err);
      dispatch({
        type: NotificationsActions.HANDLE_NOTIFICATIONS_ERROR,
        errors: [err]
      });
    }

    dispatch({
      type: NotificationsActions.HANDLE_NOTIFICATIONS_SUCCESS
    });

    dispatch(getNotifications(selectedFulfillers));
  } catch (e) {
    SentryWrapper.reportError(e);
    dispatch({
      type: NotificationsActions.HANDLE_NOTIFICATIONS_ERROR,
      errors: [e]
    });
  }
};
