import React, { PropsWithChildren, useEffect } from 'react';
import { Card, shapes } from '@cimpress/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { NumberDisplayCard } from './numberDisplayCard';
import { useTranslation } from 'react-i18next';
import { pomUrlConstructor } from '../../../utils/urlConstructor';
import { ErrorComponent } from '../../errorComponent';
import { getTodayWidgetValues } from '../../../store/dashboard/actions';
import { useSelectedView } from '../../shared/hooks/useSelectedView';
import moment from 'moment';
import './todayWidget.scss';

export const TodayWidget: React.FC = () => {
  const { data, errors, isLoading } = useSelector((state: AppState) => state.dashboard.todayWidget);
  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers);
  const selectedFulfillerId = selectedFulfillers ? selectedFulfillers[0] : '';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedView } = useSelectedView();
  const showLimitedLateTag = selectedView?.extensions.features.limitedLateTag?.show;

  useEffect(() => {
    if (selectedFulfillerId && !isLoading) {
      dispatch(getTodayWidgetValues());
    }
    // eslint-disable-next-line
  }, [selectedFulfillerId, dispatch]);

  const TodayWidgetContainer: React.FC = (props: PropsWithChildren<{}>) => (
    <Card header={
      <div className='flexContainer'>
        <div className='flexChildGrow'>
          {t('dashboard.todayWidget.panelName')}
        </div>
      </div>
    } isMinimal={true}>
      {props.children}
    </Card>
  );

  if (isLoading) {
    const { Spinner } = shapes;
    return (
      <TodayWidgetContainer>
        <Spinner className={'text-center'} />
      </TodayWidgetContainer>
    );
  }

  if (!data) {
    if (errors) {
      return (
        <TodayWidgetContainer>
          <ErrorComponent errors={errors} />
        </TodayWidgetContainer>
      );
    }
    return null;
  }

  const newItemsLink = pomUrlConstructor('items', [
    ['fulfillerId', selectedFulfillerId],
    ['status', 'new']
  ]);

  const openItemsLink = pomUrlConstructor('items', [
    ['fulfillerId', selectedFulfillerId],
    ['status', 'new,accepted,production']
  ]);

  const today = moment(new Date()).format('MM-DD-YYYY');

  const shipTodayLink = pomUrlConstructor('items', [
    ['fulfillerId', selectedFulfillerId],
    ['status', 'new,accepted,production'],
    ['sortBy', 'promisedArrivalDate'],
    ['sortOrder', 'asc'],
    ['expectedShipTimeFrom', today],
    ['expectedShipTimeTo', today]
  ]);

  const forecastedLateLink = pomUrlConstructor('items', [
    ['fulfillerId', selectedFulfillerId],
    ['status', 'new,accepted,production'],
    ['sortBy', 'promisedArrivalDate'],
    ['sortOrder', 'asc'],
    ['limitForecastedLate', 'true']
  ]);

  const pendingCancellationsLink = pomUrlConstructor('pendingActions', []);

  const noPlanItemLink = pomUrlConstructor('items', [
    ['fulfillerId', selectedFulfillerId],
    ['status', 'new,accepted,production'],
    ['sortBy', 'promisedArrivalDate'],
    ['sortOrder', 'asc'],
    ['filterItemsWithNoPlan', 'true']
  ]);

  return (
    <TodayWidgetContainer>
      <div className='todayWidget'>
        {selectedView?.extensions.ui.screens.dashboard.todayWidget.openItems.show
          ? <NumberDisplayCard
            text={t('dashboard.todayWidget.openItems')}
            count={data?.openItems || 0}
            link={openItemsLink}
            gaItem={'dashboard.todayWidget.link.openItems'}
            className='openItemsSubWidget'
          />
          : null}
        {selectedView?.extensions.ui.screens.dashboard.todayWidget.newItems.show
          ? <NumberDisplayCard
            text={t('dashboard.todayWidget.newItems')}
            count={data.newItems}
            link={newItemsLink}
            gaItem={'dashboard.todayWidget.link.openItems'}
          />
          : null}
        {selectedView?.extensions.ui.screens.dashboard.todayWidget.shipToday.show
          ? <NumberDisplayCard
            text={t('dashboard.todayWidget.shipToday')}
            count={data.shipToday}
            link={shipTodayLink}
            gaItem={'dashboard.todayWidget.link.shipToday'}
          />
          : null}
        {selectedView?.extensions.ui.screens.dashboard.todayWidget.noShippingPlan.show
          ? <NumberDisplayCard
            text={t('dashboard.todayWidget.noShippingPlan')}
            count={data.noPlanItems}
            link={noPlanItemLink}
            gaItem={'dashboard.todayWidget.link.noShippingPlan'}
          />
          : null}
        {selectedView?.extensions.ui.screens.dashboard.todayWidget.lateItems.show
          ? <NumberDisplayCard
            text={t(showLimitedLateTag ? 'dashboard.todayWidget.lateItems' : 'dashboard.todayWidget.forecastedLateItems')}
            count={data.forecastedLateItems}
            link={forecastedLateLink}
            gaItem={'dashboard.todayWidget.link.lateItems'}
            className='lateItemsSubWidget'
          />
          : null}
        {selectedView?.extensions.ui.screens.dashboard.todayWidget.pendingCancellations.show
          ? <NumberDisplayCard
            text={t('dashboard.todayWidget.pendingCancellations')}
            count={data.pendingCancellations}
            link={pendingCancellationsLink}
            gaItem={'dashboard.todayWidget.link.pendingCancellations'}
          />
          : null}
      </div>
    </TodayWidgetContainer>
  );
};
