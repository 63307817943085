import { apiRequest } from '../../../clients/apiClient';
import { useAsync } from 'react-async-hook';

export const useFomaPreview = (accessToken: string, fomaPreviewUrl?: string) => {
  const response = useAsync(async () => {
    if (accessToken && fomaPreviewUrl) {
      const res = await apiRequest<string>({
        accessToken,
        url: fomaPreviewUrl,
        responseType: 'arraybuffer',
        noErrorReportingForStatusCodes: [404]
      });
      const newState = {
        previewData: '',
        error: false
      };

      if (res?.data) {
        const base64Data = Buffer.from(res.data, 'binary').toString('base64');
        newState.previewData = `data:${res.headers['content-type'].toLowerCase()};base64,${base64Data}`;
      } else if (res === null) {
        // ignore. Could be cancelled
      } else {
        newState.error = true;
      }
      return newState;
    }
    return {
      error: false,
      previewData: ''
    };
  },
  // eslint-disable-next-line
    [accessToken, fomaPreviewUrl]
  );

  return {
    loadingFomaPreview: response.loading,
    fomaPreviewData: response.result?.previewData,
    errorLoadingFomaPreview: response.error || response.result?.error
  };
};
