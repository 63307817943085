import { ProductCategoriesActions } from '../storeActions';
import { AppState } from '../store';
import { apiRequest, ApiResponse } from '../../clients/apiClient';
import { ItemAggregationResponse } from '../../clients/foma/itemAgreggationResponseModel';
import { createAggregationQueryParamsEx } from '../../clients/foma/itemAggregation';
import { i18n } from '../../locales/i18n';
import { fomaUrl } from '../../utils/environmentProvider';
import {
  getProductCategoryNames,
  getProductCategoryTreeResponse,
  ProductCategoryEx
} from '../../clients/products/productCatalogClient';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

const getProductCategoriesSuccess = values => ({
  type: ProductCategoriesActions.GET_PRODUCT_CATEGORIES_SUCCESS,
  data: values
});

const getProductCategoriesError = error => ({
  type: ProductCategoriesActions.GET_PRODUCT_CATEGORIES_ERROR,
  data: null,
  error: error
});

export const getProductCategoriesRequest = selectedFulfillerIds => async (dispatch, getState) => {
  dispatch({ type: ProductCategoriesActions.GET_PRODUCT_CATEGORIES_REQUEST });

  if (!selectedFulfillerIds || selectedFulfillerIds.length === 0) {
    return dispatch(getProductCategoriesSuccess([]));
  }

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const itemAggregationsUrl = `${fomaUrl}/v0/item-aggregations`;

  try {
    const productCategoryTreeResponse: ApiResponse<ProductCategoryEx> = await getProductCategoryTreeResponse(accessToken);
    const leafCategoryNames = Object.keys(getProductCategoryNames(productCategoryTreeResponse.data));

    const itemAggregationResponse = await apiRequest<ItemAggregationResponse>({
      accessToken,
      url: itemAggregationsUrl,
      query: createAggregationQueryParamsEx(selectedFulfillerIds, true)
    });

    const errorResponse = itemAggregationResponse?.error;
    const itemAggregations = itemAggregationResponse?.data;
    if (errorResponse || !itemAggregations) {
      if (itemAggregationResponse?.status === 403) {
        return dispatch(getProductCategoriesError({
          message: i18n.t('errorMessages.insufficientFulfillerPermissions'),
          severity: 'warning'
        }));
      }

      return dispatch(getProductCategoriesError({
        message: i18n.t('errorMessages.failedToRetrieveData'),
        details: errorResponse
      }));
    }

    const mapResponse = itemAggregationResponse.data?.results.map(x => ({ name: x.key, isLeaf: leafCategoryNames.includes(x.key) })) || [];

    return dispatch(getProductCategoriesSuccess(mapResponse));
  } catch (e) {
    SentryWrapper.reportError(e);
    return dispatch(getProductCategoriesError(e));
  }
};
