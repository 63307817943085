import { Label } from '@cimpress/react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrackedButton } from '../../trackingLayer/trackedButton';
import { TrackedModal } from '../../trackingLayer/trackedModal';
import { renderKeyValue } from './renderKeyValue';

export interface QuoteLabelProps {
  itemId?: string;
  quoteHref?: string;
  attributes?: {name: string; value: string}[];
}

export const QuoteLabel: React.FC<QuoteLabelProps> = ({ itemId, quoteHref, attributes }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => setOpen(false);

  const productName = attributes?.find(att => att.name === 'productName');
  const productDescription = attributes?.find(att => att.name === 'productDescription');
  const quoteRequestId = attributes?.find(att => att.name === 'quoteRequestId');
  const fulfillerQuoteReference = attributes?.find(att => att.name === 'fulfillerQuoteReference');

  if (!itemId && !quoteHref) {
    return null;
  }

  return (
    <>
      <span onClick={e => {
        setOpen(true);
        e.stopPropagation();
      } } style={{ cursor: 'pointer' }}>
        <Label className='spacedLabel' text={ t('details.customQuote')} type={'info'}/>
      </span>
      <TrackedModal item={'quotesModal'} bsStyle={'info'} closeButton={true}
        className="columns-modal"
        show={open}
        onRequestHide={closeModal}
        title={t('items.quotes.modal.title')}
        footer={
          <TrackedButton item="quotesModal.close" className="btn btn-default" onClick={closeModal}>
            {t('items.quotes.modal.ok')}
          </TrackedButton>
        }>
        {renderKeyValue(t('items.quotes.modal.quoteRequestId'), quoteRequestId?.value)}
        {renderKeyValue(t('items.quotes.modal.productName'), productName?.value)}
        {renderKeyValue(t('items.quotes.modal.additionalDescription'), productDescription?.value)}
        {renderKeyValue(t('items.quotes.modal.internalQuoteReferenceId'), fulfillerQuoteReference?.value)}
      </TrackedModal>
    </>
  );
};
