import { apiRequest } from '../../../clients/apiClient';
import { ManufacturingDetails } from '../../../clients/foma/manufacturingDetailsModel';
import { useAsync } from 'react-async-hook';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

export interface ManufacturingDetailsState {
  loadingManufacturingDetails: boolean;
  manufacturingDetails: ManufacturingDetails;
  errorLoadingManufacturingDetails: any;
}

export const useManufacturingDetails = (accessToken, manufacturingDetailsLink): ManufacturingDetailsState => {
  const response = useAsync(async () => {
    if (accessToken && manufacturingDetailsLink) {
      try {
        const manufacturingDetailsResponse = await apiRequest<any>({
          url: manufacturingDetailsLink,
          // headers: { 'content-type': 'application/json' },
          accessToken
        });

        return {
          data: manufacturingDetailsResponse?.data,
          error: undefined
        };
      } catch (error) {
        SentryWrapper.reportError(error);
        return {
          data: null,
          error: error
        };
      }
    }
    return {
      data: null,
      error: null
    };
  }, [accessToken, manufacturingDetailsLink]);

  return {
    loadingManufacturingDetails: response.loading,
    manufacturingDetails: response.result?.data,
    errorLoadingManufacturingDetails: response.error ?? response.result?.error
  };
};
