import { saveAs } from 'file-saver';
import { parse } from 'content-disposition';
import { apiRequest } from '../clients/apiClient';
import { parseBlobError } from './blob';

export const saveDocument = async (accessToken, documentLink: string, basename: string) => {
  if (!documentLink) {
    return Promise.reject({
      errorCode: 'NoLink'
    });
  }

  const res = await apiRequest({
    accessToken,
    url: documentLink,
    responseType: 'blob',
    timeout: 60 * 60 * 1000 // 1 hour is enough?
  });

  if (!res) {
    // Could be because the request was cancelled
    return Promise.resolve();
  }

  if (res.error) {
    return Promise.reject(res.error?.details ? await parseBlobError(res.error.details as Blob).catch(() => {/* */ }) : null);
  }

  const contentDispositionFilename: string = res.headers['content-disposition'] ? parse(res.headers['content-disposition'])?.parameters?.filename : '';
  const split = contentDispositionFilename.split('.');
  const extension = split.length > 1 ? split[split.length - 1] || 'pdf' : 'pdf';
  return saveAs(res.data, `${basename}.${extension}`);
};
