import React, { PropsWithChildren, useEffect } from 'react';
import { Card, shapes } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { BacklogChart } from './backlogChart';
import { BacklogTable } from './backlogTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import {
  getBacklog,
  toggleBacklogByQuantitiesAction,
  toggleGroupByProductCategoriesAction
} from '../../../store/dashboard/actions';
import './backlog.scss';
import { useSelectedView } from '../../shared/hooks/useSelectedView';
import { ProductCategory } from '../../../models/products';
import { ErrorComponent } from '../../errorComponent';
import {
  createEmptyAggregationRow,
  createLateAndFutureColumns,
  createNonCategorizedAggregation
} from './backlogHelper';
import { ItemAggregations } from '../../../clients/foma/itemAgreggationResponseModel';
import { TrackedCheckbox } from '../../../trackingLayer/trackedCheckbox';

export const BacklogWidget: React.FC = () => {
  const { isLoading, data, errors } = useSelector((state: AppState) => state.dashboard.backlog.items);
  // const { groupByProductCategories } = useSelector((state: AppState) => state.dashboard.backlog);
  const { selectedView } = useSelectedView();

  const categories = useSelector<AppState, ProductCategory[]>(state => state.productCategories?.categories?.data || []);
  const categoriesLeafMap = {};
  categories.forEach(c => categoriesLeafMap[c.name] = c.isLeaf);

  const filteredData = (selectedView?.extensions.ui.screens.dashboard.backlogWidget.leafProductCategoriesOnly.show)
    ? (data?.aggregatedByDateAndCategory.filter(categoryRow => categoriesLeafMap[categoryRow.key]) || [])
    : (data?.aggregatedByDateAndCategory || []);

  if (isLoading) {
    const { Spinner } = shapes;
    return (
      <BacklogWidgetContainer>
        <Spinner className={'text-center'}/>
      </BacklogWidgetContainer>
    );
  }

  if (!data) {
    if (errors) {
      return (
        <BacklogWidgetContainer>
          <ErrorComponent errors={errors}/>
        </BacklogWidgetContainer>
      );
    }
    return null;
  }

  // Group LATE & FUTURE
  const { groupedData, totalAggregationRow } = createLateAndFutureColumns(filteredData);

  // Get data grouped by expectedShipDate (this excludes items with expectedShipDate=null)
  const othersAggregationRow: ItemAggregations = {
    key: 'rowOthers',
    total: 0,
    quantity: 0,
    aggregations: createEmptyAggregationRow()
  };

  // Figure out how many items are either NOT categorized OR categorized in a category which is not shown
  const nonCateg = createNonCategorizedAggregation(data?.aggregatedByDate || []);
  nonCateg.forEach(x => {
    const dateAggIndex = totalAggregationRow.aggregations.findIndex(a => a.key === x.key);
    if (dateAggIndex !== -1) {
      othersAggregationRow.aggregations[dateAggIndex].total = x.total - totalAggregationRow.aggregations[dateAggIndex].total;
      othersAggregationRow.aggregations[dateAggIndex].quantity = x.quantity - totalAggregationRow.aggregations[dateAggIndex].quantity;
    }
  });

  // Add late items (mostly the ones with expectedShipDate = null)
  const nonCategorizedLate = othersAggregationRow.aggregations[0].total;
  const categorizedLate = totalAggregationRow.aggregations[0].total;
  const othersLateComputed = data?.totalLateItemsCount - categorizedLate;
  othersAggregationRow.aggregations[0].total = othersLateComputed;
  othersAggregationRow.aggregations[othersAggregationRow.aggregations.length - 1].total
    = othersAggregationRow.aggregations[othersAggregationRow.aggregations.length - 1].total + othersLateComputed - nonCategorizedLate;

  groupedData.push(othersAggregationRow);

  // Add "others" to "totals"
  for (let i = 0; i < totalAggregationRow.aggregations.length; i++) {
    totalAggregationRow.aggregations[i].total += othersAggregationRow.aggregations[i].total;
    totalAggregationRow.aggregations[i].quantity += othersAggregationRow.aggregations[i].quantity;
  }

  groupedData.push(totalAggregationRow);

  return (
    <BacklogWidgetContainer>
      <BacklogChart isLoading={isLoading}/>
      <hr/>
      <BacklogTable aggregatedByDateAndCategory={groupedData} />
    </BacklogWidgetContainer>
  );
};

const BacklogWidgetHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { groupByProductCategories, showQuantities } = useSelector((state: AppState) => state.dashboard.backlog);

  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers);
  const selectedFulfillerId = selectedFulfillers && selectedFulfillers.length > 0 ? selectedFulfillers[0] : '';

  useEffect(() => {
    if (selectedFulfillerId) {
      dispatch(getBacklog());
    }
  }, [selectedFulfillerId, dispatch]);

  return (
    <div>
      {t('dashboard.backlogWidget.panelName')}
      <div className="pull-right">
        <TrackedCheckbox
          item={'backlogWidget.showQuantityToggle'}
          label={t('dashboard.backlogWidget.quantityCheckboxLabel')}
          checked={showQuantities}
          onChange={() => {
            dispatch(toggleBacklogByQuantitiesAction());
          }}
        />
        <TrackedCheckbox
          item={'backlogWidget.productCategoryToggle'}
          label={t('dashboard.backlogWidget.productCategoryToggleLabel')}
          checked={groupByProductCategories}
          onChange={() => {
            dispatch(toggleGroupByProductCategoriesAction());
          }}
        />
      </div>
    </div>
  );
};

const BacklogWidgetContainer: React.FC = (props: PropsWithChildren<{}>) => (
  <Card className={'backlogWrapper'} header={<BacklogWidgetHeader/>} isMinimal={true}>
    { props.children }
  </Card>
);
