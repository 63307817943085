import React from 'react';
import { renderKeyValue } from './renderKeyValue';
import { IdField } from './idField';
import { EmailField } from './emailField';
import { PhoneField } from './phoneField';

export const renderMerchantInfo = (t, order, itemId) => {
  const { id, orderId, support } = order?.merchantInformation || {};

  return order
    ? <div>
      {renderKeyValue(t('details.name'), id)}
      {renderKeyValue(t('details.itemId'), <IdField id={itemId}/>)}
      {renderKeyValue(t('details.orderId'), <IdField id={orderId}/>)}
      {renderKeyValue(t('details.email'), <EmailField email={support?.email}/>, support?.email)}
      {renderKeyValue(t('details.phoneNumber'), <PhoneField phone={support?.phoneNumber}/>, support?.phoneNumber)}
    </div >
    : null;
};
