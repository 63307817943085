import { ItemsActionTypes, ItemsState, SelectionState } from './types';
import { ItemsActions } from '../storeActions';
import { addKeyValue, deleteKey } from '../../utils/imutableObjectOperations';
import { ItemSearchResults, ItemSearchStatus, OrderItemWithStatusAndOrderInfo } from '../../clients/foma/itemsClient';

const initialState: ItemsState = {
  filters: {
    initialized: false,
    fulltext: undefined,
    status: [
      ItemSearchStatus.New
    ],
    productCategories: [],
    deliveryOptionIds: []
  },
  table: {
    isLoading: false,
    data: {
      items: [],
      count: 0,
      totalCount: 0
    },
    platformItemsByItemsId: {},
    byItemIds: {},
    page: 0,
    pageSize: 20,
    lastRefreshed: 0,
    sorted: []
  },
  byId: {
  },
  selection: {
    selectedRows: {},
    selectedAll: false
  }
};

const getUpdatedSelectionState = (currentState: SelectionState,
  key: string, itemsCount: number | undefined): SelectionState => {
  const newState = { selectedRows: {}, selectedAll: false };

  newState.selectedRows = currentState.selectedRows[key] ? deleteKey(currentState.selectedRows, key)
    : addKeyValue(currentState.selectedRows, key, true);

  newState.selectedAll = Object.keys(newState.selectedRows).length === itemsCount;
  return newState;
};

const getToggleAllSelectionState = (stateSelection: SelectionState,
  items: ItemSearchResults | undefined): SelectionState => {
  if (items && !stateSelection.selectedAll) {
    return { selectedRows: items.items.reduce((acc, i) => ({ ...acc, [i.itemId]: true }), {}), selectedAll: true };
  }
  return { selectedRows: {}, selectedAll: false };
};

export function itemsReducer(state = initialState, action: ItemsActionTypes): ItemsState {
  switch (action.type) {
    case ItemsActions.SHOW_ALERT:
      return {
        ...state,
        errors: (state.errors || []).concat(action.errors)
      };
    case ItemsActions.DISMISS_ALERT:
      return {
        ...state,
        errors: (state.errors || []).filter(error => error !== action.error)
      };
    case ItemsActions.GET_ITEMS_REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true,
          errors: undefined
        }
      };
    case ItemsActions.GET_ITEMS_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          data: action.data,
          errors: undefined,
          isLoading: false,
          lastRefreshed: Date.now(),
          byItemIds: action.data.items.reduce((acc, item) => ({ ...acc, [item.itemId]: item }), {})
        },
        byId: {
          ...state.byId,
          ...(action.data.items.reduce((acc, item) => ({ ...acc, [item.itemId]: { isLoading: false, data: item } }), {}))
        }
      };
    case ItemsActions.GET_ITEMS_ERROR:
      return {
        ...state,
        table: {
          ...initialState.table,
          errors: [action.error],
          isLoading: false
        }
      };
    case ItemsActions.SET_ITEMS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        },
        table: {
          ...state.table
        },
        selection: initialState.selection
      };
    case ItemsActions.SET_ITEMS_TABLE_STATE:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.table
        },
        selection: initialState.selection
      };
    case ItemsActions.TOGGLE_ROW_SELECTION:
      return {
        ...state,
        selection: getUpdatedSelectionState(state.selection, action.selection, state?.table?.data?.count)
      };
    case ItemsActions.TOGGLE_ALL_SELECTION:
      return {
        ...state,
        selection: getToggleAllSelectionState(state.selection, state.table.data)
      };
    case ItemsActions.BULK_ACCEPT_REQUEST:
    case ItemsActions.BULK_SET_TO_PRODUCTION:
    case ItemsActions.BULK_REJECT_ORDER:
    case ItemsActions.GET_PLATFORM_ITEMS_REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: true
        }
      };
    case ItemsActions.BULK_ACCEPT_DONE:
    case ItemsActions.BULK_SET_TO_PRODUCTION_DONE:
    case ItemsActions.BULK_REJECT_ORDER_DONE:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false
        },
        selection: initialState.selection
      };
    case ItemsActions.GET_PLATFORM_ITEMS_DONE:
      return {
        ...state,
        table: {
          ...state.table,
          isLoading: false,
          platformItemsByItemsId: action.platformItemsByItemsId
        }
      };
    case ItemsActions.GET_FOMA_ITEM_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.itemId]: {
            isLoading: true,
            data: null as unknown as OrderItemWithStatusAndOrderInfo,
            error: null
          }
        }
      };
    case ItemsActions.GET_FOMA_ITEM_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.itemId]: {
            isLoading: false,
            data: action.data,
            error: null
          }
        }
      };
    case ItemsActions.GET_FOMA_ITEM_ERROR:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.itemId]: {
            isLoading: false,
            data: null as unknown as OrderItemWithStatusAndOrderInfo,
            error: action.error
          }
        }
      };
    case ItemsActions.NOTIFY_DELAY_DONE:
      return {
        ...state,
        table: {
          ...state.table,
          platformItemsByItemsId: {
            ...state.table.platformItemsByItemsId,
            [action.itemId]: action.data
          }
        }
      };
    default:
      return state;
  }
}
