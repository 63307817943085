import React from 'react';
import { Button, ButtonProps } from '@cimpress/react-components';
import { trackEvent } from './utils';

export interface TrackedButtonProps extends ButtonProps {
  item: string;
}

export const TrackedButton: React.FC<TrackedButtonProps> = props => {
  const { item, onClick, ...buttonProps } = props;

  return <Button
    onClick={e => {
      trackEvent(item);
      if (onClick) {
        onClick(e);
      }
    }}
    {...buttonProps}
  />;
};
