import { apiRequest, ApiRequestOptions, ErrorResponse, SuccessResponse } from './apiClient';
import {
  getCachedResultByKey,
  getPendingPromiseByKey,
  setCachedResultAndClearPendingPromiseForKey,
  setPendingPromiseForKey
} from './runningPromiseCache';

export async function apiClientWithCachedResults<T>(accessToken: string, key: string, axiosOptions: ApiRequestOptions): Promise<SuccessResponse<T> | ErrorResponse<T> |undefined> {
  const alreadyCached = getCachedResultByKey(key);
  if (alreadyCached) {
    return alreadyCached;
  }

  let responsePromise = getPendingPromiseByKey(key);
  if (!responsePromise) {
    responsePromise = apiRequest<T>(axiosOptions);
  }

  setPendingPromiseForKey(key, responsePromise);
  const response: SuccessResponse<T> | ErrorResponse<T> = (await responsePromise);

  setCachedResultAndClearPendingPromiseForKey(key, response);
  return response;
}
