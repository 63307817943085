import { colors } from '@cimpress/react-components';
import * as React from 'react';

interface PrettyHeaderButtonProps {
  onClick: React.MouseEventHandler<React.DOMAttributes<HTMLSpanElement>>;
  icon: React.FC<{
      color?: string;
      size?: string;
      weight?: string;
      [any: string]: any;
  }>;
  label: React.ReactNode;
  activeLabel?: React.ReactNode;
}

export const PrettyHeaderButton: React.FC<PrettyHeaderButtonProps> = props => {
  const [isActive, setIsActive] = React.useState<boolean>(false);

  return (
    <>
      &nbsp;|&nbsp;
      <span className='internalLink' onClick={(...args) => {
        setIsActive(true);
        props.onClick(...args);
      }}>
        <props.icon color={colors.teal.base} style={{ verticalAlign: 'middle' }} />&nbsp;{isActive && props.activeLabel ? props.activeLabel : props.label}
      </span>
    </>
  );
};
