import axios, { AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { ErrorDetails } from '../store/commonTypes';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

export type ApiRequestMethod = 'get' | 'post' | 'head';
const domainsToRetryRedirectWithGetMethod: string[] = ['https://stereotype-service.s3.eu-west-1.amazonaws.com'];

export interface ApiRequestOptions {
  url: string;
  accessToken?: string;
  method?: ApiRequestMethod;
  query?: { [key: string]: unknown };
  data?: unknown;
  timeout?: number;
  headers?: Record<string, string>;
  responseType?: 'blob' | 'arraybuffer';
  dispatchErrorAction?: (details: object) => void;
  noErrorReportingForStatusCodes?: Array<number>;
  cancelTokenSource?: CancelTokenSource;
}

export interface ApiResponse<T = any> {
  data?: T;
  error?: ErrorDetails;
  status?: number;
  headers?: any;
}

export interface SuccessResponse<T = any> extends ApiResponse<T> {
  data: T;
}

export interface ErrorResponse<T = any> extends ApiResponse<T> {
  error: ErrorDetails;
}

export interface BatchOptions {
  batchSize?: number;
  noThrowErrors?: boolean;
}

export async function batchRequests<T>(promises: Promise<T>[], batchOptions: BatchOptions): Promise<T[]> {
  const batchSize = batchOptions.batchSize || 5;
  let results = [] as T[];

  let prs = promises;
  if (batchOptions.noThrowErrors) {
    prs = promises.map(p => p.catch(e => e));
  }

  for (let i = 0; i < prs.length; i = i + batchSize) {
    const batchResult = await Promise.all(prs.slice(i, i + batchSize));
    results = results.concat(batchResult);
  }

  return results;
}

const shouldReportInSentry = (err, axiosOptions: ApiRequestOptions): boolean => {
  // Hack: No need to log 401s to Sentry.
  const ignoredErrors = (axiosOptions.noErrorReportingForStatusCodes || []).concat([302, 401]);

  const msg = (err?.message || err?.response?.data || '').toLowerCase();
  const path = axiosOptions.url || '';

  if (ignoredErrors.includes(err?.response?.status)) {
    return false;
  }

  if (msg.includes('timeout') && path.includes('/item-search')) {
    // This usually happens for users with access to LOTS of fulfillers. The UI shows warning if fulfillers are >25
    // Do not report for now.
    return false;
  }

  if (msg.includes('timeout') && path.includes('/v2/ordersearch')) {
    // Calling OM is not mandatory for POM and the UI would continue to work fine.
    // Do not report for now.
    return false;
  }

  return true;
};

const isAxiosError = (error: any): error is AxiosError => { return error && error.isAxiosError; };
const handleRedirectAuthorizationError = <T>(options: AxiosRequestConfig, error: any) => {
  if (!(isAxiosError(error) && error.response)) {
    throw error;
  }

  const response = error.response;
  const wasRedirected: boolean = response.request?.responseURL && response.request?.responseURL !== options.url;

  if (![400, 401, 403].includes(response.status) || !wasRedirected) {
    throw error;
  }

  const parsedUrl = new URL(response.request.responseURL);
  const origin = `${parsedUrl.protocol}//${parsedUrl.host}`;

  if (!domainsToRetryRedirectWithGetMethod.includes(origin)) {
    throw error;
  }

  const headersWithoutAuthorization = Object.fromEntries(Object.entries(options.headers).filter(([key, _value]) => !['Authorization', 'authorization'].includes(key)));

  // Forcing redirection to GET method ALWAYS
  return axios.request<T>({
    ...options,
    url: response.request.responseURL,
    headers: headersWithoutAuthorization,
    method: 'GET',
    data: undefined
  });
};

export async function apiRequest<T>(options: ApiRequestOptions): Promise<SuccessResponse<T> | ErrorResponse<T> | null> {
  const axiosOptions: AxiosRequestConfig = {
    url: options.url,
    method: options.method || 'get',
    params: options.query,
    data: options.data,
    headers: options.headers ?? {},
    timeout: options.timeout ?? 20000,
    responseType: options.responseType,
    cancelToken: options.cancelTokenSource?.token
  };

  if (options.accessToken) {
    axiosOptions.headers.Authorization = `Bearer ${options.accessToken}`;
  }

  try {
    const response = await axios.request<T>(axiosOptions)
      .catch(err => handleRedirectAuthorizationError<T>(axiosOptions, err));
    return {
      data: response.data,
      headers: response.headers,
      status: response.status
    };
  } catch (error: any) {
    const err = error as AxiosError;
    const errorDetails = getErrorDetailsFromAxiosError<T>(err);
    if (!errorDetails) {
      return null as unknown as ErrorResponse<T>;
    }

    // handle error
    if (shouldReportInSentry(err, options)) {
      SentryWrapper.reportError(err);
    }

    if (options.dispatchErrorAction) {
      options.dispatchErrorAction(errorDetails.error.details);
    }

    return errorDetails;
  }
}

export const getErrorDetailsFromAxiosError = <T>(axiosError: AxiosError): ErrorResponse<T> | null => {
  const err = axiosError as AxiosError;
  if (axios.isCancel(axiosError)) {
    return null;
  }

  // handle error
  const details = err?.message || err?.response?.data || { message: 'No further details available' };

  const errorDetails = {
    error: {
      details,
      message: 'Request failed'
    },
    status: err.response && err.response.status
  };
  return errorDetails;
};
