import { ViewsActions } from '../storeActions';
import { AppState } from '../store';
import { getViews } from '@cimpress-technology/fulfillment-views';
import { defaultView, defaultViewWithoutManufacturingCost } from '../../assets/defaultView';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

export const POM_MAJOR_VIEW_VERSION = 1;

const getViewsSuccess = values => ({
  type: ViewsActions.GET_VIEWS_SUCCESS,
  data: values
});

const getViewsError = error => ({
  type: ViewsActions.GET_VIEWS_ERROR,
  data: null,
  error: error
});

export const getViewsRequest = () => async (dispatch, getState) => {
  dispatch({ type: ViewsActions.GET_VIEWS_REQUEST });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  try {
    let views = await getViews(accessToken, {});
    const newState = getState() as AppState;

    if (!views || views.length === 0) {
      // In case no view has been explicitly granted - use the default one
      // Initially, this default view was modelled with pattern grant '*::*' in COAM
      // But there is no 'negative' access grant so we couldn't have removed access for certain users
      views.push(defaultView, defaultViewWithoutManufacturingCost);
    }

    if (newState.settings?.customPomV2View && (newState.settings?.customPomV2View?.version || 1) === POM_MAJOR_VIEW_VERSION) {
      // This is a hack/workaround. Extensions are NOT supposed to be user-based
      // Keep this until all custom views are auto-cleaned.
      newState.settings.customPomV2View.extensions = defaultView.extensions;
      views = views.concat(newState.settings.customPomV2View);
    }

    dispatch(getViewsSuccess(views));
  } catch (e) {
    SentryWrapper.reportError(e);
    dispatch(getViewsError(e));
  }
};
