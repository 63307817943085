import { CustomizrClient } from 'cimpress-customizr';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

const PREFERRED_FULFILLERS_CUSTOMIZR_RESOURCE = 'https://manage.production.cimpress.io';

export const storeSelectedFulfillerIds = (accessToken: string, selectedFulfillerIds: string[]) => {
  const customizrClient = new CustomizrClient({
    resource: PREFERRED_FULFILLERS_CUSTOMIZR_RESOURCE
  });

  customizrClient
    .getSettings(accessToken)
    .then(data => {
      const newData = Object.assign({}, data);
      newData.selectedFulfillerIds = selectedFulfillerIds;
      return customizrClient
        .putSettings(accessToken, newData)
        .catch(e => {
          SentryWrapper.reportError(e);
        });
    })
    .catch(e => {
      SentryWrapper.reportError(e);
    });
};

export const getSelectedFulfillerIds = (accessToken: string) => {
  const customizrClient = new CustomizrClient({
    resource: PREFERRED_FULFILLERS_CUSTOMIZR_RESOURCE
  });

  return customizrClient
    .getSettings(accessToken)
    .then(data => {
      const selected = data.selectedFulfillerIds || [];
      return selected;
    })
    .catch(e => {
      SentryWrapper.reportError(e);
    });
};
