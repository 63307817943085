import React from 'react';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { TrackedButton } from '../../../trackingLayer/trackedButton';

export const ShipButton: React.FC<ShipButtonProps> = props => {
  return <TrackedButton item="ship" type={'primary'}
    disabled={props.disabled}
    onClick={() => {
      props.history.push({
        pathname: '/items/shipping',
        search: `?${props.items.filter(it => it && !it.status?.statusDetails.rejected).map(i => `itemId=${i.itemId}`).join('&')}`
      });
    }}>
    {props.t('buttons.ship')}
  </TrackedButton>;
};

interface ShipButtonProps {
  t: Function;
  history: any;
  disabled?: boolean;
  items: OrderItemWithStatusAndOrderInfo[];
}

