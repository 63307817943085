import { apiRequest, ErrorResponse, SuccessResponse } from '../apiClient';
import { PlatformOrderItem, PlatformSearchOrderItem } from './platformItemsModel';
import { Nullable } from '../../store/commonTypes';
import { itemServiceUrl } from '../../utils/environmentProvider';
import {
  clearCachedResultAndPendingPromiseForKey,
  getCachedResultByKey,
  getPendingPromiseByKey,
  setCachedResultAndClearPendingPromiseForKey,
  setPendingPromiseForKey
} from '../runningPromiseCache';

// TODO: Add expiry for the cache
const cache = {};

// DO NOT EXPORT FOR NOW
interface OrderManagerSearchResponse {
  results: OrderManagerSearchResponseResultItem[];
}

interface OrderManagerSearchResponseResultItem {
  order: OrderManagerSearchResponseOrder;
}

interface OrderManagerSearchResponseOrder {
  items: PlatformSearchOrderItem[];
}

export class PlatformClient {
  async getItemByShortItemId(accessToken, shortItemId) {
    const itemKey = `platform-item-for-${shortItemId}`;
    const alreadyCached = getCachedResultByKey(itemKey);
    if (alreadyCached) {
      return alreadyCached;
    }

    let responsePromise = getPendingPromiseByKey(itemKey);
    if (!responsePromise) {
      responsePromise = apiRequest<{ items: PlatformOrderItem[] }>({
        url: `${itemServiceUrl}/v1/items`,
        headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
        accessToken,
        query: { shortItemId }
      });
    }

    setPendingPromiseForKey(itemKey, responsePromise);
    const response: SuccessResponse<{ items: PlatformOrderItem[] }> | ErrorResponse<{ items: PlatformOrderItem[] }> = (await responsePromise);

    const item = Object.values(response.data?.items || {}).find((it: PlatformOrderItem) => it.shortItemId === shortItemId.toUpperCase());

    setCachedResultAndClearPendingPromiseForKey(itemKey, item);

    return item;
  }

  async getPlatformItem(accessToken, platformItemLink): Promise<Nullable<PlatformOrderItem>> {
    const alreadyCached = getCachedResultByKey(platformItemLink);
    if (alreadyCached) {
      return alreadyCached;
    }

    let responsePromise = getPendingPromiseByKey(platformItemLink);
    if (!responsePromise) {
      responsePromise = apiRequest<PlatformOrderItem>({
        url: platformItemLink,
        headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
        accessToken,
        query: {}
      });
      setPendingPromiseForKey(platformItemLink, responsePromise);
    }

    const response = await responsePromise;

    if (response.error) {
      Object.assign(cache, { [platformItemLink]: response.error });
      clearCachedResultAndPendingPromiseForKey(platformItemLink);
      throw response;
    }

    setCachedResultAndClearPendingPromiseForKey(platformItemLink, response.data);
    return response.data;
  }

  async searchPlatformItemsByIds(accessToken: string, itemIds: string[]): Promise<Record<string, PlatformSearchOrderItem>|undefined> {
    const response = await apiRequest<OrderManagerSearchResponse>({
      url: 'https://order-manager.commerce.cimpress.io/v2/ordersearch',
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      accessToken,
      data: {
        ids: itemIds,
        pageSize: 100
      }
    });

    if (response?.error) {
      throw response;
    }

    const items = {};
    response?.data?.results?.forEach(resultItem => {
      resultItem.order.items.forEach(it => {
        if (itemIds.includes(it.shortItemId)) {
          items[it.shortItemId] = it;
        }
      });
    });

    return items;
  }
}
