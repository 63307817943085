
const pendingPromiseByKey: Record<string, Promise<any>> = {};
const cachedResultByKey: Record<string, any> = {};

export const getPendingPromiseByKey = (key: string): Promise<any> => {
  return pendingPromiseByKey[key];
};

export const clearPendingPromiseForKey = (key: string) => {
  pendingPromiseByKey[key] = null as unknown as Promise<any>;
};

export const setPendingPromiseForKey = (key: string, p: Promise<any>) => {
  Object.assign(pendingPromiseByKey, { [key]: p });
};

export const getCachedResultByKey = (key: string): any => {
  return cachedResultByKey[key];
};

export const setCachedResultAndClearPendingPromiseForKey = (key: string, r: any) => {
  Object.assign(cachedResultByKey, { [key]: r });
  clearPendingPromiseForKey(key);
};

export const clearCachedResultAndPendingPromiseForKey = (key: string) => {
  cachedResultByKey[key] = null as unknown as any;
  clearPendingPromiseForKey(key);
};
