import React, { useEffect } from 'react';
import { getFomaOrderItem } from '../../../store/items/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { FomaItemState } from '../../../store/items/types';
import { usePlatformOrderItem } from '../../shared/hooks/usePlatformOrderItem';
import { useTranslation } from 'react-i18next';
import { ItemFulfillmentPreview } from '../../shared/itemFulfillmentPreview';

interface ItemPreviewProps {
  itemId: string;
  documentReferenceUrlOverride?: string;
}

export const ItemPreview: React.FC<ItemPreviewProps> = ({ itemId, documentReferenceUrlOverride }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const orderItemsMap = useSelector((state: AppState): Record<string, FomaItemState> => state.items.byId || {});
  const { auth } = useSelector((state: AppState) => state);
  const { accessToken } = auth;

  const itemInfo = orderItemsMap[itemId];
  const platformItemLink = ((itemInfo?.data?.links || {})['platform-item'] || {}).href;
  const { platformOrderItem } = usePlatformOrderItem(accessToken, platformItemLink, itemId || null);

  useEffect(() => {
    const info = orderItemsMap[itemId];
    if (!info) {
      dispatch(getFomaOrderItem(itemId));
    }
  }, [orderItemsMap, dispatch, itemId]);

  if (!itemId || !itemInfo?.data?.product?.sku || !(documentReferenceUrlOverride || platformOrderItem?.documentReferenceUrl)) {
    return <span>{t('shipping.no_preview')}</span>;
  }

  return <ItemFulfillmentPreview
    pxSize={90}
    minimal={true}
    hideIndicators
    accessToken={accessToken}
    skuCode={itemInfo?.data?.product?.sku}
    fomaPreviewUrl={itemInfo?.data?.links?.preview?.href}
    manufacturingReadyDataUrl={platformOrderItem?.manufacturingReadyDataUrl}
    documentReferenceUrl={documentReferenceUrlOverride || platformOrderItem?.documentReferenceUrl}
    variableAttributes={platformOrderItem?.variableAttributes || []}
    standardOutputUrl={platformOrderItem?._links.standardOutputUrl?.href}
  />;
};
