import React from 'react';
import { renderKeyValue } from './renderKeyValue';
import {
  ManufacturingDetails,
  SpotColorInsights,
  BoundingBox,
  ThreadColor
} from '../../clients/foma/manufacturingDetailsModel';

const renderSpotColor = (c?: SpotColorInsights) => {
  if (!c) {
    return null;
  }

  if (!c.spotColors) {
    return c.spotColor;
  }

  return <span>{c.spotColors.map((sc, i) => {
    return <div key={i} style={{ marginTop: '2px' }}>
      {sc.name}
      &nbsp;
      -
      &nbsp;
      <span style={{
        backgroundColor: `rgba(${sc.color.red},${sc.color.green},${sc.color.blue},1)`,
        paddingLeft: '5px',
        paddingRight: '5px',
        marginRight: '5px'
      }}> &nbsp;
      </span>
      rgb({sc.color.red}, {sc.color.green}, {sc.color.blue})
    </div>;
  })}</span>;
};

const renderBoundingBox = (box?: BoundingBox) => {
  if (!box) {
    return null;
  }
  return <span>{box.width}mm x {box.height}mm</span>;
};

const renderThreadColors = (t, threadColors?: ThreadColor[]) => {
  if (!threadColors) {
    return null;
  }
  return <span>
    {threadColors.map((tc, index) => {
      return <div key={index}>{index}.
        {t('manufacturingDetails.embroidery.stitchNumber')}:
        {tc.stitchNumber},
        {t('manufacturingDetails.embroidery.color')}: {tc.colorId} {tc.colorName} ({tc.colorManufacturer})</div>;
    })}
  </span>;
};

const renderMRDSpotColors = (colors: string[]) => {
  if (!colors) {return null;}
  if (!Array.isArray(colors)) {return null;}
  return <span>
    { colors.map((tc, index) => {
      if (typeof tc === 'object') {
        return <div key={index}>{JSON.stringify(tc)}</div>;
      }
      // TODO: This actually may still fail...
      if (Array.isArray(tc)) {
        return <div key={index}>{tc.join(', ')}</div>;
      }
      return <div key={index}>{tc}</div>;
    })}
  </span>;
};

export const renderManufacturingDetails = (t, manufacturingDetails: ManufacturingDetails, colors: string[]) => {
  return manufacturingDetails && (manufacturingDetails.insights.padPrinting
    || manufacturingDetails.insights.inkJet
    || manufacturingDetails.insights.screenPrinting
    || manufacturingDetails.insights.embroidery)
    ? <div className='item-details-box'>
      {manufacturingDetails.insights.padPrinting ? <h5>{t('details.manufacturingDetails.padPrinting')}</h5> : null}
      {renderKeyValue(t('manufacturingDetails.padPrinting.spotColor'), renderSpotColor(manufacturingDetails.insights.padPrinting))}

      {manufacturingDetails.insights.inkJet ? <h5>{t('details.manufacturingDetails.inkJet')}</h5> : null}
      {renderKeyValue(t('manufacturingDetails.inkJet.spotColor'), renderSpotColor(manufacturingDetails.insights.inkJet))}

      {manufacturingDetails.insights.screenPrinting ? <h5>{t('details.manufacturingDetails.screenPrinting')}</h5> : null}
      {renderKeyValue(t('manufacturingDetails.screenPrinting.spotColor'), renderSpotColor(manufacturingDetails.insights.screenPrinting))}

      {manufacturingDetails.insights.embroidery ? <h5>{t('details.manufacturingDetails.embroidery')}</h5> : null}
      {renderKeyValue(t('manufacturingDetails.embroidery.numberOfStitches'), manufacturingDetails.insights.embroidery?.numberOfStitches)}
      {renderKeyValue(t('manufacturingDetails.embroidery.boundingBoxSize'), renderBoundingBox(manufacturingDetails.insights.embroidery?.boundingBoxSize))}
      {renderKeyValue(t('manufacturingDetails.embroidery.threadColors'), renderThreadColors(t, manufacturingDetails.insights.embroidery?.threadColors))}
    </div>
    : colors.length > 0 ? <div className='item-details-box'>
      <h5>{t('manufacturingDetails.title')}</h5>
      {renderKeyValue(t('manufacturingDetails.colors'), renderMRDSpotColors(colors))}
    </div> : null;
};
