import { getDeliveryRequest } from '../clients/delivery/deliveryRequests';
import { ExtendedOrderItem } from '../clients/foma/itemsClient';

export const getDeliveryCountryDetails = async(items: ExtendedOrderItem[], accessToken: string) => {
  await Promise.all(items.map(async item => {
    if (!item.orderInfo?.destinationAddress) {
      const deliveryDetails = await Promise.all(item.deliveryDetails.map(deliveryDetail => getDeliveryRequest(accessToken, deliveryDetail?.links?.self?.href)));
      item.deliveryDetails.forEach((dd, i) => {
        dd.destinationAddress = deliveryDetails[i]?.destinationAddress;
      });
    }
  }));
  return items;
};
  
