import { Alert } from '@cimpress/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { Loading } from '../../shared/loading';
import { usePlatformOrderItem } from '../../shared/hooks/usePlatformOrderItem';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { ItemFulfillmentPreview } from '../../shared/itemFulfillmentPreview';

interface CancellationItemProps {
  item: OrderItemWithStatusAndOrderInfo;
  notificationItem: any;
  disabled: boolean;
  isLoading: boolean;
  onAccept: (costsIncurred: boolean) => void;
  onReject: () => void;
}

export const CancellationItem: React.FC<CancellationItemProps> = ({ item, isLoading, notificationItem, disabled, onReject, onAccept }) => {
  const { t } = useTranslation();
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const { useMerchantIds } = useSelector((state: AppState) => state.settings);

  const platformItemLink = ((item?.links || {})['platform-item'] || {}).href;
  const { loadingPlatformOrderItem, platformOrderItem } = usePlatformOrderItem(accessToken, platformItemLink, item?.itemId || null);

  if (!item) {
    return <div>
      <hr/>
      <Alert type={'warning'} message={`${t('pendingActions.cancellations.errors.missingItem')}`}/>
    </div>;
  }

  const itemId = useMerchantIds ? item.merchantInformation?.itemId || item.itemId : item.itemId;

  if (isLoading) {
    return <div>
      <Loading message={t('common.loading')}/>
    </div>;
  }

  let actionsOrStatus = <div>
    <TrackedButton item="cancellation.confirmNoCosts" type={'primary'} disabled={disabled} onClick={() => {
      onAccept(false);
    }}>
      {t('pendingActions.cancellations.buttons.confirmNoCosts')}
    </TrackedButton>
    &nbsp;&nbsp;
    <TrackedButton item="cancellation.confirmWithCosts" type={'primary'} disabled={disabled} onClick={() => {
      onAccept(true);
    }}>
      {t('pendingActions.cancellations.buttons.confirmWithCosts')}
    </TrackedButton>
    &nbsp;&nbsp;
    <TrackedButton item="cancellation.rejectCancellation" type={'dark'} disabled={disabled} onClick={() => {
      onReject();
    }}>
      {t('pendingActions.cancellations.buttons.rejectCancellation')}
    </TrackedButton>
  </div>;

  if (notificationItem.status === 'Rejected') {
    actionsOrStatus = <div className={'label label-warning'}>{t('cancellationPage.items.couldNotCancel')}</div>;
  }

  if (notificationItem.status === 'Accepted') {
    actionsOrStatus = <div className={'label label-success'}>{t('cancellationPage.items.itemAccepted')}</div>;
  }

  return <div>
    <hr/>
    <div className='clearfix'>
      <div className='imagePreviewContainer'>
        {loadingPlatformOrderItem ? null
          : <ItemFulfillmentPreview
            pxSize={168}
            minimal={true}
            hideIndicators
            accessToken={accessToken}
            skuCode={item.product?.sku}
            fomaPreviewUrl={item.links?.preview?.href}
            manufacturingReadyDataUrl={platformOrderItem?.manufacturingReadyDataUrl}
            documentReferenceUrl={platformOrderItem?.documentReferenceUrl}
            variableAttributes={platformOrderItem?.variableAttributes || []}
            standardOutputUrl={platformOrderItem?._links.standardOutputUrl?.href}
          />}
      </div>
      <h2>{itemId}</h2>
      <h4>{item.product?.name}</h4>
      <h4>SKU: {item.product?.sku}</h4>
      <h6>{item.product?.description}</h6>

      {actionsOrStatus}

    </div>
  </div>;
};
