import { apiClientWithCachedResults } from '../apiClientWithCachedResults';

export interface CarrierServiceInfo {
  key: string;
  name: string;
}

export async function getCarrierServiceKeys(accessToken, locationId): Promise<CarrierServiceInfo[] | null> {
  const url = `https://shipping-calculator.logistics.cimpress.io/api/v2/fulfillmentLocations/${locationId}/resources/carrierServices/latestConfigurations`;
  const response = await apiClientWithCachedResults<any>(accessToken, url, {
    url,
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken: accessToken,
    noErrorReportingForStatusCodes: [404]
  });

  const data = response?.data || null;

  return data ? data.map(a => {
    return {
      key: a.tags.find(t => t.key === 'recs:carrierServiceKey')?.value || a.name,
      name: a.name
    };
  }) : [];
}
