import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../utils/dateTimeUtils';
import { Dropdown, Table } from '@cimpress/react-components';
import { Notification } from '../../clients/foma/notificationActionClient';
import { StatusKeysToDisplay } from '../../utils/statusInfo';

import { renderList } from './utils';
import { FomaItemState } from '../../store/items/types';
import { tableTranslations } from '../../utils/tableTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { getFomaOrderItem } from '../../store/items/actions';
import { ColumnWidths, usePersistentColumnResizing } from '../shared/hooks/usePersistentColumnResizing';
import { getCustomizrSettings, pomApplicationResourceId, setColumnWidths } from '../../store/settings/actions';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

interface ShipmentRequirementChangesTableProps {
  isLoading: boolean;
  useMerchantIds: boolean;
  pageSize: number;
  resetWidthsCounter: number;
  shipmentRequirementChangeRequests: Notification[];
  onSelectionChanged: (selected: Record<string, boolean>) => void;
  onAccept: (notificationId: string) => void;
  onReject: (notificationId: string) => void;
}

const renderDiff = (caption, oldText, newText) => {
  if ((oldText || '').trim() === (newText || '').trim()) {
    return <span className='diffItem'>
      <span className='noDiffCaption'>{caption}:</span> {oldText}
    </span>;
  }
  return <span className='diffItem'>
    <span className='diffCaption'>{caption}:</span> <span className='diffOldText'>{oldText}</span> <span className='diffNewText'>{newText}</span>
  </span>;
};

const renderShipmentRequirementChange = (t, oldShipmentRequirements1, shipmentRequirements1) => {
  const oldShipmentRequirements = oldShipmentRequirements1 || {};
  const shipmentRequirements = shipmentRequirements1 || {};
  return <>
    {renderDiff(t('shipmentRequirementDetails.dispatchCountry'), oldShipmentRequirements.dispatchCountry, shipmentRequirements.dispatchCountry)}
    {renderDiff(t('shipmentRequirementDetails.dispatchPostalCode'), oldShipmentRequirements.dispatchPostalCode, shipmentRequirements.dispatchPostalCode)}
    {renderDiff(
      t('shipmentRequirementDetails.carrierServices'),
      oldShipmentRequirements.carrierServices?.sort().join(),
      shipmentRequirements.carrierServices?.sort().join()
    )}
    {renderDiff(
      t('shipmentRequirementDetails.carrierServiceCapabilities'),
      oldShipmentRequirements.carrierServiceCapabilities?.sort().join(),
      shipmentRequirements.carrierServiceCapabilities?.sort().join()
    )}
    {renderDiff(t('shipmentRequirementDetails.promisedArrivalDate'), oldShipmentRequirements.promisedArrivalDate, shipmentRequirements.promisedArrivalDate)}
  </>;
};

const customCellWidth: ColumnWidths = {
  notificationId: 100,
  createdDate: 140,
  orderId: 140,
  actions: 160
};

const tableId = 'pendingChanges_shipmentRequirementChanges';

const ShipmentRequirementChangesTable: React.FC<ShipmentRequirementChangesTableProps> = ({
  isLoading, shipmentRequirementChangeRequests, onSelectionChanged, useMerchantIds, onAccept, onReject, pageSize, resetWidthsCounter
}) => {
  const settings = useSelector((state: AppState) => state.settings);
  const currentColumnWidths = settings.customColumnWidths?.[tableId];
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const orderItemsMap = useSelector((state: AppState): Record<string, FomaItemState> => state.items.byId || {});
  const [selectedRows, setSelectedRows] = useState({});
  const [loadedNotifications, setLoadedNotifications] = useState({});
  const [selectedPage, setSelectedPage] = useState(0);
  const { t } = useTranslation();
  const dateFormatter = useDateTimeFormatter(true);
  const dateFormatFunc = date => dateFormatter(new Date(date));
  const dispatch = useDispatch();
  const { handleOnResizedChange, applyColumnWidths, resetWidths } = usePersistentColumnResizing(accessToken,
    pomApplicationResourceId,
    tableId,
    currentColumnWidths,
    customCellWidth,
    (_tableId, newColumnSizes) => dispatch(setColumnWidths(tableId, newColumnSizes)),
    SentryWrapper.reportError);

  useEffect(() => {
    if (resetWidthsCounter === 0) { return; }
    resetWidths(() => dispatch(getCustomizrSettings()));
  }, [resetWidthsCounter, resetWidths, dispatch]);

  useEffect(() => {
    const firstItem = selectedPage * pageSize;
    const shipmentRequirementChangeRequestsCurrentPage = shipmentRequirementChangeRequests.slice(firstItem, firstItem + pageSize);
    shipmentRequirementChangeRequestsCurrentPage
      .forEach(notification => {
        if (!loadedNotifications[notification.notificationId]) {
          setLoadedNotifications(Object.assign(loadedNotifications, { [notification.notificationId]: true }));
          notification.items.forEach(item => {
            const itemInfo = orderItemsMap[item.itemId];
            if (!itemInfo) {
              dispatch(getFomaOrderItem(item.itemId));
            }
          });
        }
      });
  }, [loadedNotifications, selectedPage, shipmentRequirementChangeRequests, orderItemsMap, dispatch, pageSize]);

  const columns = [
    {
      id: 'notificationId',
      Header: t('pendingActions.shipmentRequirementChange.table.id'),
      show: false,
      accessor(shipmentRequirementChangeRequest): string {
        return shipmentRequirementChangeRequest.notificationId;
      },
      Cell(row) {
        return `${row.original.notificationId.substring(0, 8)}...`;
      }
    },
    {
      id: 'createdDate',
      Header: t('pendingActions.shipmentRequirementChange.table.createdAt'),
      accessor: shipmentRequirementChangeRequest => shipmentRequirementChangeRequest.createdDate,
      Cell: shipmentRequirementChangeRequest => dateFormatFunc(shipmentRequirementChangeRequest.original.createdDate)
    },
    {
      id: 'items',
      Header: t('pendingActions.shipmentRequirementChange.table.items'),
      accessor: shipmentRequirementChangeRequest => (shipmentRequirementChangeRequest.items || []).map(it => it.itemId).join(', '),
      Cell(shipmentRequirementChangeRequest) {
        const textItems: string[] = [];
        (shipmentRequirementChangeRequest.original.items || []).forEach(it => {
          const item = orderItemsMap[it.itemId];
          if (item?.isLoading === true) {
            textItems.push(`${it.itemId} - ${t('common.loading')} ...`);
          } else {
            const detailedStatus = item?.data?.status || {};
            const totalQuantity = item?.data?.orderedQuantity;
            const itemId = useMerchantIds ? item?.data?.merchantInformation?.itemId || it.itemId : it.itemId;
            let itemText = `${itemId}${totalQuantity > 1 ? ` (${totalQuantity})` : ''} - `;
            let trimFront = true;
            StatusKeysToDisplay.forEach(k => {
              if (detailedStatus && detailedStatus.statusDetails && detailedStatus.statusDetails[k]) {
                const quantity = detailedStatus.statusDetails[k].quantity;
                itemText += `${trimFront ? '' : ', '}${t(`statuses.${k}`)}${quantity > 1 ? ` (${quantity})` : ''}`;
                trimFront = false;
              }
            });
            textItems.push(itemText.replace('- ,', '-'));
          }
        });
        return renderList(textItems);
      },
      sortable: false
    },
    {
      Header: t('pendingActions.shipmentRequirementChange.table.orderId'),
      id: 'orderId',
      accessor: item => useMerchantIds
        ? orderItemsMap[item.items[0].itemId]?.data?.orderInfo?.merchantInformation?.orderId || item.order.orderId
        : item.order.orderId,
      sortable: false
    },
    {
      Header: t('pendingActions.shipmentRequirementChange.table.newShipmentRequirements'),
      id: 'newShipmentRequirements',
      accessor: shipmentRequirementChangeRequest => shipmentRequirementChangeRequest.changeRequest?.toString(),
      Cell(row) {
        const shipmentRequirements = {
          ...row.original.changeRequest?.shipmentRequirementChangeDetails?.deliveryConstraints,
          promisedArrivalDate: row.original.changeRequest?.shipmentRequirementChangeDetails?.promisedArrivalDate
        };
        const oldShipmentRequirements = {
          ...orderItemsMap[row.original.items[0].itemId]?.data?.orderInfo?.deliveryConstraints,
          promisedArrivalDate: orderItemsMap[row.original.items[0].itemId]?.data?.orderInfo?.promisedArrivalDate
        };
        return renderShipmentRequirementChange(t, oldShipmentRequirements, shipmentRequirements);
      },
      sortable: false
    },
    {
      Header: t('pendingActions.shipmentRequirementChange.table.actions'),
      id: 'actions',
      accessor: item => item.order.orderId,
      Cell(row) {
        return <Dropdown title={t('pendingActions.shipmentRequirementChange.table.actions')}>
          <button onClick={() => {
            onAccept(row.original.notificationId);
          }}>{t('pendingActions.shipmentRequirementChange.buttons.accept')}</button>
          <button onClick={() => {
            onReject(row.original.notificationId);
          }}>{t('pendingActions.shipmentRequirementChange.buttons.reject')}</button>
        </Dropdown>;
      },
      sortable: false
    }
  ];

  applyColumnWidths(columns);

  const selectAll = !shipmentRequirementChangeRequests.some(cr => !selectedRows[cr.notificationId]);

  return <>
    <Table
      columns={columns}
      data={shipmentRequirementChangeRequests}
      condensed={false}
      loading={isLoading}
      selectable={false}
      toggleSelection={key => {
        const rowId = key.split('select-')[1];
        const newSelectedRows = Object.assign({}, selectedRows, { [rowId]: !selectedRows[rowId] });
        setSelectedRows(newSelectedRows);
        onSelectionChanged(newSelectedRows);
      }}
      toggleAll={() => {
        const newSelectedRows = {};
        if (!selectAll) {
          shipmentRequirementChangeRequests.forEach(cr => newSelectedRows[cr.notificationId] = true);
        }
        setSelectedRows(newSelectedRows);
        if (onSelectionChanged) {
          onSelectionChanged(newSelectedRows);
        }
      }}
      selectAll={selectAll}
      keyField={'notificationId'}
      isSelected={key => {
        return !!selectedRows[key];
      }}
      filterable={false}
      pageSize={shipmentRequirementChangeRequests.length === 0 ? pageSize : Math.min(shipmentRequirementChangeRequests.length, pageSize)}
      onPageChange={setSelectedPage}
      onResizedChange={handleOnResizedChange}
      showPageSizeOptions={false}
      {...tableTranslations(t)}
      noDataText={t('pendingActions.noDataText')}
    />
  </>;
};

export {
  ShipmentRequirementChangesTable
};
