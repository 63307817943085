export const sortingKeysMap = {
  'product.name': 'productName',
  'quantity': 'completedQuantity',
  'forecastedshipdate': 'expectedShipTime',
  'plan.shipping.expectedShipTime': 'expectedShipTime',
  'itemId': 'itemId',
  'order.orderId': 'orderId',
  'promiseddelivery': 'promisedArrivalDate',
  'promisedArrivalDate': 'promisedArrivalDate',
  'order.promisedArrivalDate': 'promisedArrivalDate',
  'status.lastUpdated': 'lastUpdateDate',
  'orderInfo.fulfiller.fulfillerId': 'fulfillerName'
};

interface SearchRequestTerms {
  fulfillerIds: string[];
  status?: string;
  forecastedLate?: boolean;
  planScheduled?: boolean;
  productCategories?: string[];
  deliveryOptionIds?: string[];
}

interface SearchRequestDateRanges {
  createdDate?: ItemSearchDateRangeOperator[];
  expectedShipTime?: ItemSearchDateRangeOperator[];
}

export interface ItemSearchDateRangeOperator {
  operator: string;
  dateTime: string;
}

interface SearchRequestSort {
  field: string;
  direction: string;
}

export interface ItemSearchRequestModel {
  terms: SearchRequestTerms;
  dateRanges?: SearchRequestDateRanges;
  fulltext?: string[];
  pageSize?: number;
  offset?: number;
  sort?: SearchRequestSort[];
}
