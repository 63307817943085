import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Alert, Breadcrumbs, BreadcrumbItem, Tooltip, RadioGroup, Radio } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';

import { AppState } from '../../../store/store';
import { ShipItemCard } from './shipItemCard';
import { ShipForm } from './shipForm';
import { Loading } from '../../shared/loading';
import { renderDeliveryDetail } from '../../shared/deliveryAddress';
import './shipping.scss';
import { NotificationTypes } from '../../../clients/foma/notificationActionClient';
import { DeliveryDetails, OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { getRemainingQuantity } from '../quantities';
import { FomaItemState } from '../../../store/items/types';
import { getFomaOrderItem } from '../../../store/items/actions';
import { itemsCheckAssumingNoSplitShipping, itemsCheckWithSplitShipping } from './helpers/itemsCheck';
import { renderItemsShippedInfo } from './helpers/renderItemsShippedInfo';
import { useSelectedView } from '../../shared/hooks/useSelectedView';
import { TrackedLink } from '../../../trackingLayer/trackedLink';
import { TrackedLocalLink } from '../../../trackingLayer/trackedLocalLink';
import { useDeliveryRequests } from '../../shared/hooks/useDeliveryRequests';
import { shippingConsoleUrl } from '../../../utils/environmentProvider';

const getQuantitiesToShip = (items: OrderItemWithStatusAndOrderInfo[], overrides, otherOverrides): Record<string, number> => {
  const quantitiesToShip = {};
  items.forEach(it => {
    quantitiesToShip[it.itemId] = overrides[it.itemId] || (otherOverrides || {})[it.itemId] || getRemainingQuantity(it.orderedQuantity, it.status);
  });
  return quantitiesToShip;
};

export const ShipItemsPage: React.FC = () => {
  const itemIds = new URLSearchParams(window.location.search).getAll('itemId') || [];
  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const { useMerchantIds } = useSelector((state: AppState) => state.settings);
  const { selectedView } = useSelectedView();
  const dispatch = useDispatch();

  // const { providedItemsLoading, providedItems, setProvidedItems } = useOrderItemsWithStatus(accessToken, itemIds);
  // items from redux
  const orderItemsById = useSelector((state: AppState): Record<string, FomaItemState> => {
    const items = {} as unknown as Record<string, FomaItemState>;
    itemIds.forEach(itemId => {
      items[itemId] = state.items.byId[itemId] || {};
    });
    return items;
  });

  useEffect(() => {
    itemIds.forEach(itemId => {
      if (!orderItemsById[itemId] || (!orderItemsById[itemId].data && !orderItemsById[itemId].isLoading)) {
        dispatch(getFomaOrderItem(itemId));
      }
    });
  }, [dispatch, itemIds, orderItemsById]);

  let deliveryRequestUrls: string[] = [];
  let deliveryDetails: DeliveryDetails[] = [];
  itemIds.forEach(itemId => {
    if (orderItemsById[itemId] && orderItemsById[itemId].data) {
      deliveryDetails = deliveryDetails.concat(orderItemsById[itemId].data?.deliveryDetails || []);
      deliveryRequestUrls = deliveryRequestUrls.concat((orderItemsById[itemId].data?.deliveryDetails || []).map(a => a?.links?.self?.href).filter(a => a));
    }
  });

  const { loadingDeliveryRequests, deliveryRequestsMap } = useDeliveryRequests(accessToken, deliveryRequestUrls);

  const [itemsShippedData, setItemsShippedData] = useState();
  const [quantityToShipByItemId, setQuantityToShipByItemId] = useState({});
  const [itemIdsPendingToShip, setItemIdsPendingToShip] = useState(itemIds);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const [selectedFulfillmentLocationId, setSelectedFulfillmentLocationId] = useState<string | null>(null);
  const [selectedDeliveryDetail, setSelectedDeliveryDetail] = useState(null as unknown as DeliveryDetails);

  const { t } = useTranslation();

  const orderItems = Object.values(orderItemsById);
  const itemsList = Object.values(orderItemsById || {}).map(a => a.data).filter(a => a);

  const notifications = useSelector((state: AppState) => Object.values((state.notifications || {}).notifications || {}));
  const itemNotifications = {};
  notifications.forEach(nt => {
    nt.items.forEach(it => {
      if (!itemNotifications[it.itemId]) {
        itemNotifications[it.itemId] = {};
      }
      itemNotifications[it.itemId][nt.type] = true;
    });
  });

  const isLoadingItems = orderItems.filter(a => a.isLoading).length > 0;

  const shownItemId = useMerchantIds && (itemsList.length === 1)
    ? itemsList[0]?.merchantInformation?.itemId || itemsList[0]?.itemId
    : (itemsList.length === 1)
      ? itemsList[0]?.itemId
      : null;

  const pendingItemsList = itemsList.filter(item => itemIdsPendingToShip.includes(item.itemId));

  const haveItemsWithSplitShipping = pendingItemsList.length > 0 && pendingItemsList.some(a => a.deliveryDetails && a.deliveryDetails.length > 1);

  const itemsCheckResult = isLoadingItems || loadingDeliveryRequests
    ? undefined
    : (haveItemsWithSplitShipping
      ? itemsCheckWithSplitShipping(t, pendingItemsList)
      : itemsCheckAssumingNoSplitShipping(t, pendingItemsList, deliveryRequestsMap));

  if (itemsCheckResult?.haveValidItems && selectedDeliveryDetail === null) {
    // Hack... ok, assuming all checks work correctly, the line below should be safe
    setSelectedDeliveryDetail(pendingItemsList[0]?.deliveryDetails[0]);
  }

  const showAddressOnRight = selectedView?.extensions.ui.screens.shipping.deliveryAddress.show && !itemsCheckResult?.haveMultipleAddresses;
  const shippingConsoleQueryParams = [
    pendingItemsList.map(it => `itemId=${it.itemId}`).join('&'),
    selectedFulfillmentLocationId ? `fulfillmentLocationId=${selectedFulfillmentLocationId}` : ''
  ].filter(s => s).join('&');

  const overrides = {};
  if (selectedDeliveryDetail && haveItemsWithSplitShipping) {
    overrides[pendingItemsList[0]?.itemId || ''] = selectedDeliveryDetail.quantity;
  }

  const quantitiesToShip = getQuantitiesToShip(itemsList, quantityToShipByItemId, overrides);

  return (
    <div className='container-fluid'>

      <div className='shippingBreadcrumbs'>
        <Breadcrumbs>
          <BreadcrumbItem>
            <TrackedLocalLink item={'breadcrumbs.home'} to={'/'}>{t('breadcrumbs.home')}</TrackedLocalLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <TrackedLocalLink item={'breadcrumbs.home.items'} to={{ pathname: '/items', state: { backToItemsPage: true } }}>{t('details.items')}</TrackedLocalLink>
          </BreadcrumbItem>
          {shownItemId
            ? <BreadcrumbItem><TrackedLocalLink item={'breadcrumbs.home.items.details'} to={`/items/${itemsList[0].itemId}`}>{shownItemId}</TrackedLocalLink></BreadcrumbItem>
            : null}
          <BreadcrumbItem>{t('details.ship')}</BreadcrumbItem>
        </Breadcrumbs>
        <div className={'pull-right'}>
          <Tooltip contents={selectedFulfillmentLocationId ? t('shipping.shippingConsolePrintLabelTooltip') : t('shipping.selectFulfillmentLocationFirstTooltip')}>
            <TrackedLink
              item={'shipping.link.shippingConsole'}
              target='_blank' rel="noopener noreferrer"
              href={`${shippingConsoleUrl}/ship?${shippingConsoleQueryParams}`}
              className={`btn btn-default ${selectedFulfillmentLocationId ? '' : 'disabled'}`}>
              {t('shipping.shippingConsolePrintLabel')}
            </TrackedLink>
          </Tooltip>
        </div>
      </div>
      <Card>

        {isLoadingItems
          ? <Loading message={`${t('common.loading')}...`} />
          : <div>
            <div className='row'>
              <div className={showAddressOnRight ? 'col-lg-8 col-md-8 col-sm-6 col-xs-12' : 'col-xs-12'}>
                <h4>
                  <Tooltip contents={t('shipping.publish_shipment_tooltip')}>
                    {t('shipping.publish_shipment')}&nbsp;
                    - {`${pendingItemsList.length} ${t('common.items')}`}
                  </Tooltip>
                </h4>
                {itemsCheckResult && itemsCheckResult.errors.length > 0
                  ? (itemsCheckResult.errors as string[]).map((er, i) => <Alert key={i} message={er} type={'warning'} dismissible={false} />)
                  : itemsShippedData
                    ? renderItemsShippedInfo(t, itemsList, itemsShippedData)
                    : <ShipForm
                      items={pendingItemsList}
                      deliveryDetail={selectedDeliveryDetail}
                      itemsShipQuantities={quantitiesToShip}
                      onItemsShipped={data => setItemsShippedData(data)}
                      onFulfillmentLocationSelected={fulfillmentLocationId => setSelectedFulfillmentLocationId(fulfillmentLocationId)}
                      accessToken={accessToken} />
                }

              </div>

              {selectedView?.extensions.ui.screens.shipping.deliveryAddress.show && !itemsCheckResult?.haveMultipleAddresses
                ? <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                  <div className=''>
                    <h4>{t('shipping.delivery_address')}</h4>
                    <RadioGroup name={'deliveryDetail'}>
                      {deliveryDetails.map((dd, i) => {
                        const checked = (i === selectedAddressIndex);
                        return <>
                          {renderDeliveryDetail(t, dd, deliveryRequestsMap, checked, <Radio value={i} checked={checked} />, () => {
                            setSelectedAddressIndex(i);
                            setSelectedDeliveryDetail(dd);
                            setQuantityToShipByItemId({});
                          })}
                        </>;
                      })}
                    </RadioGroup>
                  </div>
                </div>
                : null}

            </div>

            <br />

            <div>
              <h4>{t('shipping.items')}</h4>
              <hr className='thinHr' />
              {pendingItemsList
                .map((item, i) => {
                  const itemNotif = itemNotifications[item.itemId] || {};
                  return <Fragment key={item.itemId}>
                    <div className='shipCandidate'>
                      <ShipItemCard
                        accessToken={accessToken}
                        cancellationPending={itemNotif[NotificationTypes.CancellationRequest]}
                        addressChangePending={itemNotif[NotificationTypes.ChangeRequest]}
                        artworkChangePending={itemNotif[NotificationTypes.ArtworkChangeRequest]}
                        shipmentRequirementChangePending={itemNotif[NotificationTypes.ShipmentRequirementChangeRequest]}
                        item={item}
                        itemIndex={i}
                        initialQuantityToShip={quantitiesToShip[item.itemId]}
                        orderedQuantityOverride={selectedDeliveryDetail?.quantity}
                        itemShipped={!!itemsShippedData}
                        useMerchantIds={useMerchantIds}
                        onShippingDataChange={newQuantity => {
                          const newQuantityToShipByItemId = Object.assign({}, quantityToShipByItemId);
                          newQuantityToShipByItemId[item.itemId] = newQuantity;
                          setQuantityToShipByItemId(newQuantityToShipByItemId);
                        }}
                        onRemove={() => {
                          setItemIdsPendingToShip(itemIdsPendingToShip.filter(id => id !== item.itemId));
                        }}
                        doNotCalculateRemainder={haveItemsWithSplitShipping}
                      />
                    </div>
                    <hr />
                  </Fragment>;
                })}
            </div>

          </div>
        }
      </Card>
    </div >
  );
};
