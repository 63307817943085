import React from 'react';
import { renderKeyValue } from './renderKeyValue';
import { IdField } from './idField';
import { ExtensionsModel } from '../../models/views';
import { renderStatusLabels } from './labels';

export const renderItemInfo = (t, item, useMerchantIds = false, dateFormatterFunc, extensions: ExtensionsModel|undefined) => {
  return item
    ? <>
      {renderKeyValue(t('details.status'), <>{renderStatusLabels(t, item)}</>)}
      {renderKeyValue(t('details.itemId'), <IdField id={useMerchantIds ? item.merchantInformation?.itemId || item.itemId : item.itemId}/>)}
      {renderKeyValue(t('details.orderId'), <IdField id={useMerchantIds ? item.orderInfo?.merchantInformation?.orderId || item.order?.orderId : item.order?.orderId}/>)}
      {renderKeyValue(t('details.orderedQuantity'), item.orderedQuantity)}
      {renderKeyValue(t('details.orderDate'), dateFormatterFunc(item.orderInfo?.createdDate))}
      {extensions?.ui.screens.itemDetails.promisedArrivalDate.show
        ? renderKeyValue(t('details.promisedArrivalDate'), dateFormatterFunc(item.orderInfo?.promisedArrivalDate))
        : null}
      {renderKeyValue(t('details.lastUpdated'), dateFormatterFunc(item.status?.lastUpdated))}
    </>
    : null;
};
