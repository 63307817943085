import { DeliveryRequestWithPickupPoint } from '../../../clients/delivery/deliveryRequestModel';
import { getDeliveryRequest, getPickupRequest } from '../../../clients/delivery/deliveryRequests';
import { useAsync } from 'react-async-hook';

export interface DeliveryRequestsState {
  loadingDeliveryRequests: boolean;
  deliveryRequestsMap: Record<string, DeliveryRequestWithPickupPoint>;
  deliveryRequestsErrors?: Record<string, string>;
}

export const useDeliveryRequests = (accessToken: string, deliveryRequestsUrls: string[]): DeliveryRequestsState => {
  const useEffectDepKey = deliveryRequestsUrls?.join('::');

  const response = useAsync(async () => {
    if (accessToken && deliveryRequestsUrls && deliveryRequestsUrls.length > 0) {
      const tempMap = {} as Record<string, DeliveryRequestWithPickupPoint>;
      const tempErrors = {};
      for (const delReqUrl of deliveryRequestsUrls) {
        if (tempErrors[delReqUrl]) {
          continue;
        }
        try {
          let delReqWithPickupPoint = {} as DeliveryRequestWithPickupPoint;
          const delReq = await getDeliveryRequest(accessToken, delReqUrl);
          if (delReq) {
            delReqWithPickupPoint = {
              ...delReq
            };
          }
          if (delReq?._links?.pickupPoint) {
            delReqWithPickupPoint.pickupPoint = await getPickupRequest(accessToken, delReq?._links.pickupPoint.href);
          }
         
          Object.assign(tempMap, { [delReqUrl]: delReqWithPickupPoint });
        } catch (error: any) {
          console.warn('Delivery requests failed to load');
          console.warn(error);
          Object.assign(tempErrors, { [delReqUrl]: error.message });
        }
      }
      return {
        deliveryRequestsMap: tempMap,
        deliveryRequestsErrors: tempErrors
      };
    }
    return {
      deliveryRequestsMap: {},
      deliveryRequestsErrors: undefined
    };
  },
  // eslint-disable-next-line
  [accessToken, useEffectDepKey]);

  return {
    loadingDeliveryRequests: response.loading,
    deliveryRequestsMap: response.result?.deliveryRequestsMap || {},
    deliveryRequestsErrors: response.result?.deliveryRequestsErrors
  };
};
