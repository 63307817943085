import React, { useEffect, useState } from 'react';
import * as packageInfo from '../../../package.json';
import SettingsModal from '@cimpress-technology/react-platform-settings';
import { i18n } from '../../locales/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomizrSettings, pomApplicationResourceId } from '../../store/settings/actions';
import { useTranslation } from 'react-i18next';
import { colors, Radio, RadioGroup, Tooltip } from '@cimpress/react-components';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { AppState } from '../../store/store';
import './appSettings.scss';
import { CustomizrClient } from 'cimpress-customizr';
import { TrackedSelect } from '../../trackingLayer/trackedSelect';
import { CustomizrPomV2 } from '../../store/settings/types';

export interface AppSettingsProps {
  accessToken: string;
}

export const AppSettings: React.FC<AppSettingsProps> = ({ accessToken }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { useMerchantIds, useMerchantBarcodeIds, preferredViewId, preferredPreviewSource, customPomV2View } = useSelector((state: AppState) => state.settings);
  const { views } = useSelector((state: AppState) => state.views);

  const [idFormat, setIdFormat] = useState((useMerchantIds ? 'merchantIds' : 'shortIds'));
  const [jobsheetBarcodeIdFormat, setJobsheetBarcodeIdFormat] = useState((useMerchantBarcodeIds ? 'merchantIds' : 'shortIds'));
  const [localPreferredView, setLocalPreferredView] = useState(preferredViewId);
  const [localPreviewSource, setLocalPreviewSource] = useState(preferredPreviewSource);

  useEffect(() => {
    setLocalPreferredView(preferredViewId);
  }, [preferredViewId]);

  useEffect(() => {
    setLocalPreviewSource(preferredPreviewSource);
  }, [preferredPreviewSource]);

  useEffect(() => {
    setIdFormat(useMerchantIds ? 'merchantIds' : 'shortIds');
  }, [useMerchantIds]);

  useEffect(() => {
    setJobsheetBarcodeIdFormat(useMerchantBarcodeIds ? 'merchantIds' : 'shortIds');
  }, [useMerchantBarcodeIds]);

  const onIdFormatChanged = (e, value) => {
    setIdFormat(value);
  };

  const onJobsheetBarcodeIdFormatChanged = (e, value) => {
    setJobsheetBarcodeIdFormat(value);
  };

  const onPreferredPreviewSourceChanged = (e, value) => {
    setLocalPreviewSource(value);
  };

  const onPreferredViewChanged = e => {
    setLocalPreferredView(e.value);
  };

  const viewsOptions = (views.data || []).map(v => {
    return {
      value: v.viewId,
      label: v.viewName
    };
  });

  const customViewOption = viewsOptions.find(view => view.value === customPomV2View?.viewId);
  if (customViewOption) {
    customViewOption.label = <em>{t('settings.customViewLabel')}</em> as unknown as string;
  }

  const selectedView = viewsOptions.find(v => v.value === localPreferredView);

  return <SettingsModal
    className='settingsModal'
    lang={i18n.language}
    authToken={accessToken || 'x'}
    supportedLanguages={Object.keys(i18n.options.resources as object)}
    appSettingsTitle={'POM'}
    appSettingsContent={
      <div className='settingsContainer'>
        <hr />
        <RadioGroup onChange={onIdFormatChanged} defaultSelected={idFormat} name="radioIdFormat">
          <h5>
            {t('settings.idFormat')}&nbsp;
            <Tooltip contents={t('settings.idFormat_help')}>
              <IconInformationCircle color={colors.info.base} size={'1x'} weight={'fill'} />
            </Tooltip>
          </h5>
          <Radio label={<i>{t('settings.shortIds')}</i>} value="shortIds" />
          <Radio label={<i>{t('settings.merchantIds')}</i>} value="merchantIds" />
        </RadioGroup>
        <br />
        <RadioGroup onChange={onJobsheetBarcodeIdFormatChanged} defaultSelected={jobsheetBarcodeIdFormat} name="radioBarcodeFormat">
          <h5>
            {t('settings.jobsheetBarcodeIdFormat')}&nbsp;
            <Tooltip contents={t('settings.jobsheetBarcodeIdFormat_help')}>
              <IconInformationCircle color={colors.info.base} size={'1x'} weight={'fill'} />
            </Tooltip>
          </h5>
          <Radio label={<i>{t('settings.shortIds')}</i>} value="shortIds" />
          <Radio label={<i>{t('settings.merchantIds')}</i>} value="merchantIds" />
        </RadioGroup>
        <br />
        <RadioGroup onChange={onPreferredPreviewSourceChanged} defaultSelected={localPreviewSource} name="radioBarcodeFormat">
          <h5>
            {t('settings.preferredPreviewSource')}&nbsp;
            <Tooltip contents={t('settings.preferredPreviewSource_help')}>
              <IconInformationCircle color={colors.info.base} size={'1x'} weight={'fill'} />
            </Tooltip>
          </h5>
          <Radio label={<i>{t('settings.previewSource_prepressDefault')}</i>} value="prepressDefault" />
          <Radio label={<i>{t('settings.previewSource_docRef')}</i>} value="docRef" />
          <Radio label={<i>{t('settings.previewSource_foma')}</i>} value="foma" />
        </RadioGroup>
        <br />
        <h5>{t('settings.preferredView')}</h5>
        <TrackedSelect
          item={'settings.preferredView'}
          isClearable={false}
          label={`${t('settings.preferredViewLabel')}`}
          value={selectedView}
          options={viewsOptions}
          onChange={onPreferredViewChanged}
        />
        <hr />
        <small className='settingsVersion'>POM-v{packageInfo.version}</small>
      </div>
    }
    canSave
    onSave={() => {
      const c = new CustomizrClient({
        resource: pomApplicationResourceId
      });

      c.getSettings(accessToken)
        .then((current: CustomizrPomV2) => {
          current.useMerchantIds = idFormat === 'merchantIds';
          current.useMerchantBarcodeIds = jobsheetBarcodeIdFormat === 'merchantIds';
          current.preferredViewId = selectedView?.value;
          current.preferredPreviewSource = (localPreviewSource || 'docRef') as any;

          return c.putSettings(accessToken, current);
        })
        .then(() => {
          dispatch(getCustomizrSettings());
        });
    }}
  />;
};
