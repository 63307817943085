import { getCarrierServiceKeys } from '../../../../clients/logisticsClients/shippingRecommendationClient';
import { useAsync } from 'react-async-hook';

export interface CarrierService {
  key: string;
  name: string;
}

export const useCarrierServices = (accessToken, fulfillmentLocationId) => {
  const response = useAsync(async () => {
    if (accessToken && fulfillmentLocationId) {
      const carrierServicesData = await getCarrierServiceKeys(accessToken, fulfillmentLocationId) || [];
      return {
        data: carrierServicesData,
        error: undefined
      };
    }
    return {
      data: undefined,
      error: undefined
    };
  }, [accessToken, fulfillmentLocationId]);

  return {
    carrierServices: response.result?.data || [],
    loadingCarrierServices: response.loading
  };
};
