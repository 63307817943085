import { ItemsActions } from '../storeActions';
import { DismissibleErrorDetails, ErrorDetails, ExternalData, LinkData } from '../commonTypes';
import {
  ItemSearchStatus,
  ExtendedOrderItem,
  ItemSearchResults,
  OrderItemWithStatusAndOrderInfo
} from '../../clients/foma/itemsClient';
import { PlatformOrderItem } from '../../clients/platformClient/platformItemsModel';
import { PrintJob } from '../../clients/printjobs/printjobModel';
import { Moment } from 'moment';

/*
 * State
 */

export interface FomaItemState {
  isLoading: boolean;
  data: OrderItemWithStatusAndOrderInfo;
  error: any;
}

export interface ItemsState {
  filters: Filters;
  table: TableState;
  byId: Record<string, FomaItemState>;
  selection: SelectionState;
  errors?: DismissibleErrorDetails[];
}

/*
 * Actions
 */
export interface GetFomaItemRequestAction {
  type: typeof ItemsActions.GET_FOMA_ITEM_REQUEST;
  itemId: string;
}

export interface GetFomaItemSuccessAction {
  type: typeof ItemsActions.GET_FOMA_ITEM_SUCCESS;
  itemId: string;
  data: OrderItemWithStatusAndOrderInfo;
}

export interface GetFomaItemErrorAction {
  type: typeof ItemsActions.GET_FOMA_ITEM_ERROR;
  itemId: string;
  error: ErrorDetails;
}
export interface GetItemsRequestAction {
  type: typeof ItemsActions.GET_ITEMS_REQUEST;
}

export interface GetItemsSuccessAction {
  type: typeof ItemsActions.GET_ITEMS_SUCCESS;
  data: ItemSearchResults;
}

export interface GetItemsErrorAction {
  type: typeof ItemsActions.GET_ITEMS_ERROR;
  error: ErrorDetails;
}

export interface SetItemsFiltersAction {
  type: typeof ItemsActions.SET_ITEMS_FILTERS;
  filters: Filters;
}

export interface SetItemsTableStateAction {
  type: typeof ItemsActions.SET_ITEMS_TABLE_STATE;
  table: TableState;
}

export interface ToggleRowSelectionAction {
  type: typeof ItemsActions.TOGGLE_ROW_SELECTION;
  selection: string;
}

export interface ToggleAllSelectionAction {
  type: typeof ItemsActions.TOGGLE_ALL_SELECTION;
}

export interface BulkAcceptRequestAction {
  type: typeof ItemsActions.BULK_ACCEPT_REQUEST;
}

export interface BulkAcceptDoneAction {
  type: typeof ItemsActions.BULK_ACCEPT_DONE;
}

export interface BulkSetToProductionAction {
  type: typeof ItemsActions.BULK_SET_TO_PRODUCTION;
}

export interface BulkSetToProductionDoneAction {
  type: typeof ItemsActions.BULK_SET_TO_PRODUCTION_DONE;
}

export interface GetPlatformItemsRequestAction {
  type: typeof ItemsActions.GET_PLATFORM_ITEMS_REQUEST;
}

export interface BulkRejectOrderAction {
  type: typeof ItemsActions.BULK_REJECT_ORDER;
}
export interface BulkRejectOrderDoneAction {
  type: typeof ItemsActions.BULK_REJECT_ORDER_DONE;
}

export interface NotifyDelayDoneAction {
  type: typeof ItemsActions.NOTIFY_DELAY_DONE;
  itemId: string;
  data: PlatformOrderItem;
}

export interface GetPlatformItemsDoneAction {
  type: typeof ItemsActions.GET_PLATFORM_ITEMS_DONE;
  platformItemsByItemsId: PlatformItems;
}

export interface Filters {
  initialized?: boolean;
  fulltext?: string; // search
  status?: ItemSearchStatus[];
  expectedShipTimeFrom?: Moment;
  expectedShipTimeTo?: Moment;
  productCategories: string[];
  deliveryOptionIds: string[];
  limitForecastedLate?: boolean;
  filterItemsWithNoPlan?: boolean;
  sortBy?: string;
  sortOrder?: SortOrderTypes;
}

export interface DateRange {
  start?: Moment;
  end?: Moment;
}

export interface TableItems {
  items: ExtendedOrderItem[];
  count: number;
  totalCount: number;
}

export interface TableState extends ExternalData<TableItems>, ControlledTableState {
  lastRefreshed: number;
  byItemIds: ItemsByItemsIds;
  platformItemsByItemsId: PlatformItems;
}

export interface PlatformItems {
  [key: string]: PlatformItem;
}

export interface PlatformItem {
  itemId: string;
  platformItemId?: string;
  links?: PlatformItemsLinks;
  statuses?: PlatformItemsStatuses;
  printJob?: PrintJob;
}
export interface PlatformItemsStatuses {
  fulfillmentDelayed?: PlatformItemStatus;
  fulfillment?: PlatformItemStatus;
}

export interface PlatformItemStatus {
  expectedCloseDate: string;
  detail?: string;
}

export interface PlatformItemsLinks {
 claims?: PlatformItemsLink;
 reorderClaim?: PlatformItemsLink;
}

export interface PlatformItemsLink extends LinkData {
  name: string;
}

export interface ItemsByItemsIds {
  [key: string]: ExtendedOrderItem;
}

export interface ControlledTableState {
  page: number;
  pageSize: number;
  sorted: TableSorting[];
}

export interface TableSorting {
  id: string;
  desc: boolean;
}

export interface SelectionState {
  selectedRows: SelectedRows;
  selectedAll: boolean;
}

export interface SelectedRows {
  [key: string]: boolean;
}

export interface ShowAlert {
  type: typeof ItemsActions.SHOW_ALERT;
  errors: DismissibleErrorDetails[];
}

export interface DismissAlert {
  type: typeof ItemsActions.DISMISS_ALERT;
  error: ErrorDetails;
}

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc'
}

export type SortOrderTypes = SortOrderEnum.ASC | SortOrderEnum.DESC;

export type ItemsActionTypes =
  GetItemsRequestAction |
  GetItemsSuccessAction |
  GetItemsErrorAction |
  SetItemsFiltersAction |
  SetItemsTableStateAction |
  ToggleRowSelectionAction |
  ToggleAllSelectionAction |
  BulkAcceptRequestAction |
  BulkAcceptDoneAction |
  ShowAlert |
  DismissAlert |
  BulkSetToProductionAction |
  BulkSetToProductionDoneAction |
  BulkRejectOrderAction |
  BulkRejectOrderDoneAction |
  NotifyDelayDoneAction |
  GetPlatformItemsRequestAction |
  GetPlatformItemsDoneAction |
  GetFomaItemErrorAction |
  GetFomaItemRequestAction |
  GetFomaItemSuccessAction;
