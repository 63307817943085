import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, RouteProps, Switch } from 'react-router-dom';
import { Dashboard } from './components/dashboard/dashboardPage';
import { FourOhFour, shapes } from '@cimpress/react-components';
import { Items } from './components/items/itemsPage';
import { PendingActionsPage } from './components/pendingActions/pendingActionsPage';
import { CancellationPage } from './components/pendingActions/cancellations/cancellationPage';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './store/store';
import * as fulfillersActions from './store/fulfillers/actions';
import * as viewsActions from './store/views/actions';
import { ErrorComponent } from './components/errorComponent';
import { ExternalData } from './store/commonTypes';
import { Fulfiller } from './store/fulfillers/types';
import * as authActions from './store/auth/actions';
import { ShipItemsPage } from './components/items/shipping/shipItemsPage';
import { ItemDetailsPage } from './components/items/itemDetails/itemDetailsPage';
import { JobSheetPage } from './components/items/jobsheet/jobSheetPage';
import { ContactsPage } from './components/contact/contactsPage';
import { SnackbarNotification } from './components/snackbarNotification';
import { ViewsState } from './store/views/types';
import { RequestAccessComponent } from './components/requestAccessComponent';
import { FulfillerPerformancePage } from './components/FulfillerPerformance/fulfillerPerformancePage';

const NamedRoute: React.FC<CustomRouteProps> = ({ component: Component, path, title, ...props }) => {
  const defaultPageTitle = 'Cimpress POM';
  document.title = title ? `${defaultPageTitle} - ${title}` : defaultPageTitle;
  useEffect(() => {
    if (path) {
      ReactGA.pageview(Array.isArray(path) ? path[0] : path, undefined, title);
    }
  }, [path, title]);

  return (
    <Route
      {...props}
      render={routeProps => (<Component {...routeProps} />)}
    />
  );
};

export const Routes: React.FC = () => {
  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const dispatch = useDispatch();
  const { isLoading: isLoadingFulfillers, errors: fulfillersErrors, data } = useSelector<AppState, ExternalData<Fulfiller[]>>(state => state.fulfillers.fulfillers);
  const { views } = useSelector<AppState, ViewsState>(state => state.views);

  useEffect(() => {
    if (accessToken) {
      dispatch(viewsActions.getViewsRequest());
      dispatch(fulfillersActions.getFulfillersRequest());
      dispatch(fulfillersActions.getSelectedFulfillerIdsRequest());
    } else {
      dispatch(authActions.login());
    }
  }, [accessToken, dispatch]);

  if (!accessToken || isLoadingFulfillers || views?.isLoading) {
    const { Spinner } = shapes;
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner />
      </div>
    );
  }

  if (fulfillersErrors) {
    return (
      <ErrorComponent errors={fulfillersErrors} />
    );
  }

  if (!data || data?.length === 0) {
    return <RequestAccessComponent />;
  }

  const routes = [
    <NamedRoute key={'Dashboard'} exact path={'/'} component={Dashboard} title={'Dashboard'} />,

    <NamedRoute key={'Contact'} exact path={'/contact'} component={ContactsPage} title={'Contacts'} />,

    <NamedRoute key={'Items'} exact path={'/items'} component={Items} title={'Items List'} />,
    <NamedRoute key={'Ship'} exact path={'/items/shipping'} component={ShipItemsPage} title={'Items Shipping Page'} />,
    <NamedRoute key={'JobSheet'} exact path={'/items/jobsheet'} component={JobSheetPage} title={'Jobsheets Page'} />,
    <NamedRoute key={'ItemDetails'} exact path={'/items/:itemId'} component={ItemDetailsPage} title={'Item Details Page'} />,

    <NamedRoute key={'PendingActionsPage'} exact path={'/pendingActions'} component={PendingActionsPage} title={'Pending Actions Page'} />,
    <NamedRoute key={'CancellationPage'} exact path={'/pendingActions/cancellations/:notificationId'} component={CancellationPage} title={'Cancellation Page'} />,

    <NamedRoute key={'Dashboard'} exact path={'/dashboard'} component={Dashboard} title={'Dashboard'} />,
    <NamedRoute key={'FulfillerPerformance'} exact path={'/fulfillerPerformance'} component={FulfillerPerformancePage} title={'FulfillerPerformance'} />,
    <NamedRoute key={'404'} component={FourOhFour} title={'404'} />
  ];

  return (
    <>
      <SnackbarNotification />
      <Switch>
        {routes}
      </Switch>
    </>
  );
};

interface CustomRouteProps extends RouteProps {
  title: string;
  component: React.ComponentType<any>;
}
