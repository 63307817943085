import { apiClientWithCachedResults } from '../../../../clients/apiClientWithCachedResults';
import { useAsync } from 'react-async-hook';

export const usePlanResponse = (accessToken, item) => {
  const planLink = item?.links?.plan?.href;
  const itemId = item?.itemId;

  const response = useAsync(async () => {
    if (accessToken && planLink && itemId) {
      const planResponse = await apiClientWithCachedResults<any>(accessToken, planLink, {
        url: planLink,
        headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
        accessToken: accessToken
      });

      const res = Object.values((planResponse?.data?.shippingPlans || {}))[0] || null;
      return {
        planResponse: res
      };
    }
    return {
      planResponse: undefined,
      error: undefined
    };
  }, [accessToken, planLink, itemId]);

  return {
    planResponse: response.result?.planResponse,
    loadingPlanResponse: response.loading,
    errorLoadingPlanResponse: response.error
  };
};
