import React, { useEffect, useState } from 'react';
import { Fulfiller } from '../../store/fulfillers/types';
import { useTranslation } from 'react-i18next';
import { storeSelectedFulfillerIds } from '../../utils/pomSettings';
import { setSelectedFulfillers } from '../../store/fulfillers/actions';
import { getNotifications } from '../../store/notifications/actions';
import { useDispatch } from 'react-redux';
import { getJobsheetPresetRequest } from '../../store/jobsheetPresets/actions';
import { getProductCategoriesRequest } from '../../store/products/actions';
import { TrackedSelect } from '../../trackingLayer/trackedSelect';
import { getDeliveryOptionsRequest } from '../../store/deliveryOptions/actions';

export const FulfillerMultiSelectWrapper: React.FC<FulfillerMultiSelectWrapperProps> = ({ accessToken, disabled, fulfillers, selectedFulfillerIds }) => {
  const selectOptions = (fulfillers || [])
    .map(({ fulfillerId, name }) => ({ value: fulfillerId, label: name }));

  const currentSelectedOptions = selectOptions.filter(a => selectedFulfillerIds?.includes(a.value));
  const selectedOptionValues = currentSelectedOptions.map(s => s.value) || [];

  const [selectedOptions, setSelectedOptions] = useState(selectedOptionValues);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const allFulfillerIds = (fulfillers || []).map(f => f.fulfillerId);
    storeSelectedFulfillerIds(accessToken, selectedOptions);
    dispatch(setSelectedFulfillers(selectedOptions));

    // This can result in too many requests..., defaulting to selectedOptions([])
    dispatch(getNotifications(selectedOptions));
    selectedOptions.forEach(fulfillerId => {
      dispatch(getJobsheetPresetRequest(fulfillerId));
    });

    const fulfillerIds = selectedOptions.length
      ? selectedOptions
      : allFulfillerIds
        .slice(0, 60); // MAX 60 fulfillers, otherwise 414 error code.

    dispatch(getProductCategoriesRequest(fulfillerIds));
    dispatch(getDeliveryOptionsRequest(fulfillerIds));
  }, [fulfillers, selectedOptions, accessToken, dispatch]);

  return <TrackedSelect
    item={'filters.fulfillers'}
    // @ts-ignore
    isClearable={false}
    isDisabled={disabled}
    // @ts-ignore
    isMulti={true}
    label={t('filters.fulfillers')}
    // @ts-ignore
    value={currentSelectedOptions}
    options={selectOptions}
    onChange={selectedOpts => {
      // @ts-ignore
      setSelectedOptions(selectedOpts?.map(x => x.value) || []);
    }}
  />;
};

interface FulfillerMultiSelectWrapperProps {
  // onChange: (selectedFulfillers: string[]) => void;
  accessToken: string;
  disabled?: boolean;
  fulfillers: Fulfiller[];
  selectedFulfillerIds?: string[];
}
