import React from 'react';
import { TFunction } from 'i18next';
import { OrderItemWithStatusAndOrderInfo } from '../../clients/foma/itemsClient';
import { PlatformItem } from '../../store/items/types';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../utils/dateTimeUtils';
import { Label, Tooltip } from '@cimpress/react-components';
import { StatusKeysToDisplay } from '../../utils/statusInfo';
import { View } from '../../models/views';

const itemStatusLabelTypeMapping = {
  new: 'primary',
  production: 'primary',
  accepted: 'primary',
  shipped: 'default',
  cancelled: 'default',
  rejected: 'default'
};

const _renderLateOrForecastedLateLabel = (labelType: 'late' | 'forecastedLate') => {
  const { t } = useTranslation();

  return <Label className='spacedLabel' text={t(`shipping.${labelType}`)} type="warning" />;
};

export const LateOrForecastedLateLabel: React.FC<LateLabelProps> = ({ currentItem, plan, selectedView }) => {
  if (!currentItem) {
    return null;
  }

  const { status, orderInfo } = currentItem;
  const { statusDetails } = status || {};
  const completedQuantity = currentItem?.status?.statusSummary?.completed?.quantity ?? 0;
  const canBeShipped = completedQuantity < currentItem.orderedQuantity && (!!statusDetails?.new || !!statusDetails?.accepted || !!statusDetails?.production);
  const showLimitedLateTag = selectedView?.extensions.features.limitedLateTag?.show;

  if (!canBeShipped) {
    return null;
  }
  if (orderInfo?.promisedArrivalDate && new Date(orderInfo.promisedArrivalDate) < new Date()) {
    return _renderLateOrForecastedLateLabel('late');
  }
  if (plan?.shipping?.expectedShipTimeIsLate) {
    return _renderLateOrForecastedLateLabel(showLimitedLateTag ? 'late' : 'forecastedLate');
  }
  return null;
};

export interface LateLabelProps {
    currentItem: OrderItemWithStatusAndOrderInfo;
    plan: any;
    selectedView: View | undefined;
}

export const DelayedLabel: React.FC<DelayedLabelProps> = ({ currentPlatformItem }) => {
  const { t } = useTranslation();
  const dateFormatter = useDateTimeFormatter();
  const dateFormatFunc = date => dateFormatter(new Date(date));

  if (currentPlatformItem?.statuses?.fulfillment && currentPlatformItem?.statuses?.fulfillmentDelayed) {
    const reason = currentPlatformItem?.statuses?.fulfillmentDelayed?.detail;
    const reasonString = reason ? `${t('items.labels.delayedExtra.reason')}: ${reason}` : t('items.labels.delayedExtra.noDetail');
    return <Tooltip
      contents={reasonString}>
      <span className={'label label-warning spacedLabel'}>{t('items.labels.delayed')} {dateFormatFunc(currentPlatformItem.statuses?.fulfillment?.expectedCloseDate)}</span>
    </Tooltip>;
  }
  return null;
};

export interface DelayedLabelProps {
    currentPlatformItem: PlatformItem;
}

export const renderStatusLabels = (t: TFunction, currentItem: OrderItemWithStatusAndOrderInfo) => {
  const renderStatusQuantity = (status: string, quantity: string, totalQuantity: number) => {
    if (!StatusKeysToDisplay.includes(status)) {
      return null;
    }
    const quantityNode = (`${quantity}` === `${totalQuantity}`)
      ? t(`statuses.${status}`)
      : `${t(`statuses.${status}`)} (${quantity})`;
    return <Label key={status} type={itemStatusLabelTypeMapping[status]} text={quantityNode} />;
  };
  return Object.keys(currentItem?.status?.statusDetails || {})
    .map(kk => renderStatusQuantity(kk, currentItem?.status?.statusDetails[kk].quantity, currentItem?.orderedQuantity))
    .filter(s => s);
};
