import { DashboardActions } from '../storeActions';
import {
  TodayWidgetValues,
  GetTodayWidgetValuesErrorAction,
  GetBacklogValuesErrorAction,
  GetTodayWidgetValuesSuccessAction,
  GetBacklogValuesSuccessAction,
  ToggleGroupByProductCategoriesAction,
  ToggleBacklogByQuantitiesAction
} from './types';
import { AppState } from '../store';
import { apiRequest, ApiRequestOptions } from '../../clients/apiClient';
import moment from 'moment';
import { constructUrlWithQueryParams } from '../../utils/urlConstructor';
import { constructRequestBody, ItemSearchStatus } from '../../clients/foma/itemsClient';

import { fomaUrl } from '../../utils/environmentProvider';
import { ItemSearchResponseModel } from '../../clients/foma/itemSearchResponseModel';
import { ErrorDetails } from '../commonTypes';
import { ItemAggregationResponse } from '../../clients/foma/itemAgreggationResponseModel';
import { createAggregationQueryParams } from '../../clients/foma/itemAggregation';
import { i18n } from '../../locales/i18n';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

const itemSearchUrl = `${fomaUrl}/v1/item-search`;
const itemAggregationsUrl = `${fomaUrl}/v0/item-aggregations`;

const getTodayValuesSuccess = (values: TodayWidgetValues): GetTodayWidgetValuesSuccessAction => ({
  type: DashboardActions.GET_TODAY_WIDGET_VALUES_SUCCESS,
  data: values
});

const getTodayValuesError = (error: ErrorDetails): GetTodayWidgetValuesErrorAction => ({
  type: DashboardActions.GET_TODAY_WIDGET_VALUES_ERROR,
  error
});

const getBacklogValuesSuccess = (values): GetBacklogValuesSuccessAction => ({
  type: DashboardActions.GET_BACKLOG_VALUES_SUCCESS,
  data: values
});

const getBacklogValuesError = (error: ErrorDetails): GetBacklogValuesErrorAction => ({
  type: DashboardActions.GET_BACKLOG_VALUES_ERROR,
  error
});

export const toggleGroupByProductCategoriesAction = (): ToggleGroupByProductCategoriesAction => ({
  type: DashboardActions.TOGGLE_GROUP_BY_PRODUCT_CATEGORIES
});

export const toggleBacklogByQuantitiesAction = (): ToggleBacklogByQuantitiesAction => ({
  type: DashboardActions.TOGGLE_BACKLOG_BY_QUANTITIES
});

export const getTodayWidgetValues = () => async (dispatch, getState) => {
  dispatch({ type: DashboardActions.GET_TODAY_WIDGET_VALUES_REQUEST });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const selectedFulfillers = state.fulfillers.selectedFulfillers;
  const selectedFulfillerId = selectedFulfillers && selectedFulfillers.length > 0 ? selectedFulfillers[0] : '';

  const cancellationUrl = constructUrlWithQueryParams(`${fomaUrl}/v1/notifications`, [
    ['fulfillerId', selectedFulfillerId],
    ['type', 'CancellationRequest'],
    ['status', 'New']
  ]);

  const commonSearchItemRequestOptions: ApiRequestOptions = {
    accessToken,
    method: 'post',
    url: itemSearchUrl
  };

  try {
    const [
      newItemsResponse,
      openItemsResponse,
      forecastedLateResponse,
      shipTodayResponse,
      pendingCancellationsResponse,
      noPlanResponse
    ] = await Promise.all([
      apiRequest<ItemSearchResponseModel>({
        ...commonSearchItemRequestOptions,
        data: constructRequestBody({
          fulfillerIds: [selectedFulfillerId],
          productCategories: [],
          status: [ItemSearchStatus.New],
          pageSize: 1
        })
      }),
      apiRequest<ItemSearchResponseModel>({
        ...commonSearchItemRequestOptions,
        data: constructRequestBody({
          fulfillerIds: [selectedFulfillerId],
          productCategories: [],
          status: [ItemSearchStatus.New, ItemSearchStatus.Accepted, ItemSearchStatus.Production],
          pageSize: 1
        })
      }),
      apiRequest<ItemSearchResponseModel>({
        ...commonSearchItemRequestOptions,
        data: constructRequestBody({
          fulfillerIds: [selectedFulfillerId],
          productCategories: [],
          status: [ItemSearchStatus.New, ItemSearchStatus.Accepted, ItemSearchStatus.Production],
          forecastedLate: true,
          pageSize: 1
        })
      }),
      apiRequest<ItemSearchResponseModel>({
        ...commonSearchItemRequestOptions,
        data: constructRequestBody({
          fulfillerIds: [selectedFulfillerId],
          productCategories: [],
          status: [ItemSearchStatus.New, ItemSearchStatus.Accepted, ItemSearchStatus.Production],
          expectedShipTimeFrom: moment().startOf('day').utc(),
          expectedShipTimeTo: moment().endOf('day').utc(),
          pageSize: 1
        })
      }),
      apiRequest<ItemSearchResponseModel>({
        accessToken,
        url: cancellationUrl.toString()
      }),
      apiRequest<ItemSearchResponseModel>({
        ...commonSearchItemRequestOptions,
        data: constructRequestBody({
          fulfillerIds: [selectedFulfillerId],
          productCategories: [],
          status: [ItemSearchStatus.New, ItemSearchStatus.Accepted, ItemSearchStatus.Production],
          noExpectedShipTime: true,
          pageSize: 1
        })
      })
    ]);

    if (newItemsResponse?.data
      && openItemsResponse?.data
      && shipTodayResponse?.data
      && forecastedLateResponse?.data
      && pendingCancellationsResponse?.data
      && noPlanResponse?.data
    ) {
      const values: TodayWidgetValues = {
        newItems: newItemsResponse.data.totalCount,
        shipToday: shipTodayResponse.data.totalCount,
        openItems: openItemsResponse.data.totalCount,
        forecastedLateItems: forecastedLateResponse.data.totalCount,
        pendingCancellations: pendingCancellationsResponse.data.count,
        noPlanItems: noPlanResponse.data.totalCount
      };

      return dispatch(getTodayValuesSuccess(values));
    }

    const errorResponse = [
      newItemsResponse,
      openItemsResponse,
      shipTodayResponse,
      forecastedLateResponse,
      pendingCancellationsResponse,
      noPlanResponse
    ].find(response => response?.error);

    if (errorResponse && errorResponse.status === 403) {
      SentryWrapper.reportError(errorResponse);
      return dispatch(getTodayValuesError({
        message: i18n.t('errorMessages.insufficientFulfillerPermissions'),
        severity: 'warning'
      }));
    }

    SentryWrapper.reportError(errorResponse);
    return dispatch(getTodayValuesError({
      ...(errorResponse && errorResponse.error),
      message: i18n.t('errorMessages.failedToRetrieveTodaysData')
    }));
  } catch (e) {
    SentryWrapper.reportError(e);
    return dispatch(getTodayValuesError({
      message: i18n.t('errorMessages.failedToRetrieveTodaysData')
    }));
  }
};

export const getBacklog = () => async (dispatch, getState) => {
  dispatch({ type: DashboardActions.GET_BACKLOG_VALUES_REQUEST });
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const selectedFulfillers = state.fulfillers.selectedFulfillers;
  const selectedFulfillerId = selectedFulfillers && selectedFulfillers.length > 0 ? selectedFulfillers[0] : '';

  if (!selectedFulfillerId) {
    return dispatch(getBacklogValuesSuccess({
      aggregatedByDateAndCategory: [],
      aggregatedByDate: []
    }));
  }

  const lateItemsPromise = apiRequest<ItemSearchResponseModel>({
    accessToken,
    method: 'post',
    url: itemSearchUrl,
    data: constructRequestBody({
      fulfillerIds: [selectedFulfillerId],
      productCategories: [],
      status: [ItemSearchStatus.New, ItemSearchStatus.Accepted, ItemSearchStatus.Production],
      forecastedLate: true,
      pageSize: 1
    })
  });

  const itemAggregationsByDateAndCategoryResponsePromise = apiRequest<ItemAggregationResponse>({
    accessToken,
    url: itemAggregationsUrl,
    query: createAggregationQueryParams([selectedFulfillerId], true)
  });

  const itemAggregationsByDateResponsePromise = apiRequest<ItemAggregationResponse>({
    accessToken,
    url: itemAggregationsUrl,
    query: createAggregationQueryParams([selectedFulfillerId], false)
  });

  try {
    const [
      itemAggregationsByDateAndCategoryResponse,
      itemAggregationsByDateResponse,
      lateItemsResponse
    ] = await Promise.all([
      itemAggregationsByDateAndCategoryResponsePromise,
      itemAggregationsByDateResponsePromise,
      lateItemsPromise]);

    const errorResponse = itemAggregationsByDateAndCategoryResponse?.error;
    const itemAggregations = itemAggregationsByDateAndCategoryResponse?.data;
    if (errorResponse || !itemAggregations) {
      if (itemAggregationsByDateAndCategoryResponse?.status === 403) {
        SentryWrapper.reportError(errorResponse);
        return dispatch(getBacklogValuesError({
          message: i18n.t('errorMessages.insufficientFulfillerPermissions'),
          severity: 'warning'
        }));
      }

      SentryWrapper.reportError(errorResponse);
      return dispatch(getBacklogValuesError({
        message: i18n.t('errorMessages.failedToRetrieveData'),
        details: errorResponse
      }));
    }

    const results = JSON.parse(JSON.stringify({
      aggregatedByDateAndCategory: itemAggregationsByDateAndCategoryResponse?.data?.results || [],
      aggregatedByDate: itemAggregationsByDateResponse?.data?.results || [],
      totalLateItemsCount: lateItemsResponse?.data?.totalCount || 0
    }));

    // hack - convert quantitySum aggregation
    const handleQuantitySum = element => {
      (element.aggregations || []).forEach(a => handleQuantitySum(a));
      const qBySum = (element.aggregations || []).find(a => a.key === 'quantitySum');
      if (qBySum) {
        element.quantity = qBySum.total;
      }
    };
    results.aggregatedByDateAndCategory.forEach(a => handleQuantitySum(a));
    results.aggregatedByDate.forEach(a => handleQuantitySum(a));
    return dispatch(getBacklogValuesSuccess(results));
  } catch (e: any) {
    SentryWrapper.reportError(e);
    return dispatch(getBacklogValuesError({
      message: i18n.t('errorMessages.failedToRetrieveBacklogData'),
      details: e.message
    }));
  }
};
