import React from 'react';
import { Select, SelectProps } from '@cimpress/react-components';
import { trackEvent } from './utils';

export interface TrackedSelectProps extends SelectProps {
  item: string;
}

export const TrackedSelect: React.FC<TrackedSelectProps> = props => {
  const { item, onChange, ...selectProps } = props;

  return <Select
    onChange={(values, b) => {
      let selected = 'none';
      if (Array.isArray(values)) {
        selected = (values || []).map(v => v.value).join(':');
      } else if (values) {
        // @ts-ignore
        selected = values.value;
      }

      trackEvent(`${item}`);
      trackEvent(`${item}[${selected}]`);
      if (onChange) {
        onChange(values, b);
      }
    }}
    {...selectProps}
  />;
};
