import { fulfilleridentityUrl } from '../../utils/environmentProvider';
import { apiRequest } from '../apiClient';
import { FulfillerContacts } from './fulfillerContactsModel';

export async function getFulfillerContact(fulfillerId: string, accessToken: string) {
  const response = await apiRequest<FulfillerContacts>({
    url: `${fulfilleridentityUrl}/v1/fulfillers/${fulfillerId}/contacts`,
    method: 'get',
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken: accessToken,
    timeout: 20000
  });
  
  return response?.data ?? undefined;
}
  
