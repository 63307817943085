import { fulfilleridentityUrl } from '../../utils/environmentProvider';
import { apiRequest } from '../apiClient';

export async function getFulfillers(accessToken: string) {
  const response = await apiRequest<any>({
    url: `${fulfilleridentityUrl}/v1/fulfillers`,
    method: 'get',
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken: accessToken,
    timeout: 20000
  });
  
  return response?.data ?? null;
}
  
