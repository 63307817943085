import { apiRequest } from '../apiClient';
import { PrintJob } from './printjobModel';
import {
  getCachedResultByKey,
  getPendingPromiseByKey,
  setCachedResultAndClearPendingPromiseForKey,
  setPendingPromiseForKey
} from '../runningPromiseCache';

export const toHumanError = (txt: string) => {
  switch (txt) {
    case 'Request failed with status code 401':
      return 'No access. (401)';
    case 'Request failed with status code 403':
      return 'No access. (403)';
    default:
      return txt;
  }
};

export async function getPrintJob(accessToken, printJobLink): Promise<PrintJob|undefined> {
  const cacheKey = printJobLink;
  const alreadyCached = getCachedResultByKey(cacheKey);
  if (alreadyCached) {
    return alreadyCached;
  }

  let p = getPendingPromiseByKey(cacheKey);
  if (!p) {
    p = apiRequest<PrintJob>({
      url: printJobLink,
      accessToken,
      query: {},
      noErrorReportingForStatusCodes: [401, 403, 404]
    });
    setPendingPromiseForKey(cacheKey, p);
  }

  const response = await p;
  let result = response.data;

  if (response.error) {
    result = {
      error: {
        message: response.error.message,
        details: toHumanError(response.error.details)
      }
    };
  }

  // Clear the promise, we have the cashed results.
  setCachedResultAndClearPendingPromiseForKey(cacheKey, result);

  return result;
}
