import React from 'react';
import { Tag } from '@cimpress/react-components';
import { renderKeyValue } from './renderKeyValue';
import { IdField } from './idField';
import { ExtensionsModel } from '../../models/views';

const getProductAttributes = productAttributes => {
  const attr = {};
  (productAttributes || []).forEach(a => attr[a.name] = a);
  return Object.values(attr);
};

export const renderProductDetails = (t, item, extensions: ExtensionsModel|undefined, categoriesLeafMap: Record<string, any>) => {
  const filteredCategories = (item?.product?.categories || [])
    .filter(c => !extensions?.ui.screens.itemDetails.leafProductCategoriesOnly.show || categoriesLeafMap[c]);

  const categories = filteredCategories && filteredCategories.length > 0
    ? <>{filteredCategories.map((s, i) => <span key={i} className='spaced-tag'><Tag label={s}/></span>)}</>
    : <>{'---'}</>;

  return item
    ? <>
      <h5>
        {t('details.productDetails')} &nbsp;
      </h5 >
      {renderKeyValue(t('details.productName'), item.product?.name)}
      {renderKeyValue(t('details.sku'), <IdField id={item.product?.sku}/>)}
      {renderKeyValue(t('details.productCategories'), categories)}
      {renderKeyValue(t('details.description'), item.product?.description !== item.product?.name ? <p>{item.product?.description}</p> : null)}
      {renderKeyValue(t('details.technology'), item.product?.decorationTechnologies)}

      {getProductAttributes(item.product?.attributes).map((a: any) => renderKeyValue(a.name, a.value))}
    </>
    : null;
};
