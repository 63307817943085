import axios, { AxiosError } from 'axios';
import { ActionTypeKeysEnum, Env, ShipmentItemV1, postResponse, ResponsePostRequestV1, ResponseShipItemsV1, TransmissionMethodKeysEnumForSirV1, isRetryableError } from '@cimpress-technology/supplier-integrations-client';
import { isProd } from '../../utils/environmentProvider';
import jwt from 'jsonwebtoken';

export interface ShipmentData {
  items: ShipmentItemV1[];
  carrierService: string;
  trackingId: string;
  trackingUrl: string;
  externalShipmentId: string;
  fulfillmentLocationId: string;
  deliveryDetailUrl?: string;
  pickupDateTime?: string;
}

export async function createShipment(accessToken: string, data: ShipmentData): Promise<string | undefined> {
  const token = jwt.decode(accessToken);
  const canonicalId = token?.['https://claims.cimpress.io/canonical_id'];

  const siPostRequest: ResponsePostRequestV1 = {
    actionTypeKey: ActionTypeKeysEnum.ShipItems,
    transmissionMethodKey: TransmissionMethodKeysEnumForSirV1.Pom,
    actionTypeConfiguration: {
      shipItems: {
        fulfillmentLocationUrl: `https://fulfillmentlocation.trdlnk.cimpress.io/v1/fulfillmentlocations/${data.fulfillmentLocationId}`,
        carrierServiceKey: data.carrierService,
        packages: [{
          trackingId: data.trackingId,
          trackingUrl: data.trackingUrl,
          items: data.items
        }],
        pickupDateTime: data.pickupDateTime,
        metadata: {
          transmissionMethodUsed: TransmissionMethodKeysEnumForSirV1.Pom,
          ShipmentCreatedBy: canonicalId }
      }
    }
  };

  if (data.deliveryDetailUrl) {
    // @ts-ignore
    siPostRequest.actionTypeConfiguration.shipItems.deliveryRequestUrl = data.deliveryDetailUrl;
  }
  const env = isProd ? Env.PRODUCTION : Env.INTEGRATION;

  try {
    const response = await postResponse(accessToken, siPostRequest, env, { retryCondition: isRetryableError });
    return (response._embedded as ResponseShipItemsV1)._links.shipment.name;
  } catch (err: any) {
    // An attempt to triage the "status code 0" issue
    console.error('SIR Ship - Error', {
      errorString: err?.toString?.(),
      jsonError: (err as AxiosError)?.toJSON?.(),
      statusCode: (err as AxiosError)?.response?.status,
      responseData: (err as AxiosError)?.response?.data,
      responseHeaders: (err as AxiosError)?.response?.headers
    });
    if (axios.isCancel(err)) {
      console.error('SIR Ship - Request is cancelled!!!');
      return undefined;
    }
    throw err;
  }
}
