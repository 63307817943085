import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { pomUrlConstructor } from '../../../utils/urlConstructor';
import {
  colors,
  chartOptions,
  getRandomColor,
  createDataSet,
  fillMissingChartData,
  getDateLabels,
  limitProductCategories
} from './backlogHelper';
import { useSelectedView } from '../../shared/hooks/useSelectedView';
import { ProductCategory } from '../../../models/products';

export interface BacklogChartProps {
  isLoading: boolean;
}

export const BacklogChart: React.FC<BacklogChartProps> = () => {
  const { data } = useSelector((state: AppState) => state.dashboard.backlog.items);
  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers);
  const { showQuantities } = useSelector((state: AppState) => state.dashboard.backlog);
  const selectedFulfillerId = selectedFulfillers && selectedFulfillers.length > 0 ? selectedFulfillers[0] : '';
  const { groupByProductCategories } = useSelector((state: AppState) => state.dashboard.backlog);
  const { selectedView } = useSelectedView();
  const { t } = useTranslation();
  const categories = useSelector<AppState, ProductCategory[]>(state => state.productCategories?.categories?.data || []);
  const categoriesLeafMap = {};
  categories.forEach(c => categoriesLeafMap[c.name] = c.isLeaf);

  let dailyDataSets: ChartDataSets[] = [];
  const dateLabels = getDateLabels();

  if (groupByProductCategories) {
    const filteredData = (selectedView?.extensions.ui.screens.dashboard.backlogWidget.leafProductCategoriesOnly.show)
      ? data?.aggregatedByDateAndCategory.filter(categoryRow => categoriesLeafMap[categoryRow.key]) || []
      : data?.aggregatedByDateAndCategory || [];

    // Here maxDisplayedCategories is limited to number of colors defined in SCSS
    const maxNumberOfCategoriesToShow = colors.length;
    const categorizedData = filteredData.length > maxNumberOfCategoriesToShow
      ? limitProductCategories(filteredData, maxNumberOfCategoriesToShow)
      : filteredData;

    dailyDataSets = categorizedData.map((category, i) => {
      const chartDataSet = fillMissingChartData(dateLabels, category.aggregations, showQuantities ? 'quantity' : 'total');
      const chartCategoryColor = getRandomColor(categorizedData.length, i);
      return createDataSet(category.key, dateLabels.map(() => chartCategoryColor), chartDataSet);
    });
  } else {
    const itemsLabel = t('dashboard.backlogWidget.items');
    const chartDataSet = fillMissingChartData(dateLabels, data?.aggregatedByDate || [], showQuantities ? 'quantity' : 'total');
    dailyDataSets.push(createDataSet(itemsLabel, dateLabels.map(() => colors[1]), chartDataSet));
  }

  const chartData: ChartData = {
    labels: dateLabels,
    datasets: dailyDataSets
  };

  const options: ChartOptions = {
    scales: {
      yAxes: [{
        scaleLabel: {
          labelString: t('dashboard.backlogWidget.items')
        }
      }]
    },
    legend: {
      display: groupByProductCategories,
      position: 'right'
    },
    ...chartOptions,
    onHover(event: MouseEvent, activeElements: Array<{}>): void {
      const section = activeElements[0];
      if (event.currentTarget) {
        (event.currentTarget as HTMLElement).style.cursor = section ? 'pointer' : 'default';
      }
    }
  };

  return (
    <div>
      <Bar
        data={chartData}
        options={options}
        height={220}
        getElementAtEvent={elem => {
          if (!elem || !elem[0]) {
            return;
          }

          const datasetIndex = elem[0]._datasetIndex;
          const index = elem[0]._index;
          const shipDate: string = ((chartData?.labels) ? chartData?.labels[index] : '') as string;
          const productCategory = chartData?.datasets ? chartData?.datasets[datasetIndex].label : '';
          if (!shipDate || (groupByProductCategories && !productCategory)) {
            return;
          }

          const pomQueryParams: [string, string][] = [
            ['fulfillerId', selectedFulfillerId],
            ['status', 'new,accepted,production'],
            ['sortBy', 'promisedArrivalDate'],
            ['sortOrder', 'asc'],
            ['shipDate', shipDate], // TODO Remove when POM v1 is completely deprecated
            ['expectedShipTimeFrom', shipDate],
            ['expectedShipTimeTo', shipDate]
          ];

          if (groupByProductCategories && productCategory) {
            pomQueryParams.push(['productCategories', productCategory]);
          }

          window.location.assign(pomUrlConstructor('items', pomQueryParams));
        }}
      />
    </div>
  );
};

