export const isProd = (window.location.hostname === 'pom.cimpress.io') || (window.location.hostname === 'manage.production.cimpress.io');

export const fomaUrl: string = isProd
  ? 'https://fulfillment.at.cimpress.io'
  : 'https://tst-fulfillment.at.cimpress.io';

export const complaintsUiUrl: string = isProd
  ? 'https://complaints.commerce.cimpress.io'
  : 'https://int-complaints.commerce.cimpress.io';

export const shippingConsoleUrl: string = isProd
  ? 'https://console-v2.shipping.cimpress.io'
  : 'https://stg-console-v2.shipping.cimpress.io';

export const deliveryOptionsUrl: string = isProd
  ? 'https://delivery-options.commerce.cimpress.io'
  : 'https://int-delivery-options.commerce.cimpress.io';

export const itemServiceUrl: string = isProd
  ? 'https://item-service.commerce.cimpress.io'
  : 'https://int-item-service.commerce.cimpress.io';

export const rejectionUnmetExpectationUrl: string = isProd
  ? 'https://fulfillmentexpectations.fi.cimpress.io/v2/expectations/'
  : 'https://int-fulfillmentexpectations.fi.cimpress.io/v2/expectations/';

export const jobsheetUrl: string = isProd
  ? 'https://api.jobsheets.cimpress.io'
  : 'https://int-api.jobsheets.cimpress.io';

export const fulfilleridentityUrl = 'https://fulfilleridentity.trdlnk.cimpress.io';
