export const constructUrlWithQueryParams = (baseUrl: string, parameters: [string, string][]): URL => {
  const url = new URL(baseUrl);
  parameters.forEach(([name, val]) => url.searchParams.append(name, val));

  return url;
};

// The reason that this exists is because in POMv1 we use the hash router, this expects the query params to come after the hash
// the problem is that the built-in URL class in javascript puts the hash after the query parameters.
// The URL class does: https://production.cimpress.io/?fulfilelrId=abc123#/items
// but we need:        https://production.cimpress.io/#/items?fulfilelrId=abc123
export const pomUrlConstructor = (route: string, parameters: [string, string][]): string => {
  const params = (parameters || [])
    .map(([name, val]) => `${name}=${encodeURIComponent(val)}`)
    .join('&');

  let uri = `/${route}`;
  if (params) {
    uri = `${uri}?${params}`;
  }

  return uri;
};
