import { DeliveryOptionsActionTypes, DeliveryOptionsState } from './types';
import { DeliveryOptionsActions } from '../storeActions';

const initialState: DeliveryOptionsState = {
  deliveryOptions: {
    isLoading: true
  }
};

export function deliveryOptionsReducer(state = initialState, action: DeliveryOptionsActionTypes): DeliveryOptionsState {
  switch (action.type) {
    case DeliveryOptionsActions.GET_DELIVERY_OPTIONS_REQUEST:
      return {
        ...state,
        deliveryOptions: {
          isLoading: true
        }
      };
    case DeliveryOptionsActions.GET_DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        deliveryOptions: {
          data: action.data,
          isLoading: false
        }
      };
    case DeliveryOptionsActions.GET_DELIVERY_OPTIONS_ERROR:
      return {
        ...state,
        deliveryOptions: {
          errors: [action.error],
          isLoading: false
        }
      };
    default:
      return state;
  }
}
