import { DeliveryRequestActions } from '../storeActions';
import { DeliveryRequestsActionTypes, DeliveryRequestsState } from './types';

const initialState: DeliveryRequestsState = {
  deliveryRequestDetails: {
    isLoading: true
  }
};

export function deliveryRequestsReducer(state = initialState, action: DeliveryRequestsActionTypes): DeliveryRequestsState {
  switch (action.type) {
    case DeliveryRequestActions.GET_DELIVERY_REQUEST_DETAILS_REQUEST:
      return {
        ...state,
        deliveryRequestDetails: {
          isLoading: true
        }
      };
    case DeliveryRequestActions.GET_DELIVERY_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        deliveryRequestDetails: {
          data: { ...state.deliveryRequestDetails.data, ...action.data },
          isLoading: false
        }
      };
    case DeliveryRequestActions.GET_DELIVERY_REQUEST_DETAILS_ERROR:
      return {
        ...state,
        deliveryRequestDetails: {
          errors: [action.error],
          isLoading: false
        }
      };
    default:
      return state;
  }
}
