import { DashboardActionTypes, DashboardState } from './types';
import { DashboardActions } from '../storeActions';

const initialState: DashboardState = {
  todayWidget: {
    isLoading: false
  },
  backlog: {
    items: {
      data: {
        aggregatedByDate: [],
        aggregatedByDateAndCategory: [],
        totalLateItemsCount: 0
      },
      isLoading: false
    },
    groupByProductCategories: true,
    showQuantities: false
  }
};

export function dashboardReducer(state = initialState, action: DashboardActionTypes): DashboardState {
  switch (action.type) {
    case DashboardActions.GET_TODAY_WIDGET_VALUES_REQUEST:
      return {
        ...state,
        todayWidget: {
          isLoading: true
        }
      };
    case DashboardActions.GET_TODAY_WIDGET_VALUES_SUCCESS:
      return {
        ...state,
        todayWidget: {
          data: action.data,
          isLoading: false
        }
      };
    case DashboardActions.GET_TODAY_WIDGET_VALUES_ERROR:
      return {
        ...state,
        todayWidget: {
          errors: [action.error],
          isLoading: false
        }
      };
    case DashboardActions.GET_BACKLOG_VALUES_REQUEST:
      return {
        ...state,
        backlog: {
          ...state.backlog,
          items: {
            isLoading: true
          }
        }
      };
    case DashboardActions.GET_BACKLOG_VALUES_SUCCESS:
      return {
        ...state,
        backlog: {
          ...state.backlog,
          items: {
            data: action.data,
            isLoading: false
          }
        }
      };
    case DashboardActions.GET_BACKLOG_VALUES_ERROR:
      return {
        ...state,
        backlog: {
          ...state.backlog,
          items: {
            errors: [action.error],
            isLoading: false
          }
        }
      };
    case DashboardActions.GET_PRODUCT_CATEGORY_TREE_ERROR:
      return {
        ...state,
        backlog: {
          ...state.backlog,
          groupByProductCategories: false
        }
      };
    case DashboardActions.TOGGLE_GROUP_BY_PRODUCT_CATEGORIES:
      return {
        ...state,
        backlog: {
          ...state.backlog,
          groupByProductCategories: !state.backlog.groupByProductCategories
        }
      };
    case DashboardActions.TOGGLE_BACKLOG_BY_QUANTITIES:
      return {
        ...state,
        backlog: {
          ...state.backlog,
          showQuantities: !state.backlog.showQuantities
        }
      };
    default:
      return state;
  }
}
