import React from 'react';
import { ErrorDetails } from '../store/commonTypes';
import { useTranslation } from 'react-i18next';
import { Snackbar } from '@cimpress/react-components';

export const ErrorComponent: React.FC<Props> = ({ errors }: Props) => {
  const { t } = useTranslation();
  const unexpectedError: ErrorDetails = {
    message: t('error.unexpectedError')
  };

  const errorContent = (errors || [unexpectedError])
    .map(error => {
      console.error(error);
      return <>
        <div>{error.message}</div>
        {error.details
          ? <div className={'text-muted'}>{error.details.toString()}</div>
          : null}
      </>;
    });

  if (!errorContent) {
    return null;
  }

  return <Snackbar
    show={true}
    bsStyle={'danger'}
    onHideSnackbar={() => {
      (errors || [unexpectedError]).forEach(error => error.onDismissed && error.onDismissed(error));
    }}
  >{errorContent}</Snackbar>;
};

interface Props {
  errors?: ErrorDetails[];
}
