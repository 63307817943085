import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Alert, BreadcrumbItem, Breadcrumbs, Card, Radio, RadioGroup } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import printJS from 'print-js';
import ReactGA from 'react-ga';

import { AppState } from '../../../store/store';
import { Loading } from '../../shared/loading';
import { fetchJobsheetPdfOrStatus, generateJobsheetPDF } from '../../../clients/jobsheets/jobsheets';
import { PdfViewer } from './pdfViewer';
import './jobSheetPage.scss';
import { getPreferredItemId, OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { FomaItemState } from '../../../store/items/types';
import { getFomaOrderItem } from '../../../store/items/actions';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { JobsheetPreset, JobsheetPresetState } from '../../../store/jobsheetPresets/reducers';
import { getJobsheetPresetRequest } from '../../../store/jobsheetPresets/actions';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { TrackedLocalLink } from '../../../trackingLayer/trackedLocalLink';

const renderItemPreview = (useMerchantIds, itemId: string, item: OrderItemWithStatusAndOrderInfo) => {
  return <>
    <strong>{item.product?.name}</strong><br />
    <TrackedLocalLink item={'jobsheet.itemLink'} to={`/items/${itemId}`}>
      {useMerchantIds
        ? item?.merchantInformation?.itemId || itemId
        : itemId}
    </TrackedLocalLink>
  </>;
};

export const JobSheetPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const itemsById = useSelector((state: AppState): Record<string, FomaItemState> => state.items.byId);
  const jobsheetPresetsByFulfiller = useSelector((state: AppState): Record<string, JobsheetPresetState> => state.jobsheets.presetsByFulfiller);
  const { auth, settings } = useSelector<AppState, AppState>(state => state);
  const { accessToken } = auth;
  const { useMerchantIds, useMerchantBarcodeIds } = settings;

  const [presetId, setPresetId] = useState('');
  const [jobsheet, setJobsheet] = useState('');
  const [jobsheetPdfUrl, setJobsheetPdfUrl] = useState('');
  const [generatingJobsheet, setGeneratingJobsheet] = useState(false);
  const [checkStatusAttemptNumber, setCheckStatusAttemptNumber] = useState(0);
  const [checkingJobsheetStatus, setCheckingJobsheetStatus] = useState(false);
  const [jobsheetError, setJobsheetError] = useState(null);
  const [materializeAsync, setMaterializeAsync] = useState<undefined | boolean>(undefined);

  const itemIds = new URLSearchParams(window.location.search).getAll('itemId') || [];
  itemIds.forEach(itemId => {
    if (!itemsById[itemId] || (!itemsById[itemId].data && !itemsById[itemId].isLoading && !itemsById[itemId].error)) {
      dispatch(getFomaOrderItem(itemId));
    }
  });

  const allLoaded = itemIds.every(itemId => !itemsById[itemId]?.isLoading && itemsById[itemId]?.data)
    && Object.values(jobsheetPresetsByFulfiller).every(x => !x.isLoading);

  const jobSheetPresets: Record<string, JobsheetPreset> = {};

  const uniqueFulfillers = {};
  let payload = null as any;
  if (allLoaded) {
    const items = [] as any[];
    itemIds.forEach(itemId => {
      if (!itemsById[itemId].error && itemsById[itemId].data?.orderInfo?.fulfiller) {
        const item = JSON.parse(JSON.stringify(itemsById[itemId].data)) as OrderItemWithStatusAndOrderInfo;
        uniqueFulfillers[item.orderInfo.fulfiller.fulfillerId] = 1;

        const payloadItem = {
          itemId: item.itemId,
          preferredItemId: getPreferredItemId(item, useMerchantIds),
          preferredOrderId: useMerchantIds ? item.orderInfo.merchantInformation?.orderId || item.orderInfo.orderId : item.orderInfo.orderId,
          preferredBarcodeItemId: getPreferredItemId(item, useMerchantBarcodeIds),
          links: {
            self: {
              href: item.links.self.href,
              rel: item.links.self.rel || 'self'
            }
          }
        };

        if ((jobsheetPresetsByFulfiller[item.orderInfo.fulfiller.fulfillerId]?.data.presets || []).length > 0) {
          // eslint-disable-next-line no-unused-expressions
          jobsheetPresetsByFulfiller[item.orderInfo.fulfiller.fulfillerId].data.presets.forEach(preset => {
            jobSheetPresets[preset.id] = preset;
          });
        }

        items.push(payloadItem);
      }
    });
    payload = { items };

    if (!presetId) {
      if (Object.keys(jobSheetPresets).length > 0) {
        setPresetId(Object.keys(jobSheetPresets)[0]);
      } else {
        Object.keys(uniqueFulfillers).forEach(fulfillerId => {
          dispatch(getJobsheetPresetRequest(fulfillerId));
        });
      }
    }
  }

  useEffect(() => {
    const f = async () => {
      if (accessToken && presetId && payload && !generatingJobsheet) {
        setCheckStatusAttemptNumber(0);
        setJobsheetPdfUrl('');
        setJobsheet('');
        setGeneratingJobsheet(true);
        const shouldBeAsync = materializeAsync || payload.items.length > 3;
        try {
          if (shouldBeAsync) {
            const pdfLocation = (await generateJobsheetPDF(accessToken, presetId, payload, true));
            if (!pdfLocation) {
              setGeneratingJobsheet(false);
              return;
            }
            setJobsheetPdfUrl(pdfLocation);
          } else {
            const pdf = (await generateJobsheetPDF(accessToken, presetId, payload, false));
            setJobsheet(pdf);
          }
        } catch (e: any) {
          if (e.status === 502) {
            setGeneratingJobsheet(false);
            setMaterializeAsync(true);
            return;
          }
          setGeneratingJobsheet(false);
          setJobsheetError(e.message);
          return;
        }
        setGeneratingJobsheet(false);
      }
    };
    f();
    // DO NOT ADD payload OR generatingJobsheet AS DEPS !!! It would cause infinite loop
  },
  // eslint-disable-next-line
    [accessToken, presetId, allLoaded, materializeAsync]);

  useEffect(() => {
    const f = async () => {
      if (accessToken && jobsheetPdfUrl) {
        setCheckingJobsheetStatus(true);
        try {
          const pdf = (await fetchJobsheetPdfOrStatus(accessToken, jobsheetPdfUrl));
          if (pdf === null) {
            setTimeout(() => {
              setCheckStatusAttemptNumber(checkStatusAttemptNumber + 1);
            }, 2000);
            return;
          }
          setJobsheet(pdf as any);
        } catch (e: any) {
          setCheckingJobsheetStatus(false);
          setJobsheetError(e.message);
        }
        setCheckingJobsheetStatus(false);
      }
    };
    f();
    // DO NOT ADD payload OR generatingJobsheet AS DEPS !!! It would cause infinite loop
  },
  // eslint-disable-next-line
    [accessToken, jobsheetPdfUrl, checkStatusAttemptNumber]);

  if (itemIds.length === 0) {
    return <Redirect to={{ pathname: '/items', state: { backToItemsPage: true } }} />;
  }

  if (!allLoaded) {
    return <Loading message={t('common.loading')} />;
  }

  return (
    <div className='container-fluid'>

      <Breadcrumbs>
        <BreadcrumbItem>
          <TrackedLocalLink item={'breadcrumbs.home'} to={'/'}>{t('breadcrumbs.home')}</TrackedLocalLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <TrackedLocalLink item={'breadcrumbs.home.items'} to={{ pathname: '/items', state: { backToItemsPage: true } }}>{t('details.items')}</TrackedLocalLink>
        </BreadcrumbItem>
        {itemIds.length === 1
          ? <BreadcrumbItem>
            <TrackedLocalLink item={'breadcrumbs.home.item.details'} to={{ pathname: `/items/${itemIds[0]}` }}>{getPreferredItemId(itemsById[itemIds[0]].data, useMerchantIds)}</TrackedLocalLink>
          </BreadcrumbItem>
          : null}
        <BreadcrumbItem>{t('jobsheet.breadcrumb')}</BreadcrumbItem>
      </Breadcrumbs>

      <Card style={{ marginBottom: '5px' }}>

        <div className='flexContainer'>
          <div className='flexChild horizontalRadios'>
            <strong>{t('jobsheet.jobsheetType')}&nbsp;&nbsp;&nbsp;</strong>
            <RadioGroup className='jobsheetRadioGroup' onChange={(e, value) => {
              setPresetId(value);
              ReactGA.event({
                category: window.location.pathname,
                action: 'jobsheet.preset.change',
                label: value
              });
            }} name={'templateType'} valueSelected={presetId}>
              {Object.values(jobSheetPresets)
                .map((preset, i) => {
                  return <Radio key={i} disabled={generatingJobsheet} label={preset.name || preset.id} value={preset.id} />;
                })}
            </RadioGroup>
          </div>
          <div className='flexChild'>

            <div style={{ textAlign: 'right' }}>
              <div className=''>
                <TrackedButton item="jobsheet.print"
                  disabled={generatingJobsheet || !jobsheet}
                  type={'default'}
                  onClick={() => {
                    const base64Data = Buffer.from(jobsheet, 'binary').toString('base64');
                    printJS({ printable: base64Data, type: 'pdf', base64: true });
                  }}>
                  {t('jobsheet.print')}
                </TrackedButton>
                &nbsp;
                <TrackedButton item="jobsheet.download"
                  disabled={generatingJobsheet || !jobsheet}
                  type={'default'}
                  onClick={() => {
                    const firstItem = itemsById[itemIds[0]]?.data;
                    if (!firstItem) {
                      return;
                    }
                    const filename = `${getPreferredItemId(firstItem, useMerchantIds)}-jobsheet-${moment().toISOString()}.pdf`;
                    saveAs(new Blob([jobsheet], { type: 'application/pdf' }), filename);
                  }}>
                  {t('jobsheet.download')}
                </TrackedButton>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <div className='flexContainer'>
        <div className='flexChildGrow' style={{ marginRight: '5px' }}>
          {generatingJobsheet
            ? (<Loading message={t('jobsheet.generating')} />)
            : checkingJobsheetStatus
              ? <Loading message={t('jobsheet.checkingStatusAttempt', { count: checkStatusAttemptNumber })} />
              : (jobsheet
                ? <Card className='documentCard'>
                  <PdfViewer pdf={{ data: jobsheet }} onPagesCount={() => {
                    // don't worry
                  }} />
                </Card>
                : (jobsheetPdfUrl || jobsheetError
                  ? <Alert message={<>
                    {t('jobsheet.failedToGenerate')}<br />
                    <span className='text-muted'>{jobsheetPdfUrl}<br />
                      <span className='text-muted'>{jobsheetError}</span></span>
                  </>} dismissible={false} />
                  : null)
              )}
        </div>
        <div className='flexChild'>
          <Card>
            <h2>{t('jobsheet.selectedItems', { count: itemIds.length })}</h2>
            {itemIds.map((itemId, i) => {
              return <div key={i} className={'jobsheet-item-list'}>{renderItemPreview(useMerchantIds, itemId, itemsById[itemId].data)}</div>;
            })}
          </Card>
        </div>
      </div>

    </div >
  );
};
