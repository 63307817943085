import { SettingsActions } from '../storeActions';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import { getMcpSettings, CustomizrClient, CustomizrMcpSettingsResponse } from 'cimpress-customizr';
import { getViewsRequest } from '../views/actions';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { CustomizrPomV2 } from './types';
import { ColumnWidths } from '../../components/shared/hooks/usePersistentColumnResizing';

export const pomApplicationResourceId = 'pom-c7e885f019364f63b91ab25caa5a0a38';

export const setSettings = settings => ({ type: SettingsActions.SET_SETTNGS, settings });

export const setColumnWidths = (tableId: string, newColumnSizes: ColumnWidths) => ({
  type: SettingsActions.SET_COLUMN_WIDTHS,
  columnWidths: {
    [tableId]: newColumnSizes
  }
});

export const getCustomizrSettings = (): ThunkAction<void, AppState, void, AnyAction> => async (dispatch, getState) => {
  const { accessToken } = getState().auth;

  try {
    const settings = await Promise.all([
      (new CustomizrClient({}).getSettings(accessToken, pomApplicationResourceId) as Promise<CustomizrPomV2>),
      getMcpSettings(accessToken) as CustomizrMcpSettingsResponse
    ]);

    const allSettings = settings.reduce((acc, setting) => Object.assign(acc, setting), {});

    allSettings.language = allSettings.language[0];
    allSettings.locale = allSettings.regionalSettings;

    // no need to null check because there are defaults provided: https://github.com/Cimpress/cimpress-customizr/blob/master/src/mcpHelpers.js#L23
    dispatch(getViewsRequest());

    return dispatch(setSettings(allSettings));
  } catch (e) {
    SentryWrapper.reportError(e);
    return dispatch(setSettings({}));
  }
};
