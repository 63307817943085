import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../utils/dateTimeUtils';
import { Dropdown, Table } from '@cimpress/react-components';
import { Notification } from '../../clients/foma/notificationActionClient';
import { StatusKeysToDisplay } from '../../utils/statusInfo';

import { FomaItemState } from '../../store/items/types';
import { tableTranslations } from '../../utils/tableTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { getFomaOrderItem } from '../../store/items/actions';
import { ItemPreview } from './artworkChanges/itemPreview';
import { DownloadDocumentButton } from '../shared/buttons/downloadDocumentButton';
import { getCustomizrSettings, pomApplicationResourceId, setColumnWidths } from '../../store/settings/actions';
import { ColumnWidths, usePersistentColumnResizing } from '../shared/hooks/usePersistentColumnResizing';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

interface ArtworkChangesTableProps {
  isLoading: boolean;
  useMerchantIds: boolean;
  pageSize: number;
  resetWidthsCounter: number;
  artworkChangeRequests: Notification[];
  onSelectionChanged: (selected: Record<string, boolean>) => void;
  onAccept: (notificationId: string, itemId: string, withCosts: boolean) => void;
  onReject: (notificationId: string, itemId: string) => void;
}

const customCellWidth: ColumnWidths = {
  notificationId: 100,
  createdDate: 140,
  itemsNewDocRefUrl: 140,
  itemsOldDocRefUrl: 140,
  orderId: 140,
  actions: 160
};

const tableId = 'pendingChanges_artworkChanges';

const ArtworkChangesTable: React.FC<ArtworkChangesTableProps> = ({ isLoading, resetWidthsCounter, artworkChangeRequests, onSelectionChanged, useMerchantIds, onAccept, onReject, pageSize }) => {
  const settings = useSelector((state: AppState) => state.settings);
  const currentColumnWidths = settings.customColumnWidths?.[tableId];
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const orderItemsMap = useSelector((state: AppState): Record<string, FomaItemState> => state.items.byId || {});
  const [selectedRows, setSelectedRows] = useState({});
  const [loadedNotifications, setLoadedNotifications] = useState({});
  const [selectedPage, setSelectedPage] = useState(0);
  const { t } = useTranslation();
  const dateFormatter = useDateTimeFormatter(true);
  const dateFormatFunc = date => dateFormatter(new Date(date));
  const dispatch = useDispatch();
  const { handleOnResizedChange, applyColumnWidths, resetWidths } = usePersistentColumnResizing(accessToken,
    pomApplicationResourceId,
    tableId,
    currentColumnWidths,
    customCellWidth,
    (_tableId, newColumnSizes) => dispatch(setColumnWidths(tableId, newColumnSizes)),
    SentryWrapper.reportError);

  useEffect(() => {
    if (resetWidthsCounter === 0) { return; }
    resetWidths(() => dispatch(getCustomizrSettings()));
  }, [resetWidthsCounter, resetWidths, dispatch]);

  useEffect(() => {
    const firstItem = selectedPage * pageSize;
    const artworkChangeRequestsCurrentPage = artworkChangeRequests.slice(firstItem, firstItem + pageSize);
    artworkChangeRequestsCurrentPage
      .forEach(notification => {
        if (!loadedNotifications[notification.notificationId]) {
          setLoadedNotifications(Object.assign(loadedNotifications, { [notification.notificationId]: true }));
          notification.items.forEach(item => {
            const itemInfo = orderItemsMap[item.itemId];
            if (!itemInfo) {
              dispatch(getFomaOrderItem(item.itemId));
            }
          });
        }
      });
  }, [loadedNotifications, selectedPage, artworkChangeRequests, orderItemsMap, dispatch, pageSize]);

  const columns = [
    {
      id: 'notificationId',
      Header: t('pendingActions.artworkChange.table.id'),
      show: false,
      accessor(artworkChangeRequest): string {
        return artworkChangeRequest.notificationId;
      },
      Cell(row) {
        return `${row.original.notificationId.substring(0, 8)}...`;
      }
    },
    {
      id: 'createdDate',
      Header: t('pendingActions.artworkChange.table.createdAt'),
      accessor: artworkChangeRequest => artworkChangeRequest.createdDate,
      Cell: artworkChangeRequest => dateFormatFunc(artworkChangeRequest.original.createdDate)
    },
    {
      id: 'itemsOldDocRefUrl',
      Header: t('pendingActions.artworkChange.table.itemsOldDocRefUrl'),
      accessor: artworkChangeRequest => (artworkChangeRequest.items || []).map(it => it.itemId).join(', '),
      Cell(artworkChangeRequest) {
        // TODO: Fix a single item for now!
        return <ItemPreview itemId={artworkChangeRequest?.original?.changeRequest?.artworkChangeDetails?.itemId} />;
      }
    },
    {
      id: 'itemsNewDocRefUrl',
      Header: t('pendingActions.artworkChange.table.itemsNewDocRefUrl'),
      accessor: artworkChangeRequest => (artworkChangeRequest.items || []).map(it => it.itemId).join(', '),
      Cell(artworkChangeRequest) {
        // TODO: Fix a single item for now!
        return <ItemPreview
          itemId={artworkChangeRequest?.original?.changeRequest?.artworkChangeDetails?.itemId}
          documentReferenceUrlOverride={artworkChangeRequest?.original?.changeRequest?.artworkChangeDetails?.documentReferenceUrl}
        />;
      }
    },
    {
      id: 'itemsStatus',
      Header: t('pendingActions.artworkChange.table.itemsStatus'),
      accessor: artworkChangeRequest => (artworkChangeRequest.items || []).map(it => it.itemId).join(', '),
      Cell(artworkChangeRequest) {
        const itemId = artworkChangeRequest?.original?.changeRequest?.artworkChangeDetails?.itemId;
        const item = orderItemsMap[itemId];
        const visibleItemId = useMerchantIds ? item?.data?.merchantInformation?.itemId || itemId : itemId;
        const caption = `${visibleItemId} - ${t('common.loading')} ...`;
        if (item?.isLoading === true) {
          return caption;
        }
        const detailedStatus = item?.data?.status || {};
        const totalQuantity = item?.data?.orderedQuantity;
        let itemText = `${visibleItemId}${totalQuantity > 1 ? ` (${totalQuantity})` : ''} - `;
        let trimFront = true;
        StatusKeysToDisplay.forEach(k => {
          if (detailedStatus && detailedStatus.statusDetails && detailedStatus.statusDetails[k]) {
            const quantity = detailedStatus.statusDetails[k].quantity;
            itemText += `${trimFront ? '' : ', '}${t(`statuses.${k}`)}${quantity > 1 ? ` (${quantity})` : ''}`;
            trimFront = false;
          }
        });
        return itemText.replace('- ,', '-');
      },
      sortable: false
    },
    {
      Header: t('pendingActions.artworkChange.table.orderId'),
      id: 'orderId',
      accessor: item => useMerchantIds
        ? orderItemsMap[item.items[0].itemId]?.data?.orderInfo?.merchantInformation?.orderId || item.order.orderId
        : item.order.orderId,
      sortable: false
    },
    {
      Header: t('pendingActions.artworkChange.table.actions'),
      id: 'actions',
      accessor: item => item.order.orderId,
      Cell(row) {
        const item = orderItemsMap[row.original.changeRequest?.artworkChangeDetails?.itemId];

        // TODO: This is a hack - we kind of have multiple items part of the Notification but we only use 1 from the CR
        const crItem = row.original.items.find(it => it.itemId === row.original.changeRequest?.artworkChangeDetails?.itemId);
        const docOverrides = {};
        docOverrides[row.original.changeRequest?.artworkChangeDetails?.itemId] = (crItem?.links || {})['new-document']?.href;

        return <Dropdown title={t('pendingActions.artworkChange.table.actions')}>
          <button onClick={() => {
            const itemId = row.original.changeRequest.artworkChangeDetails.itemId;
            onAccept(row.original.notificationId, itemId, false);
          }}>{t('pendingActions.artworkChange.buttons.acceptNoCosts')}</button>
          <button onClick={() => {
            const itemId = row.original.changeRequest.artworkChangeDetails.itemId;
            onAccept(row.original.notificationId, itemId, true);
          }}>{t('pendingActions.artworkChange.buttons.acceptWithCosts')}</button>
          <button onClick={() => {
            const itemId = row.original.changeRequest.artworkChangeDetails.itemId;
            onReject(row.original.notificationId, itemId);
          }}>{t('pendingActions.artworkChange.buttons.reject')}</button>
          {item && item?.data
            ? <DownloadDocumentButton items={[item?.data]} dropDown={true} dispatch={dispatch} documentLinkOverrideByItemId={docOverrides} />
            : null}
        </Dropdown>;
      },
      sortable: false
    }
  ];

  applyColumnWidths(columns);

  const selectAll = !artworkChangeRequests.some(cr => !selectedRows[cr.notificationId]);

  return <>
    <Table
      columns={columns}
      data={artworkChangeRequests}
      condensed={false}
      loading={isLoading}
      selectable={false}
      toggleSelection={key => {
        const rowId = key.split('select-')[1];
        const newSelectedRows = Object.assign({}, selectedRows, { [rowId]: !selectedRows[rowId] });
        setSelectedRows(newSelectedRows);
        onSelectionChanged(newSelectedRows);
      }}
      toggleAll={() => {
        const newSelectedRows = {};
        if (!selectAll) {
          artworkChangeRequests.forEach(cr => newSelectedRows[cr.notificationId] = true);
        }
        setSelectedRows(newSelectedRows);
        if (onSelectionChanged) {
          onSelectionChanged(newSelectedRows);
        }
      }}
      selectAll={selectAll}
      keyField={'notificationId'}
      isSelected={key => {
        return !!selectedRows[key];
      }}
      filterable={false}
      pageSize={artworkChangeRequests.length === 0 ? pageSize : Math.min(artworkChangeRequests.length, pageSize)}
      onPageChange={setSelectedPage}
      onResizedChange={handleOnResizedChange}
      showPageSizeOptions={false}
      {...tableTranslations(t)}
      noDataText={t('pendingActions.noDataText')}
    />
  </>;
};

export {
  ArtworkChangesTable
};
