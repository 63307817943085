import React from 'react';
import { default as PlatformHeader } from '@cimpress-technology/react-platform-header';
import { getBranding } from '@cimpress-technology/branding-support';
import { useTranslation } from 'react-i18next';
import * as authActions from './store/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './store/store';
import { pomUrlConstructor } from './utils/urlConstructor';
import { Fulfiller } from './store/fulfillers/types';
import { setSelectedFulfillers } from './store/fulfillers/actions';
import { AppSettings } from './components/settings/appSettings';
import { getJobsheetPresetRequest } from './store/jobsheetPresets/actions';
import { useSelectedView } from './components/shared/hooks/useSelectedView';
import { TrackedLocalLink } from './trackingLayer/trackedLocalLink';

export const Header: React.FC = () => {
  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const profile = useSelector((state: AppState) => state.auth.profile);
  const allFulfillersAreLoading = useSelector((state: AppState) => state.fulfillers?.fulfillers?.isLoading) || false;
  const allFulfillers = useSelector((state: AppState) => state.fulfillers?.fulfillers?.data) as (Fulfiller[] | null);
  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers) || [];
  const selectedFulfillerId = selectedFulfillers.length > 0 ? selectedFulfillers[0] : '';
  const notifications = useSelector((state: AppState) => state.notifications) || {};
  const { selectedView } = useSelectedView();
  const numberOfNotifications = Object.keys(notifications.notifications || {}).length;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  let filteredSelectedFulfillers: (string[] | null) = null;
  if (!allFulfillersAreLoading && allFulfillers) {
    filteredSelectedFulfillers = selectedFulfillers.filter(fid => allFulfillers.find(ff => ff.fulfillerId === fid));
    if (filteredSelectedFulfillers && filteredSelectedFulfillers.length !== selectedFulfillers.length) {
      if (filteredSelectedFulfillers.length === 0 && allFulfillers.length > 0) {
        filteredSelectedFulfillers.push(allFulfillers[0].fulfillerId);
      }
      dispatch(setSelectedFulfillers(filteredSelectedFulfillers));
      filteredSelectedFulfillers.forEach(fulfillerId => {
        dispatch(getJobsheetPresetRequest(fulfillerId));
      });
    }
  }

  const itemsLinkHref = pomUrlConstructor('items', [['fulfillerId', selectedFulfillerId]]);
  let itemsLink = <a href={itemsLinkHref} key={'items'}>{t('menu.items')}</a>;

  const pendingActionsLinkHref = pomUrlConstructor('pending', [['fulfillerId', selectedFulfillerId]]);
  const pendingActionsBadge = numberOfNotifications > 0
    ? (<div className={'nav-item-badge'}>
      <span className={'badge badge-danger'}>
        {numberOfNotifications > 99 ? '99+' : numberOfNotifications}
      </span>
    </div>)
    : null;

  let pendingActionsLink = <a href={pendingActionsLinkHref} key={'pendingActions'}>
    {t('menu.pendingActions')}
    {pendingActionsBadge}
  </a>;

  const contactsLink = <TrackedLocalLink item='link.header.contact' to={'/contact'} key={'contact'}>{t('menu.contact')}</TrackedLocalLink>;
  itemsLink = <TrackedLocalLink item='link.header.items' to={'/items'} key={'items'}>{t('menu.items')}</TrackedLocalLink>;
  pendingActionsLink = <TrackedLocalLink item='link.header.pendingActions' to={'/pendingActions'} key={'pendingActions'}>
    {t('menu.pendingActions')}
    {pendingActionsBadge}
  </TrackedLocalLink>;

  const appTitle = (
    <TrackedLocalLink item={'link.headerTitle'} to={'/'}>Production Order Manager</TrackedLocalLink>
  );

  const appLinks = [
    {
      id: 'menuLinkDashboard',
      type: 'default',
      content: <TrackedLocalLink item={'link.header.dashboard'} to={'/dashboard'} key={'dashboard'}>{t('menu.dashboard')}</TrackedLocalLink>
    },
    {
      id: 'menuLinkFulfillerPerformance',
      type: 'default',
      content: <TrackedLocalLink item={'link.header.fulfillerPerformance'} to={'/fulfillerPerformance'} key={'FulfillerPerformance'}>{t('menu.fulfillerPerformance')}</TrackedLocalLink>
    },
    {
      id: 'menuLinkItems',
      type: 'default',
      content: itemsLink
    },
    {
      id: 'menuLinkPendingActions',
      type: 'default',
      content: pendingActionsLink
    },
    {
      id: 'menuLinkContant',
      type: 'default',
      content: contactsLink
    },
    {
      id: 'menuLinkSettings',
      type: 'default',
      content: <AppSettings accessToken={accessToken} />
    }
  ];

  if (!selectedView?.extensions.ui.screens.pendingActions?.show) {
    appLinks.splice(appLinks.findIndex(l => l.id === 'menuLinkPendingActions'), 1);
  }

  const loggedIn = !!accessToken;

  return (
    <div>
      <PlatformHeader
        appTitle={appTitle}
        profile={profile}
        appId={'POMv2'}
        isLoggedIn={loggedIn}
        accessToken={accessToken}
        showLeftNav={loggedIn && !isWmd(accessToken)}
        showLogo={loggedIn}
        onLogInClicked={() => dispatch(authActions.login())}
        onLogOutClicked={() => dispatch(authActions.logout())}
        responsive={true}
        appLinks={accessToken ? appLinks : []}
        useBranding={true}
      />
    </div>
  );
};

function isWmd(accessToken: string) {
  return getBranding(accessToken)?.brand === 'WIRmachenDRUCK';
}
