import React from 'react';
import { setToProduction } from '../../../store/items/actions';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { useTranslation } from 'react-i18next';
import { TrackedButton } from '../../../trackingLayer/trackedButton';

export const SetToProductionButton: React.FC<SetToProductionButtonProps> = props => {
  const { t } = useTranslation();

  return <TrackedButton item="setToProduction" type={'primary'}
    disabled={props.disabled}
    onClick={() => {
      props.dispatch(setToProduction(props.items, props.refreshItemList));
    }}>
    {t('buttons.setToProduction')}
  </TrackedButton>;
};

interface SetToProductionButtonProps {
  dispatch: Function;
  disabled?: boolean;
  items: OrderItemWithStatusAndOrderInfo[];
  refreshItemList: boolean;
}
