import React from 'react';
import ReactGA from 'react-ga';
import * as fulfillersActions from '../../store/fulfillers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { Fulfiller } from '../../store/fulfillers/types';
import { useHistory } from 'react-router-dom';
import { getNotifications } from '../../store/notifications/actions';
import { determineFulfillerId, handleSingleFulfillerSelect } from '../../utils/selectedFulfillersHandler';
import { useTranslation } from 'react-i18next';
import { getJobsheetPresetRequest } from '../../store/jobsheetPresets/actions';
import { getProductCategoriesRequest } from '../../store/products/actions';
import { TrackedSelect } from '../../trackingLayer/trackedSelect';

const fulfillerIdQueryParamKey = 'fulfillerIds';

export const FulfillerSelectWrapper: React.FC = () => {
  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers);
  const fulfillers = useSelector<AppState, Fulfiller[] | undefined>(state => state.fulfillers.fulfillers.data) ?? [];
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const changeFulfiller = fulfiller => {
    let newlySelectedFulfillers;
    if (selectedFulfillers[0] === fulfiller.value) {
      newlySelectedFulfillers = handleSingleFulfillerSelect(selectedFulfillers, fulfiller.value);
    } else {
      newlySelectedFulfillers = [fulfiller.value];
    }
    dispatch(fulfillersActions.setSelectedFulfillerId(newlySelectedFulfillers));
    dispatch(getNotifications(newlySelectedFulfillers));
    dispatch(getProductCategoriesRequest([newlySelectedFulfillers]));

    newlySelectedFulfillers.forEach(fulfillerId => {
      dispatch(getJobsheetPresetRequest(fulfillerId));
    });

    const search = new URLSearchParams(history.location.search);
    if (fulfiller.value === search.get(fulfillerIdQueryParamKey)) {
      return;
    }

    // dimension1 and dimension2 are the keys for fulfillerId and fulfillerName in GA, do not change.
    ReactGA.set({ dimension1: fulfiller.value, dimension2: fulfiller.label });
  };

  let defaultSelectedFulfillerId = determineFulfillerId(fulfillers, fulfillerIdQueryParamKey, selectedFulfillers, history.location.search);

  if (!fulfillers.some(f => f.fulfillerId === defaultSelectedFulfillerId)) {
    defaultSelectedFulfillerId = fulfillers[0]?.fulfillerId;
  }
  let defaultSelectedFulfillerSelectValue;
  if (defaultSelectedFulfillerId) {
    const defaultSelectedFulfiller = fulfillers.find(f => f.fulfillerId === defaultSelectedFulfillerId);
    defaultSelectedFulfillerSelectValue = defaultSelectedFulfiller && {
      value: defaultSelectedFulfiller.fulfillerId,
      label: defaultSelectedFulfiller.name
    };
    if (selectedFulfillers[0] !== defaultSelectedFulfillerId) {
      changeFulfiller(defaultSelectedFulfillerSelectValue);
    }
  }

  return (
    <TrackedSelect
      item={'filters.fulfiller'}
      // @ts-ignore
      isClearable={false}
      // @ts-ignore
      isMulti={false}
      label={t('filters.fulfiller')}
      // @ts-ignore
      value={defaultSelectedFulfillerSelectValue}
      options={fulfillers.map(fulfiller => {
        return {
          value: fulfiller.fulfillerId,
          label: fulfiller.name
        };
      })}
      onChange={changeFulfiller}
    />
  );
};
