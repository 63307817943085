import React from 'react';
import { Checkbox, CheckboxProps } from '@cimpress/react-components';
import { trackEvent } from './utils';

export interface TrackedCheckboxProps extends CheckboxProps {
  item: string;
}

export const TrackedCheckbox: React.FC<TrackedCheckboxProps> = props => {
  const { item, onChange, ...checkboxProps } = props;

  return <Checkbox
    onChange={(e, payload) => {
      trackEvent(item, 'Toggle');
      if (onChange) {
        onChange(e, payload);
      }
    }}
    {...checkboxProps}
  />;
};
