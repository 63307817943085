import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../utils/dateTimeUtils';
import { Dropdown, Table } from '@cimpress/react-components';
import { Notification } from '../../clients/foma/notificationActionClient';
import { StatusKeysToDisplay } from '../../utils/statusInfo';

import { renderList } from './utils';
import { FomaItemState } from '../../store/items/types';
import { tableTranslations } from '../../utils/tableTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { getFomaOrderItem } from '../../store/items/actions';
import { ColumnWidths, usePersistentColumnResizing } from '../shared/hooks/usePersistentColumnResizing';
import { getCustomizrSettings, pomApplicationResourceId, setColumnWidths } from '../../store/settings/actions';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { getDeliveryDetailsRequest } from '../../store/deliveryRequests/actions';
import { DeliveryRequest } from '../../models/deliveryRequest';

interface AddressChangesTableProps {
  isLoading: boolean;
  useMerchantIds: boolean;
  pageSize: number;
  resetWidthsCounter: number;
  addressChangeRequests: Notification[];
  onSelectionChanged: (selected: Record<string, boolean>) => void;
  onAccept: (notificationId: string) => void;
  onReject: (notificationId: string) => void;
}

const renderDiff = (caption, oldText, newText) => {
  if ((oldText || '').trim() === (newText || '').trim()) {
    return <span className='diffItem'>
      <span className='noDiffCaption'>{caption}:</span> {oldText}
    </span>;
  }
  return <span className='diffItem'>
    <span className='diffCaption'>{caption}:</span> <span className='diffOldText'>{oldText}</span> <span className='diffNewText'>{newText}</span>
  </span>;
};

const renderAddressChange = (t, oldAddr1, newAddr1) => {
  const oldAddr = oldAddr1 || {};
  const newAddr = newAddr1 || {};
  return <>
    {renderDiff(t('address.name'), `${oldAddr.firstName} ${oldAddr.lastName}`, `${newAddr.firstName} ${newAddr.lastName}`)}
    {renderDiff(t('address.street'), oldAddr.street1, newAddr.street1)}
    {renderDiff(t('address.city'), oldAddr.city, newAddr.city)}
    {renderDiff(t('address.stateOrProvince'), oldAddr.stateOrProvince, newAddr.stateOrProvince)}
    {renderDiff(t('address.postalCode'), oldAddr.postalCode, newAddr.postalCode)}
    {renderDiff(t('address.country'), oldAddr.country, newAddr.country)}
    {renderDiff(t('address.email'), oldAddr.email, newAddr.email)}
  </>;
};

const customCellWidth: ColumnWidths = {
  notificationId: 100,
  createdDate: 140,
  orderId: 140,
  actions: 160
};

const tableId = 'pendingChanges_addressChanges';

const AddressChangesTable: React.FC<AddressChangesTableProps> = ({ isLoading, addressChangeRequests, onSelectionChanged, useMerchantIds, onAccept, onReject, pageSize, resetWidthsCounter }) => {
  const settings = useSelector((state: AppState) => state.settings);
  const currentColumnWidths = settings.customColumnWidths?.[tableId];
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const orderItemsMap = useSelector((state: AppState): Record<string, FomaItemState> => state.items.byId || {});
  const deliveryDetailsMap = useSelector((state: AppState): Record<string, DeliveryRequest> => state.deliveryRequest.deliveryRequestDetails.data || {});
  const [selectedRows, setSelectedRows] = useState({});
  const [loadedNotifications, setLoadedNotifications] = useState({});
  const [selectedPage, setSelectedPage] = useState(0);
  const { t } = useTranslation();
  const dateFormatter = useDateTimeFormatter(true);
  const dateFormatFunc = date => dateFormatter(new Date(date));
  const dispatch = useDispatch();
  const { handleOnResizedChange, applyColumnWidths, resetWidths } = usePersistentColumnResizing(accessToken,
    pomApplicationResourceId,
    tableId,
    currentColumnWidths,
    customCellWidth,
    (_tableId, newColumnSizes) => dispatch(setColumnWidths(tableId, newColumnSizes)),
    SentryWrapper.reportError);

  useEffect(() => {
    if (resetWidthsCounter === 0) { return; }
    resetWidths(() => dispatch(getCustomizrSettings()));
  }, [resetWidthsCounter, resetWidths, dispatch]);

  useEffect(() => {
    const firstItem = selectedPage * pageSize;
    const addressChangeRequestsCurrentPage = addressChangeRequests.slice(firstItem, firstItem + pageSize);
    addressChangeRequestsCurrentPage
      .forEach(notification => {
        if (!loadedNotifications[notification.notificationId]) {
          setLoadedNotifications(Object.assign(loadedNotifications, { [notification.notificationId]: true }));
          notification.items.forEach(item => {
            const itemInfo = orderItemsMap[item.itemId];
            if (!itemInfo) {
              dispatch(getFomaOrderItem(item.itemId));
            }
          });
          const deliveryRequestsUrls: Array<string> = [];
          notification.changeRequest?.deliveryChangeDetails?.itemDeliveryDetails
            ?.forEach(dd => dd.deliveryDetails
              ?.forEach(d => deliveryRequestsUrls.push(d.links['delivery-request'].href)));
          if (deliveryRequestsUrls?.length > 0) {
            dispatch(getDeliveryDetailsRequest(deliveryRequestsUrls));
          }
        }
      });
  }, [loadedNotifications, selectedPage, addressChangeRequests, orderItemsMap, dispatch, pageSize]);

  const columns = [
    {
      id: 'notificationId',
      Header: t('pendingActions.addressChange.table.id'),
      show: false,
      accessor(addressChangeRequest): string {
        return addressChangeRequest.notificationId;
      },
      Cell(row) {
        return `${row.original.notificationId.substring(0, 8)}...`;
      }
    },
    {
      id: 'createdDate',
      Header: t('pendingActions.addressChange.table.createdAt'),
      accessor: addressChangeRequest => addressChangeRequest.createdDate,
      Cell: addressChangeRequest => dateFormatFunc(addressChangeRequest.original.createdDate)
    },
    {
      id: 'items',
      Header: t('pendingActions.addressChange.table.items'),
      accessor: addressChangeRequest => (addressChangeRequest.items || []).map(it => it.itemId).join(', '),
      Cell(addressChangeRequest) {
        const textItems: string[] = [];
        (addressChangeRequest.original.items || []).forEach(it => {
          const item = orderItemsMap[it.itemId];
          if (item?.isLoading === true) {
            textItems.push(`${it.itemId} - ${t('common.loading')} ...`);
          } else {
            const detailedStatus = item?.data?.status || {};
            const totalQuantity = item?.data?.orderedQuantity;
            const itemId = useMerchantIds ? item?.data?.merchantInformation?.itemId || it.itemId : it.itemId;
            let itemText = `${itemId}${totalQuantity > 1 ? ` (${totalQuantity})` : ''} - `;
            let trimFront = true;
            StatusKeysToDisplay.forEach(k => {
              if (detailedStatus && detailedStatus.statusDetails && detailedStatus.statusDetails[k]) {
                const quantity = detailedStatus.statusDetails[k].quantity;
                itemText += `${trimFront ? '' : ', '}${t(`statuses.${k}`)}${quantity > 1 ? ` (${quantity})` : ''}`;
                trimFront = false;
              }
            });
            textItems.push(itemText.replace('- ,', '-'));
          }
        });
        return renderList(textItems);
      },
      sortable: false
    },
    {
      Header: t('pendingActions.addressChange.table.orderId'),
      id: 'orderId',
      accessor: item => useMerchantIds
        ? orderItemsMap[item.items[0].itemId]?.data?.orderInfo?.merchantInformation?.orderId || item.order.orderId
        : item.order.orderId,
      sortable: false
    },
    {
      Header: t('pendingActions.addressChange.table.newAddress'),
      id: 'newAddress',
      accessor: addressChangeRequest => addressChangeRequest.changeRequest?.toString(),
      Cell(row) {
        let newAddr;
        let oldAddr;
        const addresses = row.original.changeRequest?.deliveryChangeDetails?.itemDeliveryDetails?.[0]?.modifiedDeliveryDetails;
        if (addresses) {
          newAddr = addresses.newAddress;
          oldAddr = addresses.oldAddress;
        } else {
          // To maintain backward compatibility for old Address change request, we can remove this condition after 6 months as FOMA notification exist for 6 months.
          newAddr = row.original.changeRequest?.deliveryChangeDetails?.destinationAddress || {};
          oldAddr = orderItemsMap[row.original.items[0].itemId]?.data?.orderInfo?.destinationAddress;
        }
        return renderAddressChange(t, oldAddr, newAddr);
      },
      sortable: false
    },
    {
      Header: t('pendingActions.addressChange.table.actions'),
      id: 'actions',
      accessor: item => item.order.orderId,
      Cell(row) {
        return <Dropdown title={t('pendingActions.addressChange.table.actions')}>
          <button onClick={() => {
            onAccept(row.original.notificationId);
          }}>{t('pendingActions.addressChange.buttons.accept')}</button>
          <button onClick={() => {
            onReject(row.original.notificationId);
          }}>{t('pendingActions.addressChange.buttons.reject')}</button>
        </Dropdown>;
      },
      sortable: false
    }
  ];

  applyColumnWidths(columns);

  const selectAll = !addressChangeRequests.some(cr => !selectedRows[cr.notificationId]);
  addressChangeRequests
    ?.forEach(acr => {
      const itemDeliveryDetails = acr.changeRequest?.deliveryChangeDetails.itemDeliveryDetails;

      if (itemDeliveryDetails && itemDeliveryDetails.length > 0) {
        itemDeliveryDetails?.forEach(itemDeliveryDetail => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const itemId = itemDeliveryDetail.itemId!;
          const oldDeliveryRequests = orderItemsMap[itemId]?.data?.deliveryRequestsDetails;
          itemDeliveryDetail.deliveryDetails?.forEach((dd, i) => {
            if (oldDeliveryRequests && oldDeliveryRequests?.[i]?.deliveryRequestLink.href && dd.links['delivery-request'].href) {
              // @ts-ignore
              itemDeliveryDetail.modifiedDeliveryDetails = {
                oldAddress: oldDeliveryRequests?.[i]?.destinationAddress,
                newAddress: deliveryDetailsMap[dd.links['delivery-request'].href]?.destinationAddress
              };
            }
          });
        });
      }
    });

  return <>
    <Table
      columns={columns}
      data={addressChangeRequests}
      condensed={false}
      loading={isLoading}
      selectable={false}
      toggleSelection={key => {
        const rowId = key.split('select-')[1];
        const newSelectedRows = Object.assign({}, selectedRows, { [rowId]: !selectedRows[rowId] });
        setSelectedRows(newSelectedRows);
        onSelectionChanged(newSelectedRows);
      }}
      toggleAll={() => {
        const newSelectedRows = {};
        if (!selectAll) {
          addressChangeRequests.forEach(cr => newSelectedRows[cr.notificationId] = true);
        }
        setSelectedRows(newSelectedRows);
        if (onSelectionChanged) {
          onSelectionChanged(newSelectedRows);
        }
      }}
      selectAll={selectAll}
      keyField={'notificationId'}
      isSelected={key => {
        return !!selectedRows[key];
      }}
      filterable={false}
      pageSize={addressChangeRequests.length === 0 ? pageSize : Math.min(addressChangeRequests.length, pageSize)}
      onPageChange={setSelectedPage}
      onResizedChange={handleOnResizedChange}
      showPageSizeOptions={false}
      {...tableTranslations(t)}
      noDataText={t('pendingActions.noDataText')}
    />
  </>;
};

export {
  AddressChangesTable
};
