import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { App } from './app';
import { Provider } from 'react-redux';
import { AppState, store } from './store/store';
import * as serviceWorker from './serviceWorker';
import { i18n } from './locales/i18n';
import './styles/main.scss';
import { I18nextProvider } from 'react-i18next';
import { GIT_SHA, BUILD_DATE } from './utils/buildInformation';
import { onStoreUpdate } from './utils/storeSubscriber';
import { isProd } from './utils/environmentProvider';
import { FullStory } from '@cimpress-technology/react-reporting-redux';
import 'moment/min/locales.min';
import 'url-search-params-polyfill';

const isV1Url = [
  'fulfillment.at.cimpress.io',
  'tst-fulfillment.at.cimpress.io',
  'pom.at.cimpress.io',
  'tst-pom.at.cimpress.io',
  'production.cimpress.io',
  'tst.production.cimpress.io',
  'pom.trdlnk.cimpress.io',
  'staging.production.cimpress.io',
  'manage.production.cimpress.io'
].some(dm => dm === window.location.hostname);

if (isV1Url) {
  //
  // do the magical redirects here !
  let v2Url = window.location.href;

  const replacements = {
    '/tst-pom.at.cimpress.io': '/staging.production.cimpress.io',
    '/tst.production.cimpress.io': '/staging.production.cimpress.io',
    '/tst-fulfillment.cimpress.io': '/staging.production.cimpress.io',
    '/pom.at.cimpress.io': '/manage.production.cimpress.io',
    '/production.cimpress.io': '/manage.production.cimpress.io',
    '/fulfillment.cimpress.io': '/manage.production.cimpress.io',
    '/#/items': '/items',
    '/#/pending': '/pendingActions',
    '/#/contact': '/contact',
    '/#/settings': '/',
    'search=': 'fulltext=',
    'fulfillerId=': 'fulfillerIds=',
    '?utm_source=puremail&utm_medium=email&utm_campaign=order_request&utm_content=text#/items': '/items',
    '//items?': '/items?',
    '/#': '',
    'staging.production.cimpress.io': 'int-pom.cimpress.io',
    'manage.production.cimpress.io': 'pom.cimpress.io'
  };

  Object.keys(replacements).forEach(keyToReplace => {
    if (v2Url.includes(keyToReplace)) {
      v2Url = v2Url.replace(keyToReplace, replacements[keyToReplace]);
    }
  });

  window.location.replace(v2Url);
  // end this madness
  //
} else {
  console.log({ GIT_SHA, BUILD_DATE });

  const gaTrackingCode = 'UA-88706407-7';
  if (isProd) {
    ReactGA.initialize(gaTrackingCode);
  } else {
    ReactGA.initialize(gaTrackingCode, { debug: false });
    // sendHitTask: null will disable sending events to GA when running in test env or locally
    ReactGA.set({ sendHitTask: null });
  }

  onStoreUpdate(store, (state: AppState) => state.settings.language, newLang => {
    i18n.changeLanguage(newLang);
  });

  ReactDOM.render(
    <Provider store={store}>
      <FullStory fsOptions={{ org: '97CT5', allowLocalhost: false, debug: false }} selector={state => state.auth} />
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>,
    document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
