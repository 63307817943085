import React from 'react';
import { Card } from '@cimpress/react-components';
import { IconAlertTriangle } from '@cimpress-technology/react-streamline-icons/lib';
import { useTranslation } from 'react-i18next';
import { useSelectedView } from './shared/hooks/useSelectedView';
import { TrackedLink } from '../trackingLayer/trackedLink';

export const RequestAccessComponent: React.FC = () => {
  const { t } = useTranslation();
  const { selectedView } = useSelectedView();
  const email = selectedView?.extensions.support.email || 'PlatformSupport@cimpress.com';

  return (
    <div className={'container-fluid'}>
      <Card>
        <h1>
          <IconAlertTriangle size={'3x'} /> &nbsp;
          <span>{t('noaccess.requestAccess')}</span>
        </h1>
        <h3>{t('noaccess.noAccessViewOrdersForAnyFulfiller')}</h3>
        <br />
        <p>
          <span>{t('noaccess.askAdministratorForAccess')}</span>
        </p>

        <p>

          {selectedView?.extensions.features.showDocumentationLinks.enabled
            ? <span>
              <span>
                {t('noaccess.adminCanNavigateTo')}
                <TrackedLink item={'requestAccess.link.userCimpressIo'} href="https://users.cimpress.io"> Cimpress Open Access Management </TrackedLink>
                {t('noaccess.andAddToFulfillerGroup')}.
              </span>
              <br />
              <span>
                {t('noaccess.docsCanBeFound')}
                <TrackedLink item={'requestAccess.link.manageGroups'} href="https://developer.cimpress.io/docs/user_management/manage_groups.html"> {t('noaccess.here')}</TrackedLink>.
              </span>
            </span>
            : null
          }

        </p>

        <span><b>{t('noaccess.needTechnicalAssistanceReachTo')} <TrackedLink item={'requestAccess.link.needHelp'} href={`mailto:${email}`}>{email}</TrackedLink>.</b></span>
      </Card>
    </div>
  );
};
