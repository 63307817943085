import { ProductCategoriesActionTypes, ProductCategoriesState } from './types';
import { ProductCategoriesActions } from '../storeActions';

const initialState: ProductCategoriesState = {
  categories: {
    isLoading: true
  }
};

export function productCategoriesReducer(state = initialState, action: ProductCategoriesActionTypes): ProductCategoriesState {
  switch (action.type) {
    case ProductCategoriesActions.GET_PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        categories: {
          isLoading: true
        }
      };
    case ProductCategoriesActions.GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          data: action.data,
          isLoading: false
        }
      };
    case ProductCategoriesActions.GET_PRODUCT_CATEGORIES_ERROR:
      return {
        ...state,
        categories: {
          errors: [action.error],
          isLoading: false
        }
      };
    default:
      return state;
  }
}
