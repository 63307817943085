import React, { Fragment, useState } from 'react';
import { Label } from '@cimpress/react-components';
import ReactClaims from '@cimpress-technology/react-claims';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { isProd } from '../../utils/environmentProvider';
import { TrackedButton } from '../../trackingLayer/trackedButton';
import { TrackedModal } from '../../trackingLayer/trackedModal';

export interface ClaimsInfoModalProps {
  itemId?: string;
  claimId?: string;
  labelStyle?: 'danger' | 'default' | 'info' | 'primary' | 'success' | 'warning';
  labelTitle?: string;
}

export const ClaimsLabelWithModal: React.FC<ClaimsInfoModalProps> = ({ itemId, claimId, labelStyle, labelTitle }) => {
  const { t, i18n } = useTranslation();
  const locale = useSelector<AppState, string>(state => state.settings.locale);
  const accessToken = useSelector<AppState, string>(state => state.auth.accessToken);
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  if (!itemId && !claimId) {
    return null;
  }

  return <>
    <span onClick={e => {
      setOpen(true);
      e.stopPropagation();
    }} style={{ cursor: 'pointer' }}>
      <Label className='spacedLabel' text={labelTitle || t('details.claim')} type={labelStyle || 'danger'} />
    </span>
    <span onClick={e => e.stopPropagation()}>
      <TrackedModal item={'claimsModal'} bsStyle={'info'} closeButton={true}
        className="columns-modal"
        show={open}
        onRequestHide={closeModal}
        title={t('items.claims.modal.title')}
        footer={
          <Fragment>
            <TrackedButton item="claimsModal.close" className="btn btn-default" onClick={closeModal}>
              {t('items.claims.modal.ok')}
            </TrackedButton>
          </Fragment>
        }>
        <ReactClaims
          title={null}
          environment={isProd ? 'prd' : 'int'}
          language={i18n.language}
          locale={locale.toLocaleLowerCase() === 'x-iso8601' ? 'en' : locale}
          displayLimit={5}
          accessToken={accessToken}
          displayInCard={false}
          queryParameters={
            claimId
              ? ({ id: claimId })
              : ({ itemId: [itemId] })
          }
        />
      </TrackedModal>
    </span>
  </>;
};
