import { apiRequest } from '../apiClient';

const cache = {};

export async function resolveCarrierService(accessToken, carrierServiceCode): Promise<string | null> {
  if (cache[carrierServiceCode]) {
    return cache[carrierServiceCode];
  }

  const url = `https://carriers.logistics.cimpress.io/api/v1/carrierServices/${carrierServiceCode}`;
  const response = await apiRequest<any>({
    url,
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken: accessToken,
    noErrorReportingForStatusCodes: [404]
  });

  const data = response?.data || null;

  if (data && data.name) {
    Object.assign(cache, { [carrierServiceCode]: data.name });
    return cache[carrierServiceCode];
  }

  return null;
}
