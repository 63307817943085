
import { useEffect, useState } from 'react';
import { apiRequest } from '../../../clients/apiClient';
import { resolveCarrierService } from '../../../clients/logisticsClients/carrierRegistryClient';

interface Shipment {
  id: string;
}

export const useShipment = (accessToken, platformOrderItemId, shipmentLink) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shipments, setShipments] = useState([] as Shipment[]);

  useEffect(() => {
    const runEffect = async () => {
      if (accessToken && platformOrderItemId && shipmentLink) {
        try {
          const shipmentsResponse = await apiRequest<any>({
            url: shipmentLink,
            headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
            accessToken
          });
          const shipmentsData = shipmentsResponse?.data?.shipments || [];

          const carrierServices = {};
          shipmentsData.forEach(shipment => carrierServices[shipment.carrierServiceKey] = null);

          const carrierServiceKeys = Object.keys(carrierServices);
          const resolvedCarrierServices = await Promise.all(carrierServiceKeys.map(k => resolveCarrierService(accessToken, k)));
          carrierServiceKeys.forEach((k, i) => carrierServices[k] = resolvedCarrierServices[i]);

          const res = shipmentsData.map(sD => {
            const quantity = platformOrderItemId
              ? (sD.items?.find(it => it.mcpItemId === platformOrderItemId)
                || sD.packages.reduce((a, b) => a.concat(b)).items.find(it => it.mcpItemId === platformOrderItemId)).quantity
              : null;

            return {
              carrier: carrierServices[sD.carrierServiceKey],
              shipmentId: sD.shipmentId,
              created: sD.shipmentCreationDateTime,
              pickupDateTime: sD.pickupDateTime,
              tracking: sD.packages && sD.packages.map(({ trackingId, trackingUrl }) => ({ trackingId, trackingUrl })),
              quantity: quantity,
              destinationAddress: sD.destinationAddress
            };
          });

          setShipments(res);
        } catch (error) {
          console.warn('Item was shipped but shipments failed to load');
          console.warn(error);
        }
        setIsLoading(false);
      }
    };
    runEffect();
  }, [accessToken, shipmentLink, platformOrderItemId]);

  return { loadingShipments: isLoading, shipments };
};
