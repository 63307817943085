import React from 'react';
import { FulfillerSelectWrapper } from './fulfillerSelectWrapper';
import { ShipmentsWidget } from './shipmentsWidget/shipmentsWidget';
import { TodayWidget } from './todayWidget/todayWidget';
import { BacklogWidget } from './backlogWidget/backlogWidget';
import { ClaimsWidgetWrapper } from './claimsWidget/claimsWidgetWrapper';
import { LatestInvoicesWidgetWrapper } from './latestInvoicesWidget/latestInvoicesWidgetWrapper';
import { BreadcrumbItem, Breadcrumbs } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={'container-fluid'}>
      <div className={'row'}>
        <div className={'col-lg-9 col-md-8 col-sm-8 col-xs-12'}>
          <Breadcrumbs>
            <BreadcrumbItem>
              {t('breadcrumbs.home')}
            </BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <div className={'col-lg-3 col-md-4 col-sm-4 col-xs-12'}>
          <FulfillerSelectWrapper/>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-xs-12'}>
          <TodayWidget/>
          <br/>
          <BacklogWidget/>
          <br/>
          <div className={'row'}>
            <div className={'col-md-4'}>
              <ClaimsWidgetWrapper/>
            </div>
            <div className={'col-md-4'}>
              <LatestInvoicesWidgetWrapper/>
            </div>
            <div className={'col-md-4'}>
              <ShipmentsWidget/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

