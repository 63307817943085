import React from 'react';

export const renderList = (textItems: string[]) => {
  if (textItems.length === 0) {
    return 'n/a';
  }

  if (textItems.length === 1) {
    return textItems[0];
  }

  return <ul>
    {textItems.map((it, i) => <li key={i} className={'noWrap'}>{it}</li>)}
  </ul>;
};
