import React, { Fragment, useState } from 'react';
import { Alert, shapes } from '@cimpress/react-components';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import moment from 'moment';
import { fomaUrl } from '../../../utils/environmentProvider';
import StereotypeClient from 'stereotype-client';
import { saveAs } from 'file-saver';
import { TemplateCloneButton, TemplateModifyButton, TemplateSelect } from '@cimpress-technology/react-templates';
import { useTranslation } from 'react-i18next';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { TrackedModal } from '../../../trackingLayer/trackedModal';

const { Spinner } = shapes;

export const DownloadCsvButton: React.FC<DownloadCsvButtonProps> = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const btnProps = {
    disabled: props.disabled,
    onClick: openModal
  };
  const btnContent = <>
    {t('actions.buttons.downloadCsv')}
  </>;

  return <>
    <span onClick={e => e.stopPropagation()}>
      <DownloadCsvModal
        accessToken={props.accessToken}
        items={props.items}
        isOpen={isModalOpen}
        closeModal={closeModal} />
    </span>

    {props.dropDown
      ? <button {...btnProps}>{btnContent}</button>
      : <TrackedButton item="downloadCsv" {...btnProps} type={'default'}>{btnContent}</TrackedButton>}
  </>;
};

interface DownloadCsvButtonProps {
  accessToken: string;
  items: OrderItemWithStatusAndOrderInfo[];
  disabled?: boolean;
  dispatch: Function;
  dropDown?: boolean;
}

const DownloadCsvModal: React.FC<DownloadCsvModalContentsProps> = ({ accessToken, isOpen, items, closeModal }) => {
  const { t, i18n } = useTranslation();
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<{ label?: string; value: string } | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [sequence, setSequence] = useState(1);

  const startDownload = templateUrl => {
    if (!isSaving) {
      setIsSaving(true);
      setShowAlert(false);
    }

    const payload = {
      Items: items.map(item => ({
        links: {
          self: {
            rel: 'self',
            href: new URL(`/v1/items/${item.itemId}`, fomaUrl).toString()
          },
          shipments: {
            rel: 'shipments',
            href: `https://shipment-manager.shipping.cimpress.io/api/v1/shipments?search=${item.platformItemId}`
          },
          prices: {
            rel: 'prices',
            href: `https://pas.cimpress.io/v1/itemPrices?itemUrl=${encodeURIComponent(item.links['platform-item'].href)}`
          }
        }
      }))
    };

    const sc = new StereotypeClient(accessToken, { timeout: 59000 });
    sc.setAcceptPreferenceHeader('image/*;q=0.1,application/json;q=0.9');
    sc.setWhitelistHeader('self,shipments,prices,https://fulfillment.at.cimpress.io/rels/plan,https://fulfillment.at.cimpress.io/rels/status,https://fulfillment.at.cimpress.io/rels/pricing-information,https://item-service.commerce.cimpress.io');
    sc.setMaximumCrawlDepthHeader(3); // Expand also the order link

    // Awful :)
    const urlParts = templateUrl.split('/');
    const templateId = decodeURIComponent(urlParts[urlParts.length - 1]);

    sc.materializeById(templateId, payload)
      .then(materialization => {
        const filename = `pom-csv-${moment().toISOString()}.csv`;
        saveAs(new Blob([materialization], { type: 'text/csv;charset=utf-8' }), filename);

        closeModal();
        setShowAlert(false);
      })
      .catch(err => {
        SentryWrapper.reportError(err);

        setShowAlert(true);
      })
      .then(() => {
        setIsSaving(false);
      });
  };

  const acceptModal = () => {
    if (selectedTemplateOption) {
      startDownload(selectedTemplateOption.value);
    }
  };

  const ModalFooter: React.FC = () => {
    return (
      <Fragment>
        <TrackedButton item="csvModal.close" className="btn btn-default" onClick={closeModal} disabled={isSaving}>
          {t('buttons.csv.modalFooterButtons.close')}
        </TrackedButton>
        <TrackedButton item="csvModal.download" className="btn btn-warning" onClick={acceptModal} disabled={!selectedTemplateOption || isSaving}>
          {isSaving ? <><Spinner size='small' className='inline' />&nbsp;</> : null}
          {t(isSaving ? 'buttons.downloading' : 'buttons.csv.modalFooterButtons.download')}
        </TrackedButton>
      </Fragment>
    );
  };

  return (
    <TrackedModal
      item={'downloadCsvModal'}
      className={'overflow-modal'}
      bsStyle='warning'
      show={isOpen}
      title={t('buttons.csv.modalTitle')}
      onRequestHide={closeModal}
      footer={<ModalFooter />}>
      <div>
        <Alert
          message={t('buttons.csv.alert.message')}
          title={t('buttons.csv.alert.title')}
          dismissible={true}
          dismissed={!showAlert}
          onDismiss={() => setShowAlert(false)}
        />
        <div className='flexContainer'>
          <div className='flexChildGrow'>
            <TemplateSelect
              language={i18n.language}
              accessToken={accessToken}
              selectedTemplateOption={selectedTemplateOption}
              filterTemplateTypeBy={['xcsv']}
              onChangeOption={setSelectedTemplateOption}
              refreshKey={sequence}
              onError={(error: any) => {
                SentryWrapper.reportError(error);
              }} />
            <div>
              <TemplateCloneButton
                language={i18n.language}
                accessToken={accessToken}
                fromTemplateUrl={selectedTemplateOption?.value}
                onCloned={template => {
                  setSelectedTemplateOption({ label: template.templateName, value: template.links.self.href });
                  setSequence(sequence + 1);
                }}
                showError={false}
                onError={(error: any) => {
                  SentryWrapper.reportError(error);
                }} />
              &nbsp;
              <TemplateModifyButton
                language={i18n.language}
                accessToken={accessToken}
                templateUrl={selectedTemplateOption?.value}
                onError={(error: any) => {
                  SentryWrapper.reportError(error);
                }} />
            </div>
          </div>
        </div>
      </div>
    </TrackedModal>
  );
};

interface DownloadCsvModalContentsProps {
  accessToken: string;
  isOpen: boolean;
  items: OrderItemWithStatusAndOrderInfo[];
  closeModal: () => void;
}
