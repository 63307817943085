import React from 'react';
import { FulfillerPerformance } from '@cimpress-technology/supplier-performance';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppState } from '../../store/store';
import { Fulfiller } from '../../store/fulfillers/types';
import { FulfillerSelectWrapper } from '../dashboard/fulfillerSelectWrapper';
import { determineFulfillerId } from '../../utils/selectedFulfillersHandler';

export const FulfillerPerformancePage: React.FC = () => {
  const fulfillerIdQueryParamKey = 'fulfillerIds';
  const accessToken = useSelector((state: AppState) => state.auth.accessToken);
  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers);
  const fulfillerData = useSelector((state: AppState) => state.fulfillers.fulfillers?.data) || [];

  const history = useHistory();
  const selectedFulfillerId = determineFulfillerId(fulfillerData, fulfillerIdQueryParamKey, selectedFulfillers, history.location.search);
  
  const selectedFulfillerName = fulfillerData?.find(f => f.fulfillerId === selectedFulfillerId) as Fulfiller;
  const selectedFulfiller = {
    fulfillerId: selectedFulfillerId,
    name: selectedFulfillerName?.name || ''
  };

  return <>
    <div className={'row'}>
      <div className={'col-lg-3 col-md-4 col-sm-4 col-xs-12'} style = {{ float: 'right' }}>
        <FulfillerSelectWrapper/>
      </div>
    </div>
    {selectedFulfiller.fulfillerId ? <FulfillerPerformance
      accessToken={accessToken}
      fulfiller={selectedFulfiller}
      hideHeading= {true}
      componentHeight='calc(220vh - 247px + 47px)'
    /> : null}
  </>;
};
