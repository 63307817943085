import { View } from '../models/views';

export const defaultView: View = {
  viewId: '4ce42f12-09c2-41ce-8166-0ec14c9341da',
  viewName: 'POM Default',
  filters: [],
  columns: [
    {
      name: 'product',
      accessor: 'product.name'
    },
    {
      name: 'status',
      accessor: 'status'
    },
    {
      name: 'Quantity',
      accessor: 'quantity'
    },
    {
      name: 'Forecasted Ship Date',
      accessor: 'plan.shipping.expectedShipTime'
    },
    {
      name: 'Item Id',
      accessor: 'itemId'
    },
    {
      name: 'Order Id',
      accessor: 'order.orderId'
    },
    {
      name: 'Promised Delivery',
      accessor: 'order.promisedArrivalDate'
    },
    {
      name: 'Categories',
      accessor: 'not-used'
    },
    {
      name: 'Last Updated Date',
      accessor: 'status.lastUpdated'
    },
    {
      name: 'Fulfiller',
      accessor: 'orderInfo.fulfiller.fulfillerId'
    },
    {
      name: 'Merchant',
      accessor: 'orderInfo.merchantInformation.id'
    }
  ],
  sortedBy: [
    {
      columnKey: 'order.promisedArrivalDate',
      direction: 'DESC'
    }
  ],
  extensions: {
    ui: {
      actions: {
        accept: {
          show: true
        },
        reject: {
          show: true
        },
        setToProduction: {
          show: true
        },
        downloadCsv: {
          show: true
        },
        artworkChange: {
          show: false
        },
        rejectCancellation: {
          show: true
        },
        downloadDeliveryNote: {
          show: false
        }
      },
      screens: {
        itemsList: {
          leafProductCategoriesOnly: {
            show: true
          },
          allowedStatusFilters: {
            new: true,
            accepted: true,
            production: true,
            shipped: true,
            completed: true,
            rejected: true,
            cancelled: true
          }
        },
        itemDetails: {
          externalProductToolsLinks: {
            show: true
          },
          promisedArrivalDate: {
            show: true
          },
          deliveryAddress: {
            show: true
          },
          leafProductCategoriesOnly: {
            show: true
          },
          manufacturingCost: {
            show: true
          }
        },
        shipping: {
          deliveryAddress: {
            show: true
          }
        },
        dashboard: {
          todayWidget: {
            openItems: {
              show: true
            },
            newItems: {
              show: true
            },
            shipToday: {
              show: true
            },
            noShippingPlan: {
              show: true
            },
            lateItems: {
              show: true
            },
            pendingCancellations: {
              show: true
            }
          },
          backlogWidget: {
            leafProductCategoriesOnly: {
              show: true
            }
          }
        },
        pendingActions: {
          show: true
        }
      }
    },
    features: {
      limitedLateTag: {
        show: false
      },
      sendEmailOnPlacingComment: {
        enabled: false
      },
      showDocumentationLinks: {
        enabled: true
      },
      customizeColumns: {
        enabled: true
      }
    },
    support: {
      email: 'PlatformSupport@cimpress.com'
    }
  },
  pageSize: 20
};

export const defaultViewWithoutManufacturingCost: View = {
  viewId: '8cb2ee26-e1b8-11ec-8fea-0242ac120002',
  viewName: 'POM Default without manufacturing cost',
  filters: [],
  columns: [
    {
      name: 'product',
      accessor: 'product.name'
    },
    {
      name: 'status',
      accessor: 'status'
    },
    {
      name: 'Quantity',
      accessor: 'quantity'
    },
    {
      name: 'Forecasted Ship Date',
      accessor: 'plan.shipping.expectedShipTime'
    },
    {
      name: 'Item Id',
      accessor: 'itemId'
    },
    {
      name: 'Order Id',
      accessor: 'order.orderId'
    },
    {
      name: 'Promised Delivery',
      accessor: 'order.promisedArrivalDate'
    },
    {
      name: 'Categories',
      accessor: 'not-used'
    },
    {
      name: 'Last Updated Date',
      accessor: 'status.lastUpdated'
    },
    {
      name: 'Fulfiller',
      accessor: 'orderInfo.fulfiller.fulfillerId'
    },
    {
      name: 'Merchant',
      accessor: 'orderInfo.merchantInformation.id'
    }
  ],
  sortedBy: [
    {
      columnKey: 'order.promisedArrivalDate',
      direction: 'DESC'
    }
  ],
  extensions: {
    ui: {
      actions: {
        accept: {
          show: true
        },
        reject: {
          show: true
        },
        setToProduction: {
          show: true
        },
        downloadCsv: {
          show: true
        },
        artworkChange: {
          show: false
        },
        rejectCancellation: {
          show: true
        },
        downloadDeliveryNote: {
          show: false
        }
      },
      screens: {
        itemsList: {
          leafProductCategoriesOnly: {
            show: true
          },
          allowedStatusFilters: {
            new: true,
            accepted: true,
            production: true,
            shipped: true,
            completed: true,
            rejected: true,
            cancelled: true
          }
        },
        itemDetails: {
          externalProductToolsLinks: {
            show: true
          },
          promisedArrivalDate: {
            show: true
          },
          deliveryAddress: {
            show: true
          },
          leafProductCategoriesOnly: {
            show: true
          },
          manufacturingCost: {
            show: false
          }
        },
        shipping: {
          deliveryAddress: {
            show: true
          }
        },
        dashboard: {
          todayWidget: {
            openItems: {
              show: true
            },
            newItems: {
              show: true
            },
            shipToday: {
              show: true
            },
            noShippingPlan: {
              show: true
            },
            lateItems: {
              show: true
            },
            pendingCancellations: {
              show: true
            }
          },
          backlogWidget: {
            leafProductCategoriesOnly: {
              show: true
            }
          }
        },
        pendingActions: {
          show: true
        }
      }
    },
    features: {
      limitedLateTag: {
        show: false
      },
      sendEmailOnPlacingComment: {
        enabled: false
      },
      showDocumentationLinks: {
        enabled: true
      },
      customizeColumns: {
        enabled: true
      }
    },
    support: {
      email: 'PlatformSupport@cimpress.com'
    }
  },
  pageSize: 20
};
