import { apiRequest } from '../apiClient';
import { DeliveryOptionResponse } from './deliveryOptionModel';
import { DeliveryOption } from '../../models/deliveryOptions';
import { deliveryOptionsUrl } from '../../utils/environmentProvider';

// TODO: Add expiry for the cache
const cache = {};

export async function getDeliveryOption(accessToken, deliveryOptionId): Promise<DeliveryOption|undefined> {
  if (cache[deliveryOptionId]) {
    return cache[deliveryOptionId];
  }

  const response = await apiRequest<DeliveryOptionResponse>({
    url: `${deliveryOptionsUrl}/v0/deliveryOptions/${deliveryOptionId}`,
    accessToken
  });

  if (!response) {
    return undefined;
  }

  if (response.error) {
    throw response;
  }

  if (!cache[deliveryOptionId]) {
    Object.assign(cache, { [deliveryOptionId]: response.data });
  }

  return cache[deliveryOptionId];
}

