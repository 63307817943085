import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbItem, Breadcrumbs, Card } from '@cimpress/react-components';
import { TrackedLocalLink } from '../../trackingLayer/trackedLocalLink';
import { ContactInfo } from './contactInfo';

export const ContactsPage: React.FC = () => {
  const { t } = useTranslation();

  return (<div className={'container-fluid'}>
    <Breadcrumbs>
      <BreadcrumbItem>
        <TrackedLocalLink item={'breadcrumbs.home'} to={'/'}>{t('breadcrumbs.home')}</TrackedLocalLink>
      </BreadcrumbItem>
      <BreadcrumbItem active>
        {t('breadcrumbs.contacts')}
      </BreadcrumbItem>
    </Breadcrumbs>
    <Card header={<h3>{t('menu.contact')}</h3>} isMinimal>
      <ContactInfo />
    </Card>
  </div>
  );
};

