import { Column, Resize } from 'react-table';
import { CustomizrClient } from 'cimpress-customizr';
import { debounce } from 'debounce';

export interface ColumnWidths {
    [id: string]: number;
}

export interface TableColumnWidths {
    [tableId: string]: ColumnWidths;
}

export interface CustomizrCustomColumnWidths {
  customColumnWidths?: TableColumnWidths;
}

export type SetColumnWidthsLocallyCallback = (tableId: string, newColumnWidths: ColumnWidths) => void;

export const usePersistentColumnResizing = (accessToken: string,
  applicationResourceId: string,
  tableId: string,
  customColumnWidths: ColumnWidths | undefined,
  defaultColumnWidths: ColumnWidths | undefined,
  setColumnWidthsLocallyCallback: SetColumnWidthsLocallyCallback,
  onError: (err: any) => void) => {
  const c = new CustomizrClient({
    resource: applicationResourceId
  });

  const applyColumnWidths = (columns: Column<any>[]): Column<any>[] => {
    const aggregatedColumnWidths: ColumnWidths = { ...defaultColumnWidths, ...customColumnWidths };

    for (const column of columns) {
      if (!column.id) { continue; }
      column.width = aggregatedColumnWidths[column.id] || column.width;
    }

    return columns;
  };

  const updateInCustomizrAndLocally = (newColumnWidths: ColumnWidths): Promise<unknown> => {
    setColumnWidthsLocallyCallback(tableId, newColumnWidths);

    return c.getSettings(accessToken)
      .then((current: CustomizrCustomColumnWidths) => {
        current.customColumnWidths = {
          ...current.customColumnWidths,
          [tableId]: newColumnWidths
        };

        return c.putSettings(accessToken, current);
      })
      .catch(onError);
  };

  const handleOnResizedChange: (resized: Resize[]) => void = debounce(resized => {
    const newColumnWidths: ColumnWidths = { ...customColumnWidths };

    resized.forEach(element => {
      newColumnWidths[element.id] = element.value;
    });

    return updateInCustomizrAndLocally(newColumnWidths);
  }, 250);

  const resetWidths = (rerenderTableCallback: () => void): Promise<unknown> => {
    return updateInCustomizrAndLocally({})
      .then(() => rerenderTableCallback());
  };

  return { handleOnResizedChange, applyColumnWidths, resetWidths };
};
