import { DeliveryRequestActions } from '../storeActions';
import { AppState } from '../store';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { getDeliveryRequest } from '../../clients/delivery/deliveryRequests';

const getDeliveryDetailsSuccess = values => ({
  type: DeliveryRequestActions.GET_DELIVERY_REQUEST_DETAILS_SUCCESS,
  data: values
});

const getDeliveryDetailsError = error => ({
  type: DeliveryRequestActions.GET_DELIVERY_REQUEST_DETAILS_ERROR,
  data: null,
  error: error
});

export const getDeliveryDetailsRequest = (deliveryRequestUrls: Array<string>) => async (dispatch, getState) => {
  dispatch({ type: DeliveryRequestActions.GET_DELIVERY_REQUEST_DETAILS_REQUEST });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const deliveryRequests = state.deliveryRequest.deliveryRequestDetails.data || {};
  const newDeliveryRequests: Array<string> = [];

  deliveryRequestUrls.forEach(dd => {
    if (!state.deliveryRequest.deliveryRequestDetails.data?.[dd]) {
      newDeliveryRequests.push(dd);
    }
  });
  
  try {
    const deliveryDetailsData = await Promise.all(newDeliveryRequests.map(dd => getDeliveryRequest(accessToken, dd)));
    deliveryDetailsData.forEach((dd, i) => {
      // @ts-ignore
      if (dd) { deliveryRequests[dd?.deliveryRequestLink.href || newDeliveryRequests[i]] = dd;}
    });
    return dispatch(getDeliveryDetailsSuccess({ ...deliveryRequests }));
  } catch (e) {
    SentryWrapper.reportError(e);
    return dispatch(getDeliveryDetailsError(e));
  }
};
