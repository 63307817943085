import { ItemSearchStatus } from './itemsClient';

export enum ItemAggregationGroupBy {
  ExpectedShipDate = 'expectedShipDate',
  ProductCategory = 'productCategory',
  DeliveryOptionId = 'deliveryOptionId'
}

export const createAggregationQueryParams = (selectedFulfillerIds: string[], groupByProductCategories: boolean) => {
  return {
    fulfillerIds: selectedFulfillerIds.join(','),
    status: [ItemSearchStatus.New, ItemSearchStatus.Accepted, ItemSearchStatus.Production].join(','),
    // expectedShipTimeFrom: dayjs().startOf('day').format('YYYY-MM-DD'),
    // expectedShipTimeTo: dayjs().add(6, 'day').startOf('day').format('YYYY-MM-DD'),
    groupBy: `${
      groupByProductCategories
        ? `${ItemAggregationGroupBy.ProductCategory},`
        : ''
    }${ItemAggregationGroupBy.ExpectedShipDate}`
  };
};

export const createAggregationQueryParamsEx = (selectedFulfillerIds: string[], groupByProductCategories: boolean) => {
  return {
    fulfillerIds: selectedFulfillerIds.join(','),
    groupBy: `${
      groupByProductCategories
        ? `${ItemAggregationGroupBy.ProductCategory},`
        : ''
    }${ItemAggregationGroupBy.ExpectedShipDate}`
  };
};

export const createDeliveryOptionAggregationQueryParams = (selectedFulfillerIds: string[]) => {
  return {
    fulfillerIds: selectedFulfillerIds.join(','),
    groupBy: ItemAggregationGroupBy.DeliveryOptionId
  };
};
