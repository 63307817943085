/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-default-export */
/* @ts-nocheck */

import React, { Component } from 'react';
import { Img } from 'react-image';
import isEqual from 'lodash.isequal';
import { Button, Modal, Tooltip, Carousel, shapes } from '@cimpress/react-components';
import PropTypes from 'prop-types';
import { getProductScenes } from './services';

const MODAL_WIDTH = 600;
export const DEFAULT_SIZE = 'xs';
export const DEFAULT_EMBIGGENED_SIZE = 2000;
export const SIZES = {
  [DEFAULT_SIZE]: 250,
  s: 320,
  m: 390,
  l: 460,
  xl: 530
};

export const DEFAULT_PX_SIZE = SIZES[DEFAULT_SIZE];

export default class ItemPreview extends Component {
  static propTypes = {
    size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
    pxSize: PropTypes.number,
    minimal: PropTypes.bool,
    hideIndicators: PropTypes.bool,
    includeModal: PropTypes.bool,
    includePreviewInfo: PropTypes.bool,
    mcpSku: PropTypes.string,
    docRefUrl: PropTypes.string,
    variableAttributes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })),
    additionalPreviewUrls: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, info: PropTypes.string })),
    auth: PropTypes.object,
    title: PropTypes.node,
    additionalInfo: PropTypes.node,
    fallbackTooltip: PropTypes.node,
    embiggenButton: PropTypes.bool,
    embiggenedSize: PropTypes.number,
    includeMerchandising: PropTypes.bool
  };

  static defaultProps = {
    includeModal: true,
    size: DEFAULT_SIZE,
    pxSize: DEFAULT_PX_SIZE,
    fallbackTooltip: 'No Preview Available',
    embiggenedSize: DEFAULT_EMBIGGENED_SIZE,
    includeMerchandising: true
  };

  state = {
    loading: false,
    hasError: false,
    previewUrls: [],
    showModal: false,
    currentPosition: 0
  };

  componentDidMount() {
    this.updatePreviewUrls();
  }

  componentDidUpdate(prevProps) {
    const { docRefUrl, mcpSku, variableAttributes, additionalPreviewUrls, includeMerchandising } = this.props;
    if (
      docRefUrl !== prevProps.docRefUrl
      || mcpSku !== prevProps.mcpSku
      || includeMerchandising !== prevProps.includeMerchandising
      || !isEqual(variableAttributes, prevProps.variableAttributes)
      || !isEqual(additionalPreviewUrls, prevProps.additionalPreviewUrls)
    ) {
      this.setState({ hasError: false });
      this.updatePreviewUrls();
    }
  }

  updatePreviewUrls = () => {
    this.setState({ loading: true, currentPosition: 0 });

    const {
      docRefUrl,
      mcpSku,
      variableAttributes,
      additionalPreviewUrls,
      auth,
      includeModal,
      size,
      pxSize,
      includeMerchandising
    } = this.props;
    const width = includeModal ? SIZES.xl : SIZES[size] || pxSize;

    return getProductScenes({
      mcpSku,
      width,
      auth,
      variableAttributes: this.flattenAttributes(variableAttributes),
      docRefUrl,
      includeMerchandising
    })
      .then(previewUrls => {
        if (additionalPreviewUrls && additionalPreviewUrls.length) {
          // eslint-disable-next-line no-param-reassign
          previewUrls = previewUrls.concat(additionalPreviewUrls);
        }
        this.setState({ previewUrls, loading: false });
      })
      .catch(err => {
        console.warn(err);
        if (additionalPreviewUrls && additionalPreviewUrls.length) {
          this.setState({ previewUrls: additionalPreviewUrls, loading: false });
        } else {
          this.setState({ hasError: true, loading: false });
        }
      });
  };

  close = () => this.setState({ showModal: false });

  open = () => this.setState({ showModal: true });

  onChangeCarousel = currentPosition => this.setState({ currentPosition });

  handleImageError = () => this.setState({ hasError: true, currentPosition: 0 });

  flattenAttributes = (variableAttributes = []) => {
    return variableAttributes.length
      ? variableAttributes.reduce((vars, attribute) => {
        vars[attribute.name] = attribute.value;
        return vars;
      }, {})
      : undefined;
  };

  chooseCarouselSize = () => {
    const height = window.innerHeight;
    return height <= 550 ? 'xs' : height <= 600 ? 's' : height <= 800 ? 'm' : height <= 900 ? 'l' : 'xl';
  };

  render() {
    const {
      size,
      pxSize,
      minimal,
      hideIndicators,
      includeModal,
      mcpSku,
      title,
      additionalInfo,
      fallbackTooltip,
      embiggenButton,
      embiggenedSize
    } = this.props;
    const { loading, previewUrls = [], hasError, currentPosition } = this.state;
    const canRenderImages = previewUrls.length && !hasError && !loading;
    const sizeInPixels = size && !minimal ? SIZES[size] : pxSize;
    const pxWidth = `${sizeInPixels}px`;

    const showModal = includeModal && canRenderImages;
    // const { Spinner } = shapes;

    const slides = canRenderImages
      ? previewUrls.map((preview, idx) => (
        <Img
          key={preview.url + preview.info}
          src={preview.url}
          alt=""
          loader={idx === currentPosition && <span>loading...</span>}
          onError={this.handleImageError}
        />
      ))
      : null;

    const carousel = (
      <Carousel
        className={showModal ? 'clickable' : ''}
        onCarouselClick={showModal ? this.open : undefined}
        loading={loading}
        size={size}
        pxSize={pxSize}
        minimal={minimal}
        hideIndicators={hideIndicators}
        onSlideChanged={this.onChangeCarousel}
        currentSlide={currentPosition}
        showZoomOverlay={Boolean(showModal)}>
        {slides}
      </Carousel>
    );

    return !canRenderImages && fallbackTooltip ? (
      <Tooltip direction="right" contents={fallbackTooltip}>
        <div
          style={{
            width: pxWidth,
            height: pxWidth,
            display: 'flex',
            flexShrink: '0'
          }}
          className={showModal ? 'clickable' : ''}>
          {carousel}
        </div>
      </Tooltip>
    ) : (
      <div>
        {carousel}
        {showModal ? (
          <Modal
            show={this.state.showModal}
            onRequestHide={this.close}
            closeButton
            closeOnOutsideClick
            title={title || mcpSku}
            footer={
              <div>
                <Button onClick={this.close}>Close</Button>
                {embiggenButton ? (
                  <a
                    className="btn btn-primary"
                    href={previewUrls[currentPosition].url.replace(`width=${SIZES.xl}`, `width=${embiggenedSize}`)}
                    target="_blank"
                    rel="noopener noreferrer">
                    View full-size
                  </a>
                ) : null}
              </div>
            }
            style={{ width: `${MODAL_WIDTH + 30}px` }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {/* Carousel needs to size dynamically based on height to minimize scrollbar on smaller resolutions */}
              <Carousel
                size={this.chooseCarouselSize()}
                onSlideChanged={this.onChangeCarousel}
                currentSlide={currentPosition}>
                {slides}
              </Carousel>
            </div>
            {this.props.includePreviewInfo && (
              <div style={{ textAlign: 'center' }}>{previewUrls[currentPosition].info}</div>
            )}
            <div>{additionalInfo}</div>
          </Modal>
        ) : null}
      </div>
    );
  }
}
