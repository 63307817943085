import { apiRequest } from '../apiClient';
import { jobsheetUrl } from '../../utils/environmentProvider';

export const getJobsheetPreset = async (accessToken, fulfillerId) => {
  if (accessToken && fulfillerId) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<any>({
      url: `${jobsheetUrl}/v1/entityMapping`,
      method: 'get',
      query: {
        entityId: fulfillerId,
        entityType: 'fulfiller'
      },
      timeout: 30 * 1000,
      accessToken
    })
      .then(res => res?.data);
  }
  return null;
};

export const generateJobsheetPDF = async (accessToken: string, presetId: string, payload: any, async?: boolean) => {
  if (accessToken && presetId && payload) {
    // eslint-disable-next-line no-return-await
    return await apiRequest<ArrayBuffer>({
      url: `${jobsheetUrl}/v1/jobsheets`,
      method: 'post',
      query: {
        presetId: presetId,
        preferAsync: async ? 'true' : 'false'
      },
      headers: {
        accept: async ? 'application/json' : 'application/pdf'
      },
      responseType: 'arraybuffer',
      timeout: 30 * 1000,
      data: payload,
      accessToken
    })
      .then(response => {
        if (async) {
          return response?.headers.location;
        }
        if (response?.status === 502) {
          throw response;
        }
        return response?.data;
      });
  }
  return null;
};

export const fetchJobsheetPdfOrStatus = async (accessToken: string, jobsheetPdfUrl: string) => {
  // eslint-disable-next-line no-return-await
  return await apiRequest<ArrayBuffer>({
    url: jobsheetPdfUrl,
    method: 'get',
    headers: {
      accept: 'application/pdf'
    },
    responseType: 'arraybuffer',
    timeout: 30 * 1000,
    accessToken
  })
    .then(response => {
      if (response?.status === 202) {
        return Promise.resolve(null);
      }
      if (response?.status === 200) {
        return response.data;
      }
      throw new Error(`Unexpected response ${response?.status} from ${jobsheetPdfUrl}`);
    });
};
