import { Store } from 'redux';
import { AppState } from '../store/store';
import { v4 } from 'uuid';

const currentValue: { [key: string]: any } = {};

export function onStoreUpdate(store: Store<AppState>, selector: (state: AppState) => any, action: (val: any) => any) {
  const key = v4();
  const handleChange = () => {
    const previousValue = currentValue[key] || null;
    currentValue[key] = selector(store.getState());

    if (previousValue !== currentValue[key]) {
      action(currentValue[key]);
    }
  };
  return store.subscribe(handleChange);
}
