export function parseBlobError(blob: Blob): any {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Problem parsing error response'));
    };

    fileReader.onload = () => {
      let details = fileReader.result as string;
      try {
        details = JSON.parse(details);
      } catch (err) {
        resolve(details);
      }
      resolve(details);
    };

    fileReader.readAsText(blob);
  });
}
