import React, { ReactNode } from 'react';
import { renderKeyValue } from './renderKeyValue';
import { EmailField } from './emailField';
import { PhoneField } from './phoneField';
import { DeliveryRequestAddress, DeliveryRequestWithPickupPoint } from '../../clients/delivery/deliveryRequestModel';
import { DeliveryDetails } from '../../clients/foma/itemsClient';
import { IdField } from './idField';
import { camelToFlat } from '../../utils/helper';

export const renderAddressFields = (t, deliveryAddress) => {
  return <>
    {renderKeyValue(t('shipping.name'), (deliveryAddress.firstName ? `${deliveryAddress.firstName} ` : '').concat(deliveryAddress.lastName || ''))}
    {renderKeyValue(t('shipping.name'), deliveryAddress.name)}
    {renderKeyValue(t('shipping.company'), deliveryAddress.company)}
    {renderKeyValue(t('shipping.street'), deliveryAddress.street1)}
    {renderKeyValue(t('shipping.street_2'), deliveryAddress.street2)}
    {renderKeyValue(t('shipping.city'), (deliveryAddress.postalCode ? `${deliveryAddress.postalCode} ` : '').concat(deliveryAddress.city || ''))}
    {renderKeyValue(t('shipping.stateOrProvince'), deliveryAddress.stateOrProvince)}
    {renderKeyValue(t('shipping.country'), deliveryAddress.country)}
    {renderKeyValue(t('shipping.country'), deliveryAddress.countryCode)}
    {deliveryAddress.addressType && renderKeyValue(t('shipping.addressType'), <IdField id={camelToFlat(deliveryAddress.addressType)} enableCopy={false}/>)}
    {renderKeyValue(t('shipping.phone'), <PhoneField phone={deliveryAddress.phone}/>, !!deliveryAddress.phone)}
    {renderKeyValue(t('shipping.email'), <EmailField email={deliveryAddress.email}/>, !!deliveryAddress.email)}
  </>;
};

export const renderDeliveryAddress = (t, deliveryAddress) => {
  if (!deliveryAddress) {
    return null;
  }
  return <div className='deliveryAddressBox'>
    {renderAddressFields(t, deliveryAddress)}
  </div >;
};

export const renderDeliveryRequest = (t, deliveryRequestWithPickupPoint: DeliveryRequestWithPickupPoint) => {
  if (!deliveryRequestWithPickupPoint) {
    return null;
  }

  let deliveryAddress: Partial<DeliveryRequestAddress | { addressType?: string; }> = {};
  if (deliveryRequestWithPickupPoint.destinationAddress) {
    deliveryAddress = deliveryRequestWithPickupPoint.destinationAddress;
  }
  
  if (deliveryRequestWithPickupPoint.pickupPoint) {
    deliveryAddress = {
      city: deliveryRequestWithPickupPoint?.pickupPoint.address.city,
      firstName: deliveryRequestWithPickupPoint?.pickupPoint.name,
      street1: deliveryRequestWithPickupPoint?.pickupPoint.address.addressLine1,
      country: deliveryRequestWithPickupPoint?.pickupPoint.address.countryCode,
      addressType: deliveryRequestWithPickupPoint?.pickupPoint.address.addressType
    };
  }
  if (!deliveryAddress) {
    return null;
  }

  return <>
    {renderAddressFields(t, deliveryAddress)}
  </>;
};

export const renderDeliveryDetail = (t, deliveryDetail: DeliveryDetails, deliveryRequestMap: Record<string, DeliveryRequestWithPickupPoint>, selected?: boolean, addon?: ReactNode, onClick?: any) => {
  if (!deliveryDetail) {
    return null;
  }

  const deliveryRequest = deliveryRequestMap[deliveryDetail.links?.self?.href || ''];
  return <div className={`deliveryAddressBox ${selected ? 'selected' : ''}`} onClick={onClick}>
    {addon}
    <div className='delivery-detail-section-title'>
      {renderKeyValue(t('deliveryDetails.quantity'), <>{deliveryDetail.quantity}</>)}
      {renderKeyValue(t('deliveryDetails.type'), deliveryDetail.type)}
    </div>
    {renderDeliveryRequest(t, deliveryRequest)}
  </div>;
};

export const renderDeliveryRequests = (t, orderedQuantity: number, deliveryDetails: DeliveryDetails[], deliveryRequestMap: Record<string, DeliveryRequestWithPickupPoint>) => {
  if (!deliveryDetails) {
    return null;
  }

  const result: ReactNode[] = [];
  for (const deliveryDetail of deliveryDetails) {
    result.push(renderDeliveryDetail(t, deliveryDetail, deliveryRequestMap));
  }

  return <div className='flexContainer'>
    {result.map((a, i) => <div key={i} className='flexChild'>{a}</div>)}
    <div className='flexChildGrow'>&nbsp;</div>
  </div>;
};
