import React from 'react';
import { shapes, SpinnerProps } from '@cimpress/react-components';

const { Spinner } = shapes;

export const Loading: React.FC<LoadingProps> = props => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Spinner size={props.size} />
      <span>{props.message}</span>
    </div>
  );
};

interface LoadingProps {
    message: string;
    size?: SpinnerProps['size'];
}

