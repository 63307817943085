import React from 'react';
import { Header } from './header';
import { CssLoader } from '@cimpress/react-components';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import { ReportIssueSticker } from './components/shared/reportIssueSticker';

export const App: React.FC = () => {
  return (
    <CssLoader>
      <BrowserRouter>
        <Header/>
        <div className='container-responsive'>
          <Routes/>
        </div>
      </BrowserRouter>
      <br/>
      <br/>
      <br/>
      <ReportIssueSticker />
    </CssLoader>
  );
};
