import { FulfillersActionTypes, FulfillersState } from './types';
import { FulfillersActions } from '../storeActions';

const initialState: FulfillersState = {
  selectedFulfillers: [],
  fulfillers: {
    isLoading: true
  }
};

export function fulfillersReducer(state = initialState, action: FulfillersActionTypes): FulfillersState {
  switch (action.type) {
    case FulfillersActions.SET_SELECTED_FULFILLER_ID:
      return {
        ...state,
        selectedFulfillers: action.selectedFulfillerIds
      };
    case FulfillersActions.SET_SELECTED_FULFILLERS:
      return {
        ...state,
        selectedFulfillers: action.selectedFulfillers
      };
    case FulfillersActions.GET_FULFILLERS_REQUEST:
      return {
        ...state,
        fulfillers: {
          isLoading: true
        }
      };
    case FulfillersActions.GET_FULFILLERS_SUCCESS:
      return {
        ...state,
        fulfillers: {
          data: action.data,
          isLoading: false
        }
      };
    case FulfillersActions.GET_FULFILLERS_ERROR:
      return {
        ...state,
        fulfillers: {
          errors: [action.error],
          isLoading: false
        }
      };
    case FulfillersActions.GET_SELECTED_FULFILLER_IDS_REQUEST:
      // TODO: Do we need this ?
      return {
        ...state
      };
    case FulfillersActions.GET_SELECTED_FULFILLER_IDS_SUCCESS:
      return {
        ...state,
        selectedFulfillers: action.data
      };
    case FulfillersActions.GET_SELECTED_FULFILLER_IDS_ERROR:
      // TODO: Do we need this ?
      return {
        ...state
      };
    default:
      return state;
  }
}
