import { OrderItemStatus } from '../../clients/foma/itemsClient';

export const getRemainingQuantity = (orderedQuantity: number, status: OrderItemStatus): number => {
  const rejectedQuantity = status?.statusDetails.rejected?.quantity || 0;
  const cancelledQuantity = status?.statusDetails.cancelled?.quantity || 0;
  const shippedQuantity = status?.statusDetails.shipped?.quantity || 0;
  const remainingQuantity = orderedQuantity - rejectedQuantity - cancelledQuantity - shippedQuantity;

  return remainingQuantity;
};
