import { ViewsActionTypes, ViewsState } from './types';
import { ViewsActions } from '../storeActions';

const initialState: ViewsState = {
  views: {
    isLoading: true
  }
};

export function viewsReducer(state = initialState, action: ViewsActionTypes): ViewsState {
  switch (action.type) {
    case ViewsActions.GET_VIEWS_REQUEST:
      return {
        ...state,
        views: {
          isLoading: true
        }
      };
    case ViewsActions.GET_VIEWS_SUCCESS:
      return {
        ...state,
        views: {
          data: action.data,
          isLoading: false
        }
      };
    case ViewsActions.GET_VIEWS_ERROR:
      return {
        ...state,
        views: {
          errors: [action.error],
          isLoading: false
        }
      };
    default:
      return state;
  }
}
