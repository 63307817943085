import React from 'react';
import { acceptOrderRequest } from '../../../store/items/actions';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { TrackedButton } from '../../../trackingLayer/trackedButton';

export const AcceptOrderButton: React.FC<AcceptOrderButtonProps> = props => {
  return <TrackedButton item={props.items.length > 1 ? 'acceptBulk' : 'accept'} type={'primary'}
    disabled={props.disabled}
    onClick={() => {
      props.dispatch(acceptOrderRequest(props.items, props.refreshItemList));
    }}>
    {props.t(props.items.length > 1 ? 'buttons.acceptItems' : 'buttons.acceptItem')}
  </TrackedButton>;
};

interface AcceptOrderButtonProps {
  t: Function;
  dispatch: Function;
  disabled?: boolean;
  items: OrderItemWithStatusAndOrderInfo[];
  refreshItemList: boolean;
}
