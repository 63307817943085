import React from 'react';
import { CopyToClipboardIcon } from './buttons/copyToClipboardIcon';
import { TrackedLink } from '../../trackingLayer/trackedLink';

interface EmailFieldProps {
  phone: string;
}
export const PhoneField: React.FC<EmailFieldProps> = ({ phone }) => {
  if (!phone) {
    return null;
  }

  return <>
    <TrackedLink item={'phoneField.link'}
      href={`tel:${phone}`}>
      {phone}
    </TrackedLink>
    &nbsp;
    <CopyToClipboardIcon toCopy={phone}/>
  </>;
};
