import { Alert } from '@cimpress/react-components';
import React from 'react';

export const renderItemsShippedInfo = (t, items, formData) => {
  const printPair = (key, value) => <div className='row'>
    <div className='col-md-3'>
      <span>{`${key}: `}</span>
    </div>
    <div className='col-md-9'>
      <span><strong>{value}</strong></span>
    </div>
  </div>;

  const message = <div>
    {printPair(t('shipping.items'), items.map(itemData => itemData.itemId).join(', '))}
    {printPair(t('shipping.shippingReference'), formData.shipmentId)}
    {formData.fulfillmentLocation
      ? <div>
        {printPair(t('shipping.carrierService'), formData.carrierService?.name || formData.carrierService?.key)}
        {printPair(t('shipping.shippingLocation'), formData.fulfillmentLocation.name)}
      </div>
      : null
    }

  </div >;

  return <Alert title={t('shipping.successfullyShippedItem')} message={message} type={'success'} dismissible={false} />;
};
