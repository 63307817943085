import { ExtendedOrderItem } from '../clients/foma/itemsClient';
import sortby from 'lodash.sortby';

export const keysSupportingLocalSort = ['orderInfo.destinationAddress.country'];

const sortTableByDeliveryCountry = (items: ExtendedOrderItem[]): ExtendedOrderItem[] => {
  return sortby(items, [(item: ExtendedOrderItem) => {
    if (!item.orderInfo?.destinationAddress?.country) {return item.deliveryDetails[0].destinationAddress?.country;}
    return item.orderInfo?.destinationAddress?.country;
  }]);
};
  
export const sortItemsTableLocally = (items: ExtendedOrderItem[], sortField: string, isSortOrderDesc: boolean): ExtendedOrderItem[] => {
  let sortedItems: ExtendedOrderItem[];
  
  switch (sortField) {
    case 'orderInfo.destinationAddress.country':
      sortedItems = sortTableByDeliveryCountry(items);
      break;
    default:
      sortedItems = sortby(items, [sortField]);
      break;
  }

  if (isSortOrderDesc) {sortedItems = sortedItems.reverse();}
  return sortedItems;
};
