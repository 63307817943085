import { apiRequest } from '../apiClient';
import { Account } from './accountsModel';

export async function getAccount(accessToken, accountId): Promise<Account|undefined> {
  const response = await apiRequest<Account>({
    url: `https://accounts.cimpress.io/api/v1/accounts/${accountId}`,
    accessToken
  });

  if (!response) {
    return undefined;
  }

  if (response.error) {
    throw response;
  }

  return response.data;
}
