import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { trackEvent } from './utils';

export interface TrackedLocalLinkProps extends LinkProps {
  item: string;
}

export const TrackedLocalLink: React.FC<TrackedLocalLinkProps> = props => {
  const { item, onClick, to, ...linkProps } = props;

  return <Link
    onClick={e => {
      trackEvent(item);
      if (onClick) {
        onClick(e);
      }
    }}
    to={to}
    {...linkProps}
  >{props.children}</Link>;
};
