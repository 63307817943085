import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { ErrorComponent } from '../../errorComponent';
import { useTranslation } from 'react-i18next';
import { BreadcrumbItem, Breadcrumbs, Card } from '@cimpress/react-components';
import { useHistory, useParams } from 'react-router-dom';
import { CancellationHeader } from './cancellationHeader';
import { CancellationItem } from './cancellationItem';
import { ActionTypeKeysEnum } from '@cimpress-technology/supplier-integrations-client';

import { acceptAction, rejectAction } from '../../../store/notifications/actions';
import { getFomaOrderItem } from '../../../store/items/actions';
import { FomaItemState } from '../../../store/items/types';
import { TrackedLocalLink } from '../../../trackingLayer/trackedLocalLink';

export const CancellationPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // @ts-ignore
  const { notificationId } = useParams();
  const { t } = useTranslation();

  const errors = useSelector((state: AppState) => state.items.errors);
  const notifications = useSelector((state: AppState) => Object.values((state.notifications || {}).notifications || {}));
  const itemInfoById = useSelector((state: AppState): Record<string, FomaItemState> => state.items.byId || {});
  const isActionRunning = useSelector((state: AppState) => state.notifications.isLoading || false);

  const notification = notifications.find(n => n.notificationId === notificationId);
  if (!notification) {
    history.push('/pendingActions');
  }

  useEffect(() => {
    if (notification) {
      notification.items.forEach(item => {
        const itemInfo = itemInfoById[item.itemId];
        if (!itemInfo) {
          dispatch(getFomaOrderItem(item.itemId));
        }
      });
    }
  }, [itemInfoById, notification, dispatch]);

  return (
    <div className={'container-fluid'}>

      {(errors && errors.length > 0) && <ErrorComponent errors={errors} />}

      <Breadcrumbs>
        <BreadcrumbItem>
          <TrackedLocalLink item={'breadcrumbs.home'} to={'/'}>{t('breadcrumbs.home')}</TrackedLocalLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <TrackedLocalLink item={'breadcrumbs.home.pendingActions'} to={'/pendingActions'}>
            {t('menu.pendingActions')}
          </TrackedLocalLink>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t('cancellationPage.breadcrumbCancellation')} {t('cancellationPage.orderId')} {notification?.order.orderId}
        </BreadcrumbItem>
      </Breadcrumbs>

      {notification
        ? <>
          <Card>
            <h2>{t('cancellationPage.headers.cancellation')} - {t('cancellationPage.orderId')} {notification?.order?.orderId}</h2>
            <hr />
            <CancellationHeader notification={notification} orderInfo={itemInfoById[notification?.items[0].itemId]?.data?.orderInfo} />
            <br />
            <h2>{t('cancellationPage.headers.items')}</h2>
            {notification.items.map((item, i) => <CancellationItem
              key={i}
              disabled={isActionRunning}
              item={(itemInfoById[item.itemId] || {}).data}
              isLoading={(itemInfoById[item.itemId] || {}).isLoading}
              notificationItem={item}
              onAccept={costsIncurred => {
                dispatch(acceptAction({
                  notificationUrl: notification.links.self.href,
                  itemId: item.itemId,
                  actionTypeKey: ActionTypeKeysEnum.AcceptCancellationRequest,
                  costsIncurred
                }));
              }}
              onReject={() => {
                dispatch(rejectAction({
                  notificationUrl: notification.links.self.href,
                  itemId: item.itemId,
                  actionTypeKey: ActionTypeKeysEnum.RejectCancellationRequest
                }));
              }}
            />)}
          </Card>
        </>
        : ''}

    </div>
  );
};
