import { PlatformClient } from '../../../clients/platformClient/platformClient';
import { useAsync } from 'react-async-hook';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

export const usePlatformOrderItem = (accessToken: string, platformItemLink: string, shortItemId: string|null) => {
  const response = useAsync(async () => {
    if (accessToken) {
      try {
        if (platformItemLink) {
          const res = await new PlatformClient().getPlatformItem(accessToken, platformItemLink);
          return {
            data: res,
            error: undefined
          };
        }

        const res = await new PlatformClient().getItemByShortItemId(accessToken, shortItemId);
        return {
          data: res,
          error: undefined
        };
      } catch (error) {
        SentryWrapper.reportError(error);
        return {
          data: null,
          error: error
        };
      }
    }
    return {
      data: undefined,
      error: undefined
    };
  }, [accessToken, platformItemLink, shortItemId]);

  return {
    loadingPlatformOrderItem: response.loading,
    platformOrderItem: response.result?.data,
    errorLoadingPlatformOrderItem: response.error ?? response.result?.error
  };
};
