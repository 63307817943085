import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { ItemsNotificationsByTypes, NotificationTypes } from '../../clients/foma/notificationActionClient';
import { PlatformItems } from '../../store/items/types';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../utils/dateTimeUtils';
import { CommentIndicator } from '@cimpress-technology/react-comments';
import { Tag, Tooltip } from '@cimpress/react-components';
import './items.scss';
import { Fulfiller } from '../../store/fulfillers/types';

import { ExtendedOrderItem } from '../../clients/foma/itemsClient';
import { useSelectedView } from '../shared/hooks/useSelectedView';
import { ProductCategory } from '../../models/products';
import { renderStatusLabels, DelayedLabel, LateOrForecastedLateLabel } from '../shared/labels';
import { ClaimsLabelWithModal } from '../shared/claimsLabelWithModal';

export const DateCellFormatter: React.FC<CellProps> = props => {
  const formatter = useDateTimeFormatter();
  const date = props.value;
  if (!date) {
    return <></>;
  }

  return <>{formatter(new Date(date))}</>;
};

export const DeliveryCountryCellFormatter: React.FC<CellProps> = props => {
  let deliveryCountry = props.row?._original?.orderInfo?.destinationAddress?.country;
  if (!deliveryCountry) {
    const countries = props.row?._original.deliveryDetails.map(dd => dd?.destinationAddress?.country);
    deliveryCountry = [...new Set(countries)].join(',');
  }

  return (
    <div>
      <span>{deliveryCountry}</span>
    </div>);
};

export const getFulfillerCellFormatter = (fulfillersMap: Record<string, Fulfiller>) => {
  const FulfillerCellFormatter: React.FC<CellProps> = ({ value }) => {
    return <>{fulfillersMap[value]?.name || value}</>;
  };
  return FulfillerCellFormatter;
};

export const getOrderIdCellFormatter = (useMerchantIds: boolean) => {
  const OrderIdCellFormatter: React.FC<CellProps> = ({ value, row }) => {
    return <>{useMerchantIds ? (row._original?.orderInfo?.merchantInformation?.orderId || value) : value}</>;
  };
  return OrderIdCellFormatter;
};

export const getItemIdCellFormatter = (useMerchantIds: boolean) => {
  const ItemIdCellFormatter: React.FC<CellProps> = ({ value, row }) => {
    return <>{useMerchantIds ? (row._original?.merchantInformation?.itemId || value) : value}</>;
  };
  return ItemIdCellFormatter;
};

export const getProductCategoriesCellFormatter = (showLeafProductCategoriesOnly: boolean | undefined) => {
  const ProductCategoriesCellFormatter: React.FC<CellProps> = props => {
    const tableItems = useSelector<AppState, ExtendedOrderItem[]>(state => state.items.table.data?.items ?? []);
    const categories = useSelector<AppState, ProductCategory[]>(state => state.productCategories?.categories?.data || []);
    const categoriesLeafMap = {};
    categories.forEach(c => categoriesLeafMap[c.name] = c.isLeaf);
    const currentItem = tableItems[props.row._index];
    if (currentItem?.product?.categories) {
      return <>
        {currentItem.product.categories
          .filter(c => !showLeafProductCategoriesOnly || categoriesLeafMap[c])
          .map((s, i) => <span key={i} className='spaced-tag'><Tag label={s} /></span>)}
      </>;
    }
    return <span>-</span>;
  };
  return ProductCategoriesCellFormatter;
};

export const DeliveryOptionsCellFormatter: React.FC<CellProps> = props => {
  const plans = props.row?._original?.shipmentPlanningAnalysis?.shipmentPlans || [];
  const deliveryOptions = plans.map(p => p.deliveryOption?.name)
    .filter(d => d);

  return (
    <div>
      {deliveryOptions.length
        ? <span>{deliveryOptions.join(', ')}</span>
        : null}
    </div>);
};
export const ProductCellFormat: React.FC<CellProps> = props => {
  const accessToken = useSelector<AppState, string>(state => state.auth.accessToken);
  const tableItems = useSelector<AppState, ExtendedOrderItem[]>(state => state.items.table.data?.items ?? []);
  const platformItems = useSelector<AppState, PlatformItems>(state => state.items.table.platformItemsByItemsId);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const currentItem = tableItems[props.row._index];
  if (!currentItem) {
    return <div>
      No current item {props.row._index} ?
    </div>;
  }
  const currentPlatformItem = platformItems[currentItem.itemId];

  const claims = () => {
    if (currentPlatformItem?.links?.reorderClaim?.name) {
      // the name is actually the claimId ...
      return <ClaimsLabelWithModal claimId={currentPlatformItem?.links?.reorderClaim?.name} labelStyle={'warning'} labelTitle={t('items.labels.reprint')}/>;
    }
    if (currentPlatformItem?.links?.claims?.name) {
      return <ClaimsLabelWithModal itemId={currentPlatformItem.platformItemId} labelStyle={'danger'} labelTitle={t('items.labels.claim')}/>;
    }
    return null;
  };

  return (
    <div>
      {currentItem.product.name}
      {currentItem.isTestItem && <span className={'label label-default'}>{t('items.labels.testItem')}</span>}
      {claims()}
      {<CommentIndicator
        locale={i18n.language}
        accessToken={accessToken}
        resourceUri={currentItem.links.self.href}
        refreshInterval={300} />}
    </div>);
};

export const PrintJobCellFormat: React.FC<CellProps> = props => {
  const tableItems = useSelector<AppState, ExtendedOrderItem[]>(state => state.items.table.data?.items ?? []);
  const platformItems = useSelector<AppState, PlatformItems>(state => state.items.table.platformItemsByItemsId);
  const { t } = useTranslation();
  const currentItem = tableItems[props.row._index];

  if (!currentItem) {
    return <div></div>;
  }

  const pJ = platformItems[currentItem.itemId]?.printJob;
  return (
    <div>
      {pJ
        ? pJ.error
          ? <Tooltip contents={pJ.error.details}><span className={'label label-danger'}>{pJ.error.message}</span></Tooltip>
          : <span>{`${pJ.shortId}, ${t(`printJob.status.${pJ.status}`) || ''}`}</span>
        : null}
    </div>);
};

export const StatusCellFormat: React.FC<CellProps> = props => {
  const groupedNotifications = useSelector<AppState, ItemsNotificationsByTypes>(state => state.notifications.groupedNotifications ?? {});
  const tableItems = useSelector<AppState, ExtendedOrderItem[]>(state => state.items.table.data?.items ?? []);
  const platformItems = useSelector<AppState, PlatformItems>(state => state.items.table.platformItemsByItemsId);
  const { t } = useTranslation();
  const { selectedView } = useSelectedView();

  const currentItem = tableItems[props.row._index];
  if (!currentItem) {
    return <div></div>;
  }

  const currentPlatformItem = platformItems[currentItem.itemId];
  const itemsNotificationTypes = groupedNotifications[currentItem.itemId] ?? {};

  return (
    <div>
      {renderStatusLabels(t, currentItem)}
      <LateOrForecastedLateLabel
        currentItem={currentItem}
        plan={currentItem?.plan}
        selectedView={selectedView}/>
      <DelayedLabel
        currentPlatformItem={currentPlatformItem}/>
      {itemsNotificationTypes[NotificationTypes.CancellationRequest] && <span className={'label label-danger spacedLabel'}>{t('items.labels.cancellationRequest')}</span>}
      {itemsNotificationTypes[NotificationTypes.ChangeRequest] && <span className={'label label-warning spacedLabel'}>{t('items.labels.changeRequest')}</span>}
      {itemsNotificationTypes[NotificationTypes.ArtworkChangeRequest] && <span className={'label label-warning spacedLabel'}>{t('items.labels.artworkChangeRequest')}</span>}
      {itemsNotificationTypes[NotificationTypes.ShipmentRequirementChangeRequest] && <span className={'label label-warning spacedLabel'}>{t('items.labels.shipmentRequirementChangeRequest')}</span>}
    </div>);
};

export const QuantityCellFormat: React.FC<CellProps> = props => {
  const tableItems = useSelector<AppState, ExtendedOrderItem[]>(state => state.items.table.data?.items ?? []);

  const currentItem = tableItems[props.row._index];

  const shipped = 0
    + (currentItem?.status?.statusDetails?.shipped?.quantity || 0)
    + (currentItem?.status?.statusDetails?.rejected?.quantity || 0)
    + (currentItem?.status?.statusDetails?.cancelled?.quantity || 0);

  return (
    <>
      {shipped} / {currentItem?.orderedQuantity}
    </>);
};

interface CellProps {
  value: string;
  row: {
    _index: number;
    _original: any;
  };
}
