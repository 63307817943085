import { useSelector } from 'react-redux';
import { AppState } from '../store/store';
import moment from 'moment';

export const useDateTimeFormatter: ((showTime?: boolean) => (date: Date) => string) = (showTime = false) => {
  const locale = useSelector<AppState, string>(state => state.settings.locale);
  const format = ((locale.toLowerCase() === 'iso8601' || locale.toLocaleLowerCase() === 'x-iso8601'))
    ? (showTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD')
    : (showTime
      ? `${moment().locale(locale).localeData().longDateFormat('L')} ${moment().locale(locale).localeData().longDateFormat('LT')}`
      : moment().locale(locale).localeData().longDateFormat('L'));
  return (date: Date) => moment.utc(date).format(format);
};
