import React from 'react';
import { CopyToClipboardIcon } from './buttons/copyToClipboardIcon';
import { TrackedLink } from '../../trackingLayer/trackedLink';

interface EmailFieldProps {
  email: string;
}
export const EmailField: React.FC<EmailFieldProps> = ({ email }) => {
  if (!email) {
    return null;
  }

  return <><TrackedLink item={'emailField.link'}
    href={`mailto:${email}`}>
    {email}
  </TrackedLink>
    &nbsp;
  <CopyToClipboardIcon toCopy={email}/>
  </>;
};
