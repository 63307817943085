import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@cimpress/react-components';
import { ContactInfo } from '../contact/contactInfo';

export const ReportIssueSticker: React.FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  return (<div >
    <div className='report-issue-sticker'
      onClick={() => {
        setShowModal(true);
      }}>
      {t('reportIssue.title')}
    </div>
    <Modal
      show={showModal}
      onRequestHide={closeModal}
      title= {t('reportIssue.title')}
      closeButton={true}
      footer={(
        <button className="btn btn-default" onClick={closeModal}>
          {t('reportIssue.close')}
        </button>
      )}
    >
      <ContactInfo />
    </Modal>

  </div>);
};

