import { apiRequest, ApiResponse } from '../apiClient';
import { ItemAggregations } from '../foma/itemAgreggationResponseModel';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

const productCategoryTreeUrl = 'https://catalogschema.products.cimpress.io/v2/tree';

export interface ProductCategoryEx {
  name: string;
  categoryId: string;
  parentId?: string;
  children: ProductCategoryEx[];
}

export const getProductCategoryTreeLeaves = (node: ProductCategoryEx): Map<string, ProductCategoryEx> => {
  const leafCategoryMap = new Map<string, ProductCategoryEx>();
  if (node.children.length === 0) {
    leafCategoryMap.set(node.name, node);
    return leafCategoryMap;
  }
  node.children.forEach(child => {
    getProductCategoryTreeLeaves(child).forEach(
      (v, k) => leafCategoryMap.set(k, v)
    );
  });
  return leafCategoryMap;
};

export const getProductCategoryNames = (node: ProductCategoryEx|undefined): Record<string, boolean> => {
  if (!node) {
    return {};
  }
  const leafCategoryMap: Record<string, boolean> = {};
  if (node.children.length === 0) {
    leafCategoryMap[node.name] = true;
    return leafCategoryMap;
  }
  node.children.forEach(child => {
    Object.keys(getProductCategoryNames(child))
      .forEach(k => leafCategoryMap[k] = true);
  });
  return leafCategoryMap;
};

// eslint-disable-next-line no-unused-vars
export const selectLeafProductCategoryAggregations = (itemAggregations: ItemAggregations[], productCategories: Record<string, boolean>): ItemAggregations[] => {
  return itemAggregations.filter(aggregation => productCategories[aggregation.key]);
};

export const getProductCategoryTreeResponse = async (accessToken: string): Promise<ApiResponse<ProductCategoryEx>> => {
  let productCategoryTreeResponse: ApiResponse<ProductCategoryEx> = {};

  try {
    productCategoryTreeResponse = (await apiRequest<ProductCategoryEx>({
      accessToken,
      url: productCategoryTreeUrl
    })) ?? {};
  } catch (e) {
    SentryWrapper.reportError(e);
  }

  if (productCategoryTreeResponse.error || !productCategoryTreeResponse.data) {
    // TODO: Show a warning snackbar
    // dispatch(getProductTreeCategoryError({
    //   message: i18n.t('errorMessages.failedToRetrieveProductCategories'),
    //   severity: 'warning'
    // }));
  }

  return productCategoryTreeResponse;
};
