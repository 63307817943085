import React from 'react';
import ReactClaims from '@cimpress-technology/react-claims';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from '../../../store/store';
import { complaintsUiUrl, isProd } from '../../../utils/environmentProvider';
import { Card, shapes } from '@cimpress/react-components';
import { TrackedLink } from '../../../trackingLayer/trackedLink';

export const ClaimsWidgetWrapper: React.FC = () => {
  const accessToken = useSelector<AppState, string>(state => state.auth.accessToken);
  const selectedFulfillers = useSelector<AppState, string[]>(state => state.fulfillers.selectedFulfillers);
  const selectedFulfillerId = selectedFulfillers && selectedFulfillers.length > 0 ? selectedFulfillers[0] : undefined;
  const locale = useSelector<AppState, string>(state => state.settings.locale);
  const language = useSelector<AppState, string>(state => state.settings.language);

  const { t } = useTranslation();

  if (!selectedFulfillerId) {
    const { Spinner } = shapes;

    return (
      <Card header={t('dashboard.claimsWidget.panelName')} isMinimal={true}>
        <Spinner size={'medium'}/>
      </Card>
    );
  }

  const title = <TrackedLink item={'dashboard.claimsWidget.link'} href={`${complaintsUiUrl}/fulfillerClaims`}>
    {t('dashboard.claimsWidget.manageClaims')}
  </TrackedLink>;

  return (
    <Card header={t('dashboard.claimsWidget.panelName')} isMinimal={true}>
      <ReactClaims
        title={title}
        environment={isProd ? 'prd' : 'int' }
        language={language}
        locale={locale.toLocaleLowerCase() === 'x-iso8601' ? 'en' : locale}
        displayLimit={1}
        tenant={{
          tenantType: 'fulfiller',
          tenantValue: selectedFulfillerId
        }}
        accessToken={accessToken}
        queryParameters={{
          globalFulfillerId: [selectedFulfillerId],
          status: ['open']
        }}
        displayInCard={false}
      />
    </Card>
  );
};
