import React from 'react';
import { renderKeyValue } from './renderKeyValue';
import { IdField } from './idField';
import { EmailField } from './emailField';
import { PhoneField } from './phoneField';
import { FulfillerContacts } from '../../clients/fulfillerIdentity/fulfillerContactsModel';

export const renderFulfillerInfo = (t, fulfiller, fulfillerContacts?: FulfillerContacts) => {
  const defaultContact = fulfillerContacts?.find(c => c.defaultContact === true);
  return fulfiller
    ? <div className='fulfiller-information-section'>
      {renderKeyValue(t('details.name'), <>{fulfiller.name} (<IdField id={fulfiller.fulfillerId}/>)</>)}
      {renderKeyValue(t('details.email'), <EmailField email={defaultContact?.email || fulfiller.email}/>)}
      {renderKeyValue(t('details.phoneNumber'), <PhoneField phone={fulfiller.phone}/>, !!fulfiller.phone)}
    </div >
    : null;
};
