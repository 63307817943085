import { DeliveryOptionsActions } from '../storeActions';
import { AppState } from '../store';
import { apiRequest, batchRequests } from '../../clients/apiClient';
import { ItemAggregationResponse } from '../../clients/foma/itemAgreggationResponseModel';
import { createDeliveryOptionAggregationQueryParams } from '../../clients/foma/itemAggregation';
import { i18n } from '../../locales/i18n';
import { fomaUrl } from '../../utils/environmentProvider';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { DeliveryOption } from '../../models/deliveryOptions';
import { getDeliveryOption } from '../../clients/deliveryOptions/deliveryOptionsClient';

const getDeliveryOptionsSuccess = values => ({
  type: DeliveryOptionsActions.GET_DELIVERY_OPTIONS_SUCCESS,
  data: values
});

const getDeliveryOptionsError = error => ({
  type: DeliveryOptionsActions.GET_DELIVERY_OPTIONS_ERROR,
  data: null,
  error: error
});

export const getDeliveryOptionsRequest = selectedFulfillerIds => async (dispatch, getState) => {
  dispatch({ type: DeliveryOptionsActions.GET_DELIVERY_OPTIONS_REQUEST });

  if (!selectedFulfillerIds || selectedFulfillerIds.length === 0) {
    return dispatch(getDeliveryOptionsSuccess([]));
  }
  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;
  const itemAggregationsUrl = `${fomaUrl}/v0/item-aggregations`;

  try {
    const itemAggregationResponse = await apiRequest<ItemAggregationResponse>({
      accessToken,
      url: itemAggregationsUrl,
      query: createDeliveryOptionAggregationQueryParams(selectedFulfillerIds)
    });

    const errorResponse = itemAggregationResponse?.error;
    const itemAggregations = itemAggregationResponse?.data;
    if (errorResponse || !itemAggregations) {
      if (itemAggregationResponse?.status === 403) {
        return dispatch(getDeliveryOptionsError({
          message: i18n.t('errorMessages.insufficientFulfillerPermissions'),
          severity: 'warning'
        }));
      }

      return dispatch(getDeliveryOptionsError({
        message: i18n.t('errorMessages.failedToRetrieveData'),
        details: errorResponse
      }));
    }
    const itemAggregationResults = itemAggregations?.results || [];

    // Fetch names
    const names = await batchRequests<DeliveryOption | undefined>(itemAggregationResults.map(x => getDeliveryOption(accessToken, x.key)), { noThrowErrors: true });

    const mapResponse = names.reduce((dOs, d) => {
      if (d) {
        dOs.push(d);
      }
      return dOs;
    }, [] as DeliveryOption[]);

    return dispatch(getDeliveryOptionsSuccess(mapResponse));
  } catch (e) {
    SentryWrapper.reportError(e);
    return dispatch(getDeliveryOptionsError(e));
  }
};
