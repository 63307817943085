import React, { useState } from 'react';
import { Img } from 'react-image';
import Modal from '@cimpress/react-components/lib/Modal';
// import { Alert, Button } from '@cimpress/react-components';
// import Tooltip from '@cimpress/react-components/lib/Tooltip';
import Carousel from '@cimpress/react-components/lib/Carousel';
import CarouselLoading from '@cimpress/react-components/lib/Carousel/CarouselLoading';
import { useTranslation } from 'react-i18next';
import { usePrepressOutput } from './hooks/usePrepressOutput';
import PreviewCarousel from '../../hacks/mex-preview/index';
import { useFomaPreview } from './hooks/useFomaPreview';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';

const MODAL_WIDTH = 600;
export const DEFAULT_SIZE = 'xs';
export const DEFAULT_EMBIGGENED_SIZE = 2000;
export const SIZES = {
  [DEFAULT_SIZE]: 250,
  s: 320,
  m: 390,
  l: 460,
  xl: 530
};
export const DEFAULT_PX_SIZE = SIZES[DEFAULT_SIZE];

export interface ItemFulfillmentPreviewProps {
  accessToken: string;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | undefined;
  pxSize?: number;
  fomaPreviewUrl: string;
  manufacturingReadyDataUrl?: string;
  minimal: boolean;
  hideIndicators: boolean;
  documentReferenceUrl?: string;
  skuCode?: string;
  variableAttributes: any;
  setSpotColors?: Function;
  standardOutputUrl?: string;
}

const chooseCarouselSize = () => {
  const height = window.innerHeight;
  return height <= 550 ? 'xs' : height <= 600 ? 's' : height <= 800 ? 'm' : height <= 900 ? 'l' : 'xl';
};

export const ItemFulfillmentPreview: React.FC<ItemFulfillmentPreviewProps> = ({
  accessToken,
  fomaPreviewUrl,
  manufacturingReadyDataUrl,
  minimal,
  size,
  pxSize, hideIndicators,
  documentReferenceUrl,
  skuCode,
  variableAttributes,
  setSpotColors,
  standardOutputUrl
}) => {
  const { preferredPreviewSource } = useSelector((state: AppState) => state.settings);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [showModal, setShowModal] = useState(false);
  // const [documentReferenceBasedPreviews, setDocumentReferenceBasedPreviews] = useState(false);
  const { t } = useTranslation();

  const { loadingPrepressResult, colors, prepressPreviews } = usePrepressOutput(accessToken, manufacturingReadyDataUrl, standardOutputUrl);
  const { loadingFomaPreview, fomaPreviewData } = useFomaPreview(accessToken, fomaPreviewUrl);

  const handleImageError = () => {
    setCurrentPosition(0);
  };

  if (setSpotColors && !loadingPrepressResult && colors) {
    setSpotColors(colors);
  }

  const sizeInPixels = size && !minimal ? SIZES[size] : (pxSize || DEFAULT_PX_SIZE);
  const embiggenButton = true;

  const renderLoadingCarousel = () => {
    return <Carousel
      loading={true}
      size={size}
      pxSize={pxSize}
      minimal={minimal}
      hideIndicators={hideIndicators}
    />;
  };

  const renderPrepressPreviews = (overrides?: ({ url: string; info: string }[])) => {
    const previews = (overrides ?? prepressPreviews);
    const slides = previews?.map((preview, idx) => {
      return <Img
        key={preview.url + preview.info}
        src={preview.url}
        alt=""
        loader={idx === currentPosition ? <CarouselLoading size={sizeInPixels} /> : undefined}
        onError={handleImageError}
      />;
    });
    const modal = showModal ? (
      <Modal
        show={showModal}
        title={t('previews.modalTitle')}
        onRequestHide={() => {
          setShowModal(false);
        }}
        closeButton
        closeOnOutsideClick
        footer={
          <div>
            <button className="btn btn-default" onClick={() => setShowModal(false)}>
              {t('previews.modalButtonCloseTitle')}
            </button>
            {embiggenButton && !overrides ? (
              <a
                className="btn btn-primary"
                href={previews[currentPosition].url.replace(`width=${SIZES.xl}`, `width=${DEFAULT_EMBIGGENED_SIZE}`)}
                target="_blank"
                rel="noopener noreferrer">
                {t('previews.viewFullSize')}
              </a>
            ) : null}
          </div>
        }
        style={{ width: `${MODAL_WIDTH + 30}px` }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* Carousel needs to size dynamically based on height to minimize scrollbar on smaller resolutions */}
          <Carousel
            size={chooseCarouselSize()}
            onSlideChanged={pos => setCurrentPosition(pos)}
            currentSlide={currentPosition}>
            {slides}
          </Carousel>
        </div>
      </Modal>
    ) : null;

    return <div>
      <Carousel
        // @ts-ignore
        className={showModal ? 'clickable' : undefined}
        onCarouselClick={() => {
          setShowModal(true);
        }}
        loading={false}
        size={size}
        pxSize={pxSize}
        minimal={minimal}
        hideIndicators={hideIndicators}
        onSlideChanged={pos => {
          setCurrentPosition(pos);
        }}
        currentSlide={currentPosition}
        showZoomOverlay={true}>
        {slides}
      </Carousel>
      {modal}
    </div>;
  };

  const renderFomaPreviews = () => {
    return renderPrepressPreviews([{
      url: fomaPreviewData || '',
      info: 'default'
    }]);
  };

  const renderDocumentReferencePreviews = () => {
    return <PreviewCarousel
      auth={{ getAccessToken: () => accessToken }}
      size={size}
      pxSize={pxSize}
      minimal={minimal}
      includePreviewInfo
      includeMerchandising={false}
      hideIndicators={false}
      docRefUrl={documentReferenceUrl}
      mcpSku={skuCode}
      variableAttributes={variableAttributes || []}
    />;
  };

  // If both are loading -> wait
  const loading = (loadingPrepressResult || (loadingFomaPreview && fomaPreviewUrl));

  if (loading) {
    return renderLoadingCarousel();
  }

  if (preferredPreviewSource === 'foma') {
    return renderFomaPreviews();
  }

  if (preferredPreviewSource === 'prepressDefault') {
    return renderPrepressPreviews();
  }

  return renderDocumentReferencePreviews();
};
