import { ErrorResponse, SuccessResponse, apiRequest } from '../apiClient';
import { PrepressJob, PrepressOutput, PrepressStandardOutput } from './prepressModels';
import { apiClientWithCachedResults } from '../apiClientWithCachedResults';

export const isManufacturingReadyDataJSON = async (accessToken: string, manufacturingReadyDataUrl: string): Promise<boolean> => {
  const response = await apiClientWithCachedResults<PrepressOutput>(accessToken, `head-${manufacturingReadyDataUrl}`, {
    method: 'head',
    url: manufacturingReadyDataUrl,
    accessToken
  });

  return response && response.headers
    ? response.headers['content-type'] === 'application/json'
    : false;
};

export const getManufacturingReadyData = async (accessToken: string, manufacturingReadyDataUrl: string): Promise<SuccessResponse<PrepressOutput> | ErrorResponse<PrepressOutput> |undefined> => {
  const data = await apiClientWithCachedResults<PrepressOutput>(accessToken, manufacturingReadyDataUrl, {
    url: manufacturingReadyDataUrl,
    accessToken
  });
  return data;
};

export const getPrepressJobResult = async (accessToken: string, prepressJobUrl: string): Promise<SuccessResponse<PrepressJob> | ErrorResponse<PrepressJob> |undefined> => {
  const data = await apiClientWithCachedResults<PrepressJob>(accessToken, prepressJobUrl, {
    url: prepressJobUrl,
    accessToken
  });
  return data;
};

export const getStandardOutputData = async (accessToken: string, standardOutputUrl: string): Promise<SuccessResponse<PrepressStandardOutput> | ErrorResponse<PrepressStandardOutput> | null> => {
  const data = await apiRequest<PrepressStandardOutput>({
    url: standardOutputUrl,
    accessToken
  });
  return data;
};
