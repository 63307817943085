import React, { Fragment, useEffect, useState, useRef } from 'react';
import { ButtonGroup, TextField, Alert } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import './columnsModal.scss';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/store';
import { ViewsState } from '../../store/views/types';
import { CustomizrPomV2, SettingsState } from '../../store/settings/types';
import {
  IconViewColumn,
  IconArrowButtonLeft,
  IconArrowButtonRight,
  IconArrowButtonUp,
  IconArrowButtonDown
} from '@cimpress-technology/react-streamline-icons';
import { ViewColumn, View } from '../../models/views';
import { TFunction } from 'i18next';
import { CustomizrClient } from 'cimpress-customizr';
import { pomApplicationResourceId, getCustomizrSettings } from '../../store/settings/actions';
import uuid from 'uuid/v4';
import { getViewsRequest, POM_MAJOR_VIEW_VERSION } from '../../store/views/actions';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { TrackedButton } from '../../trackingLayer/trackedButton';
import { TrackedModal } from '../../trackingLayer/trackedModal';
import { PrettyHeaderButton } from './prettyHeaderButton';

interface Option {
  label: string;
  value: string;
}

interface CustomizeColumnsButtonProps {
  attributeKeys: string[];
}

export const CustomizeColumnsButton: React.FC<CustomizeColumnsButtonProps> = ({ attributeKeys }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const onCancel = () => setOpen(false);

  return <>
    <ColumnsModal
      open={open}
      attributeKeys={attributeKeys}
      onCancel={onCancel} />

    <PrettyHeaderButton
      onClick={() => setOpen(true)}
      icon={IconViewColumn}
      label={t('items.columnsTitle')}/>
  </>;
};

interface ColumnsModalProps {
  open: boolean;
  attributeKeys: string[];
  onCancel: Function;
}

const compareByLabel = (a: Option, b: Option) => a.label.localeCompare(b.label);
const translateOption = (t: TFunction) =>
  (option: Option) =>
    ({ value: option.value, label: t(`columns.${option.label}`, option.label) });
const filterLabelByTerm = (term: string) => (option: Option) => option.label.toLowerCase().includes(term.toLowerCase());

export const ColumnsModal: React.FC<ColumnsModalProps> = ({ open, attributeKeys, onCancel }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLhsOptions, setSelectedLhsOptions] = useState<Option[]>([]);
  const [allRhsOptions, setAllRhsOptions] = useState<Option[]>([]);
  const [selectedRhsOptions, setSelectedRhsOptions] = useState<Option[]>([]);

  // Load current columns: view and Customizr.
  const { views } = useSelector<AppState, ViewsState>(state => state.views);
  const { preferredViewId } = useSelector<AppState, SettingsState>(state => state.settings);
  const activeView = views.isLoading
    ? undefined
    : views?.data?.find(view => view.viewId === preferredViewId);
  const previouslyActiveViewId = useRef<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const accessToken = useSelector<AppState, string>(state => state.auth.accessToken);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  /*
    Holds the superset of potential column options.
  */
  const [allAvailableOptions, setAllAvailableOptions] = useState<Option[]>([]);

  /*
    col.I Historical columns

    Every time the user saves or changes the view, columns that POM knows of, but that aren't
    part of the selected view shouldn't disappear.
  */
  const previousAllAvailableOptions = useRef<Option[]>([]);

  /*
    The user effectively chooses columns from three places: a list of hardcoded columns,
    product attributes from currently loaded items and anything that was already selected
    in the currently selected view. This may include product attributes which do not feature
    among the currently loaded items.
  */

  useEffect(() => {
    /*
      Hardcoded columns

      These column options are not attributes, but come from various fields of an order item.
      Therefore, we have to list them here as a hardcoded list.
      Most of them will be populated on every item.
      They are translated differently in each language.
    */
    const defaultHardcodedOptions: Option[] = ([
      { label: 'product', value: 'product.name' },
      { label: 'status', value: 'status' },
      { label: 'Quantity', value: 'quantity' },
      { label: 'Forecasted Ship Date', value: 'plan.shipping.expectedShipTime' },
      { label: 'Item Id', value: 'itemId' },
      { label: 'Order Id', value: 'order.orderId' },
      { label: 'Promised Delivery', value: 'order.promisedArrivalDate' },
      { label: 'Last Updated Date', value: 'status.lastUpdated' },
      { label: 'Fulfiller', value: 'orderInfo.fulfiller.fulfillerId' },
      { label: 'Merchant', value: 'orderInfo.merchantInformation.id' },
      { label: 'Delivery Option', value: 'shipmentPlanningAnalysis.shipmentPlans[0].deliveryOption.name' },
      { label: 'printJob', value: 'printJob' },
      { label: 'Delivery Country', value: 'orderInfo.destinationAddress.country' }
    ]).map(opt => ({ label: opt.label, value: opt.value }));

    // Columns from product attributes
    const attributeKeyOptions = attributeKeys.map(name => ({ label: name, value: `attributeMap[${name}]` }));

    /*
      Columns from the currently selected view.
      If the user has a view in Customizr, that one takes priority.
    */
    const viewOptions = activeView?.columns
      ? (activeView.columns as ViewColumn[])
        .filter(column => column.accessor) // TODO: Views migrated from POMv1 have an empty accessor field. Discuss why that is and how to fix.
        .map(({ name, accessor }) => ({ label: name, value: accessor }))
      : [];

    // The complete list mustn't contain duplicates.
    let all = [
      ...previousAllAvailableOptions.current,
      ...defaultHardcodedOptions,
      ...attributeKeyOptions,
      ...viewOptions
    ].sort(compareByLabel);
    all = all.filter((option, index) => all.findIndex(opt => opt.value === option.value) === index);

    setAllAvailableOptions(all);
    previousAllAvailableOptions.current = all;

    /*
      When the user changes the view, their current right hand selection should be updated
      to reflect their newest setting.

      This should also occur whenever the modal is closed, so that the right-hand side
      selection is reset between the time when the user accesses the modal.
    */
    if (activeView && (!open || preferredViewId !== previouslyActiveViewId.current)) {
      setAllRhsOptions(viewOptions);
      previouslyActiveViewId.current = preferredViewId;
    }
  }, [attributeKeys, activeView, open]); // eslint-disable-line react-hooks/exhaustive-deps

  // Because the modal is not unmounted when hidden, we have to manually reset its state when it is hidden.
  useEffect(() => {
    if (!open) {
      setSelectedLhsOptions([]);
      setSelectedRhsOptions([]);
    }
  }, [open]);

  // Calculating options visible in the selection components.
  const displayedLhsOptions = allAvailableOptions.filter(option => !allRhsOptions.find(rhsOption => rhsOption.value === option.value))
    .filter(filterLabelByTerm(searchTerm));

  const closeModal = () => onCancel();
  const save = () => {
    if (!activeView) { return; }

    if (!isSaving) {
      setIsSaving(true);
    }

    const c = new CustomizrClient({
      resource: pomApplicationResourceId
    });

    c.getSettings(accessToken)
      .then((current: CustomizrPomV2) => {
        const customViewId = current.customPomV2View?.viewId || uuid();

        const updatedView: View = Object.assign({}, activeView);
        updatedView.viewId = customViewId;
        updatedView.viewName = 'Custom';
        updatedView.columns = allRhsOptions.map(({ label, value }) => ({ name: label, accessor: value }));
        // @ts-ignore
        delete updatedView.extensions;
        updatedView.version = POM_MAJOR_VIEW_VERSION;
        current.customPomV2View = updatedView;

        current.preferredViewId = customViewId;

        return c.putSettings(accessToken, current);
      })
      .then(() => {
        dispatch(getViewsRequest());
        dispatch(getCustomizrSettings());
        closeModal();
        setShowAlert(false);
      })
      .catch(err => {
        SentryWrapper.reportError(err);

        setShowAlert(true);
      })
      .then(() => {
        setIsSaving(false);
      });
  };

  const addToRightHand = () => {
    setAllRhsOptions(allRhsOptions.concat(selectedLhsOptions));
    setSelectedLhsOptions([]);
    setSelectedRhsOptions(selectedLhsOptions);
  };

  const removeFromRightHand = () => {
    setAllRhsOptions(allRhsOptions.filter((option => !selectedRhsOptions.includes(option))));
    setSelectedLhsOptions(selectedRhsOptions);
    setSelectedRhsOptions([]);
  };

  const moveToTop = () => {
    const optionIndex = allRhsOptions.findIndex(rhsOption => rhsOption.value === selectedRhsOptions[0].value);
    if (optionIndex === 0) { return; }

    const left = allRhsOptions.slice(0, optionIndex);
    const right = allRhsOptions.slice(optionIndex + 1);
    setAllRhsOptions([allRhsOptions[optionIndex], ...left, ...right]);
  };

  const moveToBottom = () => {
    const optionIndex = allRhsOptions.findIndex(rhsOption => rhsOption.value === selectedRhsOptions[0].value);
    if (optionIndex === allRhsOptions.length - 1) { return; }

    const left = allRhsOptions.slice(0, optionIndex);
    const right = allRhsOptions.slice(optionIndex + 1);
    setAllRhsOptions([...left, ...right, allRhsOptions[optionIndex]]);
  };

  const moveUp = () => {
    const optionIndex = allRhsOptions.findIndex(rhsOption => rhsOption.value === selectedRhsOptions[0].value);
    if (optionIndex === 0) { return; }

    const left = allRhsOptions.slice(0, optionIndex - 1);
    const before = allRhsOptions[optionIndex - 1];
    const right = allRhsOptions.slice(optionIndex + 1);
    setAllRhsOptions([...left, allRhsOptions[optionIndex], before, ...right]);
  };

  const moveDown = () => {
    const optionIndex = allRhsOptions.findIndex(rhsOption => rhsOption.value === selectedRhsOptions[0].value);
    if (optionIndex === allRhsOptions.length - 1) { return; }

    const left = allRhsOptions.slice(0, optionIndex);
    const after = allRhsOptions[optionIndex + 1];
    const right = allRhsOptions.slice(optionIndex + 2);
    setAllRhsOptions([...left, after, allRhsOptions[optionIndex], ...right]);
  };

  const updateSearchTerm = (term: string) => {
    setSelectedLhsOptions([]);
    setSelectedRhsOptions([]);
    setSearchTerm(term);
  };

  return <TrackedModal item={'columnsModal'} bsStyle={'info'} closeButton={true}
    className="columns-modal"
    show={open}
    onRequestHide={closeModal}
    style={{ width: '90%' }}
    title={t('items.columns.modal.title')}
    footer={
      <Fragment>
        <TrackedButton item="columnsModal.cancel" className="btn btn-default" onClick={closeModal} disabled={isSaving}>
          {t('items.columns.modal.cancel')}
        </TrackedButton>
        <TrackedButton item="columnsModal.confirm" className="btn btn-primary" onClick={() => {
          save();
        }} disabled={isSaving || allRhsOptions.length === 0}>
          {t('items.columns.modal.confirm')}
        </TrackedButton>
      </Fragment>
    }>
    <Alert
      message={t('items.columns.modal.alert.message')}
      title={t('items.columns.modal.alert.title')}
      dismissible={true}
      dismissed={!showAlert}
      onDismiss={() => setShowAlert(false)} />

    <div className='flexContainer' style={{ minHeight: '60vh' }}>
      <div className='flexChildGrow'>
        <div className='flexContainer' style={{ flexDirection: 'column', height: '100%' }}>
          <h6>{t('items.columns.modal.availableColumns')}</h6>
          <TextField
            label={t('items.columns.modal.search')}
            value={searchTerm}
            onChange={e => updateSearchTerm(e.target.value)} />
          <SelectMultiple
            className="flexChildGrow"
            shouldSort={true}
            options={displayedLhsOptions}
            selected={selectedLhsOptions}
            onChange={selected => {
              setSelectedLhsOptions(selected);
              setSelectedRhsOptions([]);
            }} />
        </div>
      </div>

      <div className='flexChild' style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <h6>&nbsp;</h6>
        <ButtonGroup type="vertical">
          <TrackedButton item="columnsModal.addColumn" disabled={isSaving || selectedLhsOptions.length === 0} onClick={() => addToRightHand()}><IconArrowButtonRight /></TrackedButton>
          <TrackedButton item="columnsModal.removeColumn" disabled={isSaving || selectedRhsOptions.length === 0} onClick={() => removeFromRightHand()}><IconArrowButtonLeft /></TrackedButton>
        </ButtonGroup>
      </div>

      <div className='flexChildGrow'>
        <div className='flexContainer' style={{ flexDirection: 'column', height: '100%' }}>
          <h6>{t('items.columns.modal.selectedColumns')}</h6>
          <SelectMultiple
            className='flexChildGrow'
            shouldSort={false}
            options={allRhsOptions}
            selected={selectedRhsOptions}
            onChange={selected => {
              setSelectedLhsOptions([]);
              setSelectedRhsOptions(selected);
            }} />
        </div>
      </div>

      <div className='flexChild' style={{ paddingLeft: '10px' }}>
        <h6>&nbsp;</h6>
        <ButtonGroup type="vertical">
          <TrackedButton item="columnsModal.moveColumnTop" disabled={isSaving || selectedRhsOptions.length !== 1} onClick={moveToTop}>{t('common.moveTop')}</TrackedButton>
          <TrackedButton item="columnsModal.moveColumnUp" disabled={isSaving || selectedRhsOptions.length !== 1} onClick={moveUp}><IconArrowButtonUp /></TrackedButton>
          <TrackedButton item="columnsModal.moveColumnDown" disabled={isSaving || selectedRhsOptions.length !== 1} onClick={moveDown}><IconArrowButtonDown /></TrackedButton>
          <TrackedButton item="columnsModal.moveColumnBottom" disabled={isSaving || selectedRhsOptions.length !== 1} onClick={moveToBottom}>{t('common.moveBottom')}</TrackedButton>
        </ButtonGroup>
      </div>
    </div>
  </TrackedModal>;
};

const SelectMultiple = ({ className, options, selected, onChange, shouldSort }: SelectMultipleProps) => {
  const { t } = useTranslation();

  const translatedOptions = options.map(translateOption(t));
  const maybeSortedOptions = shouldSort
    ? translatedOptions.sort(compareByLabel)
    : translatedOptions;

  return (
    <select value={selected.map(option => option.value)}
      multiple
      className={`multi-select form-control ${className || ''}`}
      onChange={e => onChange([...e.target.selectedOptions].map(selectedOption => options.find(option => option.value === selectedOption.value)))}>
      {maybeSortedOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </select>
  );
};

type SelectMultipleProps = {
  className?: string;
  shouldSort: boolean;
  selected: Option[];
  options: Option[];
  onChange: Function;
};
