import { FulfillmentLocationClient } from 'cimpress-fulfillment-location';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { useAsync } from 'react-async-hook';
import { Fulfiller } from '../../../../store/fulfillers/types';
import { TFunction } from 'i18next';

export interface FulfillmentLocation {
  fulfillmentLocationId: string;
  name: string;
  location: string;
  timeZone: string;
}

export const useFulfillmentLocations = (t: TFunction, accessToken: string, fulfiller: Partial<Fulfiller> & { fulfillerId: string }) => {
  const fulfillerId = fulfiller?.fulfillerId;

  const response = useAsync(async () => {
    if (accessToken && fulfillerId) {
      try {
        const client = new FulfillmentLocationClient({
          timeout: 10000
        });

        const locations = await client.getLocations({ accessToken: `Bearer ${accessToken}`, fulfillerId });

        const locationsById: Record<string, FulfillmentLocation> = {};
        locations.forEach(l => locationsById[l.fulfillmentLocationId] = l);

        return {
          fulfillmentLocations: locationsById,
          errorLoadingFulfillmentLocations: undefined
        };
      } catch (err) {
        SentryWrapper.reportError(err);

        if (err instanceof Error) {
          err.message = t('errorMessages.failedToRetrieveFulfillmentLocations');
        }

        return {
          fulfillmentLocations: {},
          errorLoadingFulfillmentLocations: err as any
        };
      }
    }

    return {
      fulfillmentLocations: {},
      errorLoadingFulfillmentLocations: undefined
    };
  }, [accessToken, fulfillerId]);

  return {
    loadingFulfillmentLocations: response.loading,
    fulfillmentLocations: response.result?.fulfillmentLocations || {},
    errorFetchingFulfillmentLocations: response.error ?? response.result?.errorLoadingFulfillmentLocations as any
  };
};

