import React from 'react';

export const renderKeyValue = (key, originalValue, condition = true) => {
  let value = originalValue;
  if (Array.isArray(originalValue)) {
    value = originalValue.join(', ');
    if (originalValue.length === 0) {
      value = null;
    }
  }

  return value && condition
    ? <div className='item-row'>
      <div className='item-key h6'>{key}</div>
      <div className='item-value'>{value}</div>
    </div>
    : null;
};
