import { useAsync } from 'react-async-hook';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import { getFulfillerContact } from '../../../clients/fulfillerIdentity/getFulfillerContact';
import { FulfillerContacts } from '../../../clients/fulfillerIdentity/fulfillerContactsModel';

export interface FulfillerContactsState {
  loadingFulfillerContacts: boolean;
  fulfillerContacts: FulfillerContacts | undefined;
  errorLoadingFulfillerContacts: any;
}

export const useFulfillerContacts = (accessToken, fulfillerId): FulfillerContactsState => {
  const response = useAsync(async () => {
    if (accessToken && fulfillerId) {
      try {
        const fulfillerContacts = await getFulfillerContact(fulfillerId, accessToken);
        return {
          data: fulfillerContacts,
          error: undefined
        };
      } catch (error) {
        SentryWrapper.reportError(error);
        return {
          data: undefined,
          error: error
        };
      }
    }
    return {
      data: undefined,
      error: null
    };
  }, [accessToken, fulfillerId]);

  return {
    loadingFulfillerContacts: response.loading,
    fulfillerContacts: response.result?.data,
    errorLoadingFulfillerContacts: response.error ?? response.result?.error
  };
};
