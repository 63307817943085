import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { authReducer } from './auth/reducers';
import * as AuthActions from './auth/actions';
import { settingsReducer } from './settings/reducers';
import { dashboardReducer } from './dashboard/reducers';
import { fulfillersReducer } from './fulfillers/reducers';
import { itemsReducer } from './items/reducers';
import { viewsReducer } from './views/reducers';
import { notificationsReducer } from './notifications/reducers';
import { snackbarReducer } from './snackbar/reducers';
import { jobsheetPresetsReducer } from './jobsheetPresets/reducers';
import { productCategoriesReducer } from './products/reducers';
import { deliveryOptionsReducer } from './deliveryOptions/reducers';
import { deliveryRequestsReducer } from './deliveryRequests/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  items: itemsReducer,
  fulfillers: fulfillersReducer,
  views: viewsReducer,
  notifications: notificationsReducer,
  snackbar: snackbarReducer,
  jobsheets: jobsheetPresetsReducer,
  productCategories: productCategoriesReducer,
  deliveryOptions: deliveryOptionsReducer,
  deliveryRequest: deliveryRequestsReducer

});

export type AppState = ReturnType<typeof rootReducer>;

function configureStore() {
  const middlewares = [
    thunkMiddleware,
    routerMiddleware(createBrowserHistory())
  ];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  /**
   * Bootstrapping store objects
   * store.dispatch is not compatible with redux-thunk, so we need to do this cast
   */
  (store.dispatch as ThunkDispatch<AppState, void, AnyAction>)(AuthActions.initializeAuth());

  return store;
}

export const store = configureStore();
