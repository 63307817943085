import React, { useEffect } from 'react';
import { ItemsTable } from './itemsTable';
import { ItemsFilters } from './itemsFilters';
import { getItemsRequest, setItemsFilters, setItemsTableState } from '../../store/items/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { AppState } from '../../store/store';
import { ErrorComponent } from '../errorComponent';
import { RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import './items.scss';
import { getNotifications } from '../../store/notifications/actions';
import { FulfillerMultiSelectWrapper } from '../shared/fulfillerMultiSelectWrapper';
import { Fulfiller } from '../../store/fulfillers/types';
import { BreadcrumbItem, Breadcrumbs, Card } from '@cimpress/react-components';
import { Filters, ControlledTableState, SortOrderEnum } from '../../store/items/types';
import { ItemSearchStatus } from '../../clients/foma/itemsClient';
import { useTranslation } from 'react-i18next';
import { useSelectedView } from '../shared/hooks/useSelectedView';
import deepEqual from 'deep-equal';
import { TrackedCheckbox } from '../../trackingLayer/trackedCheckbox';
import { TrackedLocalLink } from '../../trackingLayer/trackedLocalLink';

let initialFiltersDispatched = false;

const updateQueryParams = (newParams, history) => {
  const currentQueryParams = new URLSearchParams(history.location.search);

  Object.keys(newParams).forEach(filterName => {
    const val = newParams[filterName];

    if (val?.length === 0 || val === false || val === undefined || val === 0) {
      currentQueryParams.delete(filterName);
    } else {
      currentQueryParams.set(filterName, val instanceof Date ? moment(val).format('MM-DD-YYYY') : val);
    }
  });

  history.push(`${history.location.pathname}?${currentQueryParams.toString()}`);
};

const getFiltersFromBrowserLocation = (allowedStatusFilters): Filters => {
  const queryParams = new URLSearchParams(window.location.search);
  const dateFrom = Date.parse(queryParams.get('expectedShipTimeFrom') as string);
  const dateTo = Date.parse(queryParams.get('expectedShipTimeTo') as string);

  const f: Filters = {
    initialized: true,
    limitForecastedLate: !!queryParams.get('limitForecastedLate'),
    filterItemsWithNoPlan: !!queryParams.get('filterItemsWithNoPlan'),
    status: (queryParams.get('status')?.split(',') || []).filter(s => allowedStatusFilters[s]) as ItemSearchStatus[] || [ItemSearchStatus.New],
    deliveryOptionIds: queryParams.get('deliveryOptionIds')?.split(',') as string[],
    productCategories: queryParams.get('productCategories')?.split(',') as string[],
    expectedShipTimeFrom: !isNaN(dateFrom) ? moment(dateFrom).startOf('day') : undefined,
    expectedShipTimeTo: !isNaN(dateTo) ? moment(dateTo).endOf('day') : undefined,
    fulltext: queryParams.get('fulltext') || ''
  };

  return f;
};

const getTableStateFromBrowserLocation = selectedView => {
  const queryParams = new URLSearchParams(window.location.search);
  const sortBy = queryParams.get('sortBy') || selectedView?.sortedBy[0]?.columnKey;
  const redactedTableState: ControlledTableState = {
    page: parseInt(queryParams.get('page') || '0', 10),
    pageSize: parseInt(queryParams.get('pageSize') || '20', 10),
    sorted: []
  };

  if (sortBy) {
    redactedTableState.sorted = [{
      id: sortBy,
      desc: (queryParams.get('sortOrder') || selectedView?.sortedBy[0]?.direction || SortOrderEnum.DESC.toString()).toLowerCase() === SortOrderEnum.DESC.toString()
    }];
  }

  return redactedTableState;
};

export const Items: React.FC<ItemsPageProps> = (props: ItemsPageProps) => {
  const dispatch = useDispatch();
  const errors = useSelector((state: AppState) => state.items.errors);
  const selectedFulfillerIds = useSelector<AppState, string[]>(state => state.fulfillers?.selectedFulfillers || []) || [];
  const itemsFilters = useSelector((state: AppState) => state.items.filters);
  const accessToken = useSelector<AppState, string>(state => state.auth.accessToken);
  const fulfillers = useSelector<AppState, Fulfiller[] | undefined>(state => state.fulfillers.fulfillers.data) ?? [];
  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers);
  const { page, sorted, pageSize, data } = useSelector((state: AppState) => state.items.table);
  const { selectedView } = useSelectedView();
  const { t } = useTranslation();
  const tableErrors = useSelector((state: AppState) => state.items.table.errors);
  const { count, totalCount } = data || {};

  if (!itemsFilters.initialized && !initialFiltersDispatched) {
    // initialize
    initialFiltersDispatched = true;
    const initialFilters = getFiltersFromBrowserLocation(selectedView?.extensions.ui.screens.itemsList.allowedStatusFilters || {});
    const redactedTableState = getTableStateFromBrowserLocation(selectedView);

    dispatch(setItemsTableState(redactedTableState));
    dispatch(setItemsFilters(initialFilters));
  } else if (!props.location?.state?.backToItemsPage) {
    // ok... what if we have new filters in the address bar ?!
    const currentFilters = getFiltersFromBrowserLocation(selectedView?.extensions.ui.screens.itemsList.allowedStatusFilters || {});
    if (!deepEqual(itemsFilters, currentFilters)) {
      dispatch(setItemsFilters(currentFilters));
    }

    // ok... what if we have new ordering in the address bar ?!
    const currentRedactedTableState = getTableStateFromBrowserLocation(selectedView);
    const storedRedactedTableState = { page, pageSize, sorted };
    if (!deepEqual(storedRedactedTableState, currentRedactedTableState)) {
      dispatch(setItemsTableState(currentRedactedTableState));
    }
  } else {
    updateQueryParams(itemsFilters, props.history);
  }

  if (page !== 0 && count === 0 && (totalCount || 0) > 0) {
    updateQueryParams({ page: 0 }, props.history);
  }

  useEffect(() => {
    if (!itemsFilters.initialized || (tableErrors && tableErrors.length)) {
      // Skip the initial dispatch in case of uninitialized filters
      // Once the filters are initialized, this will be called again because of the dependency
      return;
    }
    dispatch(getItemsRequest());
  }, [dispatch, itemsFilters, page, sorted, pageSize, selectedFulfillerIds, tableErrors]);

  // Update ITEMS + NOTIFICATIONS if the selected fulfiller changes
  useEffect(() => {
    dispatch(getNotifications(selectedFulfillerIds));
  }, [dispatch, selectedFulfillerIds]);

  const tableColumns = (selectedView?.columns || []).map(c => ({
    name: c.name,
    accessor: c.accessor || c.name
  }));

  const itemFilterOptions = [
    { value: ItemSearchStatus.New, label: t('filters.new') },
    { value: ItemSearchStatus.Accepted, label: t('filters.accepted') },
    { value: ItemSearchStatus.Production, label: t('filters.production') },
    { value: ItemSearchStatus.Shipped, label: t('filters.shipped') },
    { value: ItemSearchStatus.Rejected, label: t('filters.rejected') },
    { value: ItemSearchStatus.Cancelled, label: t('filters.cancelled') }
  ];

  const updateItemsFilters = filters => {
    updateQueryParams(filters, props.history);
    dispatch(setItemsFilters(filters));
  };

  return (
    <>
      <div className={'container-fluid'}>

        <div className='row'>
          <div className='col-md-6'>
            <Breadcrumbs>
              <BreadcrumbItem>
                <TrackedLocalLink item={'breadcrumbs.home'} to={'/'}>{t('breadcrumbs.home')}</TrackedLocalLink>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {t('breadcrumbs.itemsList')}
              </BreadcrumbItem>
            </Breadcrumbs>
          </div>
          <div className='col-md-6'>
            <FulfillerMultiSelectWrapper
              accessToken={accessToken}
              fulfillers={fulfillers}
              selectedFulfillerIds={selectedFulfillers}
            />
          </div>
        </div>

        <Card style={{ marginBottom: '5px' }}>

          {(errors && errors.length > 0) && <ErrorComponent errors={errors} />}

          <div className='advancedFilters' style={{ borderBottom: '1px solid #edf3f8' }}>
            <div className='flexContainer'>
              <div className='flexChildGrow advancedFilters'>
                <strong>{t('filters.filterByStatusCaption')}</strong><br />
                {itemFilterOptions.map((status, i) => {
                  const allowedStatusFilters = selectedView?.extensions.ui.screens.itemsList.allowedStatusFilters || {};
                  if (!allowedStatusFilters[status.value]) {
                    return null;
                  }

                  const checked = (itemsFilters.status || []).includes(status.value);
                  return <span key={i} style={{ marginRight: '10px', marginTop: '10px', display: 'inline-block' }}>
                    <TrackedCheckbox item={`fitlers.itemsStatusToggle.${status.value}`} key={i} inline checked={checked} label={status.label}
                      // disabled={isLoading}
                      onChange={() => {
                        const newSelected = (checked)
                          ? (itemsFilters.status || []).filter(a => a !== status.value)
                          : (itemsFilters.status || []).concat([status.value]);
                        updateItemsFilters({ status: newSelected });
                      }}
                    />
                  </span>;
                })}
              </div>
              <div className='flexChild'>
                <strong>{t('filters.advancedFiltersCaption')}</strong><br />
                <span style={{ marginRight: '10px', marginTop: '10px', display: 'inline-block' }}>
                  <TrackedCheckbox
                    item={'filters.onlyShowLateItemsToggle'}
                    label={t('filters.onlyShowLateItems')}
                    inline
                    checked={itemsFilters.limitForecastedLate || false}
                    // disabled={isLoading}
                    onChange={() => {
                      const newValue = !itemsFilters.limitForecastedLate;
                      updateItemsFilters({ limitForecastedLate: newValue });
                    }}
                  />
                </span>
                <span style={{ marginRight: '10px', marginTop: '10px', display: 'inline-block' }}>
                  <TrackedCheckbox
                    item={'filters.itemsWithNoPlanToggle'}
                    label={t('filters.itemsWithNoPlan')}
                    inline
                    checked={itemsFilters.filterItemsWithNoPlan || false}
                    // disabled={isLoading}
                    onChange={() => {
                      const newValue = !itemsFilters.filterItemsWithNoPlan;
                      updateItemsFilters({ filterItemsWithNoPlan: newValue });
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <br />

          <ItemsFilters onUpdateItemsFilters={filters => {
            updateQueryParams(filters, props.history);
            dispatch(setItemsFilters(filters));
          }} />

        </Card>

        <Card>

          <ItemsTable
            history={props.history}
            selectedColumns={tableColumns}
            onSetTableState={(tableState: ControlledTableState) => {
              // eslint-disable-next-line
              const { sorted, ...table } = tableState;
              const sortFields = (sorted || [])[0] || {};
              const queryParams = table as any;

              if (Array.isArray(sorted) && sorted.length) {
                queryParams.sortBy = sortFields.id;
                queryParams.sortOrder = sortFields.desc ? SortOrderEnum.DESC : SortOrderEnum.ASC;
              }

              updateQueryParams(queryParams, props.history);
              dispatch(setItemsTableState(tableState));
            }}
            onRetryRequest={() => dispatch(getItemsRequest())}
            onRowClick={itemId => props.history.push({ pathname: `/items/${itemId}` })} />

        </Card >

      </div >
    </>
  );
};

export interface ItemsPageRouteStateProps {
  backToItemsPage: boolean;
}

export type ItemsPageProps = RouteComponentProps<{}, StaticContext, ItemsPageRouteStateProps>;
