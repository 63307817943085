import { apiRequest, ApiRequestOptions } from '../apiClient';
import { fomaUrl } from '../../utils/environmentProvider';
import { ItemSearchResponseModel } from './itemSearchResponseModel';
import { ItemSearchDateRangeOperator, ItemSearchRequestModel } from './itemSearchRequestModel';
import { TableSorting } from '../../store/items/types';
import axios, { CancelTokenSource } from 'axios';
import { Moment } from 'moment';
import { getDeliveryRequest } from '../delivery/deliveryRequests';
import { DeliveryRequest } from '../delivery/deliveryRequestModel';

const __cancelTokens: Record<string, CancelTokenSource> = {};

export enum ItemSearchStatus {
  New = 'new',
  Accepted = 'accepted',
  Production = 'production',
  Shipped = 'shipped',
  Completed = 'completed',
  Rejected = 'rejected',
  Cancelled = 'cancelled'
}

export const getPreferredItemId = (item: Pick<OrderItem, 'itemId' | 'merchantInformation'>, useMerchantIds: boolean | undefined) => useMerchantIds
  ? item.merchantInformation?.itemId || item.itemId
  : item.itemId;

export interface Attribute {
  name: string;
  value: string;
}

export interface ItemProduct {
  attributes?: Attribute[];
  [key: string]: any;
}

export interface OrderItem {
  itemId: string;
  order: Record<string, any>;
  product: ItemProduct;
  orderInfo: Record<string, any>;
  merchantInformation: ItemMerchantInformation;
  links: Record<string, BasicLink>;
  isTestItem: boolean;
  orderedQuantity: number;
  deliveryDetails: DeliveryDetails[];
}

export interface DeliveryDetails {
  type: string;
  quantity: number;
  links: DeliveryDetailsLinks;
  destinationAddress?: OrderAddress;
}

export interface DeliveryDetailsLinks {
  self: BasicLink;
}

interface BasicLink {
  href: string;
  rel?: string;
}

export interface OrderAddress {
  firstName: string;
  lastName: string;
  street1: string;
  stateOrProvince: string;
  country: string;
  postalCode: string;
  city: string;
}

export interface OrderFulfiller {
  fulfillerId: string;
}

export interface OrderMerchangInformation {
  id: string;
  orderId: string;
}

export interface Order {
  orderId: string;
  fulfiller: OrderFulfiller;
  createdDate: string;
  promisedArrivalDate: string;
  destinationAddress: OrderAddress;
  links: Record<string, any>;
  merchantInformation: OrderMerchangInformation;
}

export interface ShipmentPlanningAnalysis {
  id: string;
  shipmentPlans: ShipmentPlan[];
}

export interface ShipmentPlan {
  lateArrival: boolean;
  deliveryOption: DeliveryOption;
}
export interface DeliveryOption {
  id: string;
  name: string;
}

export interface OrderItemStatus {
  itemId: string;
  statusSummary: Record<string, any>;
  statusDetails: Record<string, any>;
  links: Record<string, any>;
}

export interface ItemMerchantInformation {
  itemId: string;
  productName: string;
}

export interface ExtendedOrderItem extends OrderItemWithStatusAndOrderInfo {
  plan: Plan;
  attributeMap: Record<string, string>;
}

export interface Plan {
  shipping: Record<string, any>;
  shippingPlans: Record<string, any>;
  links: Record<string, any>;
}

export interface OrderItemWithStatusAndOrderInfo extends OrderItem {
  status: OrderItemStatus;
  orderInfo: Record<string, any>;
  platformItemId?: string;
  deliveryRequestsDetails?: Array<DeliveryRequest | undefined>;
}

export async function getItemByShortItemId(itemId: string, accessToken: string): Promise<OrderItem | null> {
  const response = await apiRequest<OrderItem>({
    url: `${fomaUrl}/v1/items/${itemId}`,
    method: 'get',
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken: accessToken,
    noErrorReportingForStatusCodes: [404]
  });

  return response?.data ?? null;
}

export async function getItemStatusByShortItemId(itemId: string, accessToken: string): Promise<OrderItemStatus | null> {
  const response = await apiRequest<OrderItemStatus>({
    url: `${fomaUrl}/v1/items/${itemId}/status`,
    method: 'get',
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken: accessToken
  });

  const r = response?.data ?? null;
  if (r) {
    // TBD: OMG!!!!
    r.itemId = itemId;
  }
  return r;
}

export async function getOrder(orderId: string, accessToken: string): Promise<Order | null> {
  const response = await apiRequest<Order>({
    url: `${fomaUrl}/v1/orders/${orderId}`,
    method: 'get',
    headers: { 'prefer': 'wait=5', 'content-type': 'application/json' },
    accessToken: accessToken
  });

  return response?.data ?? null;
}

export async function getItemsWithStatusAndOrderInfo(itemIds: string[], accessToken: string): Promise<OrderItemWithStatusAndOrderInfo[]> {
  const data = await Promise.all(itemIds.map(itemId => getItemByShortItemId(itemId, accessToken)));
  const items = [] as OrderItemWithStatusAndOrderInfo[];
  const ordersInfo = {};

  data.forEach(d => {
    if (d) {
      ordersInfo[d.order.orderId] = d.order.links.self.href;
      items.push(d as OrderItemWithStatusAndOrderInfo);
    }
  });

  const [ordersData, itemsStatus] = await Promise.all([
    Promise.all(Object.keys(ordersInfo).map(orderId => getOrder(orderId, accessToken))),
    Promise.all(items.map(item => apiRequest<OrderItemStatus>({ url: item.links.status.href, accessToken })))
  ]);

  itemsStatus.forEach((statusData, i) => items[i].status = statusData?.data as OrderItemStatus);
  const itemDeliveryDetails = await Promise.all(items.map(item => Promise.all(item.deliveryDetails?.map(dd => getDeliveryRequest(accessToken, dd?.links.self.href)))));

  itemDeliveryDetails?.forEach((dd, i) => items[i].deliveryRequestsDetails = dd);

  ordersData
    .filter(o => o !== null)
    .forEach(o => {
      ordersInfo[o?.orderId || ''] = o;
    });

  items.forEach(item => item.orderInfo = ordersInfo[item.order.orderId]);

  return items;
}

export interface ItemSearchResults {
  items: ExtendedOrderItem[];
  count: number;
  totalCount: number;
}

export interface SearchParameters {
  fulfillerIds: string[];
  // Restricting items to a given status
  status?: ItemSearchStatus[];
  // Filter items which are forecasted to be delivered late.
  forecastedLate?: boolean;
  // Filter items which have a plan and the ExpectedShipTime is specified for the item
  noExpectedShipTime?: boolean;
  // FulltextSearch
  searchString?: string;
  // Search by specifying fields and values to match date ranges.
  expectedShipTimeFrom?: Moment;
  expectedShipTimeTo?: Moment;
  productCategories: string[];
  deliveryOptionIds?: string[];
  pageSize?: number;
  pageNumber?: number;
  sort?: TableSorting[];
}

export function constructRequestBody(searchParameters: SearchParameters): object {
  console.log(searchParameters);
  const requestBody: ItemSearchRequestModel = {
    terms: {
      fulfillerIds: searchParameters.fulfillerIds
    },
    pageSize: 1000
  };

  if (searchParameters.status) {
    requestBody.terms.status = searchParameters.status.join(',');
  }

  if (searchParameters.noExpectedShipTime) {
    requestBody.terms.planScheduled = false;
  }

  if (searchParameters.pageSize) {
    requestBody.pageSize = searchParameters.pageSize;
    if (searchParameters.pageNumber) {
      requestBody.offset = searchParameters.pageSize * searchParameters.pageNumber;
    }
  }

  if (searchParameters.sort) {
    requestBody.sort = searchParameters.sort.map(sort =>
      ({
        field: sort.id,
        direction: sort.desc ? 'Descending' : 'Ascending'
      })
    );
  }

  if (searchParameters.searchString) {
    requestBody.fulltext = [searchParameters.searchString.substr(0, 99)];
  }

  if (searchParameters.forecastedLate) {
    requestBody.terms.forecastedLate = true;
  }

  if (searchParameters.productCategories) {
    requestBody.terms.productCategories = searchParameters.productCategories;
  }

  if (searchParameters.deliveryOptionIds && searchParameters.deliveryOptionIds.length) {
    requestBody.terms.deliveryOptionIds = searchParameters.deliveryOptionIds;
  }

  if (searchParameters.expectedShipTimeFrom || searchParameters.expectedShipTimeTo) {
    const ranges: ItemSearchDateRangeOperator[] = [];

    if (searchParameters.expectedShipTimeFrom) {
      ranges.push({ operator: 'gte', dateTime: searchParameters.expectedShipTimeFrom.toISOString() });
    }
    if (searchParameters.expectedShipTimeTo) {
      ranges.push({ operator: 'lt', dateTime: searchParameters.expectedShipTimeTo.toISOString() });
    }

    requestBody.dateRanges = {
      expectedShipTime: ranges
    };
  }

  return requestBody;
}

export async function searchForItems(searchParameters: SearchParameters, accessToken: string): Promise<ItemSearchResults | null> {
  for (const cancelTokenSourceKey of Object.keys(__cancelTokens)) {
    __cancelTokens[cancelTokenSourceKey].cancel();
    delete __cancelTokens[cancelTokenSourceKey];
  }

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const key = JSON.stringify(searchParameters);
  __cancelTokens[key] = source;

  const commonSearchItemRequestOptions: ApiRequestOptions = {
    accessToken,
    method: 'post',
    url: `${fomaUrl}/v0/item-search`,
    query: { embedded: true },
    timeout: 60 * 1000,
    cancelTokenSource: source
  };

  const getItemsResponse = await apiRequest<ItemSearchResponseModel>({
    ...commonSearchItemRequestOptions,
    data: constructRequestBody(searchParameters)
  });

  if (getItemsResponse === null) {
    // likely the call was cancelled
    return null;
  }

  const items = [] as ExtendedOrderItem[];
  if (getItemsResponse.data) {
    // convert to extended
    (getItemsResponse.data.items || []).forEach(searchItem => {
      const attributeMap = searchItem.embedded.self?.product?.attributes.reduce((acc, attr: Attribute) => Object.assign(acc, { [attr.name]: attr.value }), {});
      const orderItem = {
        itemId: searchItem.itemId,
        order: searchItem.order as Record<string, any>,
        orderInfo: searchItem.embedded.order as unknown as Order,
        status: searchItem.embedded.status as unknown as OrderItemStatus,
        shipmentPlanningAnalysis: searchItem.embedded.shipmentPlanningAnalysis as ShipmentPlanningAnalysis,
        plan: searchItem.embedded.plan as unknown as Plan,
        attributeMap: attributeMap,
        product: searchItem.embedded.self?.product as unknown,
        orderedQuantity: searchItem.quantity,
        merchantInformation: searchItem.embedded.self.merchantInformation as unknown as ItemMerchantInformation,
        isTestItem: searchItem.embedded.self.isTestItem,
        deliveryDetails: searchItem.embedded.self.deliveryDetails,
        links: Object.assign({}, searchItem.links) as unknown
      } as ExtendedOrderItem;

      items.push(orderItem);
    });

    return {
      items: items,
      count: items.length,
      totalCount: getItemsResponse.data.totalCount
    };
  }

  if (getItemsResponse.error) {
    throw getItemsResponse;
  }

  return {
    items: [],
    count: 0,
    totalCount: 0
  };
}
