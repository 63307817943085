import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { Table } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { pomUrlConstructor } from '../../../utils/urlConstructor';
import { ProductCategory } from '../../../models/products';
import { TrackedLocalLink } from '../../../trackingLayer/trackedLocalLink';

export interface BacklogTableProps {
  aggregatedByDateAndCategory: any;
}

export const BacklogTable: React.FC<BacklogTableProps> = ({ aggregatedByDateAndCategory }) => {
  const selectedFulfillers = useSelector((state: AppState) => state.fulfillers.selectedFulfillers);
  const { showQuantities } = useSelector((state: AppState) => state.dashboard.backlog);
  const selectedFulfillerId = selectedFulfillers && selectedFulfillers.length > 0 ? selectedFulfillers[0] : '';
  const { t } = useTranslation();
  const categories = useSelector<AppState, ProductCategory[]>(state => state.productCategories?.categories?.data || []);

  const categoriesLeafMap = {};
  categories.forEach(c => categoriesLeafMap[c.name] = c.isLeaf);

  const tableData = [];

  const totalRow = {
    category: t('dashboard.backlogWidget.tableTotalTitle')
  };

  aggregatedByDateAndCategory.forEach(category => {
    const rowInfo = {
      category: category.key,
      total: showQuantities ? category.quantity : category.total
    };
    category.aggregations.forEach(agg => {
      rowInfo[agg.key] = showQuantities ? agg.quantity : agg.total;
      totalRow[agg.key] += showQuantities ? agg.quantity : agg.total;
    });
    tableData.push(rowInfo as never);
  });

  const columns = [{
    Header: t('dashboard.backlogWidget.tableCategoryTitle'),
    accessor: 'category',
    Cell(row) {
      if (row.original.category === 'rowTotal') {
        return t('dashboard.backlogWidget.tableRowTotalTitle');
      }
      if (row.original.category === 'rowOthers') {
        return t('dashboard.backlogWidget.tableRowOthersTitle');
      }
      return row.original.category;
    }
  }];

  const labels = aggregatedByDateAndCategory && aggregatedByDateAndCategory.length > 0
    ? aggregatedByDateAndCategory[0].aggregations.map(a => a.key)
    : [];

  labels.forEach(d => {
    columns.push({
      Header: (d === 'rowTotal' || d === 'total')
        ? t('dashboard.backlogWidget.tableRowTotalTitle')
        : (d === 'late'
          ? t('dashboard.backlogWidget.tableLateTitle')
          : (d === 'future'
            ? t('dashboard.backlogWidget.tableFutureTitle')
            : (d === 'rowOthers'
              ? <i>{t('dashboard.backlogWidget.tableRowTotalTitle')}</i>
              : d))),
      // @ts-ignore
      accessor: d,
      // @ts-ignore
      getProps(state, rowInfo, column) {
        let columnClass = '';
        switch (column.id) {
          case 'total':
            columnClass = 'totalRowOrColumn';
            break;
          case 'late':
            columnClass = 'lateColumn';
            break;
          case 'future':
            columnClass = 'futureColumn';
            break;
          default:
            columnClass = '';
            break;
        }
        return {
          class: `rt-td ${columnClass}`
        };
      },
      // @ts-ignore
      Cell(row) {
        const dd = row.original[d];

        const pomQueryParams: [string, string][] = [];
        switch (d) {
          case 'late':
            pomQueryParams.push(['fulfillerId', selectedFulfillerId]);
            pomQueryParams.push(['status', 'new,accepted,production']);
            pomQueryParams.push(['limitForecastedLate', 'true']);
            break;
          case 'future':
            pomQueryParams.push(['fulfillerId', selectedFulfillerId]);
            pomQueryParams.push(['status', 'new,accepted,production']);
            // TODO: Add the correct date here!
            // pomQueryParams.push(['expectedShipTimeFrom', d]);
            break;
          case 'total':
            pomQueryParams.push(['fulfillerId', selectedFulfillerId]);
            pomQueryParams.push(['status', 'new,accepted,production']);
            break;
          default:
            pomQueryParams.push(['fulfillerId', selectedFulfillerId]);
            pomQueryParams.push(['status', 'new,accepted,production']);
            pomQueryParams.push(['shipDate', d]); // TODO Remove when POM v1 is completely deprecated
            pomQueryParams.push(['expectedShipTimeFrom', d]);
            pomQueryParams.push(['expectedShipTimeTo', d]);
            break;
        }

        const link = pomUrlConstructor('items', pomQueryParams);
        if (!dd || dd === 0) {
          return '---';
        }

        if (row.original.category === 'rowOthers') {
          return dd;
        }

        if ((d === 'total') && (row.index === tableData.length - 1)) {
          return <>&nbsp;</>;
        }

        if ((row.index >= tableData.length - 1) || (d === 'total')) {
          return dd;
        }

        return <span><TrackedLocalLink item={'backlogWidget.cell.link'} to={link}>{dd}</TrackedLocalLink></span>;
      }
    });
  });

  return (
    <div className='backlogWrapper'>
      {tableData.length > 1
        ? <Table
          columns={columns}
          data={tableData}
          selectable={false}
          sortable={false}
          showPageSizeOptions={false}
          showPagination={false}
          showPageJump={false}
          pageSize={tableData.length}
          getTheadThProps={(state, rowInfo, column) => {
            let columnClass = '';
            switch (column?.id) {
              case 'total':
                columnClass = 'totalRowOrColumn';
                break;
              case 'late':
                columnClass = 'lateColumn';
                break;
              case 'future':
                columnClass = 'futureColumn';
                break;
              default:
                columnClass = '';
                break;
            }
            return {
              // @ts-ignore
              class: `rt-td ${columnClass}`
            };
          }}
          getTrProps={(state, rowInfo) => {
            return {
              // @ts-ignore
              class: rowInfo?.index === tableData.length - 1 ? 'rt-tr totalRowOrColumn' : 'rt-tr'
            };
          }}
        />
        : null}
    </div>
  );
};

