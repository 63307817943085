import React, { Fragment, useState } from 'react';
import { Radio, RadioGroup, TextField } from '@cimpress/react-components';
import { rejectOrderRequest } from '../../../store/items/actions';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { useTranslation } from 'react-i18next';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { TrackedModal } from '../../../trackingLayer/trackedModal';
import { REJECTION_REASONS_KEYS } from '../../../utils/rejectionReasons';

const getReasonText = (key: string, freeForm: string) => {
  if (!key) {
    return freeForm;
  }
  return `${key}: ${freeForm}`;
};

export const RejectButton: React.FC<RejectButtonProps> = props => {
  const { t } = useTranslation();

  const initialState = { open: false, reasonKey: '', freeFormReason: '' };
  const [state, setState] = useState(initialState);

  const openModal = () => {
    setState({ ...state, open: true });
  };

  const closeModal = () => {
    setState(initialState);
  };

  const setReasonKey = (value: string) => setState({ ...state, reasonKey: value });
  const onReasonInputChange = e => setState({ ...state, freeFormReason: e.target.value });

  const reasonText = getReasonText(state.reasonKey, state.freeFormReason);
  const rejectItem = () => {
    closeModal();
    props.dispatch(rejectOrderRequest(props.items, reasonText, state.reasonKey, props.refreshItemList));
  };

  return (
    <Fragment>
      {props.dropDown
        ? <button className={props.className} onClick={openModal} disabled={props.disabled}>
          {t('items.actions.rejectItems.actionButtonLabel')}
        </button>
        : <TrackedButton item="rejectModal" className={props.className} type={'link'} onClick={openModal} disabled={props.disabled}>
          {t('items.actions.rejectItems.actionButtonLabel')}
        </TrackedButton>}
      <span onClick={e => e.stopPropagation()}>
        <TrackedModal item={'rejectModal'}
          bsStyle={'danger'}
          closeButton={true}
          show={state.open}
          onRequestHide={closeModal}
          title={t('items.actions.rejectItems.modalTitle', { count: props.items.length })}
          footer={
            <Fragment>
              <TrackedButton item="rejectModal.cancel" className="btn btn-default" onClick={closeModal}>
                {t('items.actions.rejectItems.modalFooterButtons.cancel')}
              </TrackedButton>
              <TrackedButton item="rejectModal.reject" className="btn btn-danger" disabled={!state.reasonKey} onClick={rejectItem}>
                {t('items.actions.rejectItems.modalFooterButtons.rejectItems', { count: props.items.length })}
              </TrackedButton>
            </Fragment>
          }
        >
          <>
            <strong>{t('items.actions.rejectItems.whyQuestion')}</strong>

            <RadioGroup name={'reason'} onChange={(e, value): void => setReasonKey(value)}>
              {REJECTION_REASONS_KEYS.map(r => <Radio key={r} label={t(`items.actions.rejectItems.rejectOptions.${r}`)} value={r} />)}
            </RadioGroup>
            <br />
            <TextField
              value={state.freeFormReason}
              onChange={onReasonInputChange}
              label={t('items.actions.rejectItems.additionalInformation')}
            />
            <hr />
            <ul>
              <li>{t('items.actions.rejectItems.importantMessage')}</li>
              <li>{t('items.actions.rejectItems.itemsConfirmationQuestion', { count: props.items.length })}</li>
            </ul>
          </>
        </TrackedModal>
      </span>
    </Fragment>
  );
};

interface RejectButtonProps {
  dispatch: Function;
  items: OrderItemWithStatusAndOrderInfo[];
  disabled?: boolean;
  className?: string;
  refreshItemList: boolean;
  dropDown?: boolean;
}

