import { SettingsActionTypes, SettingsState } from './types';
import { SettingsActions } from '../storeActions';

const initialState: SettingsState = {
  language: 'en',
  locale: 'en',
  preferredViewId: '4ce42f12-09c2-41ce-8166-0ec14c9341da',
  customPomV2View: undefined,
  preferredPreviewSource: 'docRef'
};

export function settingsReducer(state = initialState, action: SettingsActionTypes): SettingsState {
  switch (action.type) {
    case SettingsActions.SET_SETTNGS: {
      return {
        ...state,
        ...action.settings
      };
    }
    case SettingsActions.SET_COLUMN_WIDTHS: {
      return {
        ...state,
        customColumnWidths: {
          ...state.customColumnWidths,
          ...action.columnWidths
        }
      };
    }
    default:
      return state;
  }
}
