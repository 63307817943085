import { JobsheetPresetActions } from '../storeActions';
import { AppState } from '../store';
import { getJobsheetPreset } from '../../clients/jobsheets/jobsheets';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';

const getJobsheetPresetSuccess = (fulfillerId, values) => ({
  type: JobsheetPresetActions.GET_JOBSHEET_PRESET_SUCCESS,
  fulfillerId,
  data: values
});

const getJobsheetPresetError = (fulfillerId, error) => ({
  type: JobsheetPresetActions.GET_JOBSHEET_PRESET_ERROR,
  data: null,
  fulfillerId,
  error: error
});

export const getJobsheetPresetRequest = (fulfillerId: string) => async (dispatch, getState) => {
  dispatch({ type: JobsheetPresetActions.GET_JOBSHEET_PRESET_REQUEST, fulfillerId });

  const state = getState() as AppState;
  const accessToken = state.auth.accessToken;

  try {
    const presetInfo = await getJobsheetPreset(accessToken, fulfillerId);
    // const filtered = {
    //   ...presetInfo,
    //   presets: (presetInfo.presets || []).filter(p => p.type !== 'detailedJobsheet')
    // };
    dispatch(getJobsheetPresetSuccess(fulfillerId, presetInfo));
  } catch (e) {
    SentryWrapper.reportError(e);
    dispatch(getJobsheetPresetError(fulfillerId, e));
  }
};
