import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { isWebUri } from 'valid-url';
import { TextField, Snackbar, Alert } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { Loading } from '../../shared/loading';
import { useFulfillmentLocations, FulfillmentLocation } from './hooks/useFulfillmentLocations';
import { CarrierService, useCarrierServices } from './hooks/useCarrierServices';
import { useTrackingUrl } from './hooks/useTrackingUrl';
import { createShipment } from '../../../clients/foma/createShipment';
import { DeliveryDetails, OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { TrackedModal } from '../../../trackingLayer/trackedModal';
import { TrackedLink } from '../../../trackingLayer/trackedLink';
import { TrackedSelect } from '../../../trackingLayer/trackedSelect';
import { AppState } from '../../../store/store';
import { Fulfiller } from '../../../store/fulfillers/types';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import moment from 'moment-timezone';
import { usePlanResponse } from './hooks/usePlanResponse';

const otherCarrierKey = 'FED821C2-C9E6-4465-B4F4-D854B355DF3A';

const shippingCancelledOrRejected = items => {
  return items.some(item => {
    const { status, cancellationPending } = item;
    const rejectedQuantity = status?.statusDetails.rejected?.quantity || 0;
    const cancelledQuantity = status?.statusDetails.cancelled?.quantity || 0;
    return cancelledQuantity > 0 || rejectedQuantity > 0 || cancellationPending;
  });
};

const isCarrierValid = (carrierService, otherCarrierService) => {
  return carrierService?.key?.replace(/\s+/g, '').length && !(carrierService?.key === otherCarrierKey && !otherCarrierService);
};

const ship = (accessToken, items: OrderItemWithStatusAndOrderInfo[], quantityToShipByItemId: Record<string, number>, formData: ShipFormData, callback) => {
  const data = {
    items: items.map(({ itemId }) => ({ itemId: itemId, quantity: quantityToShipByItemId[itemId] })),
    carrierService: formData.carrierServiceKey,
    trackingId: formData?.trackingId,
    trackingUrl: formData?.trackingUrl,
    externalShipmentId: formData.shipmentId || v4(),
    fulfillmentLocationId: formData?.fulfillmentLocation.fulfillmentLocationId,
    deliveryDetailUrl: formData?.deliveryDetail?.links.self.href,
    pickupDateTime: formData?.pickUpDateTime
  };

  return createShipment(accessToken, data)
    .then(shipmentId => {
      if (shipmentId) {
        formData.shipmentId = shipmentId;
        callback(Object.assign({}, formData));
      } else {
        // The request could have been cancelled
      }
    });
};

export const ShipForm: React.FC<ShipFormProps> = props => {
  const { t } = useTranslation();
  const { items, accessToken } = props;
  const fulfillerId = items[0]?.orderInfo?.fulfiller.fulfillerId;
  const allFulfillers = useSelector<AppState, AppState['fulfillers']['fulfillers']>(state => state.fulfillers?.fulfillers);
  const fulfiller: Partial<Fulfiller> & { fulfillerId: string } = (allFulfillers.data?.[fulfillerId]) || { fulfillerId };

  const [isShipping, setIsShipping] = useState(false);
  const { fulfillmentLocations, loadingFulfillmentLocations, errorFetchingFulfillmentLocations } = useFulfillmentLocations(t, accessToken, fulfiller);
  const FLList = Object.values(fulfillmentLocations || {});
  const [fulfillmentLocation, setFulfillmentLocation] = useState<FulfillmentLocation>();
  const [carrierService, setCarrierService] = useState<CarrierService | null>();
  const [otherCarrierService, setOtherCarrierService] = useState<string>();
  const [finalTrackingUrl, setFinalTrackingUrl] = useState<string>();
  const [shippingReference, setShippingReference] = useState<string>();
  const [pickUpDate, setPickUpDate] = useState<string>();
  const [pickUpTime, setPickUpTime] = useState<string>();
  const [confirmationScreenOpen, setConfirmationScreenOpen] = useState<boolean>(false);
  const [errorWhileShipping, setErrorWhileShipping] = useState<any>();

  const { carrierServices, loadingCarrierServices } = useCarrierServices(accessToken, fulfillmentLocation?.fulfillmentLocationId);
  const { trackingId, trackingUrl, setTrackingId, loadingTrackingUrl } = useTrackingUrl(accessToken, carrierService?.key);
  const { planResponse } = usePlanResponse(accessToken, (items || [])[0] || null);

  const carrierServicesPlusOtherCarrier = carrierServices.concat([{ key: otherCarrierKey, name: `--- ${t('shipping.otherCarrier')} ---` }]);
  const carrierServicesDisabled = loadingFulfillmentLocations || loadingCarrierServices || !fulfillmentLocation;
  const addCarrierServiceUrl = !carrierServicesDisabled
    ? `https://qcm.qp.cimpress.io/ui/fulfillmentLocations/${fulfillmentLocation?.fulfillmentLocationId}?utm_source=pom&utm_medium=add_carrier_service_shipping_form&utm_campaign=add_carrier_service&utm_term=shipping`
    : null;

  useEffect(() => { setFinalTrackingUrl(trackingUrl); }, [trackingUrl]);

  useEffect(() => {
    if (fulfillmentLocation) {
      props.onFulfillmentLocationSelected?.(fulfillmentLocation?.fulfillmentLocationId);
    }
  }, [fulfillmentLocation]);

  // @ts-ignore
  const planCsKey = `${planResponse?.expectedCarrierService?.key || 'x'}:${carrierServices?.length}`;
  useEffect(() => {
    // @ts-ignore
    if (planResponse?.expectedCarrierService?.key) {
      // @ts-ignore
      const cs = carrierServices.find(a => a.key === planResponse?.expectedCarrierService?.key);
      setCarrierService(cs || carrierServices[0]);
    }
  }, [planCsKey]);

  if (loadingFulfillmentLocations) {
    return <Loading message={`${t('shipping.loading_form')} ...`} />;
  }

  if (!fulfillmentLocation && FLList.length) {
    setFulfillmentLocation(FLList[0]);
  }

  // @ts-ignore
  const flSelectOptions = FLList.map(l => ({ value: l.fulfillmentLocationId, label: l.name }));
  const currentlySelectedFLOption = flSelectOptions.find(a => a.value === fulfillmentLocation?.fulfillmentLocationId);

  const availablePickUpTime = [{ value: '00:00', label: '00:00' }, { value: '13:00', label: '13:00' }, { value: '14:00', label: '14:00' }, { value: '15:00', label: '15:00' }, { value: '16:00', label: '16:00' }];
  const getUTCDateAndTime = () => {
    if (pickUpDate) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const timezoneBasedOnFL = fulfillmentLocation!.timeZone;
      const utcTime = moment.tz(`${pickUpDate} ${pickUpTime}`, timezoneBasedOnFL).utc().format();
      return utcTime;
    }
    return undefined;
  };

  const csSelectOptions = Object.values(carrierServicesPlusOtherCarrier).map(l => ({ value: l.key, label: l.name }));
  const currentlySelectedCSOption = csSelectOptions
    .find(a => a.value === carrierService?.key)
    || csSelectOptions
    // @ts-ignore
      .find(a => a.value === planResponse?.expectedCarrierService?.key)
    || null; // null fixes the select bug where the latest selection remains.

  return <div>
    <Snackbar show={!!errorWhileShipping} bsStyle={'danger'} delay={3000} onHideSnackbar={() => setErrorWhileShipping(null)}>
      {t('shipping.errorWhileShipping')}
    </Snackbar>

    {errorFetchingFulfillmentLocations
      ? <Alert type={'danger'} message={errorFetchingFulfillmentLocations.message} dismissible={false} />
      : null}

    <TrackedSelect
      item={'shipping.selectFulfillmentLocation'}
      tether
      isClearable={false}
      label={`${t('shipping.selectFulfillmentLocation')}`}
      value={currentlySelectedFLOption}
      options={flSelectOptions}
      onChange={(e: any) => {
        if (e?.value) {
          setFulfillmentLocation(fulfillmentLocations[e.value]);
          setCarrierService(null);
        }
      }}
    />

    <TrackedSelect
      item={'shipping.selectCarrierService'}
      tether
      isDisabled={carrierServicesDisabled}
      isClearable={false}
      label={t('shipping.selectCarrierService')}
      value={currentlySelectedCSOption}
      options={csSelectOptions}
      onChange={(e: any) => {
        const carrier = carrierServicesPlusOtherCarrier?.find(c => c.key === e.value);
        if (carrier) {
          setCarrierService(carrier);
        }
      }}
    />
    {addCarrierServiceUrl
      ? <div className='shipFormManageServices'>
        <TrackedLink item={'shipping.addCarrierService'} target={'_blank'}
          href={addCarrierServiceUrl}>
          {t('shipping.addCarrierService')}
        </TrackedLink>
      </div>
      : null
    }

    {
      carrierService?.key === otherCarrierKey
        ? <TextField
          label={t('shipping.carrierName')}
          value={otherCarrierService}
          required
          onChange={({ target }) => setOtherCarrierService(target.value)}
        />
        : null
    }

    <TextField
      label={t('shipping.trackingNumber')}
      value={trackingId}
      onChange={({ target }) => setTrackingId(target.value)}
    />

    <TextField
      label={t('shipping.trackingUrl')}
      value={finalTrackingUrl}
      bsStyle={finalTrackingUrl && !isWebUri(finalTrackingUrl) ? 'error' : ''}
      disabled={loadingTrackingUrl}
      onChange={({ target }) => setFinalTrackingUrl(target.value)}
    />

    <TextField
      label={t('shipping.shippingReference')}
      value={shippingReference}
      onChange={({ target }) => setShippingReference(target.value)}
    />
    <div style={{ width: '100%', display: 'inline-flex' }}>
      <DatePicker
        placeholder={t('shipping.pickUpDate')}
        value={pickUpDate}
        closeOnClickOutside={true}
        closeOnSelect={true}
        onChange={date => {
          if (date) {
            setPickUpDate(moment(date).format('YYYY-MM-DD'));
            if (!pickUpTime) { setPickUpTime(availablePickUpTime[0].value); }
          } else {
            setPickUpTime('');
            setPickUpDate(date);
          }
        }}
        timeFormat={false}
      />
      <div style={{ marginLeft: '5%', width: '35%' }}>
        <TrackedSelect
          item={'shipping.pickUpTime'}
          isDisabled={!pickUpDate}
          label={`${t('shipping.pickUpTime')} in ${fulfillmentLocation?.timeZone} timezone`}
          value={availablePickUpTime.find(op => op?.value === pickUpTime) || { label: '', value: '' }}
          options={availablePickUpTime}
          onChange={(e: any) => setPickUpTime(e?.value)}
        />
      </div>
    </div>
    <br />
    <br />
    <TrackedButton item="shipping.ship" type="primary"
      disabled={!items.length || !isCarrierValid(carrierService, otherCarrierService) || isShipping}
      onClick={() => {
        if (shippingCancelledOrRejected(items)) {
          setConfirmationScreenOpen(true);
        } else {
          setIsShipping(true);
          ship(accessToken,
            items,
            props.itemsShipQuantities,
            {
              // @ts-ignore TODO remove this comment
              shipmentId: shippingReference,
              trackingId: trackingId,
              trackingUrl: trackingUrl,
              // @ts-ignore TODO remove this comment
              carrierServiceKey: (carrierService?.key === otherCarrierKey ? otherCarrierService : carrierService?.key),
              // @ts-ignore TODO remove this comment
              fulfillmentLocation: fulfillmentLocation,
              deliveryDetail: props.deliveryDetail,
              pickUpDateTime: getUTCDateAndTime()
            }, props.onItemsShipped)
            .then(() => setErrorWhileShipping(null))
            .catch(err => setErrorWhileShipping(err || true))
            .then(() => setIsShipping(false));
        }
      }} >
      {t('common.ship')}
    </TrackedButton>
    {isShipping ? <Loading message={t('shipping.marking_ship')} /> : null}
    <TrackedModal
      item={'shipping.confirmModal'}
      bsStyle={'danger'}
      closeButton={false}
      show={confirmationScreenOpen}
      onRequestHide={() => setConfirmationScreenOpen(false)}
      title={t('shipping.confirmShipItems')}
      footer={
        <Fragment>
          <TrackedButton item="shipping.confirmModal.confirm" className="btn btn-success"
            disabled={isShipping}
            onClick={() => {
              setIsShipping(true);
              ship(accessToken, items, props.itemsShipQuantities, {
                // @ts-ignore TODO remove this comment
                shipmentId: shippingReference,
                trackingId: trackingId,
                trackingUrl: trackingUrl,
                // @ts-ignore TODO remove this comment
                carrierServiceKey: carrierService?.key === otherCarrierKey ? otherCarrierService : carrierService?.key,
                // @ts-ignore TODO remove this comment
                fulfillmentLocation: fulfillmentLocation,
                deliveryDetail: props.deliveryDetail,
                pickUpDateTime: getUTCDateAndTime()
              }, props.onItemsShipped)
                .then(() => setErrorWhileShipping(null))
                .catch(error => {
                  setErrorWhileShipping(error);
                  return null;
                })
                .then(() => {
                  setIsShipping(false);
                  setConfirmationScreenOpen(false);
                });
            }}>
            {t('common.confirm')}
          </TrackedButton>
          <TrackedButton item="shipping.confirmModal.cancel" className="btn btn-danger" onClick={() => setConfirmationScreenOpen(false)}>
            {t('common.cancel')}
          </TrackedButton>
        </Fragment>
      }
    >
      {t('shipping.itemsCancelledOrRejectedQuestion')}
    </TrackedModal>
  </div >;
};

interface ShipFormProps {
  accessToken: string;
  items: OrderItemWithStatusAndOrderInfo[];
  deliveryDetail?: DeliveryDetails;
  itemsShipQuantities: Record<string, number>;
  onItemsShipped: Function;
  onFulfillmentLocationSelected?: (fulfillmentLocationId: string) => void;
}

interface ShipFormData {
  shipmentId?: string;
  trackingId: string;
  trackingUrl: string;
  carrierServiceKey: string;
  fulfillmentLocation: FulfillmentLocation;
  deliveryDetail?: DeliveryDetails;
  pickUpDateTime?: string;
}
