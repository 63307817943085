import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { ArtworkChangeModal, IntegrationEnvironment, ProductionEnvironment } from '@cimpress-technology/react-artwork-change';
import { AppState } from '../../../store/store';
import { isProd } from '../../../utils/environmentProvider';

export const ArtworkChangeRequestButton: React.FC<ArtworkChangeRequestButtonProps> = ({ platformItemId, disabled, dropDown }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { auth } = useSelector((state: AppState) => state);
  const { accessToken } = auth;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const btnProps = {
    onClick: openModal,
    disabled: disabled || !platformItemId
  };

  const btnContent = <>
    {t('buttons.artworkChange')}
  </>;

  return <>
    <span onClick={e => e.stopPropagation()}>
      {platformItemId
        ? <ArtworkChangeModal
          modalOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onChange={() => {
            // Avoiding @typescript-eslint/no-empty-function failure
          }}
          itemId={platformItemId}
          mcpSku={'?'}
          accessToken={accessToken}
          environment={isProd ? ProductionEnvironment : IntegrationEnvironment}
        />
        : null}
    </span>

    {dropDown
      ? <button {...btnProps}>{btnContent}</button>
      : <TrackedButton item="artworkChange" {...btnProps} type={'default'}>{btnContent}</TrackedButton>}
  </>;
};

interface ArtworkChangeRequestButtonProps {
  platformItemId?: string;
  disabled?: boolean;
  dropDown?: boolean;
}
