import { Fulfiller } from '../store/fulfillers/types';

export const handleSingleFulfillerSelect = (currentlySelected: string[], newlySelectedFulfillerId: string): string[] => {
  // remove the selected fulfiller if already exists in multi-selection
  let currentFulfillers: string[] = (currentlySelected || []).filter(fId => fId !== newlySelectedFulfillerId);

  // then add it in front of the selection
  currentFulfillers = [newlySelectedFulfillerId].concat(currentFulfillers);

  // live happily ever after !
  return currentFulfillers;
};

export const determineFulfillerId = (fulfillers: Fulfiller[], fulfillerIdQueryParamKey: string, selectedFulfillers?: string[], searchString?: string): string | null => {
  if (searchString) {
    const search = new URLSearchParams(searchString);
    const fulfillerId = search.get(fulfillerIdQueryParamKey);
    const allowedFulfillerIds = fulfillers.map(f => f.fulfillerId);

    if (fulfillerId && allowedFulfillerIds.includes(fulfillerId)) {
      return fulfillerId;
    }
  }

  if (selectedFulfillers && selectedFulfillers.length !== 0) {
    return selectedFulfillers[0];
  }

  if (fulfillers.length !== 0) {
    return fulfillers[0].fulfillerId;
  }

  return null;
};
