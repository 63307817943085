import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { shapes } from '@cimpress/react-components';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { saveDocument } from '../../../utils/saveDocument';
import { showSnackbar } from '../../../store/snackbar/actions';
import { AppState } from '../../../store/store';
import { TrackedButton } from '../../../trackingLayer/trackedButton';

const { Spinner } = shapes;

const getPreferredItemId = (item: OrderItemWithStatusAndOrderInfo, useMerchantIds: boolean | undefined) => useMerchantIds
  ? item.merchantInformation?.itemId || item.itemId
  : item.itemId;

export const DownloadDocumentButton: React.FC<DownloadDocumentButtonProps> = props => {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();

  const { auth, settings } = useSelector((state: AppState) => state);
  const { accessToken } = auth;
  const { useMerchantIds } = settings;

  const getDocumentLink = (item: OrderItemWithStatusAndOrderInfo) => {
    if (props.documentLinkOverrideByItemId && props.documentLinkOverrideByItemId[item?.itemId]) {
      return props.documentLinkOverrideByItemId[item?.itemId];
    }
    return item?.links?.document?.href;
  };

  const onClick = async () => {
    if (!isSaving) {
      setIsSaving(true);
    }

    const failedArtworkRetrievals: { item: OrderItemWithStatusAndOrderInfo; err: any }[] = [];
    const successItemIds: string[] = [];

    const artworkPromises = props.items
      .filter(item => item)
      .map(item => saveDocument(accessToken, getDocumentLink(item), getPreferredItemId(item, useMerchantIds))
        .then(() => successItemIds.push(getPreferredItemId(item, useMerchantIds)))
        .catch(err => failedArtworkRetrievals.push({
          item,
          err
        }))
      );

    await Promise.all(artworkPromises);

    const artworkUnavailableItemIds = failedArtworkRetrievals
      .filter(pair => ['NoLink', 'DocumentFailure'].includes(pair.err?.errorCode))
      .map(pair => getPreferredItemId(pair.item, useMerchantIds));

    const errorItemIds = failedArtworkRetrievals
      .filter(pair => !['NoLink', 'DocumentFailure'].includes(pair.err?.errorCode))
      .map(pair => getPreferredItemId(pair.item, useMerchantIds));

    if (!artworkUnavailableItemIds.length && !errorItemIds.length) {
      props.dispatch(showSnackbar(t('buttons.document.success_title'), `${t('buttons.document.success_message')} ${successItemIds.join(', ')}`, 'success'));
    } else if (!errorItemIds.length) {
      props.dispatch(showSnackbar(t('buttons.document.unavailable_title'), `${t('buttons.document.unavailable_message')} ${artworkUnavailableItemIds[0]}`, 'warning'));
    } else {
      let detailsMessage = `${t('buttons.document.error_message')} ${errorItemIds.join(', ')}`;
      if (artworkUnavailableItemIds.length) {
        detailsMessage += ` ${t('buttons.document.error_message_unavailable')} ${artworkUnavailableItemIds.join(', ')}`;
      }
      props.dispatch(showSnackbar(t('buttons.document.error_title'), detailsMessage));
    }

    setIsSaving(false);
  };

  const btnProps = {
    disabled: isSaving || props.disabled,
    onClick: onClick
  };

  const btnContent = <>
    {isSaving ? <><Spinner size='small' className='inline' />&nbsp;</> : null}
    {t(isSaving ? 'buttons.downloading' : 'buttons.document.download_document')}
  </>;

  return (props.dropDown)
    ? <button {...btnProps}>{btnContent}</button>
    : <TrackedButton item="downloadDocument" {...btnProps} type={'default'}>{btnContent}</TrackedButton>;
};

interface DownloadDocumentButtonProps {
  items: OrderItemWithStatusAndOrderInfo[];
  documentLinkOverrideByItemId?: Record<string, string>;
  disabled?: boolean;
  dropDown?: boolean;
  dispatch: Function;
  artworkChange?: boolean;
}

