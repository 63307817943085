import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { shapes } from '@cimpress/react-components';
import { saveDocument } from '../../../utils/saveDocument';
import { showSnackbar } from '../../../store/snackbar/actions';
import { AppState } from '../../../store/store';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { PlatformOrderItem } from '../../../clients/platformClient/platformItemsModel';

const { Spinner } = shapes;

export interface DownloadDeliveryNoteButtonProps {
  items: PlatformOrderItem[];
  disabled?: boolean;
  dropDown?: boolean;
  dispatch: Function;
}

export const DownloadDeliveryNoteButton: React.FC<DownloadDeliveryNoteButtonProps> = props => {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();

  const { auth } = useSelector((state: AppState) => state);
  const { accessToken } = auth;

  const onClick = async () => {
    if (!isSaving) {
      setIsSaving(true);
    }

    const failedNoteRetrievals: { item: PlatformOrderItem; err: any }[] = [];
    const successItemIds: string[] = [];

    const deliveryNotesPromises = props.items
      .filter(item => item)
      .map(item =>
        saveDocument(accessToken, item?.deliveryNoteUrl as string, item.shortItemId)
          .then(() => successItemIds.push(item.shortItemId))
          .catch(err => failedNoteRetrievals.push({
            item,
            err
          }))
      );

    await Promise.all(deliveryNotesPromises);

    const noteUnavailableItemIds = failedNoteRetrievals
      .filter(pair => ['NoLink', 'DocumentFailure'].includes(pair.err?.errorCode))
      .map(pair => pair.item.itemId);

    const errorItemIds = failedNoteRetrievals
      .filter(pair => !['NoLink', 'DocumentFailure'].includes(pair.err?.errorCode))
      .map(pair => pair.item.itemId);

    if (!noteUnavailableItemIds.length && !errorItemIds.length) {
      props.dispatch(showSnackbar(t('buttons.document.success_title'), `${t('buttons.document.success_message')} ${successItemIds.join(', ')}`, 'success'));
    } else if (!errorItemIds.length) {
      props.dispatch(showSnackbar(t('buttons.document.unavailable_title'), `${t('buttons.document.unavailable_message')} ${noteUnavailableItemIds[0]}`, 'warning'));
    } else {
      let detailsMessage = `${t('buttons.document.error_message')} ${errorItemIds.join(', ')}`;
      if (noteUnavailableItemIds.length) {
        detailsMessage += ` ${t('buttons.document.error_message_unavailable')} ${noteUnavailableItemIds.join(', ')}`;
      }
      props.dispatch(showSnackbar(t('buttons.document.error_title'), detailsMessage));
    }

    setIsSaving(false);
  };

  const btnProps = {
    disabled: isSaving || props.disabled,
    onClick: onClick
  };

  const btnContent = <>
    {isSaving ? <><Spinner size='small' className='inline' />&nbsp;</> : null}
    {t(isSaving ? 'buttons.downloading' : 'buttons.document.download_delivery_note')}
  </>;

  return (props.dropDown)
    ? <button {...btnProps}>{btnContent}</button>
    : <TrackedButton item="downloadDeliveryNote" {...btnProps} type={'default'}>{btnContent}</TrackedButton>;
};
