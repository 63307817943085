import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentPlanAnalysisViewer } from '@cimpress-technology/shipment-planner-component';
import { Button } from '@cimpress/react-components';
import styled from 'styled-components';
import { TrackedModal } from '../../../trackingLayer/trackedModal';

const ActionLinkButton = styled(Button)`
  &.btn {
    border: none !important;
    background: transparent !important;
    padding: 0px;
    text-align: left;
    font-weight: normal;
  }

  &.btn:hover,
  &.btn:active,
  &.btn:active:hover {
    background: transparent;
    border: none !important;
    outline: none;
  }

  &.btn:focus {
    outline: none;
    outline-offset: unset;
  }
`;
export const ShipmentPlanViewer = ({ item, accessToken }) => {
  const { t } = useTranslation();
  const [showShipmentPlan, setShowShipmentPlan] = useState(false);

  const toggleModal = () => {
    setShowShipmentPlan(showPlan => !showPlan);
  };

  return (
    <span>
      <ActionLinkButton blockLevel onClick={toggleModal} >
        {t('details.shipmentPlan.ViewShipmentPlan')}
      </ActionLinkButton>
      <TrackedModal
        className='shipment-plan-viewer-modal'
        item={'shipmentPlan'}
        show={showShipmentPlan}
        title={t('details.shipmentPlan.title')}
        closeButton
        closeOnOutsideClick
        onRequestHide={toggleModal}
        bsSize={'lg'}
        footer={
          <Button className="btn btn-default" onClick={toggleModal}>
            <h5>{t('details.shipmentPlan.close')} &nbsp;</h5>
          </Button>
        }>
        <ShipmentPlanAnalysisViewer item={item} accessToken={accessToken} />
      </TrackedModal>
    </span>
  );
};

