import React from 'react';
import { LatestInvoicesContainer } from '@cimpress-technology/react-invoicing-widgets';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { Card, shapes } from '@cimpress/react-components';
import { useDateTimeFormatter } from '../../../utils/dateTimeUtils';
import './latestInvoicesWidgetWrapper.scss';

export const LatestInvoicesWidgetWrapper: React.FC = () => {
  const accessToken = useSelector<AppState, string>(state => state.auth.accessToken);
  const selectedFulfillers = useSelector<AppState, string[]>(state => state.fulfillers.selectedFulfillers);
  const selectedFulfillerId = selectedFulfillers ? selectedFulfillers[0] : '';
  const dateFormater = useDateTimeFormatter();
  const { t, i18n } = useTranslation();
  const { Spinner } = shapes;
  const dateFormatFunc = date => dateFormater(new Date(date));

  return (
    <>
      {
        !selectedFulfillerId
          ? <Card header={t('dashboard.invoices.panelName')} isMinimal={true}>
            <Spinner size={'medium'}/>
          </Card>
          : <LatestInvoicesContainer
            locale={i18n.language}
            dateFormatFunc={dateFormatFunc}
            accessToken={accessToken}
            identity={{
              id: selectedFulfillerId,
              type: 'Fulfiller'
            }}
          />
      }
    </>
  );
};
