
export const tableTranslations = t => {
  return {
    previousText: t('table.previousText'),
    nextText: t('table.nextText'),
    loadingText: t('table.loadingText'),
    noDataText: t('table.noDataText'),
    errorText: t('table.errorText'),
    pageText: t('table.pageText'),
    ofText: t('table.of'),
    rowsText: t('table.rowsText'),
    perPageLabelText: t('table.perPage'),
    goToLabelText: t('table.goTo')
  };
};
