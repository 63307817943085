import { apiRequest } from '../apiClient';
import { DeliveryRequest, PickupPoint } from './deliveryRequestModel';
import {
  getCachedResultByKey,
  getPendingPromiseByKey,
  setCachedResultAndClearPendingPromiseForKey,
  setPendingPromiseForKey
} from '../runningPromiseCache';

// 'https://delivery.commerce.cimpress.io/v1/deliveryrequests/ef2c54c0-256f-4e4e-a47f-4527ffa8ae5c',
export const getDeliveryRequest = async (accessToken, deliveryRequestUrl): Promise<DeliveryRequest|undefined> => {
  if (accessToken && deliveryRequestUrl) {
    const alreadyCached = getCachedResultByKey(deliveryRequestUrl);
    if (alreadyCached) {
      return alreadyCached;
    }

    let promise = getPendingPromiseByKey(deliveryRequestUrl);
    if (!promise) {
      promise = apiRequest<any>({
        url: deliveryRequestUrl,
        method: 'get',
        query: {},
        timeout: 30 * 1000,
        accessToken
      });
      setPendingPromiseForKey(deliveryRequestUrl, promise);
    }

    const response = (await promise);
  
    if (!response) {
      return undefined;
    }

    if (response.error) {
      setCachedResultAndClearPendingPromiseForKey(deliveryRequestUrl, response.error);
      throw response;
    }

    setCachedResultAndClearPendingPromiseForKey(deliveryRequestUrl, response.data);
    return response.data;
  }

  return undefined;
};

// https://pup.cimpress.io/v1/pickupPoints/pup%3Aproviders%3Apostnl%3A218120
export const getPickupRequest = async (accessToken, pickupPointUrl): Promise<PickupPoint|undefined> => {
  if (accessToken && pickupPointUrl) {
    const alreadyCached = getCachedResultByKey(pickupPointUrl);
    if (alreadyCached) {
      return alreadyCached;
    }

    let promise = getPendingPromiseByKey(pickupPointUrl);
    if (!promise) {
      promise = apiRequest<any>({
        url: pickupPointUrl,
        method: 'get',
        query: {},
        timeout: 30 * 1000,
        accessToken
      });
      setPendingPromiseForKey(pickupPointUrl, promise);
    }

    const response = (await promise);

    if (!response) {
      return undefined;
    }

    if (response.error) {
      setCachedResultAndClearPendingPromiseForKey(pickupPointUrl, response.error);
      throw response;
    }

    setCachedResultAndClearPendingPromiseForKey(pickupPointUrl, response.data);
    return response.data;
  }
  return undefined;
};
