import ReactGA from 'react-ga';

export const pathnameToCategory = (): string => {
  const pathname = window.location.pathname;

  // /items/M668CHC4CD
  if ((/^\/items\/([A-Z0-9]+)$/gi).test(pathname)) {
    return '/items/:itemId';
  }

  // /pendingActions/cancellations/b877a8b8-1394-4e9c-83f4-dde30a380653
  if ((/^\/pendingActions\/cancellations\/([A-Z0-9]+)$/gi).test(pathname)) {
    return '/pendingActions/cancellations/:cancellationId';
  }

  return pathname;
};

export const trackEvent = (item: string, action?: string, category?: string, value?: number): void => {
  ReactGA.event({
    category: category || pathnameToCategory(),
    action: action || 'Click',
    label: item,
    value: value
  });
};
