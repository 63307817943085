import { SnackbarProps } from '@cimpress/react-components';
import { SnackbarActions } from '../storeActions';

export const showSnackbar = (message: string, details?: string, snackbarType?: SnackbarProps['bsStyle']) => {
  return {
    type: SnackbarActions.SHOW_SNACKBAR,
    message,
    details,
    snackbarType: snackbarType || 'danger'
  };
};

export const hideSnackbar = () => {
  return {
    type: SnackbarActions.HIDE_SNACKBAR
  };
};

