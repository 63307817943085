import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextField } from '@cimpress/react-components';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import { notifyDelay } from '../../../store/items/actions';
import moment, { Moment } from 'moment';
import { OrderItemWithStatusAndOrderInfo } from '../../../clients/foma/itemsClient';
import { TrackedButton } from '../../../trackingLayer/trackedButton';
import { TrackedModal } from '../../../trackingLayer/trackedModal';
import { TrackedCheckbox } from '../../../trackingLayer/trackedCheckbox';
import { ResponsePostDelayItemsV1, ProcessDeviationReasonCategories } from '@cimpress-technology/supplier-integrations-client';

export const NotifyDelayButton: React.FC<NotifyDelayButtonProps> = ({ items, dropDown, className, disabled, refreshItemList }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const btnProps = {
    onClick: openModal,
    disabled: disabled,
    className: className
  };

  const btnContent = <>
    {t('buttons.notifyDelay')}
  </>;

  return <>
    <span onClick={e => e.stopPropagation()}>
      <NotifyDelayModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        refreshItemList={refreshItemList}
        earliestDelayedDate={moment().startOf('hours')}
        items={items} />
    </span>

    {dropDown
      ? <button {...btnProps}>{btnContent}</button>
      : <TrackedButton item="notifyDelay" {...btnProps} type={'link'}>{btnContent}</TrackedButton>}
  </>;
};

interface NotifyDelayButtonProps {
  items: OrderItemWithStatusAndOrderInfo[];
  className?: string;
  disabled?: boolean;
  refreshItemList: boolean;
  dropDown?: boolean;
}

const NotifyDelayModal: React.FC<NotifyDelayModalContentsProps> = ({ isOpen, items, closeModal, earliestDelayedDate, refreshItemList }) => {
  const { t } = useTranslation();
  const [isInventoryEnabled, setIsInventoryEnabled] = useState(false);
  const [isProductEnabled, setIsProductEnabled] = useState(false);
  const [isLogisticsEnabled, setIsLogisticsEnabled] = useState(false);
  const [isProductionEnabled, setIsProductionEnabled] = useState(false);
  const [isPrepressEnabled, setIsPrepressEnabled] = useState(false);
  const [isReceivedLateEnabled, setIsReceivedLateEnabled] = useState(false);
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [expectedShipDate, setExpectedShipDate] = useState(earliestDelayedDate);

  const lastUnselectableDate = moment(earliestDelayedDate).subtract(1, 'day');

  const dispatch = useDispatch();

  const onConfirmDelay = () => {
    closeModal();

    const reasonCategories: string[] = Object.entries({
      Inventory: isInventoryEnabled,
      Product: isProductEnabled,
      CarrierOrLogistics: isLogisticsEnabled,
      InternalProduction: isProductionEnabled,
      Prepress: isPrepressEnabled,
      ReceivedLate: isReceivedLateEnabled
    })
      .filter(pair => pair[1] === true)
      .map(pair => pair[0]);

    const reasonsToDefaultInformation = {
      Inventory: t('items.actions.notifyDelay.reasons.inventory'),
      Product: t('items.actions.notifyDelay.reasons.product'),
      CarrierOrLogistics: t('items.actions.notifyDelay.reasons.logistics'),
      InternalProduction: t('items.actions.notifyDelay.reasons.production'),
      Prepress: t('items.actions.notifyDelay.reasons.prepress'),
      ReceivedLate: t('items.actions.notifyDelay.reasons.receivedLate')
    };

    const delayOptions: Omit<ResponsePostDelayItemsV1['delayItems'], 'items'> = {
      reasonCategories: reasonCategories as ProcessDeviationReasonCategories[],
      unstructuredReason: additionalInformation || reasonCategories
        .map(reason => reasonsToDefaultInformation[reason]).join(', ') || undefined,
      expectedShipDate: expectedShipDate.toISOString()
    };

    dispatch(notifyDelay(items, delayOptions, refreshItemList));
  };

  const ModalFooter: React.FC = () => {
    return (
      <Fragment>
        <TrackedButton item="notifyDelayModal.cancel" className="btn btn-default" onClick={closeModal}>
          {t('items.actions.notifyDelay.modalFooterButtons.cancel')}
        </TrackedButton>
        <TrackedButton item="notifyDelayModal.markAsDelayed" className="btn btn-warning" onClick={onConfirmDelay}>
          {t('items.actions.notifyDelay.modalFooterButtons.markAsDelayed')}
        </TrackedButton>
      </Fragment>
    );
  };

  return (
    <TrackedModal item={'notifyDelayModal'}
      bsStyle='warning'
      className={'overflow-modal'}
      show={isOpen}
      title={t('items.actions.notifyDelay.modalTitle')}
      closeButton={true}
      onRequestHide={closeModal}
      footer={<ModalFooter />}>
      <div>
        <strong>{t('items.actions.notifyDelay.whyQuestion')}</strong>
        <TrackedCheckbox
          item={'notifyDelayModal.inventoryToggle'}
          checked={isInventoryEnabled}
          onChange={() => setIsInventoryEnabled(!isInventoryEnabled)} label={t('items.actions.notifyDelay.reasons.inventory')} />
        <TrackedCheckbox
          item={'notifyDelayModal.inventoryToggle'}
          checked={isProductEnabled}
          onChange={() => setIsProductEnabled(!isProductEnabled)} label={t('items.actions.notifyDelay.reasons.product')} />
        <TrackedCheckbox
          item={'notifyDelayModal.logisticsToggle'}
          checked={isLogisticsEnabled}
          onChange={() => setIsLogisticsEnabled(!isLogisticsEnabled)} label={t('items.actions.notifyDelay.reasons.logistics')} />
        <TrackedCheckbox
          item={'notifyDelayModal.productionToggle'}
          checked={isProductionEnabled}
          onChange={() => setIsProductionEnabled(!isProductionEnabled)} label={t('items.actions.notifyDelay.reasons.production')} />
        <TrackedCheckbox
          item={'notifyDelayModal.prepressToggle'}
          checked={isPrepressEnabled}
          onChange={() => setIsPrepressEnabled(!isPrepressEnabled)} label={t('items.actions.notifyDelay.reasons.prepress')} />
        <TrackedCheckbox
          item={'notifyDelayModal.receivedLateToggle'}
          checked={isReceivedLateEnabled}
          onChange={() => setIsReceivedLateEnabled(!isReceivedLateEnabled)} label={t('items.actions.notifyDelay.reasons.receivedLate')} />
      </div>
      <br />
      <TextField
        value={additionalInformation}
        onChange={(e => setAdditionalInformation(e.target.value))}
        label={t('items.actions.notifyDelay.additionalInformationTitle')}
        helpText={t('items.actions.notifyDelay.additionalInformation')} />
      <div>
        <DatePicker
          value={expectedShipDate}
          isValidDate={current => current.isAfter(lastUnselectableDate)}
          placeholder={t('items.actions.notifyDelay.expectedShippingDateTitle')}
          closeOnSelect={true}
          onChange={date => setExpectedShipDate(moment(date))} />
      </div>
      <small className={'help-block'}>{t('items.actions.notifyDelay.expectedShippingDate')}</small>
      <hr />
      <ul>
        <li>{t('items.actions.notifyDelay.reasonSelectionText')}</li>
      </ul>
    </TrackedModal>
  );
};

interface NotifyDelayModalContentsProps {
  isOpen: boolean;
  items: OrderItemWithStatusAndOrderInfo[];
  closeModal: () => void;
  earliestDelayedDate: Moment;
  refreshItemList: boolean;
}
