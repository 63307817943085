import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { ErrorComponent } from '../errorComponent';
import { Alert, BreadcrumbItem, Breadcrumbs, Card, NavTab, NavTabItem } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { NotificationTypes } from '../../clients/foma/notificationActionClient';
import { CancellationsTable } from './cancellationsTable';
import { AddressChangesTable } from './addressChangesTable';
import { ArtworkChangesTable } from './artworkChangesTable';
import { ShipmentRequirementChangesTable } from './shipmentRequirementChangesTable';
import { FulfillerMultiSelectWrapper } from '../shared/fulfillerMultiSelectWrapper';
import { Fulfiller } from '../../store/fulfillers/types';
import {
  acceptAction,
  getNotifications,
  rejectAction
} from '../../store/notifications/actions';
import { TrackedLocalLink } from '../../trackingLayer/trackedLocalLink';
import { PrettyHeaderButton } from '../items/prettyHeaderButton';
import { IconUndo } from '@cimpress-technology/react-streamline-icons';
import { ActionTypeKeysEnum } from '@cimpress-technology/supplier-integrations-client';

const CANCELLATIONS_TAB = 0;
const ADDRESS_CHANGES_TAB = 1;
const ARTWORK_CHANGES_TAB = 2;
const SHIPMENT_REQUIREMENT_CHANGES_TAB = 3;

export const PendingActionsPage: React.FC = () => {
  const PAGE_SIZE = 10;
  const { data: fulfillers } = useSelector((state: AppState) => state.fulfillers.fulfillers);
  const { selectedFulfillers } = useSelector((state: AppState) => state.fulfillers);
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const { useMerchantIds } = useSelector((state: AppState) => state.settings);
  const storeNotifications = useSelector((state: AppState) => (state.notifications || {}));
  const notifications = useSelector((state: AppState) => Object.values((state.notifications || {}).notifications || {}));
  const notificationsMap = useSelector((state: AppState) => (state.notifications || {}).notifications || {});
  const actionRunning = useSelector((state: AppState) => state.notifications?.isLoading || false);

  const { t } = useTranslation();
  const errors = useSelector((state: AppState) => state.notifications.errors);
  const areNotificationsLoading = useSelector((state: AppState) => state.notifications.isLoading);

  const [selectedTab, setSelectedTab] = useState(0);
  const [resetWidthsCounter, setResetWidthsCounter] = useState<number>(0);

  const addressChangeRequests = notifications
    .filter(n => n.type === NotificationTypes.ChangeRequest)
    .sort((a, b) => -a.createdDate.localeCompare(b.createdDate));

  const artworkChangeRequests = notifications
    .filter(n => n.type === NotificationTypes.ArtworkChangeRequest)
    .sort((a, b) => -a.createdDate.localeCompare(b.createdDate));

  const shipmentRequirementChangeRequests = notifications
    .filter(n => n.type === NotificationTypes.ShipmentRequirementChangeRequest)
    .sort((a, b) => -a.createdDate.localeCompare(b.createdDate));

  const cancellationRequests = notifications
    .filter(n => n.type === NotificationTypes.CancellationRequest)
    .sort((a, b) => -a.createdDate.localeCompare(b.createdDate));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!storeNotifications.isLoading && !storeNotifications.notifications && !storeNotifications.errors) {
      dispatch(getNotifications(selectedFulfillers));
    }
  });

  let content: ReactNode = null;
  switch (selectedTab) {
    case CANCELLATIONS_TAB:
      content = <>
        {cancellationRequests.length
          ? (
            <div className={'flexChild statusLineWrapper'} style={{ whiteSpace: 'nowrap' }}>
              <span>{t('pendingActions.showing', { count: Math.min(PAGE_SIZE, cancellationRequests.length), total: cancellationRequests.length })} </span>
              <PrettyHeaderButton
                icon={IconUndo}
                label={t('table.resetWidths')}
                activeLabel={t('table.resettingWidths')}
                onClick={() => setResetWidthsCounter(current => current + 1)} />
            </div>
          ) : null}
        {cancellationRequests.length > 0
          ? <CancellationsTable
            pageSize={PAGE_SIZE}
            disabled={actionRunning}
            isLoading={areNotificationsLoading}
            resetWidthsCounter={resetWidthsCounter}
            cancellationRequests={cancellationRequests}
            useMerchantIds={!!useMerchantIds}
            onSelectionChanged={() => {
              // setSelectedCancellationsMap(notificationMap);
            }}
            onAcceptWithCost={notificationId => {
              dispatch(acceptAction({
                actionTypeKey: ActionTypeKeysEnum.AcceptCancellationRequest,
                notificationUrl: notificationsMap[notificationId].links.self.href,
                costsIncurred: true
              }));
            }}
            onAcceptWithoutCost={notificationId => {
              dispatch(acceptAction({
                actionTypeKey: ActionTypeKeysEnum.AcceptCancellationRequest,
                notificationUrl: notificationsMap[notificationId].links.self.href,
                costsIncurred: false
              }));
            }}
            onReject={notificationId => {
              dispatch(rejectAction({
                actionTypeKey: ActionTypeKeysEnum.RejectCancellationRequest,
                notificationUrl: notificationsMap[notificationId].links.self.href
              }));
            }}
          />
          : <Alert type={'info'} message={t('pendingActions.noDataText')} dismissible={false} />}
      </>;
      break;
    case ADDRESS_CHANGES_TAB:
      content = <>
        {addressChangeRequests.length
          ? (
            <div className={'flexChild statusLineWrapper'} style={{ whiteSpace: 'nowrap' }}>
              <span>{t('pendingActions.showing', { count: Math.min(PAGE_SIZE, addressChangeRequests.length), total: addressChangeRequests.length })} </span>
              <PrettyHeaderButton
                icon={IconUndo}
                label={t('table.resetWidths')}
                activeLabel={t('table.resettingWidths')}
                onClick={() => setResetWidthsCounter(current => current + 1)} />
            </div>
          ) : null}
        {addressChangeRequests.length > 0
          ? <AddressChangesTable
            pageSize={PAGE_SIZE}
            isLoading={areNotificationsLoading}
            resetWidthsCounter={resetWidthsCounter}
            useMerchantIds={!!useMerchantIds}
            addressChangeRequests={addressChangeRequests}
            onSelectionChanged={() => {
              // setSelectedAddressChangesMap(notificationMap);
            }}
            onAccept={notificationId => {
              dispatch(acceptAction({
                actionTypeKey: ActionTypeKeysEnum.AcceptAddressChangeRequest,
                notificationUrl: notificationsMap[notificationId].links.self.href,
                costsIncurred: false
              }));
            }}
            onReject={notificationId => {
              dispatch(rejectAction({
                actionTypeKey: ActionTypeKeysEnum.RejectAddressChangeRequest,
                notificationUrl: notificationsMap[notificationId].links.self.href
              }));
            }}
          />
          : <Alert type={'info'} message={t('pendingActions.noDataText')} dismissible={false} />
        }
      </>;
      break;
    case ARTWORK_CHANGES_TAB:
      content = <>
        {artworkChangeRequests.length
          ? (
            <div className={'flexChild statusLineWrapper'} style={{ whiteSpace: 'nowrap' }}>
              <span>{t('pendingActions.showing', { count: Math.min(PAGE_SIZE, artworkChangeRequests.length), total: artworkChangeRequests.length })} </span>
              <PrettyHeaderButton
                icon={IconUndo}
                label={t('table.resetWidths')}
                activeLabel={t('table.resettingWidths')}
                onClick={() => setResetWidthsCounter(current => current + 1)} />
            </div>
          ) : null}
        {artworkChangeRequests.length > 0
          ? <ArtworkChangesTable
            pageSize={PAGE_SIZE}
            isLoading={areNotificationsLoading}
            resetWidthsCounter={resetWidthsCounter}
            useMerchantIds={!!useMerchantIds}
            artworkChangeRequests={artworkChangeRequests}
            onSelectionChanged={() => {
              // setSelectedAddressChangesMap(notificationMap);
            }}
            onAccept={(notificationId, itemId, withCosts: boolean) => {
              const notification = notificationsMap[notificationId];
              const notificationItem = notification.items.find(it => it.itemId === itemId);
              if (notificationItem) {
                dispatch(acceptAction({
                  actionTypeKey: ActionTypeKeysEnum.AcceptArtworkChangeRequest,
                  notificationUrl: notification.links.self.href,
                  itemId: notificationItem.itemId,
                  costsIncurred: withCosts
                }));
              }
            }}
            onReject={(notificationId, itemId) => {
              const notification = notificationsMap[notificationId];
              const notificationItem = notification.items.find(it => it.itemId === itemId);
              if (notificationItem) {
                dispatch(rejectAction({
                  actionTypeKey: ActionTypeKeysEnum.RejectArtworkChangeRequest,
                  notificationUrl: notification.links.self.href,
                  itemId: notificationItem.itemId
                }));
              }
            }}
          />
          : <Alert type={'info'} message={t('pendingActions.noDataText')} dismissible={false} />
        }
      </>;
      break;
    case SHIPMENT_REQUIREMENT_CHANGES_TAB:
      content = <>
        {shipmentRequirementChangeRequests.length
          ? (
            <div className={'flexChild statusLineWrapper'} style={{ whiteSpace: 'nowrap' }}>
              <span>{t('pendingActions.showing', { count: Math.min(PAGE_SIZE, shipmentRequirementChangeRequests.length), total: shipmentRequirementChangeRequests.length })} </span>
              <PrettyHeaderButton
                icon={IconUndo}
                label={t('table.resetWidths')}
                activeLabel={t('table.resettingWidths')}
                onClick={() => setResetWidthsCounter(current => current + 1)} />
            </div>
          ) : null}
        {shipmentRequirementChangeRequests.length > 0
          ? <ShipmentRequirementChangesTable
            pageSize={PAGE_SIZE}
            isLoading={areNotificationsLoading}
            resetWidthsCounter={resetWidthsCounter}
            useMerchantIds={!!useMerchantIds}
            shipmentRequirementChangeRequests={shipmentRequirementChangeRequests}
            onSelectionChanged={() => {
              // setSelectedAddressChangesMap(notificationMap);
            }}
            onAccept={notificationId => {
              dispatch(acceptAction({
                actionTypeKey: ActionTypeKeysEnum.AcceptShipmentRequirementChangeRequest,
                notificationUrl: notificationsMap[notificationId].links.self.href,
                costsIncurred: false
              }));
            }}
            onReject={notificationId => {
              dispatch(rejectAction({
                actionTypeKey: ActionTypeKeysEnum.RejectShipmentRequirementChangeRequest,
                notificationUrl: notificationsMap[notificationId].links.self.href
              }));
            }}
          />
          : <Alert type={'info'} message={t('pendingActions.noDataText')} dismissible={false} />
        }
      </>;
      break;
    default:
      content = null;
      break;
  }

  return (
    <div className={'container-fluid'}>

      {(errors && errors.length > 0) && <ErrorComponent errors={errors} />}

      <div className={'row'}>
        <div className={'col-md-6'}>
          <Breadcrumbs>
            <BreadcrumbItem>
              <TrackedLocalLink item={'breadcrumbs.home.items'} to={'/'}>{t('breadcrumbs.home')}</TrackedLocalLink>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {t('breadcrumbs.pendingActions')}
            </BreadcrumbItem>
          </Breadcrumbs>
        </div>
        <div className={'col-md-6'}>
          <FulfillerMultiSelectWrapper
            accessToken={accessToken}
            fulfillers={fulfillers as Fulfiller[]}
            selectedFulfillerIds={selectedFulfillers}
          />
        </div>
      </div>

      <Card>
        <NavTab>
          <NavTabItem active={selectedTab === CANCELLATIONS_TAB}>
            <button onClick={() => {
              setSelectedTab(CANCELLATIONS_TAB);
            }}>
              {t('pendingActions.cancellations.header')} <span className={'badge badge-default'}>{cancellationRequests.length}</span>
            </button>
          </NavTabItem>
          <NavTabItem active={selectedTab === ADDRESS_CHANGES_TAB}>
            <button onClick={() => {
              setSelectedTab(ADDRESS_CHANGES_TAB);
            }}>
              {t('pendingActions.addressChange.header')} <span className={'badge badge-default'}>{addressChangeRequests.length}</span>
            </button>
          </NavTabItem>
          <NavTabItem active={selectedTab === ARTWORK_CHANGES_TAB}>
            <button onClick={() => {
              setSelectedTab(ARTWORK_CHANGES_TAB);
            }}>
              {t('pendingActions.artworkChange.header')} <span className={'badge badge-default'}>{artworkChangeRequests.length}</span>
            </button>
          </NavTabItem>
          <NavTabItem active={selectedTab === SHIPMENT_REQUIREMENT_CHANGES_TAB}>
            <button onClick={() => {
              setSelectedTab(SHIPMENT_REQUIREMENT_CHANGES_TAB);
            }}>
              {t('pendingActions.shipmentRequirementChange.header')} <span className={'badge badge-default'}>{shipmentRequirementChangeRequests.length}</span>
            </button>
          </NavTabItem>
        </NavTab>

        {content}
      </Card>
    </div>
  );
};
