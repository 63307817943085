import { JobsheetPresetActionTypes } from './types';
import { JobsheetPresetActions } from '../storeActions';

export interface JobsheetPreset {
  id: string;
  name: string;
  type: string;
}

export interface JobsheetPresetResults {
  entity: {
    entityId: string;
    entityType: string;
  };
  presets: JobsheetPreset[];
}

export interface JobsheetPresetState {
  isLoading: boolean;
  data: JobsheetPresetResults;
  error: any;
}

export interface JobsheetPresetsState {
  presetsByFulfiller: Record<string, JobsheetPresetState>;
}

const initialState: JobsheetPresetsState = {
  presetsByFulfiller: {
  }
};

export function jobsheetPresetsReducer(state = initialState, action: JobsheetPresetActionTypes): JobsheetPresetsState {
  const newPresetsByFulfiller = JSON.parse(JSON.stringify(state.presetsByFulfiller));
  switch (action.type) {
    case JobsheetPresetActions.GET_JOBSHEET_PRESET_REQUEST:
      newPresetsByFulfiller[action.fulfillerId] = {
        isLoading: true
      };
      return {
        ...state,
        presetsByFulfiller: newPresetsByFulfiller
      };
    case JobsheetPresetActions.GET_JOBSHEET_PRESET_SUCCESS:
      newPresetsByFulfiller[action.fulfillerId] = {
        isLoading: false,
        data: action.data
      };
      return {
        ...state,
        presetsByFulfiller: newPresetsByFulfiller
      };
    case JobsheetPresetActions.GET_JOBSHEET_PRESET_ERROR:
      newPresetsByFulfiller[action.fulfillerId] = {
        isLoading: false,
        data: null,
        error: action.error
      };
      return {
        ...state,
        presetsByFulfiller: newPresetsByFulfiller
      };
    default:
      return state;
  }
}
