import dayjs from 'dayjs';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { ItemAggregations } from '../../../clients/foma/itemAgreggationResponseModel';
import styles from '../../../styles/mcpColors.module.scss';

export const colors = styles.chartColors
  .split(',')
  .map(color => color.trim());

export const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      title() {
        return '';
      }
    }
  },
  scales: {
    yAxes: [{
      stacked: true,
      scaleLabel: {
        display: true
      },
      ticks: { beginAtZero: true }
    }],
    xAxes: [{
      stacked: true,
      ticks: {
        beginAtZero: true
      }
    }]
  }
};

export const createDataSet = (label: string, backgroundColor: string[], chartData: number[]): ChartDataSets => ({ label, backgroundColor, data: chartData });

export const fillMissingChartData = (labels: string[], aggregations: ItemAggregations[], accessor: string): number[] => {
  return labels.map(label => {
    const dailyData = aggregations.find(data => data.key === label);
    return dailyData ? dailyData[accessor] : 0;
  });
};

export const getDateLabels = (): string[] => {
  const labels = Array(7).fill(undefined);
  labels.forEach((value, index) => {
    labels[index] = dayjs().add(index, 'day').startOf('day').format('YYYY-MM-DD');
  });
  return labels;
};

// Get deterministic color set based on total number of categories
export const getRandomColor = (total: number, current: number) => {
  if (total > colors.length / 2) {
    return colors[current];
  }

  const stepSize = Math.floor(colors.length / total);
  return colors[current * stepSize];
};

export function limitProductCategories(productCategories: ItemAggregations[], limit: number) {
  if (productCategories.length < limit) {
    return productCategories;
  }

  const byCount = productCategories.slice().sort((a, b) => b.total - a.total);
  const categoriesToShow = byCount.splice(0, limit - 1);
  const othersCategory = byCount.reduce((others, category) => {
    others.total += category.total;
    others.quantity += category.quantity;
    others.aggregations = mergeAggregations(others.aggregations, category.aggregations);
    return others;
  }, {
    key: 'Other',
    total: 0,
    quantity: 0,
    aggregations: []
  });
  categoriesToShow.push(othersCategory);

  return categoriesToShow;
}

export const createEmptyAggregationRow = () => {
  const newAggregations: ItemAggregations[] = [];
  newAggregations.push({ key: 'late', total: 0, quantity: 0, aggregations: [] });
  getDateLabels().forEach(dt => {
    newAggregations.push({ key: dt, total: 0, quantity: 0, aggregations: [] });
  });
  newAggregations.push({ key: 'future', total: 0, quantity: 0, aggregations: [] });
  newAggregations.push({ key: 'total', total: 0, quantity: 0, aggregations: [] });
  return JSON.parse(JSON.stringify(newAggregations));
};

export const createLateAndFutureColumns = aggregatonDataByDateAndCategory => {
  const totalAggregationRow: ItemAggregations = {
    key: 'rowTotal',
    total: 0,
    quantity: 0,
    aggregations: createEmptyAggregationRow()
  };

  const dateLabels = getDateLabels();
  const firstDate = dateLabels[0];
  const lastDate = dateLabels[dateLabels.length - 1];

  // Group LATE & FUTURE
  const groupedFilteredData = aggregatonDataByDateAndCategory.map(categoryRow => {
    const newAggregations: ItemAggregations[] = createEmptyAggregationRow();
    categoryRow.aggregations.forEach(byDate => {
      if (byDate.key.localeCompare(firstDate) < 0) {
        // late is '0'
        newAggregations[0].total += byDate.total;
        newAggregations[0].quantity += byDate.quantity;
      } else if (byDate.key.localeCompare(lastDate) > 0) {
        // future is 'last' - 1
        newAggregations[newAggregations.length - 2].total += byDate.total;
        newAggregations[newAggregations.length - 2].quantity += byDate.quantity;
      } else {
        const dateAgg = newAggregations.find(a => a.key === byDate.key);
        if (dateAgg) {
          dateAgg.total = byDate.total;
          dateAgg.quantity = byDate.quantity;
        }
      }
      // total is 'last'
      newAggregations[newAggregations.length - 1].total += byDate.total;
      newAggregations[newAggregations.length - 1].quantity += byDate.quantity;
    });

    for (let i = 0; i < totalAggregationRow.aggregations.length; i++) {
      totalAggregationRow.aggregations[i].total += newAggregations[i].total;
      totalAggregationRow.aggregations[i].quantity += newAggregations[i].quantity;
    }
    return {
      total: categoryRow.total,
      key: categoryRow.key,
      aggregations: newAggregations
    };
  });

  return {
    groupedData: groupedFilteredData,
    totalAggregationRow: totalAggregationRow
  };
};

export const createNonCategorizedAggregation = aggregationByDate => {
  const nonCateg = createEmptyAggregationRow();

  const dateLabels = getDateLabels();
  const firstDate = dateLabels[0];
  const lastDate = dateLabels[dateLabels.length - 1];

  aggregationByDate.forEach(byDate => {
    if (byDate.key.localeCompare(firstDate) < 0) {
      // late is '0'
      nonCateg[0].total += byDate.total;
      nonCateg[0].quantity += byDate.quantity;
    } else if (byDate.key.localeCompare(lastDate) > 0) {
      // future is 'last' - 1
      nonCateg[nonCateg.length - 2].total += byDate.total;
      nonCateg[nonCateg.length - 2].quantity += byDate.quantity;
    } else {
      const dateAgg = nonCateg.find(a => a.key === byDate.key);
      if (dateAgg) {
        dateAgg.total = byDate.total;
        dateAgg.quantity = byDate.quantity;
      }
    }
    // total is 'last'
    nonCateg[nonCateg.length - 1].total += byDate.total;
    nonCateg[nonCateg.length - 1].quantity += byDate.quantity;
  });

  return nonCateg;
};

function mergeAggregations(a: ItemAggregations[], b: ItemAggregations[]): ItemAggregations[] {
  const merged: ItemAggregations[] = [];
  a.concat(b).forEach(aggregate => {
    const existingIndex = merged.findIndex(mergedAggregate => mergedAggregate.key === aggregate.key);
    if (existingIndex === -1) {
      merged.push({ ...aggregate });
    } else {
      merged[existingIndex].total += aggregate.total;
      merged[existingIndex].quantity += aggregate.quantity;
    }
  });
  return merged;
}
