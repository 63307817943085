import React from 'react';
import { CopyToClipboardIcon } from './buttons/copyToClipboardIcon';
import { useTranslation } from 'react-i18next';
import { colors } from '@cimpress/react-components';
import { Link } from 'react-router-dom';

interface IdFieldProps {
  id: string;
  linkTo?: string;
  enableCopy?: boolean;
}

const renderLink = (c, to) => {
  if (!to) {
    return c;
  }
  return <Link to={to}>{c}</Link>;
};

export const IdField: React.FC<IdFieldProps> = ({ id, linkTo, enableCopy = true }) => {
  const { t } = useTranslation();

  if (!id) {
    return <em style={{ color: colors.slate }}>{t('common.not-available')}</em>;
  }

  return <span>
    {renderLink(<code style={{ marginRight: '2px' }}>{id}</code>, linkTo)}
    {enableCopy && <CopyToClipboardIcon toCopy={id}/>}
  </span>;
};
