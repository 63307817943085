import React, { useState } from 'react';
import { Modal, ModalProps } from '@cimpress/react-components';
import ReactGA from 'react-ga';
import { pathnameToCategory } from './utils';

export interface TrackedModalProps extends ModalProps {
  item: string;
}

export const TrackedModal: React.FC<TrackedModalProps> = props => {
  const { item, show, ...modalProps } = props;
  const [reported, setReported] = useState(false);

  if (show) {
    if (!reported) {
      const url = pathnameToCategory();
      ReactGA.modalview(`${url}@${item}`);
      setReported(true);
    }
  }
  return <Modal show={show} {...modalProps}>
    {props.children}
  </Modal>;
};
