import { OrderItemWithStatusAndOrderInfo } from '../../clients/foma/itemsClient';

export const isAcceptDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).every(item => {
    const newQuantity = item?.status?.statusDetails?.new?.quantity || 0;
    return newQuantity > 0 && !(item?.status?.statusDetails?.accepted);
  });
};

export const isRejectDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).some(item => {
    const statusDetails = (item?.status || {}).statusDetails;
    return statusDetails && !!(statusDetails.accepted || statusDetails.production || statusDetails.new || statusDetails.unknown) && !statusDetails.cancelled;
  });
};

export const isSetToProductionDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).some(item => {
    const statusDetails = (item?.status || {}).statusDetails;
    const enabled = statusDetails && !!(statusDetails.accepted || statusDetails.new || statusDetails.unknown);
    return enabled;
  });
};

export const isNotifyDelayDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || !items.filter(item => item).some(item => {
    const statusDetails = (item?.status || {}).statusDetails;
    return statusDetails && !!(statusDetails.accepted || statusDetails.new || statusDetails.production || statusDetails.unknown);
  });
};

export const isJobsheetDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  return !items.filter(item => item).length || items.some(item => item?.status.statusDetails?.shipped?.quantity === item.orderedQuantity);
};

export const isShipDisabled = (item: OrderItemWithStatusAndOrderInfo): boolean => {
  const statusDetails = (item?.status || {}).statusDetails;
  const orderedQuantity = item?.orderedQuantity;
  return item?.isTestItem || (statusDetails?.cancelled?.quantity || 0) >= orderedQuantity || !!statusDetails?.rejected;
};

export const isBulkShipDisabled = (items: OrderItemWithStatusAndOrderInfo[]): boolean => {
  if (!items.filter(item => item).length) {
    return true;
  }

  return items
    .filter(item => item)
    .some(item => {
      const statusDetails = (item?.status || {}).statusDetails;
      const orderedQuantity = item.orderedQuantity;
      return item.isTestItem || (statusDetails?.cancelled?.quantity || 0) >= orderedQuantity;
    });
};
