import { NotificationsActionTypes, NotificationsState } from './types';
import { NotificationsActions } from '../storeActions';
import { Notification } from '../../clients/foma/notificationActionClient';

const initialState: NotificationsState = {
  isLoading: false,
  errors: null,
  itemsData: {}
};

const combineNotifications = (arr1: Record<string, Notification>, arr2: Record<string, Notification>): Record<string, Notification> => {
  const result: Record<string, Notification> = {};
  Object.values(arr1).forEach(n => {
    result[n.notificationId] = JSON.parse(JSON.stringify(n));
  });
  Object.values(arr2).forEach(n => {
    result[n.notificationId] = JSON.parse(JSON.stringify(n));
  });
  return result;
};

export function notificationsReducer(state = initialState, action: NotificationsActionTypes): NotificationsState {
  switch (action.type) {
    case NotificationsActions.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: null
      };
    case NotificationsActions.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case NotificationsActions.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        groupedNotifications: action.groupedNotifications,
        notifications: action.notifications,
        itemsData: action.itemsData
      };
    case NotificationsActions.GET_NOTIFICATIONS_FOR_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: null
      };
    case NotificationsActions.GET_NOTIFICATIONS_FOR_ITEM_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };
    case NotificationsActions.GET_NOTIFICATIONS_FOR_ITEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        notifications: combineNotifications(state.notifications || {}, action.notifications)
      };

    case NotificationsActions.HANDLE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        errors: null
      };

    case NotificationsActions.HANDLE_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.errors
      };

    case NotificationsActions.HANDLE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: null
      };
    default:
      return state;
  }
}
