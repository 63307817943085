import { Card } from '@cimpress/react-components';
import React from 'react';
import { Notification } from '../../../clients/foma/notificationActionClient';
import { useTranslation } from 'react-i18next';
import { useDateTimeFormatter } from '../../../utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { Fulfiller } from '../../../store/fulfillers/types';

interface CancellationHeaderProps {
  notification: Notification;
  orderInfo: Record<string, any>;
}

export const CancellationHeader: React.FC<CancellationHeaderProps> = ({ orderInfo }) => {
  const { t } = useTranslation();
  const dateFormatter = useDateTimeFormatter();
  const dateFormatFunc = date => dateFormatter(new Date(date));
  const { data: fulfillers } = useSelector((state: AppState) => state.fulfillers?.fulfillers);

  if (!orderInfo) {
    // TBD
    return null;
  }

  const fulfillerInfo = (fulfillers?.find(f => f.fulfillerId === orderInfo.fulfiller.fulfillerId) || {}) as Fulfiller;

  return <>
    <div className="card-deck">

      <Card header={<h3>{t('cancellationPage.headers.order')}</h3>} isMinimal>
        <div className={'row'}>
          <div className={'col-md-6'}>
            {t('cancellationPage.orderDate')}<br/>
            {t('cancellationPage.promisedArrival')}<br/>
            {t('cancellationPage.orderId')}<br/>
            {t('cancellationPage.address')}<br/>
          </div>
          <div className={'col-md-6'}>
            {dateFormatFunc(orderInfo.createdDate)}<br/>
            {dateFormatFunc(orderInfo.promisedArrivalDate)}<br/>
            {orderInfo.orderId}<br/>
            {orderInfo.destinationAddress.firstName} {orderInfo.destinationAddress.lastName}<br/>
            {orderInfo.destinationAddress.street1}<br/>
            {orderInfo.destinationAddress.country}-{orderInfo.destinationAddress.postalCode} {orderInfo.destinationAddress.city}<br/>
            {orderInfo.destinationAddress.stateOrProvince}<br/>
          </div>
        </div>
      </Card>

      <Card header={<h3>{t('cancellationPage.headers.merchant')}</h3>} isMinimal>
        <div className={'row'}>
          <div className={'col-md-6'}>
            {t('cancellationPage.merchant.name')}<br/>
            {t('cancellationPage.merchant.orderId')}<br/>
            {t('cancellationPage.merchant.email')}<br/>
            {t('cancellationPage.merchant.phone')}<br/>
          </div>
          <div className={'col-md-6'}>
            {orderInfo.merchantInformation.id}<br/>
            {orderInfo.merchantInformation.orderId}<br/>
          </div>
        </div>
      </Card>
      <Card header={<h3>{t('cancellationPage.headers.fulfiller')}</h3>} isMinimal>
        <div className={'row'}>
          <div className={'col-md-6'}>
            {t('cancellationPage.fulfiller.fulfillerId')}<br/>
            {t('cancellationPage.fulfiller.name')}<br/>
            {t('cancellationPage.fulfiller.email')}<br/>
            {t('cancellationPage.fulfiller.phone')}<br/>
          </div>
          <div className={'col-md-6'}>
            {orderInfo.fulfiller.fulfillerId}<br/>
            {fulfillerInfo.name}<br/>
            {fulfillerInfo.email}<br/>
            {fulfillerInfo.phone}<br/>
          </div>
        </div>
      </Card>
    </div>
  </>;
};
